import { Bloc, Transition } from "@felangel/bloc";
import Service from "models/service";
import ServiceRepository from "repositories/service_repository";
import {
  ServicesEvent,
  LoadServices,
  AddService,
  EditService,
  DeleteService,
} from "./services_event";
import {
  ServicesState,
  ServicesLoaded,
  ServicesLoading,
  ServicesFailure,
} from "./services_state";

// A `CounterBloc` which handles converting `CounterEvent`s into `int`s.
export default class ServicesBloc extends Bloc<ServicesEvent, ServicesState> {
  serviceRepository: ServiceRepository;
  services: Array<Service> = [];

  constructor(serviceRepository: ServiceRepository) {
    super(new ServicesLoading());
    this.serviceRepository = serviceRepository;
  }

  async *mapEventToState(event: ServicesEvent) {
    if (event instanceof LoadServices) {
      yield* this.onLoadServices(event);
    } else if (event instanceof AddService) {
      yield* this.onAddService(event);
    } else if (event instanceof EditService) {
      yield* this.onEditService(event);
    } else if (event instanceof DeleteService) {
      yield* this.onDeleteService(event);
    } /*else if (event instanceof UpdateServicesUserLocation) {
			yield* this.onUpdateServicesUserLocation(event);
		}*/
  }

  async *onLoadServices(event: LoadServices) {
    try {
      yield new ServicesLoading();
      var servicesList: Array<Service> = await this.serviceRepository.getData();
      this.services = servicesList;

      yield new ServicesLoaded(this.services);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ServicesFailure(e);
      } else {
        yield new ServicesFailure(e.toString());
      }
    }
  }

  async *onAddService(event: AddService) {
    try {
      yield new ServicesLoading();
      this.services.push(event.service);

      yield new ServicesLoaded(this.services);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ServicesFailure(e);
      } else {
        yield new ServicesFailure(e.toString());
      }
    }
  }

  async *onEditService(event: EditService) {
    try {
      yield new ServicesLoading();
      this.services = this.services.map((service: Service) => {
        if (service.id == event.service.id) {
          service = event.service;
        }
        return service;
      });

      yield new ServicesLoaded(this.services);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ServicesFailure(e);
      } else {
        yield new ServicesFailure(e.toString());
      }
    }
  }

  async *onDeleteService(event: DeleteService) {
    try {
      yield new ServicesLoading();
      this.services = this.services.filter((service: Service) => {
        return service.id != event.serviceId;
      });

      yield new ServicesLoaded(this.services);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ServicesFailure(e);
      } else {
        yield new ServicesFailure(e.toString());
      }
    }
  }

  // Called whenever an `event` is added.
  onEvent(event: ServicesEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}



// Copy code above and adapt it to companies

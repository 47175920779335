import Curiosity from "models/curiosity";

export abstract class CuriositiesEvent {}

export class LoadCuriosities extends CuriositiesEvent {
  public toString = (): string => `LoadCuriosities`;
}

export class AddCuriosity extends CuriositiesEvent {
  curiosity: Curiosity;
  constructor(curiosity: Curiosity) {
    super();
    this.curiosity = curiosity;
  }

  public toString = (): string => `AddCuriosity`;
}

export class EditCuriosity extends CuriositiesEvent {
  curiosity: Curiosity;
  constructor(curiosity: Curiosity) {
    super();
    this.curiosity = curiosity;
  }

  public toString = (): string => `EditCuriosity`;
}

export class DeleteCuriosity extends CuriositiesEvent {
  curiosityId: number;
  constructor(curiosityId: number) {
    super();
    this.curiosityId = curiosityId;
  }

  public toString = (): string => `DeleteCuriosity`;
}

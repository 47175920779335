import React from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

export default class GeneralHelper {
  static warningToast(text: String) {
    toast.error(text as any, {
      position: "top-right",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
        </svg>
      ),
      duration: 3000,
    });
  }

  static successToast(text: String) {
    toast.success(text as any, {
      position: "top-right",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
      ),
      duration: 3000,
    });
  }

  static parseDoubleOrNull(value: any): number {
    if (value == null) return undefined as any;
    if (typeof value === "string" || value instanceof String) return value != undefined ? parseFloat(value as string) : (undefined as any);
    if (typeof value === "number" || value instanceof Number) return value != undefined ? value : (undefined as any);
    return undefined as any;
  }

  static parseIntOrNull(value: any): number {
    if (value == null) return undefined as any;

    if (typeof value === "string" || value instanceof String) return value != undefined ? parseInt(value as string) : (undefined as any);
    if (typeof value === "number" || value instanceof Number) return value != undefined ? value.toFixed(0) : (undefined as any);
    return undefined as any;
  }

  static avoidNullOrEmptyString(text: string): string {
    if (text != null && text != undefined && text.trim() != "") {
      return text;
    }
    return undefined as any;
  }

  static truncateText(text: string, length: number): string {
    // Vérifie si la longueur de la chaîne est supérieure à length
    if (text.length > length) {
      // Tronque la chaîne à 60 caractères et ajoute "..." à la fin
      const truncatedText = text.substring(0, length) + "...";
      return truncatedText;
    }

    return text;
  }

  static cleanHtmlString(input: string): string {
    const temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = input;

    // Supprime les balises <script> pour des raisons de sécurité
    const scripts = temporaryElement.getElementsByTagName("script");
    for (let i = scripts.length - 1; i >= 0; i--) {
      scripts[i].parentNode?.removeChild(scripts[i]);
    }

    // Retourne le contenu nettoyé
    return temporaryElement.textContent || temporaryElement.innerText || "";
  }

  static calculateDaysSince(creationDate: Date): string {
    const currentDate: Date = new Date();
    const diffTime: number = currentDate.getTime() - creationDate.getTime();
    const diffDays: number = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return "aujourd'hui";
    } else if (diffDays === 1) {
      return "hier";
    } else {
      return `${diffDays} jours`;
    }
  }

  static isIos() {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  static validateEmail(email: string): boolean {
    const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regexEmail.test(email);
  }
}

import { createContext } from "react";

import AuthenticationBloc from "blocs/authentication_bloc/authentication_bloc";
import Service from "models/service";
import ServicesBloc from "blocs/services_bloc/services_bloc";
import CompanyCategoriesBloc from "blocs/company_categories_bloc/company_categories_bloc";
import CuriositiesBloc from "blocs/curiosities_bloc/curiosities_bloc";
import CuriosityCategoriesBloc from "blocs/curiosity_categories_bloc/curiosity_categories_bloc";
import EventCategoriesBloc from "blocs/event_categories_bloc/event_categories_bloc";
import EventsBloc from "blocs/events_bloc/events_bloc";
import ItinerariesBloc from "blocs/itineraries_bloc/itineraries_bloc";
import ItineraryCategoriesBloc from "blocs/itinerary_categories_bloc/itinerary_categories_bloc";
import LocalProductCategoriesBloc from "blocs/local_product_categories_bloc/local_product_categories_bloc";
import LocalProductsBloc from "blocs/local_products_bloc/local_products_bloc";
import MerchantCategoriesBloc from "blocs/merchant_categories_bloc/merchant_categories_bloc";
import MerchantsBloc from "blocs/merchants_bloc/merchants_bloc";
import MunicipalitiesBloc from "blocs/municipalities_bloc/municipalities_bloc";
import NewsBloc from "blocs/news_bloc/news_bloc";
import NewsCategoriesBloc from "blocs/news_categories_bloc/news_categories_bloc";
import NotificationsBloc from "blocs/notifications_bloc/notifications_bloc";
import ServiceCategoriesBloc from "blocs/service_categories_bloc/service_categories_bloc";
import CompaniesBloc from "blocs/companies_bloc/companies_bloc";

type blocsContextValueType = {
  authenticationBloc: AuthenticationBloc;
  servicesBloc: ServicesBloc;
  companiesBloc: CompaniesBloc;
  companyCategoriesBloc: CompanyCategoriesBloc;
  curiositiesBloc: CuriositiesBloc;
  curiosityCategoriesBloc: CuriosityCategoriesBloc;
  eventCategoriesBloc: EventCategoriesBloc;
  eventsBloc: EventsBloc;
  itinerariesBloc: ItinerariesBloc;
  itineraryCategoriesBloc: ItineraryCategoriesBloc;
  localProductCategoriesBloc: LocalProductCategoriesBloc;
  localProductsBloc: LocalProductsBloc;
  merchantCategoriesBloc: MerchantCategoriesBloc;
  merchantsBloc: MerchantsBloc;
  municipalitiesBloc: MunicipalitiesBloc;
  newsBloc: NewsBloc;
  newsCategoriesBloc: NewsCategoriesBloc;
  notificationsBloc: NotificationsBloc;
  serviceCategoriesBloc: ServiceCategoriesBloc;
};

const blocsContextValue: blocsContextValueType = {
  authenticationBloc: null as any,
  servicesBloc: null as any,
  companiesBloc: null as any,
  companyCategoriesBloc: null as any,
  curiositiesBloc: null as any,
  curiosityCategoriesBloc: null as any,
  eventCategoriesBloc: null as any,
  eventsBloc: null as any,
  itinerariesBloc: null as any,
  itineraryCategoriesBloc: null as any,
  localProductCategoriesBloc: null as any,
  localProductsBloc: null as any,
  merchantCategoriesBloc: null as any,
  merchantsBloc: null as any,
  municipalitiesBloc: null as any,
  newsBloc: null as any,
  newsCategoriesBloc: null as any,
  notificationsBloc: null as any,
  serviceCategoriesBloc: null as any,
};

export const BlocsContext = createContext(blocsContextValue);

import { BlocBuilder } from "@felangel/react-bloc";
import { MunicipalitiesLoaded, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import { LoadServices } from "blocs/services_bloc/services_event";
import { ServicesFailure, ServicesLoaded, ServicesLoading, ServicesState } from "blocs/services_bloc/services_state";
import ArchiveFilter from "components/data_components/archive_filter";
import ServiceCard from "components/data_components/service_card";
import { SortTypes } from "constants/constants";
import { BlocsContext } from "contexts/blocs_context";
import useQuery from "helpers/use_query";
import BaseScreen from "layouts/base";
import GenericFilters from "models/filters/generic_filters";
import Service from "models/service";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Link, useLocation, useParams } from "react-router-dom";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const ServicesScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const params = useParams();
  let query = useQuery();

  const municipalityIdParams = query.get("municipalityId");
  const [servicesFilters, setServicesFilters] = useState<GenericFilters>(
    new GenericFilters({
      municipalityId: municipalityIdParams != undefined ? parseInt(municipalityIdParams) : undefined,
      sort: SortTypes.DATE_DESC,
    })
  );

  const [servicesState, setServicesState] = useState<ServicesState>(new ServicesLoading());

  useEffect(() => {
    setServicesState(blocsContext.servicesBloc.state);
    const subscription = blocsContext.servicesBloc.listen(setServicesState);

    return () => {
      subscription.unsubscribe();
    };
  });

  // const service: Service | undefined = undefined;

  if (servicesState instanceof ServicesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (servicesState instanceof ServicesFailure) {
    return (
      <BaseScreen>
        <div>Erreur</div>
        <div className="btn btn-primary" onClick={() => blocsContext.servicesBloc.add(new LoadServices())}>
          Recharger
        </div>
      </BaseScreen>
    );
  }

  if (servicesState instanceof ServicesLoaded) {
    const services: Service[] = servicesFilters.filter(servicesState.services).filter((service) => service.isExpired() == false);

    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="d-flex flex-stack flex-wrap mb-6 mt-20">
              <div className="d-flex">
                <h3 className="fs-1 fw-800">Services</h3>
              </div>

              <div className="d-flex flex-row align-items-center">
                {servicesFilters.municipalityId != undefined && (
                  <BlocBuilder
                    bloc={blocsContext.municipalitiesBloc}
                    builder={(state: MunicipalitiesState) => {
                      if (state instanceof MunicipalitiesLoaded) {
                        return (
                          <div className="badge badge-secondary fw-bolder me-2 activeService">
                            <div
                              className="cursor-pointer me-2"
                              onClick={() => {
                                var newServicesFilters = servicesFilters.clone();
                                newServicesFilters.municipalityId = undefined;
                                setServicesFilters(newServicesFilters);
                              }}
                            >
                              <i className="fa fa-xmark"></i>
                            </div>
                            Commune : {state.municipalities.find((municipality) => municipality.id == servicesFilters.municipalityId)?.name}
                          </div>
                        );
                      }
                      return <></>;
                    }}
                  />
                )}

                <ArchiveFilter
                  filters={servicesFilters}
                  onSetFilters={(filters: GenericFilters) => {
                    setServicesFilters(new GenericFilters({ ...filters }));
                  }}
                  dataType="Service"
                />
              </div>
            </div>

            {services.length == 0 && (
              <div className="card-px text-center py-20 my-20">
                <h2 className="fs-2x fw-bold mb-8">Aucun élément trouvé</h2>
                <p className="text-gray-400 fs-4 fw-semibold mb-10">Essayez d'ajuster les filtres pour trouver ce que vous cherchez</p>
                {/* <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add Customer</a> */}
              </div>
            )}
            {services.length != 0 && (
              <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
                {services.map((service) => (
                  <ServiceCard key={service.id} service={service} />
                ))}
              </div>
            )}
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(service)
};

export default withTranslation("common")(ServicesScreen);

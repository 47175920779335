import { Bloc, Transition } from "@felangel/bloc";
import UniversalCategory from "models/universal_category";
import EventCategoriesRepository from "repositories/event_category_repository"; // Adapter l'import en fonction de ton repository
import {
  EventCategoriesEvent,
  LoadEventCategories,
} from "./event_categories_event"; // Adapter les imports en fonction de tes événements
import {
  EventCategoriesState,
  EventCategoriesLoaded,
  EventCategoriesLoading,
  EventCategoriesFailure,
} from "./event_categories_state"; // Adapter les imports en fonction des états

export default class EventCategoriesBloc extends Bloc<EventCategoriesEvent, EventCategoriesState> {
  eventRepository: EventCategoriesRepository; // Adapter le nom du repository en conséquence
  categories: Array<UniversalCategory> = [];

  constructor(eventRepository: EventCategoriesRepository) {
    super(new EventCategoriesLoading()); // Adapter l'état initial en conséquence
    this.eventRepository = eventRepository; // Adapter le nom du repository en conséquence
  }

  async *mapEventToState(event: EventCategoriesEvent) {
    if (event instanceof LoadEventCategories) { 
      yield* this.onLoadEventCategories(event); 
    }
  }

  async *onLoadEventCategories(event: LoadEventCategories) { 
    try {
      yield new EventCategoriesLoading(); // Adapter l'état en conséquence
      var categoriesList: Array<UniversalCategory> = await this.eventRepository.getData(); // Adapter le nom du repository en conséquence
      this.categories = categoriesList;

      yield new EventCategoriesLoaded(this.categories); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new EventCategoriesFailure(e); // Adapter l'état en conséquence
      } else {
        yield new EventCategoriesFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }

  onEvent(event: EventCategoriesEvent): void { 
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event =", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition =", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error =", error);
  }
}

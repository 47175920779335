import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { BlocsContext } from "contexts/blocs_context";
import { useNavigate } from "react-router-dom";
import { CuriositiesLoaded, CuriositiesLoading, CuriositiesState } from "blocs/curiosities_bloc/curiosities_state";
import Curiosity from "models/curiosity";
import { CompaniesLoaded, CompaniesLoading, CompaniesState } from "blocs/companies_bloc/companies_state";
import { EventsLoaded, EventsLoading, EventsState } from "blocs/events_bloc/events_state";
import { MerchantsLoaded, MerchantsLoading, MerchantsState } from "blocs/merchants_bloc/merchants_state";
import { ServicesLoaded, ServicesLoading, ServicesState } from "blocs/services_bloc/services_state";
import Map from "components/map/map";
import Marker from "components/map/marker";
import InfoWindow from "components/map/info_window";
import Company from "models/company";
import Merchant from "models/merchant";
import Service from "models/service";
import GeneralHelper from "helpers/general_helper";

interface Props extends WithTranslation {}

const HomeSectionMap: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [companiesState, setCompaniesState] = useState<CompaniesState>(new CompaniesLoading());
  const [curiositiesState, setCuriositiesState] = useState<CuriositiesState>(new CuriositiesLoading());
  const [eventsState, setEventsState] = useState<EventsState>(new EventsLoading());
  const [merchantsState, setMerchantsState] = useState<MerchantsState>(new MerchantsLoading());
  const [servicesState, setServicesState] = useState<ServicesState>(new ServicesLoading());

  const [selectedMarkerData, setSelectedMarkerData] = useState<any>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    setCompaniesState(blocsContext.companiesBloc.state);
    const companiesSubscription = blocsContext.companiesBloc.listen(setCompaniesState);
    setCuriositiesState(blocsContext.curiositiesBloc.state);
    const curiositiesSubscription = blocsContext.curiositiesBloc.listen(setCuriositiesState);
    setEventsState(blocsContext.eventsBloc.state);
    const eventsSubscription = blocsContext.eventsBloc.listen(setEventsState);
    setMerchantsState(blocsContext.merchantsBloc.state);
    const merchantsSubscription = blocsContext.merchantsBloc.listen(setMerchantsState);
    setServicesState(blocsContext.servicesBloc.state);
    const servicesSubscription = blocsContext.servicesBloc.listen(setServicesState);

    return () => {
      companiesSubscription.unsubscribe();
      curiositiesSubscription.unsubscribe();
      eventsSubscription.unsubscribe();
      merchantsSubscription.unsubscribe();
      servicesSubscription.unsubscribe();
    };
  });

  const getMarkerDataType = (markerData: any): string => {
    if (markerData instanceof Company) {
      return "company";
    } else if (markerData instanceof Curiosity) {
      return "curiosity";
    } else if (markerData instanceof Event) {
      return "event";
    } else if (markerData instanceof Merchant) {
      return "merchant";
    } else if (markerData instanceof Service) {
      return "service";
    }
    return "";
  };

  return (
    <>
      <div className="row gy-0 gx-5 row d-flex justify-content-start mb-4 mb-xl-6 mt-10 mt-xl-20">
        <div className="col-12">
          <h3 className="fs-1 fw-700">Carte interactive</h3>
        </div>
      </div>
      <div className="ctaMap mb-15">
        <Map
          center={{ lat: 47.4, lng: 7.1 }}
          zoom={11}
          style={{}}
          onClick={() => {
            setSelectedMarkerData(undefined);
          }}
        >
          {curiositiesState instanceof CuriositiesLoaded &&
            curiositiesState.curiosities
              .filter((curiosity: Curiosity) => curiosity.latitude != null && curiosity.longitude != null)
              .map((curiosity: Curiosity) => (
                <Marker
                  key={curiosity.id}
                  position={{ lat: curiosity.latitude, lng: curiosity.longitude }}
                  icon={{ url: require("../../../assets/icons/pin.svg").default, fillColor: "#EB00FF", scaledSize: new google.maps.Size(35, 35) }}
                  onClick={() => {
                    setSelectedMarkerData(curiosity);
                  }}
                />
              ))}

          {companiesState instanceof CompaniesLoaded &&
            companiesState.companies
              .filter((company: any) => company.latitude != null && company.longitude != null)
              .map((company: any) => (
                <Marker
                  key={company.id}
                  position={{ lat: company.latitude, lng: company.longitude }}
                  icon={{ url: require("../../../assets/icons/pin.svg").default, fillColor: "#EB00FF", scaledSize: new google.maps.Size(35, 35) }}
                  onClick={() => {
                    setSelectedMarkerData(company);
                  }}
                />
              ))}

          {eventsState instanceof EventsLoaded &&
            eventsState.events
              .filter((event: any) => event.latitude != null && event.longitude != null)
              .map((event: any) => (
                <Marker
                  key={event.id}
                  position={{ lat: event.latitude, lng: event.longitude }}
                  icon={{ url: require("../../../assets/icons/pin.svg").default, fillColor: "#EB00FF", scaledSize: new google.maps.Size(35, 35) }}
                  onClick={() => {
                    setSelectedMarkerData(event);
                  }}
                />
              ))}

          {merchantsState instanceof MerchantsLoaded &&
            merchantsState.merchants
              .filter((merchant: any) => merchant.latitude != null && merchant.longitude != null)
              .map((merchant: any) => (
                <Marker
                  key={merchant.id}
                  position={{ lat: merchant.latitude, lng: merchant.longitude }}
                  icon={{ url: require("../../../assets/icons/pin.svg").default, fillColor: "#EB00FF", scaledSize: new google.maps.Size(35, 35) }}
                  onClick={() => {
                    setSelectedMarkerData(merchant);
                  }}
                />
              ))}

          {/* {servicesState instanceof ServicesLoaded &&
            servicesState.services.map((service: any) => (
              <Marker
                key={service.id}
                position={{ lat: service.latitude, lng: service.longitude }}
                icon={{ url: require("../../../assets/icons/pin.svg").default, fillColor: "#EB00FF", scaledSize: new google.maps.Size(35, 35) }}
                onClick={() => {
                  setSelectedMarkerData(service);
                }}
              />
            ))} */}

          {/* {popupInfo && (<Popup store={popupInfo} style={{ position: 'absolute', top: 0, left: 0, width: '200px' }} />)} */}

          {selectedMarkerData != undefined && (
            <InfoWindow
              // children={<div>test</div>}
              content={
                '<div class="singleItemTags map"><div class="tagsWrapper"><div class="singleItemCategory">' +
                selectedMarkerData.category.name +
                '</div><div class="singleItemMunicipality">' +
                selectedMarkerData.municipality.name +
                "</div></div></div>" +
                '<div class=""><div class="content-title mapTitleMarker"><div>' +
                selectedMarkerData.name +
                "</div></div>" +
                (selectedMarkerData.address ?? "") +
                '<p class="button-wrapper"><a href="/' +
                getMarkerDataType(selectedMarkerData) +
                "/" +
                selectedMarkerData.id +
                '"><span class="more-link">En savoir plus</span></a></p></div>'
              }
              position={{ lat: selectedMarkerData.latitude, lng: selectedMarkerData.longitude }}
              onCloseClick={() => {
                setSelectedMarkerData(undefined);
                // alert("close");
              }}
              pixelOffset={new google.maps.Size(0, -35)}
            />
            //   {selectedMarkerData.name}
            // </InfoWindow>
          )}
        </Map>
        {/* <div className="map" ref={mapRef}></div> */}
        {GeneralHelper.isIos() == false && (
          <div
            className="btnSeeMap"
            onClick={() => {
              var mapSelector = document.querySelector(".gm-style");
              mapSelector?.requestFullscreen();
              // navigate("/map");
            }}
          >
            Voir en plein écran ⛶
          </div>
        )}
      </div>
    </>
  );
};

export default withTranslation("common")(HomeSectionMap);

import React from "react";


interface InfoWindowProps extends google.maps.InfoWindowOptions {
  onCloseClick?: () => void;
}

const InfoWindow: React.FC<InfoWindowProps> = ({ onCloseClick, ...options }) => {
  const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();

  React.useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(new google.maps.InfoWindow());
    }
    if (infoWindow) {
      infoWindow.addListener("closeclick", () => {
        if (onCloseClick != undefined) onCloseClick();
      });
    }

    // remove marker from map on unmount
    return () => {
      if (infoWindow) {
        infoWindow.close();
      }
    };
  }, [infoWindow]);

  React.useEffect(() => {
    if (infoWindow) {
      infoWindow.setOptions(options);
    }
  }, [infoWindow, options]);

  return null;
};

export default InfoWindow;

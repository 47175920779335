import UniversalCategory from "models/universal_category";

export abstract class NewsCategoriesState {} 

export class NewsCategoriesLoading extends NewsCategoriesState {} 

export class NewsCategoriesLoaded extends NewsCategoriesState {
  categories: Array<UniversalCategory>; // Adapter le nom du modèle en conséquence
  constructor(categories: Array<UniversalCategory>) {
    super();
    this.categories = categories;
  }

  public toString = (): string => {
    return `NewsCategoriesLoaded ${this.categories.length} categories`; // Adapter le nom en conséquence
  };
}

export class NewsCategoriesFailure extends NewsCategoriesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `NewsCategoriesLoadingFailed { errorMessage: ${this.errorMessage} }`; // Adapter le nom en conséquence
  };
}

import CreateServiceContent from "components/modals/create_service_content/create_service_content";
import { useGeneralModal } from "components/modals/general_modal/general_modal_context";
import React from "react";
import { Modal } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
// import "./footer.scss";
import { Link } from "react-router-dom";

interface Props extends WithTranslation {
  // actionButtons: JSX.Element[] | JSX.Element;
}

const Footer: React.FC<Props> = (props: Props) => {
  const generalModal = useGeneralModal();

  return (
    <footer id="kt_footer" className="footer d-flex flex-column fs-6">
      <div className="bgFooterClass">
        <div className="container-xxl py-20 text-white">
          <div className="row">
            <div className="col-12 col-lg-5 ">
              <div className="fs-3 fw-bold mb-3">Région Haute-Ajoie</div>
              <div className="fs-6 lo-text mb-2">
                Ensemble, vers une région connectée ! Région Haute-Ajoie est une plateforme touristique, d'échange et d'entraide à destination des habitants de la région ainsi que des touristes.
              </div>
              <a href="https://www.region-hauteajoie.ch" target="_blank">
                www.region-hauteajoie.ch
              </a>
            </div>
            <div className="col-12 col-lg-2 mt-10 mt-lg-0 offset-lg-1">
              <div className="fs-3 fw-bold mb-3">Échange et entraide</div>
              <ul className="nav d-flex flex-column">
                <li className="nav-item">
                  <Link to="/echange-entraide" className="text-white lo-text">
                    Liste des annonces
                  </Link>
                </li>
                <li>
                  <Link to="" className="text-white lo-text">
                    Catégories d'annonces
                  </Link>
                </li>
                <li>
                  <a
                    onClick={() => {
                      generalModal.setContent(<CreateServiceContent />);
                      generalModal.openModal();
                    }}
                    className="text-white lo-text cursor-pointer"
                  >
                    Publier une annonce
                  </a>
                </li>
                <li>
                  <Link to="/login" className="text-white lo-text">
                    Connexion / Inscription
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 mt-10 mt-lg-0">
              <div className="fs-3 fw-bold mb-3">Tourisme et loisirs</div>
              <ul className="nav d-flex flex-column">
                <li className="nav-item">
                  <Link to="/curiosities" className="text-white lo-text">
                    Curiosités
                  </Link>
                </li>
                <li>
                  <Link to="/merchants" className="text-white lo-text">
                    Commerçants
                  </Link>
                </li>
                <li>
                  <Link to="/itineraries" className="text-white lo-text">
                    Chemins/Parcours
                  </Link>
                </li>
                <li>
                  <Link to="/events" className="text-white lo-text">
                    Evénement
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 mt-10 mt-lg-0">
              <div className="fs-3 fw-bold mb-3">Vie locale</div>
              <ul className="nav d-flex flex-column">
                <li className="nav-item">
                  <Link to="/municipalities" className="text-white lo-text">
                    Communes participatives
                  </Link>
                </li>
                <li>
                  <Link to="/companies" className="text-white lo-text">
                    Associations et sociétés
                  </Link>
                </li>
                <li>
                  <Link to="/local-products" className="text-white lo-text">
                    Produits du terroir
                  </Link>
                </li>
                <li>
                  <Link to="/news" className="text-white lo-text">
                    Actualités des communes
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-dark">
        <div className="container-xxl py-3">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center footerBanner">
              <span className="text-white text-center">&copy; {new Date().getFullYear()} Région Haute-Ajoie - SmartVillage</span>
              <span className="text-white text-center">
                Site internet développé par&nbsp;
                <a href="https://www.novadev.ch/" target="_blank">
                  Novadev Sàrl
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withTranslation("common")(Footer);

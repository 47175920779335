import UniversalCategory from "models/universal_category";

export abstract class CuriosityCategoriesState {} 

export class CuriosityCategoriesLoading extends CuriosityCategoriesState {} 

export class CuriosityCategoriesLoaded extends CuriosityCategoriesState {
  categories: Array<UniversalCategory>; // Adapter le nom du modèle en conséquence
  constructor(categories: Array<UniversalCategory>) {
    super();
    this.categories = categories;
  }

  public toString = (): string => {
    return `CuriosityCategoriesLoaded ${this.categories.length} categories`; // Adapter le nom en conséquence
  };
}

export class CuriosityCategoriesFailure extends CuriosityCategoriesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `CuriosityCategoriesLoadingFailed { errorMessage: ${this.errorMessage} }`; // Adapter le nom en conséquence
  };
}

import { LoadLocalProducts } from "blocs/local_products_bloc/local_products_event";
import { LocalProductsFailure, LocalProductsLoaded, LocalProductsLoading, LocalProductsState } from "blocs/local_products_bloc/local_products_state";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import { Link, useNavigate } from "react-router-dom";
import LocalProduct from "models/local_product";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import imgGoogleMaps from "assets/images/google-maps.png";
import imgInnerMap from "assets/images/marker_activite.png";
import imgIconDesc from "assets/images/bubble.png";
import GeneralHelper from "helpers/general_helper";
import defaultPic from "assets/images/default_pic.png";
import ImagesGallery from "components/image_gallery";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const LocalProductScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [localProductsState, setLocalProductsState] = useState<LocalProductsState>(new LocalProductsLoading());

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setLocalProductsState(blocsContext.localProductsBloc.state);
    const subscription = blocsContext.localProductsBloc.listen(setLocalProductsState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const localProduct: LocalProduct | undefined = undefined;

  if (localProductsState instanceof LocalProductsLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (localProductsState instanceof LocalProductsFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div className="btn btn-primary mt-6" onClick={() => blocsContext.localProductsBloc.add(new LoadLocalProducts())}>
              Recharger
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (localProductsState instanceof LocalProductsLoaded) {
    const localProduct: LocalProduct = localProductsState.localProducts.find((localProduct) => localProduct.id.toString() == params.id) as LocalProduct;
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <a className="cursor-pointer backLinkSingle" onClick={() => navigate(-1)}>
                  ← Retour
                </a>
                <div className="singleItem">
                  <ImagesGallery images={localProduct.responsiveImages} />
                  <div className="singleItemInnerContent">
                    <div className="singleItemTags">
                      <div className="tagsWrapper">
                        <div className="singleItemCategory">{localProduct.category.name}</div>
                        <div className="singleItemMunicipality">{localProduct.municipality.name}</div>
                      </div>
                      {/* <div className="singleItemShareLink">
                        <a href="#">⭷ Partager</a>
                      </div> */}
                    </div>
                    <h3 className="fs-1 fw-600 singleItemTitle">{localProduct.name}</h3>

                    <div className="singleItemDescription">
                      <img src={imgIconDesc} />
                      <div className="descTitle">Description</div>
                      {/* {GeneralHelper.cleanHtmlString(localProduct.description || 'Aucune description disponible.')} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: localProduct.description || "Aucune description disponible.",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(localProduct)
};

export default withTranslation("common")(LocalProductScreen);

import { Bloc, Transition } from "@felangel/bloc";
import UniversalCategory from "models/universal_category";
import MerchantCategoriesRepository from "repositories/merchant_category_repository"; // Adapter l'import en fonction de ton repository
import {
  MerchantCategoriesEvent,
  LoadMerchantCategories,
} from "./merchant_categories_event"; // Adapter les imports en fonction de tes événements
import {
  MerchantCategoriesState,
  MerchantCategoriesLoaded,
  MerchantCategoriesLoading,
  MerchantCategoriesFailure,
} from "./merchant_categories_state"; // Adapter les imports en fonction des états

export default class MerchantCategoriesBloc extends Bloc<MerchantCategoriesEvent, MerchantCategoriesState> {
  merchantRepository: MerchantCategoriesRepository; // Adapter le nom du repository en conséquence
  categories: Array<UniversalCategory> = [];

  constructor(merchantRepository: MerchantCategoriesRepository) {
    super(new MerchantCategoriesLoading()); // Adapter l'état initial en conséquence
    this.merchantRepository = merchantRepository; // Adapter le nom du repository en conséquence
  }

  async *mapEventToState(event: MerchantCategoriesEvent) {
    if (event instanceof LoadMerchantCategories) { 
      yield* this.onLoadMerchantCategories(event); 
    }
  }

  async *onLoadMerchantCategories(event: LoadMerchantCategories) { 
    try {
      yield new MerchantCategoriesLoading(); // Adapter l'état en conséquence
      var categoriesList: Array<UniversalCategory> = await this.merchantRepository.getData(); // Adapter le nom du repository en conséquence
      this.categories = categoriesList;

      yield new MerchantCategoriesLoaded(this.categories); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new MerchantCategoriesFailure(e); // Adapter l'état en conséquence
      } else {
        yield new MerchantCategoriesFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }

  onEvent(event: MerchantCategoriesEvent): void { 
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event =", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition =", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error =", error);
  }
}

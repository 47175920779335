import GeneralHelper from "helpers/general_helper";
import { ImageSizes } from "constants/constants";

export default class ResponsiveImage {
  originalUrl: string | undefined;
  bigUrl: string | undefined;
  mediumUrl: string | undefined;
  smallUrl: string | undefined;

  constructor({ originalUrl, bigUrl, mediumUrl, smallUrl }: any /*NamedParameters*/) {
    this.originalUrl = originalUrl;
    this.mediumUrl = mediumUrl;
    this.bigUrl = bigUrl;
    this.smallUrl = smallUrl;
  }

  static fromJson(map: Record<string, any>): ResponsiveImage {
    return new ResponsiveImage({
      originalUrl: GeneralHelper.avoidNullOrEmptyString(map["url"]),
      bigUrl: GeneralHelper.avoidNullOrEmptyString(map["big"]),
      mediumUrl: GeneralHelper.avoidNullOrEmptyString(map["medium"]),
      smallUrl: GeneralHelper.avoidNullOrEmptyString(map["small"]),
    });
  }

  public copyWith({ originalUrl, bigUrl, mediumUrl, smallUrl }: any): ResponsiveImage {
    return new ResponsiveImage({
      originalUrl: originalUrl ?? this.originalUrl,
      bigUrl: bigUrl ?? this.bigUrl,
      mediumUrl: mediumUrl ?? this.mediumUrl,
      smallUrl: smallUrl ?? this.smallUrl,
    });
  }

  public getUrlFromSize(size: ImageSizes): string | undefined {
    if (size === ImageSizes.original) {
      return this.originalUrl ?? this.bigUrl ?? this.mediumUrl ?? this.smallUrl;
    }
    if (size === ImageSizes.big) {
      return this.bigUrl ?? this.originalUrl ?? this.mediumUrl ?? this.smallUrl;
    }
    if (size === ImageSizes.medium) {
      return this.mediumUrl ?? this.bigUrl ?? this.originalUrl ?? this.smallUrl;
    }
    if (size === ImageSizes.small) {
      return this.smallUrl ?? this.mediumUrl ?? this.bigUrl ?? this.originalUrl;
    }
  }
}

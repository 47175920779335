import Municipality from "models/municipality";
import Config from "config/config";
import axios from "axios";

export default class MunicipalityRepository {
  static _municipalitiesEndpoint: string = "municipalities";
  static _municipalitiesDataKey: string = "municipalities_data";

  async getData(): Promise<Array<Municipality>> {
    var data: any = await axios.get(
      Config.REST_API_URL +
        "/" +
        MunicipalityRepository._municipalitiesEndpoint,
      {
        headers: {
          Accept: "application/json",
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToMunicipalities(data.data.municipalities);
  }

  dataToMunicipalities(data: any): Array<Municipality> {
    var municipalities: Array<Municipality> = [];
    for (var i = 0; i < data.length; i++) {
      var municipality: Municipality | undefined = Municipality.fromJson(
        data[i]
      );
      if (municipality != undefined) municipalities.push(municipality);
    }
    return municipalities;
  }
}

import LocalProduct from "models/local_product";

export abstract class LocalProductsState {}

// export class LocalProductsInitial extends LocalProductsState {}

export class LocalProductsLoading extends LocalProductsState {}

export class LocalProductsLoaded extends LocalProductsState {
  localProducts: Array<LocalProduct>;
  constructor(localProducts: Array<LocalProduct>) {
    super();
    this.localProducts = localProducts;
  }

  public toString = (): string => {
    return `LocalProductsLoaded ${this.localProducts.length} local products`;
  };
}

export class LocalProductsFailure extends LocalProductsState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `LocalProductsLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}

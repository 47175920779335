import React, { useState, useContext } from "react";
import axios from "axios";
import BaseScreen from "layouts/base";
import { WithTranslation, withTranslation } from "react-i18next";
import { BlocsContext } from "contexts/blocs_context";
import { AuthenticationLoggedIn, AuthenticationLoggedOut } from "blocs/authentication_bloc/authentication_event";
import AuthenticationData from "models/authentication_data";
import AuthenticationService from "services/authentication";
import User from "models/user";
import { AuthenticationAuthenticated } from "blocs/authentication_bloc/authentication_state";
// import "./login.scss";

import UsernameIcon from "assets/icons/mail.svg";
import PasswordIcon from "assets/icons/padlock.svg";
import { Link, useNavigate } from "react-router-dom";

import authBackgroundImage from "assets/images/home_section_1_background.jpg";


interface Props extends WithTranslation { }

const ForgotPasswordScreen: React.FC<Props> = ({ t }) => {
  // const blocsContext = useContext(BlocsContext);
  const navigate = useNavigate();
  const blocsContext = useContext(BlocsContext);
  const [authenticationBlocState, setAuthenticationBlocState] = React.useState(blocsContext.authenticationBloc.state);

  React.useEffect(() => {
    if (authenticationBlocState instanceof AuthenticationAuthenticated) {
      navigate({ pathname: "/" }, { replace: true });
    }

    const subscription = blocsContext.authenticationBloc.listen(setAuthenticationBlocState);
    return () => {
      subscription.unsubscribe();
    };
  }, [blocsContext.authenticationBloc, authenticationBlocState]);

  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);



  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // const response = await axios.post(
      //   "api/login",
      //   JSON.stringify(userData)
      // );
      setLoading(true);

      const response = await AuthenticationService.forgotPassword(email);

      if (response.status == 200) {
        setSuccess(true);
        setLoading(false);
        return;
      }

      setError(t("screen.login.errors.generalError"));
      setLoading(false);
    } catch (error: any) {
      console.log("error", error);
      setError(t("screen.login.errors.generalError"));
      setLoading(false);
    }
  };




  return (
    <div className="d-flex flex-column flex-root auth-bg">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
          <div className="d-flex flex-column justify-content-center">
            <Link to="/login" className="d-flex text-muted">
              <i className="bi bi-arrow-left fs-2"></i>
              <div className="ms-4">Retour à la connexion</div>
            </Link>
          </div>
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <div className="w-lg-500px p-10">
              {success && <div className="text-success text-center">

                
                <div className="text-dark fw-bolder mb-3">Un lien vous permettant de réinitialiser votre mot de passe a été envoyé par email.</div>

                <div className="d-grid mt-10">
                  <Link
                    to="/login"
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-primary"
                  >
                    <span className="indicator-label">Retour à la connexion</span>
                  </Link>
                </div>

              </div>}
              {!success &&
                <form className="form w-100" noValidate={true} id="kt_sign_in_form" data-kt-redirect-url="../../demo11/dist/index.html" action="#">
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Mot de passe perdu</h1>

                    <div className="text-gray-500 fw-semibold fs-6">
                      Entrez votre adresse email pour réinitialiser votre mot de passe
                    </div>
                  </div>


                  <div className="fv-row mb-8">
                    <input type="text" name="email" placeholder={"Adresse email"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                    }} value={email} required className="form-control bg-transparent" />
                  </div>



                  <div className="d-grid mb-10">
                    <button
                      type="submit"
                      id="kt_sign_in_submit"
                      className="btn btn-primary"
                      onClick={handleLogin}
                      data-kt-indicator={loading ? "on" : "off"}
                      disabled={loading || email == ""}
                    >
                      {!loading && <span className="indicator-label">Réinitialiser</span>}
                      {loading && (
                        <span className="indicator-progress">
                          Veuillez patienter...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    {error && <div className="text-danger mt-5 text-center">{error}</div>}
                  </div>

                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Pas encore de compte ?{" "}
                    <Link to="/register" className="link-primary">
                      S'inscrire
                    </Link>
                  </div>
                </form>
              }
            </div>
          </div>

        </div>

        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{ backgroundImage: "url(" + authBackgroundImage + ")" }}>

        </div>
      </div>
    </div>
  );
};
export default withTranslation("common")(ForgotPasswordScreen);

import GeneralHelper from "helpers/general_helper";
import UniversalCategory from "./universal_category";
import Municipality from "./municipality";
import User from "./user";

export default class ServiceComment {
  id: number;
  comment: string;
  user: User;
  serviceId: number;
  parentId: number;
  children: Array<ServiceComment>;
  createdAt: Date;

  constructor(
    {
      id,
      comment,
      user,
      serviceId,
      parentId,
      children,
      createdAt,
    }: any /*NamedParameters*/
  ) {
    this.id = id;
    this.comment = comment;
    this.user = user;
    this.serviceId = serviceId;
    this.parentId = parentId;
    this.children = children;
    this.createdAt = createdAt;
  }

  static areIdentical(a: ServiceComment, b: ServiceComment): boolean {
    if (!a && !b) return true;
    if (!a || !b) return false;
    return a.id === b.id;
  }

  static fromJson(map: Record<string, any>): ServiceComment {
    return new ServiceComment({
      id: GeneralHelper.parseIntOrNull(map["id"]),
      comment: GeneralHelper.avoidNullOrEmptyString(map["comment"]),
      user: map["user"] != undefined ? User.fromJson(map["user"]) : undefined,
      serviceId: GeneralHelper.parseIntOrNull(map["service_id"]),
      parentId: GeneralHelper.parseIntOrNull(map["parent_id"]),
      children: map["children"]?.map((child: Record<string, any>) => ServiceComment.fromJson(child)),
      createdAt: new Date(map["created_at"]),
    });
  }

  public copyWith({
    id,
    comment,
    user,
    serviceId,
    parentId,
    children,
    createdAt,
  }: any): ServiceComment {
    return new ServiceComment({
      id: id ?? this.id,
      comment: comment ?? this.comment,
      user: user ?? this.user,
      serviceId: serviceId ?? this.serviceId,
      parentId: parentId ?? this.parentId,
      children: children ?? this.children,
      createdAt: createdAt ?? this.createdAt,
    });
  }

}

import { AuthenticationAuthenticated, AuthenticationInitial, AuthenticationState } from "blocs/authentication_bloc/authentication_state";
import { LoadCompanyCategories } from "blocs/company_categories_bloc/company_categories_event";
import { CompanyCategoriesFailure, CompanyCategoriesLoaded, CompanyCategoriesLoading, CompanyCategoriesState } from "blocs/company_categories_bloc/company_categories_state";
import { LoadCuriosityCategories } from "blocs/curiosity_categories_bloc/curiosity_categories_event";
import { CuriosityCategoriesFailure, CuriosityCategoriesLoaded, CuriosityCategoriesLoading, CuriosityCategoriesState } from "blocs/curiosity_categories_bloc/curiosity_categories_state";
import { LoadEventCategories } from "blocs/event_categories_bloc/event_categories_event";
import { EventCategoriesFailure, EventCategoriesLoaded, EventCategoriesLoading, EventCategoriesState } from "blocs/event_categories_bloc/event_categories_state";
import { ItinerariesFailure, ItinerariesLoaded, ItinerariesLoading } from "blocs/itineraries_bloc/itineraries_state";
import { LoadItineraryCategories } from "blocs/itinerary_categories_bloc/itinerary_categories_event";
import { ItineraryCategoriesFailure, ItineraryCategoriesLoaded, ItineraryCategoriesLoading, ItineraryCategoriesState } from "blocs/itinerary_categories_bloc/itinerary_categories_state";
import { LoadLocalProductCategories } from "blocs/local_product_categories_bloc/local_product_categories_event";
import {
  LocalProductCategoriesFailure,
  LocalProductCategoriesLoaded,
  LocalProductCategoriesLoading,
  LocalProductCategoriesState,
} from "blocs/local_product_categories_bloc/local_product_categories_state";
import { LoadMerchantCategories } from "blocs/merchant_categories_bloc/merchant_categories_event";
import { MerchantCategoriesFailure, MerchantCategoriesLoaded, MerchantCategoriesLoading, MerchantCategoriesState } from "blocs/merchant_categories_bloc/merchant_categories_state";
import { MunicipalitiesFailure, MunicipalitiesLoaded, MunicipalitiesLoading, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import { NewsCategoriesState } from "blocs/news_categories_bloc/news_categories_state";
import { ServiceCategoriesState } from "blocs/service_categories_bloc/service_categories_state";
import { LoadServices } from "blocs/services_bloc/services_event";
import { ServicesFailure, ServicesLoaded, ServicesLoading, ServicesState } from "blocs/services_bloc/services_state";
import MyServiceCard from "components/data_components/my_service_card";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import Service from "models/service";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import ItemSuggestionRepository from "repositories/item_suggestion_repository";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const SuggestItemScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);
  const navigate = useNavigate();

  // const [servicesState, setServicesState] = useState<NewsCategoriesState>(new ServicesLoading());

  const [municipalitiesState, setMunicipalitiesState] = useState<MunicipalitiesState>(new MunicipalitiesLoading());
  const [eventCategoriesState, setEventCategoriesState] = useState<EventCategoriesState>(new EventCategoriesLoading());
  const [companyCategoriesState, setCompanyCategoriesState] = useState<CompanyCategoriesState>(new CompanyCategoriesLoading());
  // const [servicesState, setServicesState] = useState<ServiceCategoriesState>(new ServicesLoading());
  const [merchantCategoriesState, setMerchantCategoriesState] = useState<MerchantCategoriesState>(new MerchantCategoriesLoading());
  const [curiosityCategoriesState, setCuriosityCategoriesState] = useState<CuriosityCategoriesState>(new CuriosityCategoriesLoading());
  const [itineraryCategoriesState, setItineraryCategoriesState] = useState<ItineraryCategoriesState>(new ItineraryCategoriesLoading());
  const [localProductCategoriesState, setLocalProductCategoriesState] = useState<LocalProductCategoriesState>(new LocalProductCategoriesLoading());

  // event, company, merchant, curiosity, itinerary, localProduct
  const itemsCategories = [
    { id: 1, name: "Événement", slug: "event" },
    { id: 2, name: "Associations", slug: "company" },
    { id: 3, name: "Commerces", slug: "merchant" },
    { id: 4, name: "Curiosité", slug: "curiosity" },
    { id: 5, name: "Chemin / Parcour", slug: "itinerary" },
    { id: 6, name: "Produit local", slug: "localProduct" },
  ];

  const [formData, setFormData] = useState<{
    itemCategoryId?: number | undefined;
    municipalityId?: number | undefined;
    categoryId?: number | undefined;
    title: string;
    address: string;
    website: string;
    phone: string;
    email: string;
    startDate: string | undefined;
    endDate: string | undefined;
    comment: string;
  }>({
    itemCategoryId: undefined,
    municipalityId: undefined,
    categoryId: undefined,
    title: "",
    address: "",
    website: "",
    phone: "",
    email: "",
    startDate: undefined,
    endDate: undefined,
    comment: "",
  });

  const [formDataError, setFormDataError] = useState({
    itemCategoryId: "",
    municipalityId: "",
    categoryId: "",
    title: "",
    address: "",
    website: "",
    phone: "",
    email: "",
    startDate: "",
    endDate: "",
    comment: "",
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setMunicipalitiesState(blocsContext.municipalitiesBloc.state);
    const municipalitiesSubscription = blocsContext.municipalitiesBloc.listen(setMunicipalitiesState);

    setEventCategoriesState(blocsContext.eventCategoriesBloc.state);
    const eventSubscription = blocsContext.eventCategoriesBloc.listen(setEventCategoriesState);

    setCompanyCategoriesState(blocsContext.companyCategoriesBloc.state);
    const companySubscription = blocsContext.companyCategoriesBloc.listen(setCompanyCategoriesState);

    setMerchantCategoriesState(blocsContext.merchantCategoriesBloc.state);
    const merchantSubscription = blocsContext.merchantCategoriesBloc.listen(setMerchantCategoriesState);

    setCuriosityCategoriesState(blocsContext.curiosityCategoriesBloc.state);
    const curiositySubscription = blocsContext.curiosityCategoriesBloc.listen(setCuriosityCategoriesState);

    setItineraryCategoriesState(blocsContext.itineraryCategoriesBloc.state);
    const itinerarySubscription = blocsContext.itineraryCategoriesBloc.listen(setItineraryCategoriesState);

    setLocalProductCategoriesState(blocsContext.localProductCategoriesBloc.state);
    const localProductSubscription = blocsContext.localProductCategoriesBloc.listen(setLocalProductCategoriesState);

    return () => {
      municipalitiesSubscription.unsubscribe();
      eventSubscription.unsubscribe();
      companySubscription.unsubscribe();
      merchantSubscription.unsubscribe();
      curiositySubscription.unsubscribe();
      itinerarySubscription.unsubscribe();
      localProductSubscription.unsubscribe();
    };
  }, []);

  if (success) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div className="fw-bold">Merci, votre suggestion a bien été envoyée.</div>
            <Link className="btn btn-primary mt-10" to="/">
              Retour à l'accueil
            </Link>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (loading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (
    municipalitiesState instanceof MunicipalitiesLoading ||
    eventCategoriesState instanceof EventCategoriesLoading ||
    companyCategoriesState instanceof CompanyCategoriesLoading ||
    merchantCategoriesState instanceof MerchantCategoriesLoading ||
    curiosityCategoriesState instanceof CuriosityCategoriesLoading ||
    itineraryCategoriesState instanceof ItineraryCategoriesLoading ||
    localProductCategoriesState instanceof LocalProductCategoriesLoading
  ) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (
    municipalitiesState instanceof MunicipalitiesFailure ||
    eventCategoriesState instanceof EventCategoriesFailure ||
    companyCategoriesState instanceof CompanyCategoriesFailure ||
    merchantCategoriesState instanceof MerchantCategoriesFailure ||
    curiosityCategoriesState instanceof CuriosityCategoriesFailure ||
    itineraryCategoriesState instanceof ItineraryCategoriesFailure ||
    localProductCategoriesState instanceof LocalProductCategoriesFailure
  ) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div
              className="btn btn-primary mt-6"
              onClick={() => {
                blocsContext.eventCategoriesBloc.add(new LoadEventCategories());
                blocsContext.companyCategoriesBloc.add(new LoadCompanyCategories());
                blocsContext.merchantCategoriesBloc.add(new LoadMerchantCategories());
                blocsContext.curiosityCategoriesBloc.add(new LoadCuriosityCategories());
                blocsContext.itineraryCategoriesBloc.add(new LoadItineraryCategories());
                blocsContext.localProductCategoriesBloc.add(new LoadLocalProductCategories());
              }}
            >
              Recharger
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  const renderElementCategories = () => {
    // {municipalitiesState.municipalities
    //   .filter((municipality) => municipality.parent == undefined)
    //   .map((municipality) => (
    //     <option key={municipality.id} value={municipality.id} selected={formData.municipalityId == municipality.id}>
    //       {municipality.name}
    //     </option>
    //   ))}
    var categories: Array<any> = [];
    if (formData.itemCategoryId == 1) {
      categories = (eventCategoriesState as EventCategoriesLoaded).categories;
    } else if (formData.itemCategoryId == 2) {
      categories = (companyCategoriesState as CompanyCategoriesLoaded).categories;
    } else if (formData.itemCategoryId == 3) {
      categories = (merchantCategoriesState as MerchantCategoriesLoaded).categories;
    } else if (formData.itemCategoryId == 4) {
      categories = (curiosityCategoriesState as CuriosityCategoriesLoaded).categories;
    } else if (formData.itemCategoryId == 5) {
      categories = (itineraryCategoriesState as ItineraryCategoriesLoaded).categories;
    } else if (formData.itemCategoryId == 6) {
      categories = (localProductCategoriesState as LocalProductCategoriesLoaded).categories;
    }
    return categories.map((category) => (
      <option key={category.id} value={category.id} selected={formData.categoryId == category.id}>
        {category.name}
      </option>
    ));

    // if (formData.itemCategoryId == 1) {
    //   return (
    //     <>
    //       {}
    //       <option key={municipality.id} value={municipality.id} selected={formData.municipalityId == municipality.id}>
    //         {municipality.name}
    //       </option>
    //     </>
    //   );
    // }
  };

  if (
    municipalitiesState instanceof MunicipalitiesLoaded &&
    eventCategoriesState instanceof EventCategoriesLoaded &&
    companyCategoriesState instanceof CompanyCategoriesLoaded &&
    merchantCategoriesState instanceof MerchantCategoriesLoaded &&
    curiosityCategoriesState instanceof CuriosityCategoriesLoaded &&
    itineraryCategoriesState instanceof ItineraryCategoriesLoaded &&
    localProductCategoriesState instanceof LocalProductCategoriesLoaded
  ) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl suggest-item-screen">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <h3 className="fs-1 fw-800">Suggérer un ajout</h3>
                <span className="subTitle">
                  Si vous souhaitez voir apparaitre de nouveaux commerces, de nouvelles curiosités ou encore de nouveaux événements, n'hésitez pas à nous suggérer votre ajout à l'aide du formulaire
                  ci-dessous.
                </span>
              </div>
            </div>

            <div className="row mt-10 mt-lg-20">
              <div className="col-xl-8 col-md-10 col-sm-12 offset-xl-2 offset-md-1">
                <div className="formContainer p-10 p-md-20">
                  <h2 className="mb-10">Formulaire d'ajout</h2>
                  <h4 className="mb-3 mt-10">Informations de base</h4>
                  <div className="row">
                    <div className=" col-12 col-md-6 ">
                      <select
                        className="form-control form-control-lg"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            itemCategoryId: e.target.value != "-1" ? parseInt(e.target.value) : undefined,
                          });
                        }}
                      >
                        <option value={-1} selected={formData.itemCategoryId == undefined} disabled>
                          Catégorie de l'élément
                        </option>
                        {itemsCategories.map((category) => (
                          <option key={category.id} value={category.id} selected={formData.itemCategoryId == category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.itemCategoryId}</div>
                    </div>
                    <div className="col-12 col-md-6 ">
                      {municipalitiesState instanceof MunicipalitiesLoaded && (
                        <select
                          className="form-control form-control-lg"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              municipalityId: e.target.value != "-1" ? parseInt(e.target.value) : undefined,
                            });
                          }}
                        >
                          <option value={-1} selected={formData.municipalityId == undefined} disabled>
                            Commune de l'élément
                          </option>
                          {municipalitiesState.municipalities
                            .filter((municipality) => municipality.parent == undefined)
                            .map((municipality) => (
                              <option key={municipality.id} value={municipality.id} selected={formData.municipalityId == municipality.id}>
                                {municipality.name}
                              </option>
                            ))}
                        </select>
                      )}
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.municipalityId}</div>
                    </div>
                  </div>

                  <h4 className="mb-3 mt-10">Informations spécifiques</h4>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <select
                        className="form-control form-control-lg"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            categoryId: e.target.value != "-1" ? parseInt(e.target.value) : undefined,
                          });
                        }}
                      >
                        <option value={-1} selected={formData.categoryId == undefined} disabled>
                          Catégorie spécifique
                        </option>
                        {renderElementCategories()}
                      </select>
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.categoryId}</div>
                    </div>
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="title"
                        placeholder="Nom de l'élément"
                        value={formData.title}
                        onChange={(e) => {
                          setFormData({ ...formData, title: e.target.value });
                        }}
                      />
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.title}</div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="title"
                        placeholder="Adresse complète"
                        value={formData.address}
                        onChange={(e) => {
                          setFormData({ ...formData, address: e.target.value });
                        }}
                      />
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.address}</div>
                    </div>
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="title"
                        placeholder="Site internet"
                        value={formData.website}
                        onChange={(e) => {
                          setFormData({ ...formData, website: e.target.value });
                        }}
                      />
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.website}</div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-12 col-md-6 ">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="title"
                        placeholder="Téléphone"
                        value={formData.phone}
                        onChange={(e) => {
                          setFormData({ ...formData, phone: e.target.value });
                        }}
                      />
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.phone}</div>
                    </div>
                    <div className="col-12 col-md-6 ">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="title"
                        placeholder="Adresse email"
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.email}</div>
                    </div>
                  </div>

                  {formData.itemCategoryId == 1 && (
                    <div className="row">
                      <div className="col-12 col-md-6 ">
                        <h4 className="mb-3 mt-10">Date de début</h4>
                        <input
                          type="date"
                          className="form-control form-control-lg"
                          name="title"
                          placeholder="Date de début"
                          min={new Date().toISOString().split("T")[0]}
                          value={formData.startDate}
                          onChange={(e) => {
                            setFormData({ ...formData, startDate: e.target.value });
                          }}
                        />
                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.startDate}</div>
                      </div>
                      <div className="col-12 col-md-6 ">
                        <h4 className="mb-3 mt-10">Date de fin</h4>
                        <input
                          type="date"
                          className="form-control form-control-lg"
                          name="title"
                          placeholder="Date de fin"
                          value={formData.endDate}
                          min={formData.startDate ?? new Date().toISOString().split("T")[0]}
                          onChange={(e) => {
                            setFormData({ ...formData, endDate: e.target.value });
                          }}
                        />
                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.endDate}</div>
                      </div>
                    </div>
                  )}

                  <h4 className="mb-3 mt-10">Commentaire</h4>
                  <div className="row">
                    <div className="col-12">
                      <textarea
                        className="form-control form-control-lg"
                        name="name"
                        placeholder="Informations supoplémentaires"
                        value={formData.comment}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            comment: e.target.value,
                          });
                        }}
                        style={{ resize: "none" }}
                      />
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.comment}</div>
                    </div>
                  </div>
                  {error && (
                    <div className="row text-danger">
                      <div className="col-12">Une erreur inconnue s'est produite. Veuillez réésayer plus tard.</div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12 text-end mt-10">
                      <button
                        type="button"
                        className={"btn btn-lg btn-primary"}
                        onClick={() => {
                          if (loading) return;
                          var newFormDataError = {
                            itemCategoryId: "",
                            municipalityId: "",
                            categoryId: "",
                            title: "",
                            address: "",
                            website: "",
                            phone: "",
                            email: "",
                            startDate: "",
                            endDate: "",
                            comment: "",
                          };

                          if (formData.itemCategoryId == undefined || formData.itemCategoryId == -1) {
                            newFormDataError = {
                              ...newFormDataError,
                              itemCategoryId: "Veuillez selectionner une catégorie",
                            };
                          }
                          if (formData.municipalityId == undefined || formData.municipalityId == -1) {
                            newFormDataError = {
                              ...newFormDataError,
                              municipalityId: "Veuillez selectionner une commune",
                            };
                          }

                          if (formData.categoryId == undefined || formData.categoryId == -1) {
                            newFormDataError = {
                              ...newFormDataError,
                              categoryId: "Veuillez selectionner une catégorie spécifique",
                            };
                          }

                          if (formData.title == "") {
                            newFormDataError = {
                              ...newFormDataError,
                              title: "Veuillez entrer un titre",
                            };
                          }

                          if (formData.address == "") {
                            newFormDataError = {
                              ...newFormDataError,
                              address: "Veuillez entrer une adresse",
                            };
                          }

                          // if(formData.website == "") {
                          //   setFormDataError({
                          //     ...formDataError,
                          //     website: "Veuillez entrer un site web"
                          //   })
                          // }

                          // if(formData.phone == "") {
                          //   setFormDataError({
                          //     ...formDataError,
                          //     phone: "Veuillez entrer un numéro de téléphone"
                          //   })
                          // }

                          // if(formData.email == "") {
                          //   setFormDataError({
                          //     ...formDataError,
                          //     email: "Veuillez entrer une adresse email"
                          //   })
                          // }

                          if (formData.itemCategoryId == 1) {
                            if (formData.startDate == undefined) {
                              newFormDataError = {
                                ...newFormDataError,
                                startDate: "Veuillez entrer une date de début",
                              };
                            }

                            if (formData.endDate == undefined) {
                              newFormDataError = {
                                ...newFormDataError,
                                endDate: "Veuillez entrer une date de fin",
                              };
                            }
                          }

                          // if(formData.comment == "") {
                          //   setFormDataError({
                          //     ...formDataError,
                          //     comment: "Veuillez entrer un commentaire"
                          //   })
                          // }

                          setFormDataError(newFormDataError);
                          if (
                            newFormDataError.itemCategoryId != "" ||
                            newFormDataError.municipalityId != "" ||
                            newFormDataError.categoryId != "" ||
                            newFormDataError.title != "" ||
                            newFormDataError.address != "" ||
                            newFormDataError.website != "" ||
                            newFormDataError.phone != "" ||
                            newFormDataError.email != "" ||
                            newFormDataError.startDate != "" ||
                            newFormDataError.endDate != "" ||
                            newFormDataError.comment != ""
                          ) {
                            return;
                          }

                          // TODO : send request

                          const itemSuggestionRepository = new ItemSuggestionRepository();
                          setLoading(true);
                          setSuccess(false);
                          setError(false);
                          itemSuggestionRepository
                            .suggestItem(
                              formData.itemCategoryId ?? 0,
                              formData.municipalityId ?? 0,
                              formData.categoryId ?? 0,
                              formData.title,
                              formData.address,
                              formData.website,
                              formData.phone,
                              formData.email,
                              formData.startDate ?? "",
                              formData.endDate ?? "",
                              formData.comment
                            )
                            .then((success) => {
                              setLoading(false);
                              if (success) {
                                setSuccess(true);
                              } else {
                                setError(true);
                              }
                            })
                            .catch((error) => {
                              setLoading(false);
                              setError(true);
                            });
                        }}
                      >
                        {loading ? "Chargement..." : "Envoyer la demande"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;
};

export default withTranslation("common")(SuggestItemScreen);


import Config from "config/config";
import axios from "axios";
import UniversalCategory from "models/universal_category";

export default class CompanyRepository {
  static _companyCategoriesEndpoint: string = "company-categories";
  static _companyCategoriesDataKey: string = "company_categories_data";

  async getData(): Promise<Array<UniversalCategory>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + CompanyRepository._companyCategoriesEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToCompanies(data.data.companyCategories);
  }

  dataToCompanies(data: any): Array<UniversalCategory> {
    var companies: Array<UniversalCategory> = [];
    for (var i = 0; i < data.length; i++) {
      companies.push(UniversalCategory.fromJson(data[i]));
    }
    return companies;
  }
}

import { LoadEvents } from "blocs/events_bloc/events_event";
import { EventsFailure, EventsLoaded, EventsLoading, EventsState } from "blocs/events_bloc/events_state";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import Event from "models/event";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import imgGoogleMaps from "assets/images/google-maps.png";
import imgInnerMap from "assets/images/marker_activite.png";
import imgIconDesc from "assets/images/bubble.png";
import GeneralHelper from "helpers/general_helper";
import { Link } from "react-router-dom";
import moment from "moment";
import defaultPic from "assets/images/default_pic.png";
import ImagesGallery from "components/image_gallery";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const EventScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [eventsState, setEventsState] = useState<EventsState>(new EventsLoading());

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setEventsState(blocsContext.eventsBloc.state);
    const subscription = blocsContext.eventsBloc.listen(setEventsState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const event: Event | undefined = undefined;

  if (eventsState instanceof EventsLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (eventsState instanceof EventsFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div className="btn btn-primary mt-6" onClick={() => blocsContext.eventsBloc.add(new LoadEvents())}>
              Recharger
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (eventsState instanceof EventsLoaded) {
    const event: Event = eventsState.events.find((event) => event.id.toString() == params.id) as Event;
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <a className="cursor-pointer" onClick={() => navigate(-1)}>
                  ← Retour
                </a>
                <div className="singleItem">
                  <ImagesGallery images={event.responsiveImages} />
                  <div className="singleItemInnerContent">
                    <div className="singleItemTags">
                      <div className="tagsWrapper">
                        <div className="singleItemCategory">{event.category.name}</div>
                        <div className="singleItemMunicipality">{event.municipality.name}</div>
                      </div>
                      {/* <div className="singleItemShareLink">
                        <a href="#">⭷ Partager</a>
                      </div> */}
                    </div>
                    <h3 className="fs-1 fw-600 singleItemTitle">{event.name}</h3>
                    <div className="singleItemAddress">{event.address}</div>
                    <div className="shortdividerEvent"></div>
                    <div className="singleItemAddress">
                      Début : {moment(event.startAt).locale("fr").format("D MMMM YYYY")} • Fin : {moment(event.endAt).locale("fr").format("D MMMM YYYY")}
                    </div>
                    {event.latitude != undefined && event.longitude != undefined && (
                      <div className="btnWrapper">
                        <Link to={{ pathname: "/map", search: new URLSearchParams({ datatype: "event", id: event.id.toString() }).toString() }} className="seeMapBtn btnWrapper">
                          <div className="btnIcon">
                            <img src={imgInnerMap} />
                            <div className="btnText text-black">Voir sur la carte</div>
                          </div>
                        </Link>

                        <a
                          href="#"
                          className="seeMapBtn btnWrapper"
                          onClick={() => {
                            var latitude = event.latitude;
                            var longitude = event.longitude;
                            var link = "https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=" + latitude + "," + longitude + "";
                            window.open(link, "_blank");
                          }}
                        >
                          <div className="btnIcon">
                            <img src={imgGoogleMaps} />
                            <span className="btnText">Obtenir l'itinéraire</span>
                          </div>
                        </a>
                      </div>
                    )}
                    <div className="singleItemDescription">
                      <img src={imgIconDesc} />
                      <div className="descTitle">Description</div>
                      {/* {GeneralHelper.cleanHtmlString(event.description || 'Aucune description disponible.')} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event.description || "Aucune description disponible.",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(event)
};

export default withTranslation("common")(EventScreen);

import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import HomeSection1 from "./components/home_section_1";
import HomeSection2 from "./components/home_section_2";
import HomeSection3 from "./components/home_section_3";
import HomeSection4 from "./components/home_section_4";

interface Props extends WithTranslation {}

const HomeScreen: React.FC<Props> = ({ t }) => {
  return (
    <>
      <BaseScreen>
        <HomeSection1 />
        <HomeSection2 />
        <HomeSection3 />
        <HomeSection4 />
      </BaseScreen>
    </>
  );
};

export default withTranslation("common")(HomeScreen);

import { EventsLoading, EventsState } from "blocs/events_bloc/events_state";
import { MunicipalitiesLoaded, MunicipalitiesLoading, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import { ServiceCategoriesLoaded, ServiceCategoriesLoading, ServiceCategoriesState } from "blocs/service_categories_bloc/service_categories_state";
import { BlocsContext } from "contexts/blocs_context";
import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { useGeneralModal } from "../general_modal/general_modal_context";
import ServiceRepository from "repositories/service_repository";
import { BlocBuilder } from "@felangel/react-bloc";
import { AuthenticationState, AuthenticationUnAuthenticated } from "blocs/authentication_bloc/authentication_state";
import { useNavigate } from "react-router-dom";
import { LoadServices } from "blocs/services_bloc/services_event";
import { reportReasons } from "constants/constants";

// ServiceReportContentProps
interface ServiceReportContentProps {
  serviceId: number;
}

const ServiceReportContent: React.FC<ServiceReportContentProps> = ({ serviceId }) => {
  const generalModal = useGeneralModal();

  const navigate = useNavigate();

  const stepCount = 4;
  const [step, setStep] = useState<number>(1);

  const [serviceCreationLoading, setServiceCreationLoading] = useState<boolean>(false);
  const [serviceCreationError, setServiceCreationError] = useState<string | undefined>(undefined);

  const { servicesBloc, serviceCategoriesBloc, municipalitiesBloc, authenticationBloc } = useContext(BlocsContext);
  const [serviceCategoriesState, setServiceCategoriesState] = useState<ServiceCategoriesState>(new ServiceCategoriesLoading());
  const [municipalitiesState, setMunicipalitiesState] = useState<MunicipalitiesState>(new MunicipalitiesLoading());
  const [authenticationState, setAuthenticationState] = useState<AuthenticationState>(new AuthenticationUnAuthenticated());

  // store all form data
  const [formData, setFormData] = useState<{
    reasonId?: number | undefined;
    reason: string;
  }>({
    reasonId: undefined,
    reason: "",
  });

  const [formDataError, setFormDataError] = useState({
    reasonId: "",
    reason: "",
  });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setAuthenticationState(authenticationBloc.state);
    const authenticationBlocsubscription = authenticationBloc.listen(setAuthenticationState);

    return () => {
      authenticationBlocsubscription.unsubscribe();
    };
  });

  if (authenticationState instanceof AuthenticationUnAuthenticated) {
    return (
      <>
        <div className="modal-header">
          <h2>Signaler une annonce</h2>

          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              generalModal.closeModal();
            }}
          >
            <i className="ki-duotone ki-cross fs-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>
        </div>
        <div className="modal-body py-lg-10 px-lg-10">
          <div className="mustLoginTitle">Connexion obligatoire</div>
          <div className="mustLoginText">Vous devez être connecté(e) pour publier une annonce.</div>
          <div
            className="btn btn-primary"
            onClick={() => {
              navigate("/login");
              generalModal.closeModal();
            }}
          >
            Se connecter
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="modal-header">
        <h2>Signaler une annonce</h2>

        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          data-bs-dismiss="modal"
          onClick={() => {
            generalModal.closeModal();
          }}
        >
          <i className="ki-duotone ki-cross fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        <div className="d-flex flex-column">
          <div className="fv-row mb-10 fv-plugins-icon-container w-100">Attention, le signalement avertira un administrateur afin qu'il contrôle l'annonce en question.</div>

          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span className="required">Motif du signalement</span>
            </label>

            <select
              className="form-control form-control-lg form-control-solid"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  reasonId: e.target.value != "-1" ? parseInt(e.target.value) : undefined,
                });
              }}
              value={formData.reasonId}
            >
              <option value={"-1"}>Séléctionner un motif</option>
              {reportReasons.map((reportReason) => (
                <option key={reportReason.id} value={reportReason.id}>
                  {reportReason.name}
                </option>
              ))}
            </select>
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.reasonId}</div>
          </div>

          <div className="fv-row mb-10 fv-plugins-icon-container">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span className="required">Plus d'informations concernant le signalement</span>
            </label>

            <textarea
              className="form-control form-control-lg form-control-solid"
              name="name"
              placeholder="Motif"
              value={formData.reason}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  reason: e.target.value,
                });
              }}
              style={{ resize: "none" }}
            />
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.reason}</div>
          </div>

          <button
            type="button"
            className={["btn btn-lg btn-primary"].join(" ")}
            onClick={async () => {
              if (loading) return;
              var newFormDataError = {
                reason: "",
                reasonId: "",
              };

              if (formData.reasonId == undefined) {
                newFormDataError = {
                  ...newFormDataError,
                  reasonId: "Veuillez séléctionner un motif",
                };
              }
              if (formData.reason == "") {
                newFormDataError = {
                  ...newFormDataError,
                  reason: "Veuillez entrer une raison",
                };
              }
              setFormDataError(newFormDataError);
              if (newFormDataError.reason != "" || newFormDataError.reasonId != "") {
                return;
              }
              setLoading(true);
              try {
                await new ServiceRepository().reportService(serviceId, formData.reasonId!, formData.reason);
                servicesBloc.add(new LoadServices());
              } catch (e) {}
              setLoading(false);

              generalModal.closeModal();
            }}
          >
            {loading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
            {loading == false && (
              <>
                Envoyer le signalement
                <i className="ki-duotone ki-arrow-right fs-3 ms-1 me-0">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default ServiceReportContent;

import { Bloc, Transition } from "@felangel/bloc";
import UniversalCategory from "models/universal_category";
import CuriosityCategoriesRepository from "repositories/curiosity_category_repository"; // Adapter l'import en fonction de ton repository
import {
  CuriosityCategoriesEvent,
  LoadCuriosityCategories,
} from "./curiosity_categories_event"; // Adapter les imports en fonction de tes événements
import {
  CuriosityCategoriesState,
  CuriosityCategoriesLoaded,
  CuriosityCategoriesLoading,
  CuriosityCategoriesFailure,
} from "./curiosity_categories_state"; // Adapter les imports en fonction des états

export default class CuriosityCategoriesBloc extends Bloc<CuriosityCategoriesEvent, CuriosityCategoriesState> {
  curiosityRepository: CuriosityCategoriesRepository; // Adapter le nom du repository en conséquence
  categories: Array<UniversalCategory> = [];

  constructor(curiosityRepository: CuriosityCategoriesRepository) {
    super(new CuriosityCategoriesLoading()); // Adapter l'état initial en conséquence
    this.curiosityRepository = curiosityRepository; // Adapter le nom du repository en conséquence
  }

  async *mapEventToState(event: CuriosityCategoriesEvent) {
    if (event instanceof LoadCuriosityCategories) { 
      yield* this.onLoadCuriosityCategories(event); 
    }
  }

  async *onLoadCuriosityCategories(event: LoadCuriosityCategories) { 
    try {
      yield new CuriosityCategoriesLoading(); // Adapter l'état en conséquence
      var categoriesList: Array<UniversalCategory> = await this.curiosityRepository.getData(); // Adapter le nom du repository en conséquence
      this.categories = categoriesList;

      yield new CuriosityCategoriesLoaded(this.categories); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CuriosityCategoriesFailure(e); // Adapter l'état en conséquence
      } else {
        yield new CuriosityCategoriesFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }

  onEvent(event: CuriosityCategoriesEvent): void { 
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event =", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition =", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error =", error);
  }
}

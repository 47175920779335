import Merchant from "models/merchant"; // Adapter l'import en conséquence

import Config from "config/config";
import axios from "axios";

export default class MerchantRepository {
  static _merchantsEndpoint: string = "merchants"; // Adapter l'endpoint en conséquence
  static _merchantsDataKey: string = "merchants_data"; // Adapter la clé de données en conséquence

  async getData(): Promise<Array<Merchant>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + MerchantRepository._merchantsEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToMerchants(data.data.merchants);
  }

  dataToMerchants(data: any): Array<Merchant> {
    var merchants: Array<Merchant> = [];
    for (var i = 0; i < data.length; i++) {
      merchants.push(Merchant.fromJson(data[i]));
    }
    return merchants;
  }
}

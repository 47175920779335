const enum SortTypes {
  NAME_ASC = "name_asc",
  NAME_DESC = "name_desc",
  DATE_ASC = "date_asc",
  DATE_DESC = "date_desc",
}

const enum ImageSizes {
  big = "big",
  medium = "medium",
  small = "small",
  original = "original",
}

const getSortType = (sort: string): SortTypes => {
  switch (sort) {
    case "name_asc":
      return SortTypes.NAME_ASC;
    case "name_desc":
      return SortTypes.NAME_DESC;
    case "date_asc":
      return SortTypes.DATE_ASC;
    case "date_desc":
      return SortTypes.DATE_DESC;
    default:
      return SortTypes.NAME_ASC;
  }
};

const reportReasons = [
  { id: 1, name: "Informations incorrectes" },
  { id: 2, name: "Catégorie incorrecte" },
  { id: 3, name: "Doublon" },
  { id: 4, name: "Activité illégale" },
  { id: 5, name: "Fraude" },
  { id: 6, name: "SPAM" },
  { id: 7, name: "Autre raison" },
];

export { SortTypes, ImageSizes, getSortType, reportReasons };

import UniversalCategory from "models/universal_category";

export abstract class ServiceCategoriesState {} 

export class ServiceCategoriesLoading extends ServiceCategoriesState {} 

export class ServiceCategoriesLoaded extends ServiceCategoriesState {
  categories: Array<UniversalCategory>; // Adapter le nom du modèle en conséquence
  constructor(categories: Array<UniversalCategory>) {
    super();
    this.categories = categories;
  }

  public toString = (): string => {
    return `ServiceCategoriesLoaded ${this.categories.length} categories`; // Adapter le nom en conséquence
  };
}

export class ServiceCategoriesFailure extends ServiceCategoriesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `ServiceCategoriesLoadingFailed { errorMessage: ${this.errorMessage} }`; // Adapter le nom en conséquence
  };
}

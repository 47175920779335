import Municipality from "models/municipality";
import { SortTypes } from "constants/constants";
import Event from "models/event";

export default class GenericFilters {
  categories: Array<number> | undefined;
  municipalityId: number | undefined;
  sort: SortTypes;

  constructor(params: { categories?: Array<number>; municipalityId?: number; sort?: SortTypes }) {
    this.categories = params.categories ?? [];
    this.municipalityId = params.municipalityId;
    this.sort = params.sort ?? SortTypes.NAME_ASC;
  }

  // Vérification de la catégorie
  private doesMatchCategory(item: any): boolean {
    return this.categories == undefined || this.categories.length === 0 || (item.category === undefined ? false : this.categories?.map((e) => e.toString()).includes(item.category.id));
  }

  // Vérification de la municipalité
  private doesMatchMunicipality(item: any): boolean {
    return this.municipalityId === undefined || (item.municipality !== undefined && item.municipality.id.toString() == this.municipalityId.toString());
  }

  private doesMatchNotPastEvent(item: any): boolean {
    return item instanceof Event ? item.startAt > new Date() : true;
  }

  // Filter items
  filter(items: Array<any>): Array<any> {
    const filteredItems = items.filter((item) => {
      return this.doesMatchCategory(item) && this.doesMatchMunicipality(item) && this.doesMatchNotPastEvent(item);
    });
    return this.sortItems(filteredItems);
  }

  // Tri des curiosités
  sortItems(items: Array<any>): Array<any> {
    if (this.sort === SortTypes.NAME_ASC) {
      return items.slice().sort((a, b) => a.name.localeCompare(b.name));
    } else if (this.sort === SortTypes.NAME_DESC) {
      return items.slice().sort((a, b) => b.name.localeCompare(a.name));
    } else if (this.sort === SortTypes.DATE_DESC) {
      return items.slice().sort((a, b) => (a instanceof Event ? a.startAt : b.createdAt) - (b instanceof Event ? b.startAt : a.createdAt));
    }
    // Ajoutez d'autres conditions de tri ici selon vos besoins
    return items;
  }

  // clone function
  clone(): GenericFilters {
    return new GenericFilters({
      categories: this.categories,
      municipalityId: this.municipalityId,
      sort: this.sort,
    });
  }
  
}

import React, { Fragment } from "react";
import ReactDOM from "react-dom";
// import reportWebVitals from './reportWebVitals';
import Router from "./router";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route } from "react-router-dom";

// CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/theme_defaults.scss";
import "./styles/index.scss";
import { BlocsContext } from "contexts/blocs_context";
import { GlobalContextProvider } from "contexts/global_context";
// import { MaterialsBlocContext } from 'contexts/materials_bloc_context';

// import ToolsBloc from "blocs/tools_bloc/tools_bloc";
// import ToolRepository from "repositories/tool_repository";
// import { ToolsLoad } from "blocs/tools_bloc/tools_event";

// import { ToolfinderSelection } from 'blocs/machines_bloc/machines_event';

import { I18nextProvider, WithTranslationProps } from "react-i18next";
import i18next from "i18next";
import translations_fr from "./translations/fr.json";
import translations_en from "./translations/en.json";
import translations_de from "./translations/de.json";
import translations_it from "./translations/it.json";

import "moment/locale/fr"; // Importez la locale française pour les noms de mois en français

import StarterLoadingScreen from "screens/starter_loading/starter_loading";
import LanguageDetector from "i18next-browser-languagedetector";

import ServiceRepository from "repositories/service_repository";
import CompanyRepository from "repositories/company_repository";
import CompanyCategoriesRepository from "repositories/company_category_repository";
import CuriositiesRepository from "repositories/curiosity_repository";
import CuriosityCategoriesRepository from "repositories/curiosity_category_repository";
import EventCategoriesRepository from "repositories/event_category_repository";
import EventsRepository from "repositories/event_repository";
import ItinerariesRepository from "repositories/itinerary_repository";
import ItineraryCategoriesRepository from "repositories/itinerary_category_repository";
import LocalProductCategoriesRepository from "repositories/local_product_category_repository";
import LocalProductsRepository from "repositories/local_product_repository";
import MerchantCategoriesRepository from "repositories/merchant_category_repository";
import MerchantsRepository from "repositories/merchant_repository";
import MunicipalitiesRepository from "repositories/municipality_repository";
import NewsRepository from "repositories/news_repository";
import NewsCategoriesRepository from "repositories/news_category_repository";
import NotificationsRepository from "repositories/notification_repository";
import ServiceCategoriesRepository from "repositories/service_category_repository";

import AuthenticationBloc from "blocs/authentication_bloc/authentication_bloc";
import ServicesBloc from "blocs/services_bloc/services_bloc";
import CompanyCategoriesBloc from "blocs/company_categories_bloc/company_categories_bloc";
import CuriositiesBloc from "blocs/curiosities_bloc/curiosities_bloc";
import CuriosityCategoriesBloc from "blocs/curiosity_categories_bloc/curiosity_categories_bloc";
import EventCategoriesBloc from "blocs/event_categories_bloc/event_categories_bloc";
import EventsBloc from "blocs/events_bloc/events_bloc";
import ItinerariesBloc from "blocs/itineraries_bloc/itineraries_bloc";
import ItineraryCategoriesBloc from "blocs/itinerary_categories_bloc/itinerary_categories_bloc";
import LocalProductCategoriesBloc from "blocs/local_product_categories_bloc/local_product_categories_bloc";
import LocalProductsBloc from "blocs/local_products_bloc/local_products_bloc";
import MerchantCategoriesBloc from "blocs/merchant_categories_bloc/merchant_categories_bloc";
import MerchantsBloc from "blocs/merchants_bloc/merchants_bloc";
import MunicipalitiesBloc from "blocs/municipalities_bloc/municipalities_bloc";
import NewsBloc from "blocs/news_bloc/news_bloc";
import NewsCategoriesBloc from "blocs/news_categories_bloc/news_categories_bloc";
import NotificationsBloc from "blocs/notifications_bloc/notifications_bloc";
import ServiceCategoriesBloc from "blocs/service_categories_bloc/service_categories_bloc";
import { ConfirmationModalProvider } from "components/modals/logout_confirmation_modal/logout_confirmation_modal_context";
import LogoutConfirmationModal from "components/modals/logout_confirmation_modal/logout_confirmation_modal";
import GeneralModal from "components/modals/general_modal/general_modal";
import CompaniesBloc from "blocs/companies_bloc/companies_bloc";
import { GeneralModalProvider } from "components/modals/general_modal/general_modal_context";

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import config from "config/config";
import ScrollToTopOnpageChange from "components/scroll_to_top_on_page_change";

const authenticationBloc: AuthenticationBloc = new AuthenticationBloc();
const servicesBloc: ServicesBloc = new ServicesBloc(new ServiceRepository());
const companiesBloc: CompaniesBloc = new CompaniesBloc(new CompanyRepository());
const companyCategoriesBloc: CompanyCategoriesBloc = new CompanyCategoriesBloc(new CompanyCategoriesRepository());
const curiositiesBloc: CuriositiesBloc = new CuriositiesBloc(new CuriositiesRepository());
const curiosityCategoriesBloc: CuriosityCategoriesBloc = new CuriosityCategoriesBloc(new CuriosityCategoriesRepository());
const eventCategoriesBloc: EventCategoriesBloc = new EventCategoriesBloc(new EventCategoriesRepository());
const eventsBloc: EventsBloc = new EventsBloc(new EventsRepository());
const itinerariesBloc: ItinerariesBloc = new ItinerariesBloc(new ItinerariesRepository());
const itineraryCategoriesBloc: ItineraryCategoriesBloc = new ItineraryCategoriesBloc(new ItineraryCategoriesRepository());
const localProductCategoriesBloc: LocalProductCategoriesBloc = new LocalProductCategoriesBloc(new LocalProductCategoriesRepository());
const localProductsBloc: LocalProductsBloc = new LocalProductsBloc(new LocalProductsRepository());
const merchantCategoriesBloc: MerchantCategoriesBloc = new MerchantCategoriesBloc(new MerchantCategoriesRepository());
const merchantsBloc: MerchantsBloc = new MerchantsBloc(new MerchantsRepository());
const municipalitiesBloc: MunicipalitiesBloc = new MunicipalitiesBloc(new MunicipalitiesRepository());
const newsBloc: NewsBloc = new NewsBloc(new NewsRepository());
const newsCategoriesBloc: NewsCategoriesBloc = new NewsCategoriesBloc(new NewsCategoriesRepository());
const notificationsBloc: NotificationsBloc = new NotificationsBloc(new NotificationsRepository());
const serviceCategoriesBloc: ServiceCategoriesBloc = new ServiceCategoriesBloc(new ServiceCategoriesRepository());

// i18next.init({
//   interpolation: { escapeValue: false },
//   lng: 'fr',
//   resources: {
//     fr: translations_fr,
//     en: translations_en,
//     de: translations_de,
//   },
// });

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  // lng: "fr",
  fallbackLng: "fr",
  resources: {
    fr: {
      common: translations_fr,
    },
    en: {
      common: translations_en,
    },
    de: {
      common: translations_de,
    },
    it: {
      common: translations_it,
    },
  },
  detection: {
    order: ["querystring", "cookie", "header"],
    lookupCookie: "lang",
    lookupQuerystring: "lang",
    caches: ["cookie"],
  },
});

class App extends React.Component {
  // componentDidMount() {
  // 	const script = document.createElement("script");
  // 	script.async = true;
  // 	script.src = "https://some-scripturl.js";
  // 	this.div.appendChild(script);
  // }
  render() {
    return (
      <React.StrictMode>
        <Toaster
          containerStyle={{
            top: 120,
            right: 30,
            zIndex: 999999,
          }}
        />
        <I18nextProvider i18n={i18next}>
          <BlocsContext.Provider
            value={{
              authenticationBloc: authenticationBloc,
              servicesBloc: servicesBloc,
              companiesBloc: companiesBloc,
              companyCategoriesBloc: companyCategoriesBloc,
              curiositiesBloc: curiositiesBloc,
              curiosityCategoriesBloc: curiosityCategoriesBloc,
              eventCategoriesBloc: eventCategoriesBloc,
              eventsBloc: eventsBloc,
              itinerariesBloc: itinerariesBloc,
              itineraryCategoriesBloc: itineraryCategoriesBloc,
              localProductCategoriesBloc: localProductCategoriesBloc,
              localProductsBloc: localProductsBloc,
              merchantCategoriesBloc: merchantCategoriesBloc,
              merchantsBloc: merchantsBloc,
              municipalitiesBloc: municipalitiesBloc,
              newsBloc: newsBloc,
              newsCategoriesBloc: newsCategoriesBloc,
              notificationsBloc: notificationsBloc,
              serviceCategoriesBloc: serviceCategoriesBloc,
            }}
          >
            <GlobalContextProvider>
            <Wrapper apiKey={config.GOOGLE_MAPS_API_KEY}>
              <GeneralModalProvider>
                <ConfirmationModalProvider>
                  <StarterLoadingScreen>
                    <BrowserRouter basename="/">
                      <ScrollToTopOnpageChange />
                      <Router />
                      <LogoutConfirmationModal />
                      <GeneralModal />
                    </BrowserRouter>
                  </StarterLoadingScreen>
                </ConfirmationModalProvider>
              </GeneralModalProvider>
            </Wrapper>
            </GlobalContextProvider>
          </BlocsContext.Provider>
        </I18nextProvider>
      </React.StrictMode>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

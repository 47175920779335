import { Bloc, Transition } from "@felangel/bloc";
import UniversalCategory from "models/universal_category";
import LocalProductCategoriesRepository from "repositories/local_product_category_repository"; // Adapter l'import en fonction de ton repository
import {
  LocalProductCategoriesEvent,
  LoadLocalProductCategories,
} from "./local_product_categories_event"; // Adapter les imports en fonction de tes événements
import {
  LocalProductCategoriesState,
  LocalProductCategoriesLoaded,
  LocalProductCategoriesLoading,
  LocalProductCategoriesFailure,
} from "./local_product_categories_state"; // Adapter les imports en fonction des états

export default class LocalProductCategoriesBloc extends Bloc<LocalProductCategoriesEvent, LocalProductCategoriesState> {
  localProductRepository: LocalProductCategoriesRepository; // Adapter le nom du repository en conséquence
  categories: Array<UniversalCategory> = [];

  constructor(localProductRepository: LocalProductCategoriesRepository) {
    super(new LocalProductCategoriesLoading()); // Adapter l'état initial en conséquence
    this.localProductRepository = localProductRepository; // Adapter le nom du repository en conséquence
  }

  async *mapEventToState(event: LocalProductCategoriesEvent) {
    if (event instanceof LoadLocalProductCategories) { 
      yield* this.onLoadLocalProductCategories(event); 
    }
  }

  async *onLoadLocalProductCategories(event: LoadLocalProductCategories) { 
    try {
      yield new LocalProductCategoriesLoading(); // Adapter l'état en conséquence
      var categoriesList: Array<UniversalCategory> = await this.localProductRepository.getData(); // Adapter le nom du repository en conséquence
      this.categories = categoriesList;

      yield new LocalProductCategoriesLoaded(this.categories); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new LocalProductCategoriesFailure(e); // Adapter l'état en conséquence
      } else {
        yield new LocalProductCategoriesFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }

  onEvent(event: LocalProductCategoriesEvent): void { 
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event =", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition =", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error =", error);
  }
}

import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import defaultPic from "assets/images/default_pic.png";
import FsLightbox from "fslightbox-react";
import ResponsiveImage from "models/responsive_image";
import { ImageSizes } from "constants/constants";

// import styles from './home.scss';

interface Props extends WithTranslation {
  images: ResponsiveImage[];
}

const ImagesGallery: React.FC<Props> = ({ t, images }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  const [numberOfElement, setNumberOfElement] = useState(Math.abs(windowSize[0] / 70));

  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      if (windowSize[0] > 1197) {
        setNumberOfElement(14);
      } else if (window.innerWidth > 767) {
        setNumberOfElement(Math.abs((window.innerWidth - 30) / 90));
      } else {
        setNumberOfElement(Math.abs((window.innerWidth - 30) / 70));
      }
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <div className="d-flex flex-column">
      {images.length == 0 && <img src={defaultPic} className="itemImg mt-5" />}
      {images.length != 0 && <img src={images[0].bigUrl} className="itemImg cursor-pointer mt-5 flex-grow-2" onClick={() => openLightboxOnSlide(1)} />}

      {images.length > 1 && (
        <div className="d-flex flex-wrap">
          {images.slice(1, numberOfElement).map((image, index) => (
            <img src={image.mediumUrl} className="subImg cursor-pointer mt-5" onClick={() => openLightboxOnSlide(index + 2)} />
          ))}

          {images.length > numberOfElement && (
            <div className="moreImg mt-5" onClick={() => openLightboxOnSlide(6)}>
              Voir plus →
            </div>
          )}
        </div>
      )}
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={images.map<string | undefined>((image) => image.getUrlFromSize(ImageSizes.big)).filter((image) => image != undefined) as string[]}
        slide={lightboxController.slide}
        disableLocalStorage={true}
        type="image"
      />
    </div>
  );
};

export default withTranslation("common")(ImagesGallery);

import News from "models/news"; // Adapter l'import en conséquence

import Config from "config/config";
import axios from "axios";

export default class NewsRepository {
  static _newsEndpoint: string = "news"; // Adapter l'endpoint en conséquence
  static _newsDataKey: string = "news_data"; // Adapter la clé de données en conséquence

  async getData(): Promise<Array<News>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + NewsRepository._newsEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToNews(data.data.news);
  }

  dataToNews(data: any): Array<News> {
    var newsList: Array<News> = [];
    for (var i = 0; i < data.length; i++) {
      newsList.push(News.fromJson(data[i]));
    }
    return newsList;
  }
}

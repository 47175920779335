import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Municipality from "models/municipality";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { MunicipalitiesLoaded, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";

import { Link } from "react-router-dom";
import MunicipalityCard from "components/data_components/municipality_card";

interface Props extends WithTranslation {}

const HomeSection2: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  return (
    <div id="kt_content_container cities" className="d-flex flex-column-fluid align-items-start container-xxl">
      <div className="content flex-row-fluid" id="kt_content">
        <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-10">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <h3 className="fs-1 fw-700">Découvrir les communes</h3>
            <Link to="/municipalities" className="fs-5 fw-bold">
              Voir tout →
            </Link>
          </div>
        </div>
        

        <div className="row row-cols-1 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
          <BlocBuilder
            bloc={blocsContext.municipalitiesBloc}
            builder={(state: MunicipalitiesState) => {
              if (state instanceof MunicipalitiesLoaded) {
                return (
                  <>
                    {state.municipalities
                      .filter((municipality) => municipality.parent == undefined)
                      .slice(0, 5)
                      .map((municipality: Municipality) => {
                        return <MunicipalityCard key={municipality.id} municipality={municipality} />;
                      })}
                  </>
                );
              }
              return <></>;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(HomeSection2);

import { LoadServices } from "blocs/services_bloc/services_event";
import { ServicesFailure, ServicesLoaded, ServicesLoading, ServicesState } from "blocs/services_bloc/services_state";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import Service from "models/service";
import { Link, useNavigate } from "react-router-dom";
import imgGoogleMaps from "assets/images/google-maps.png";
import imgInnerMap from "assets/images/marker_activite.png";
import imgIconDesc from "assets/images/bubble.png";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import GeneralHelper from "helpers/general_helper";
import moment from "moment";
import defaultPic from "assets/images/default_pic.png";

import Comment from "./components/comment";
import ServiceComment from "models/service_comment";
import User from "models/user";

import ServiceCommentRepository from "repositories/service_comment_repository";
import ServiceCommentsBloc from "blocs/service_comments_bloc/service_comments_bloc";
import { ServiceCommentsLoaded, ServiceCommentsLoading, ServiceCommentsState } from "blocs/service_comments_bloc/service_comments_state";
import { AddServiceComment, LoadServiceComments } from "blocs/service_comments_bloc/service_comments_event";
import { AuthenticationAuthenticated, AuthenticationInitial, AuthenticationState, AuthenticationUnAuthenticated } from "blocs/authentication_bloc/authentication_state";
import CommentInput from "./components/comment_input";

import axios, { AxiosError } from "axios";
import ServiceReportContent from "components/modals/service_report_modal/service_report_modal_content";
import { useGeneralModal } from "components/modals/general_modal/general_modal_context";
import ImagesGallery from "components/image_gallery";

// import styles from './home.scss';
const serviceCommentsBloc: ServiceCommentsBloc = new ServiceCommentsBloc(new ServiceCommentRepository());

interface Props extends WithTranslation {}

const ServiceScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [authenticationState, setAuthenticationState] = useState<AuthenticationState>(new AuthenticationInitial());
  const [serviceCommentsState, setServiceCommentsState] = useState<ServiceCommentsState>(new ServiceCommentsLoading());
  const [servicesState, setServicesState] = useState<ServicesState>(new ServicesLoading());

  const [commentError, setCommentError] = useState<string | undefined>(undefined);
  const generalModal = useGeneralModal();

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setAuthenticationState(blocsContext.authenticationBloc.state);
    const authenticationSubscription = blocsContext.authenticationBloc.listen(setAuthenticationState);

    setServicesState(blocsContext.servicesBloc.state);
    const servicesSubscription = blocsContext.servicesBloc.listen(setServicesState);

    setServiceCommentsState(serviceCommentsBloc.state);
    const serviceCommentsSubscription = serviceCommentsBloc.listen(setServiceCommentsState);

    if (params.id != undefined) serviceCommentsBloc.add(new LoadServiceComments(parseInt(params.id)));
    return () => {
      authenticationSubscription.unsubscribe();
      servicesSubscription.unsubscribe();
      serviceCommentsSubscription.unsubscribe();
    };
  }, []);

  const [commentValue, setCommentValue] = useState<string>("");

  // const service: Service | undefined = undefined;

  if (servicesState instanceof ServicesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (servicesState instanceof ServicesFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div className="btn btn-primary mt-6" onClick={() => blocsContext.servicesBloc.add(new LoadServices())}>
              Recharger
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (servicesState instanceof ServicesLoaded) {
    const service: Service = servicesState.services.find((service) => service.id.toString() == params.id) as Service;

    if (service == undefined) {
      return (
        <BaseScreen>
          <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
              <h4>404 - Service introuvable</h4>
              <div className="h5 fw-400">Le service que vous cherchez n'existe pas ou n'est malheureusement plus disponbile.</div>
              <Link className="btn btn-primary mt-10" to="/services">
                Retour à la liste des services
              </Link>
            </div>
          </div>
        </BaseScreen>
      );
    }
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <a className="cursor-pointer backLinkSingle" onClick={() => navigate(-1)}>
                  ← Retour
                </a>
                <div className="singleItem">
                  <ImagesGallery images={service.responsiveImages} />
                  <div className="singleItemInnerContent">
                    <div className="singleItemTags">
                      <div className="tagsWrapper">
                        <div className="singleItemCategory">{service.category.name}</div>
                        <div className="singleItemMunicipality">{service.municipality.name}</div>
                        {service.isExpired() && <div className="singleItemExpired">Expirée</div>}
                      </div>
                      {/* <div className="singleItemShareLink">
                        <a href="#">⭷ Partager</a>
                      </div> */}

                      {blocsContext.authenticationBloc.state instanceof AuthenticationUnAuthenticated ||
                        (blocsContext.authenticationBloc.state instanceof AuthenticationAuthenticated && service.user.id != blocsContext.authenticationBloc.state.authenticationData.user.id && (
                          <div className="singleItemShareLink">
                            {service.reported == false && (
                              <a
                                href="#"
                                onClick={() => {
                                  generalModal.setContent(<ServiceReportContent serviceId={service.id} />);
                                  generalModal.openModal();
                                }}
                              >
                                Signaler
                              </a>
                            )}
                            {service.reported == true && <div>Service déjà signalé</div>}
                          </div>
                        ))}
                    </div>
                    <h3 className="fs-1 fw-600 singleItemTitle">{service.name}</h3>
                    <div className="singleItemAddress">Publié le {moment(service.createdAt).locale("fr").format("D MMMM YYYY")}</div>
                    <div className="singleItemDescription">
                      <img src={imgIconDesc} />
                      <div className="descTitle">Description</div>
                      {GeneralHelper.cleanHtmlString(service.description || "Aucune description disponible.")}
                    </div>
                    <div className="singleItemContactInfo">
                      <div className="contactTitle">Informations de contact</div>
                      <div className="contactEmail contactItem">
                        <div className="contactitemName">Adresse email :</div>
                        <div className="contactItemValue">{service.email != null ? <a href={"mailto:" + service.email}>{service.email}</a> : "non défini"}</div>
                      </div>
                      <div className="contactPhone contactItem">
                        <div className="contactitemName">N° de téléphone :</div>
                        <div className="contactItemValue">{service.phone != null ? <a href={"tel:" + service.phone}>{service.phone}</a> : "non défini"}</div>
                      </div>
                    </div>

                    <div className="serviceComments">
                      <div className="contactTitle mb-4 mb-xl-6 mt-10 mt-xl-20 fw-800">Commentaires</div>

                      {service.isExpired() == false && (
                        <CommentInput
                          onAddComment={async (comment: string) => {
                            if (comment.length == 0) return;
                            setCommentError(undefined);
                            try {
                              var newServiceComment: ServiceComment = await new ServiceCommentRepository().createServiceComment(comment, service.id, undefined); // Adapter le nom du repository en conséquence

                              serviceCommentsBloc.add(new AddServiceComment(newServiceComment));

                              setCommentValue("");
                            } catch (e: any) {
                              if (axios.isAxiosError(e)) {
                                console.log(e.response);
                                if (e.response?.status == 500) {
                                  setCommentError("Une erreur est survenue lors de l'ajout de votre commentaire. Veuillez réessayer plus tard.");
                                } else {
                                  setCommentError(e.response?.data.message);
                                }
                              } else {
                                setCommentError(e.toString());
                              }
                            }
                          }}
                          error={commentError}
                          commentValue={commentValue}
                          setCommentValue={setCommentValue}
                        />
                      )}

                      <div className="CommentsList">
                        {serviceCommentsState instanceof ServiceCommentsLoaded &&
                          serviceCommentsState.comments
                            .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                            .filter((comment: ServiceComment) => comment.parentId == undefined)
                            .map((comment: ServiceComment) => (
                              <Comment key={comment.id} comment={comment} serviceAuthorId={service.user.id} serviceCommentsBloc={serviceCommentsBloc} canReply={service.isExpired() == false} />
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(service)
};

export default withTranslation("common")(ServiceScreen);

import ServiceComment from "models/service_comment";

export abstract class ServiceCommentsEvent { }


export class LoadServiceComments extends ServiceCommentsEvent {
  serviceId: number;
  constructor(serviceId: number) {
    super();
    this.serviceId = serviceId;
  }

  public toString = (): string => `LoadServiceComments`;
}

export class AddServiceComment extends ServiceCommentsEvent {
  serviceComment: ServiceComment;

  constructor(serviceComment: ServiceComment) {
    super();
    this.serviceComment = serviceComment;
  }

  public toString = (): string => `AddServiceComment`;
}

export class EditServiceComment extends ServiceCommentsEvent {
  serviceComment: ServiceComment;

  constructor(serviceComment: ServiceComment) {
    super();
    this.serviceComment = serviceComment;
  }

  public toString = (): string => `EditServiceComment`;
}

export class DeleteServiceComment extends ServiceCommentsEvent {
  serviceCommentId: number;

  constructor(serviceCommentId: number) {
    super();
    this.serviceCommentId = serviceCommentId;
  }

  public toString = (): string => `DeleteServiceComment`;
}
import { Bloc, Transition } from "@felangel/bloc";
import { AxiosResponse } from "axios";
import AuthenticationData from "../../models/authentication_data";
import User from "../../models/user";
import AuthenticationService from "../../services/authentication";

import {
  AuthenticationEvent,
  AuthenticationAppLoaded,
  AuthenticationLoggedIn,
  AuthenticationLoggedOut,
} from "./authentication_event";
import {
  AuthenticationState,
  AuthenticationAuthenticated,
  AuthenticationInitial,
  AuthenticationUnAuthenticated,
} from "./authentication_state";

// A `CounterBloc` which handles converting `CounterEvent`s into `int`s.
export default class AuthenticationBloc extends Bloc<
  AuthenticationEvent,
  AuthenticationState
> {
  authenticationData: AuthenticationData = undefined as any;

  constructor() {
    super(new AuthenticationInitial());
  }

  async *mapEventToState(event: AuthenticationEvent) {
    if (event instanceof AuthenticationAppLoaded) {
      yield* this.mapAuthenticationAppLoadedToState(event);
    } else if (event instanceof AuthenticationLoggedIn) {
      yield* this.mapAuthenticationLoggedInToState(event);
    } else if (event instanceof AuthenticationLoggedOut) {
      yield* this.mapAuthenticationLoggedOutToState(event);
    }
  }

  async *mapAuthenticationAppLoadedToState(event: AuthenticationAppLoaded) {
    var accessToken: string = AuthenticationService.storageGetToken();
    if (accessToken != null && accessToken != undefined) {
      try {
        var mySelftData: AxiosResponse = await AuthenticationService.getMySelft(
          accessToken
        );

        if (mySelftData.status == 200) {
          this.authenticationData = new AuthenticationData({
            accessToken: accessToken,
            user: User.fromJson(mySelftData.data.user),
          });

          yield new AuthenticationAuthenticated(this.authenticationData);
          return;
        }
      } catch (e: any) {
        console.log(e);
      }
    }

    AuthenticationService.storageSetToken(null);
    yield new AuthenticationUnAuthenticated();
  }

  async *mapAuthenticationLoggedInToState(event: AuthenticationLoggedIn) {
    // AuthService().saveAuthData(event.authenticationData);
    this.authenticationData = event.authenticationData;
    AuthenticationService.storageSetToken(this.authenticationData.accessToken);

    yield new AuthenticationAuthenticated(
      event.authenticationData,
      event.fromRegistration
    );
  }

  async *mapAuthenticationLoggedOutToState(event: AuthenticationLoggedOut) {
    // await DataService.logOut();
    AuthenticationService.storageSetToken(null);

    this.authenticationData = undefined as any;

    yield new AuthenticationUnAuthenticated();
  }

  // Called whenever an `event` is added.
  onEvent(event: AuthenticationEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

import React, { useContext, useEffect, useState } from "react";

import ServiceComment from "models/service_comment";
import moment from "moment";
import GeneralHelper from "helpers/general_helper";
import { AuthenticationAuthenticated, AuthenticationInitial, AuthenticationState, AuthenticationUnAuthenticated } from "blocs/authentication_bloc/authentication_state";
import { BlocsContext } from "contexts/blocs_context";
import { Link } from "react-router-dom";
import ServiceCommentRepository from "repositories/service_comment_repository";

interface Props {
  onAddComment: (comment: string) => void;
  error: string | undefined;
  commentValue: string;
  setCommentValue: (comment: string) => void;
}

const CommentInput: React.FC<Props> = ({ onAddComment, error, commentValue, setCommentValue }) => {
  const blocsContext = useContext(BlocsContext);

  // const [commentValue, setCommentValue] = useState<string>("");

  const [authenticationState, setAuthenticationState] = useState<AuthenticationState>(new AuthenticationInitial());
  useEffect(() => {
    setAuthenticationState(blocsContext.authenticationBloc.state);
    const authenticationSubscription = blocsContext.authenticationBloc.listen(setAuthenticationState);

    return () => {
      authenticationSubscription.unsubscribe();
    };
  }, []);

  if (authenticationState instanceof AuthenticationUnAuthenticated) {
    return (
      <div className="MustLogIn mt-0">
        <div className="mustLogInBox">
          <div className="mustLogInTitle">Publier un commentaire</div>
          <div className="mustLogInText">Vous devez être connecté(e) pour pouvoir publier un commentaire.</div>
          <Link to="/login" className="mustLogInBtn">
            Se connecter
          </Link>
        </div>
      </div>
    );
  }

  if (authenticationState instanceof AuthenticationAuthenticated) {
    return (
      <div className="NewComment w-100 mb-5">
        <div className="addCommentBox">
          <textarea
            className="form-control form-control-lg form-control-solid formAddComment"
            name="name"
            placeholder="Votre commentaire ici..."
            value={commentValue}
            onChange={(e) => {
              setCommentValue(e.target.value);
            }}
            style={{ resize: "none" }}
          />
        </div>
        {error != undefined && <div className="error">{error}</div>}
        <div className="addCommentBtn" onClick={() => {
          onAddComment(commentValue);
          setCommentValue("");
        }}>Ajouter un commentaire</div>
      </div>
    );
  }
  return null;
};

export default CommentInput;

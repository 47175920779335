import GeneralHelper from "helpers/general_helper";

export default class Notification {
  id: number;
  title: string;
  content: string;
  createdAt: Date;

  constructor({
    id,
    title,
    content,
    createdAt,
  }: any /*NamedParameters*/) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.createdAt = createdAt;
  }

  static fromJson(map: Record<string, any>): Notification {
    return new Notification({
      id: GeneralHelper.parseIntOrNull(map["id"]),
      title: GeneralHelper.avoidNullOrEmptyString(map["title"]),
      content: GeneralHelper.avoidNullOrEmptyString(map["content"]),
      createdAt: new Date(map["created_at"]),
    });
  }
}

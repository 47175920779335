import { Bloc, Transition } from "@felangel/bloc";
import LocalProduct from "models/local_product";
import LocalProductRepository from "repositories/local_product_repository";
import {
  LocalProductsEvent,
  LoadLocalProducts,
  AddLocalProduct,
  EditLocalProduct,
  DeleteLocalProduct,
} from "./local_products_event";
import {
  LocalProductsState,
  LocalProductsLoaded,
  LocalProductsLoading,
  LocalProductsFailure,
} from "./local_products_state";

export default class LocalProductsBloc extends Bloc<LocalProductsEvent, LocalProductsState> {
  localProductRepository: LocalProductRepository;
  localProducts: Array<LocalProduct> = [];

  constructor(localProductRepository: LocalProductRepository) {
    super(new LocalProductsLoading());
    this.localProductRepository = localProductRepository;
  }

  async *mapEventToState(event: LocalProductsEvent) {
    if (event instanceof LoadLocalProducts) {
      yield* this.onLoadLocalProducts(event);
    } else if (event instanceof AddLocalProduct) {
      yield* this.onAddLocalProduct(event);
    } else if (event instanceof EditLocalProduct) {
      yield* this.onEditLocalProduct(event);
    } else if (event instanceof DeleteLocalProduct) {
      yield* this.onDeleteLocalProduct(event);
    }
  }

  async *onLoadLocalProducts(event: LoadLocalProducts) {
    try {
      yield new LocalProductsLoading();
      var localProductsList: Array<LocalProduct> = await this.localProductRepository.getData();
      this.localProducts = localProductsList;

      yield new LocalProductsLoaded(this.localProducts);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new LocalProductsFailure(e);
      } else {
        yield new LocalProductsFailure(e.toString());
      }
    }
  }

  async *onAddLocalProduct(event: AddLocalProduct) {
    try {
      yield new LocalProductsLoading();
      this.localProducts.push(event.localProduct);

      yield new LocalProductsLoaded(this.localProducts);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new LocalProductsFailure(e);
      } else {
        yield new LocalProductsFailure(e.toString());
      }
    }
  }

  async *onEditLocalProduct(event: EditLocalProduct) {
    try {
      yield new LocalProductsLoading();
      this.localProducts = this.localProducts.map((localProduct: LocalProduct) => {
        if (localProduct.id == event.localProduct.id) {
          localProduct = event.localProduct;
        }
        return localProduct;
      });

      yield new LocalProductsLoaded(this.localProducts);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new LocalProductsFailure(e);
      } else {
        yield new LocalProductsFailure(e.toString());
      }
    }
  }

  async *onDeleteLocalProduct(event: DeleteLocalProduct) {
    try {
      yield new LocalProductsLoading();
      this.localProducts = this.localProducts.filter((localProduct: LocalProduct) => {
        return localProduct.id != event.localProductId;
      });

      yield new LocalProductsLoaded(this.localProducts);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new LocalProductsFailure(e);
      } else {
        yield new LocalProductsFailure(e.toString());
      }
    }
  }

  onEvent(event: LocalProductsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

import { CompaniesLoaded, CompaniesLoading, CompaniesState } from "blocs/companies_bloc/companies_state";
import { CuriositiesLoaded, CuriositiesLoading, CuriositiesState } from "blocs/curiosities_bloc/curiosities_state";
import { EventsLoaded, EventsLoading, EventsState } from "blocs/events_bloc/events_state";
import ItinerariesBloc from "blocs/itineraries_bloc/itineraries_bloc";
import { ItinerariesLoaded, ItinerariesLoading, ItinerariesState } from "blocs/itineraries_bloc/itineraries_state";
import { MerchantsLoaded, MerchantsLoading, MerchantsState } from "blocs/merchants_bloc/merchants_state";
import { MunicipalitiesLoaded, MunicipalitiesLoading, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import { ServiceCategoriesLoading, ServiceCategoriesState } from "blocs/service_categories_bloc/service_categories_state";
import { ServicesLoaded, ServicesLoading, ServicesState } from "blocs/services_bloc/services_state";
import InfoWindow from "components/map/info_window";
import Map from "components/map/map";
import Marker from "components/map/marker";
import Polyline from "components/map/polyline";
import { BlocsContext } from "contexts/blocs_context";
import useQuery from "helpers/use_query";
import { data } from "jquery";
import BaseScreen from "layouts/base";
import Company from "models/company";
import Curiosity from "models/curiosity";
import Itinerary from "models/itinerary";
import Merchant from "models/merchant";
import Service from "models/service";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import markerStartEnd from "../../assets/images/marker_start_end.png";
import Municipality from "models/municipality";

interface Props extends WithTranslation {}

const MapScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  let query = useQuery();
  const datatypeParams = query.get("datatype");
  const idParams = query.get("id");

  const [serviceCategoriesState, setServiceCategoriesState] = useState<ServiceCategoriesState>(new ServiceCategoriesLoading());

  const [companiesState, setCompaniesState] = useState<CompaniesState>(new CompaniesLoading());
  const [curiositiesState, setCuriositiesState] = useState<CuriositiesState>(new CuriositiesLoading());
  const [eventsState, setEventsState] = useState<EventsState>(new EventsLoading());
  const [merchantsState, setMerchantsState] = useState<MerchantsState>(new MerchantsLoading());
  const [servicesState, setServicesState] = useState<ServicesState>(new ServicesLoading());
  const [municipalitiesState, setMunicipalitiesState] = useState<MunicipalitiesState>(new MunicipalitiesLoading());
  const [itinerariesState, setItinerariesState] = useState<ItinerariesState>(new ItinerariesLoading());

  const [selectedMarkerData, setSelectedMarkerData] = useState<any>(undefined);

  const navigate = useNavigate();

  const [isMarkerFromDataSet, setIsMarkerFromDataSet] = useState<boolean>(false);
  const [drawnItinerary, setDrawnItinerary] = useState<Itinerary | undefined>(undefined);

  const setSelectedMarkerDataFromParams = () => {
    if (isMarkerFromDataSet == true) return;

    if (datatypeParams != undefined && idParams != undefined) {
      if (datatypeParams == "curiosity" && curiositiesState instanceof CuriositiesLoaded) {
        const curiosity = curiositiesState.curiosities.find((curiosity: Curiosity) => curiosity.id == parseInt(idParams));
        if (curiosity != undefined) {
          setSelectedMarkerData(curiosity);
          setIsMarkerFromDataSet(true);
          map?.panTo({ lat: curiosity.latitude, lng: curiosity.longitude });
          map?.setZoom(15);
        }
      } else if (datatypeParams == "company" && companiesState instanceof CompaniesLoaded) {
        const company = companiesState.companies.find((company: any) => company.id == parseInt(idParams));
        if (company != undefined) {
          setSelectedMarkerData(company);
          setIsMarkerFromDataSet(true);
          map?.panTo({ lat: company.latitude, lng: company.longitude });
          map?.setZoom(15);
        }
      } else if (datatypeParams == "event" && eventsState instanceof EventsLoaded) {
        const event = eventsState.events.find((event: any) => event.id == parseInt(idParams));
        if (event != undefined) {
          setSelectedMarkerData(event);
          setIsMarkerFromDataSet(true);
          map?.panTo({ lat: event.latitude, lng: event.longitude });
          map?.setZoom(15);
        }
      } else if (datatypeParams == "merchant" && merchantsState instanceof MerchantsLoaded) {
        const merchant = merchantsState.merchants.find((merchant: any) => merchant.id == parseInt(idParams));
        if (merchant != undefined) {
          setSelectedMarkerData(merchant);
          setIsMarkerFromDataSet(true);
          map?.panTo({ lat: merchant.latitude, lng: merchant.longitude });
          map?.setZoom(15);
        }
      } else if (datatypeParams == "service" && servicesState instanceof ServicesLoaded) {
        const service = servicesState.services.find((service: any) => service.id == parseInt(idParams));
        if (service != undefined) {
          setSelectedMarkerData(service);
          setIsMarkerFromDataSet(true);
          map?.panTo({ lat: service.latitude, lng: service.longitude });
          map?.setZoom(15);
        }
      } else if (datatypeParams == "municipality" && municipalitiesState instanceof MunicipalitiesLoaded) {
        const municipality = municipalitiesState.municipalities.find((municipality: any) => municipality.id == parseInt(idParams));
        if (municipality != undefined) {
          setSelectedMarkerData(municipality);
          setIsMarkerFromDataSet(true);
          map?.panTo({ lat: municipality.latitude, lng: municipality.longitude });
          map?.setZoom(15);
        }
      } else if (datatypeParams == "itinerary" && itinerariesState instanceof ItinerariesLoaded) {
        const itinerary = itinerariesState.itineraries.find((itinerary: any) => itinerary.id == parseInt(idParams));
        if (itinerary != undefined) {
          setDrawnItinerary(itinerary);
          // map?.panTo({ lat: itinerary.latitude, lng: itinerary.longitude });
          // map?.setZoom(15);
          if (itinerary.waypoints.length > 0) {
            var bounds = itinerary.getBoundsFromLatLngList();
            map?.fitBounds(
              new google.maps.LatLngBounds(
                {
                  lat: bounds.sw.latitude,
                  lng: bounds.sw.longitude,
                },
                { lat: bounds.ne.latitude, lng: bounds.ne.longitude }
              )
            );
          }
        }
        // const itinerary = itinerariesState.itineraries.find((itinerary: any) => itinerary.id == parseInt(idParams));
        // if (itinerary != undefined) {
        //   setSelectedMarkerData(itinerary);
        //   setIsMarkerFromDataSet(true);
        //   map?.panTo({ lat: itinerary.latitude, lng: itinerary.longitude });
        //   map?.setZoom(15);
        // }
      }
    }
  };

  useEffect(() => {
    setSelectedMarkerDataFromParams();
  }, [curiositiesState, companiesState, eventsState, merchantsState, servicesState, municipalitiesState, itinerariesState]);

  useEffect(() => {
    setServiceCategoriesState(blocsContext.serviceCategoriesBloc.state);
    const serviceCategoriesSubscription = blocsContext.serviceCategoriesBloc.listen(setServiceCategoriesState);

    setCompaniesState(blocsContext.companiesBloc.state);
    const companiesSubscription = blocsContext.companiesBloc.listen(setCompaniesState);
    setCuriositiesState(blocsContext.curiositiesBloc.state);
    const curiositiesSubscription = blocsContext.curiositiesBloc.listen(setCuriositiesState);
    setEventsState(blocsContext.eventsBloc.state);
    const eventsSubscription = blocsContext.eventsBloc.listen(setEventsState);
    setMerchantsState(blocsContext.merchantsBloc.state);
    const merchantsSubscription = blocsContext.merchantsBloc.listen(setMerchantsState);
    setServicesState(blocsContext.servicesBloc.state);
    const servicesSubscription = blocsContext.servicesBloc.listen(setServicesState);
    setMunicipalitiesState(blocsContext.municipalitiesBloc.state);
    const municipalitiesSubscription = blocsContext.municipalitiesBloc.listen(setMunicipalitiesState);

    setItinerariesState(blocsContext.itinerariesBloc.state);
    const itinerariesSubscription = blocsContext.itinerariesBloc.listen(setItinerariesState);

    return () => {
      serviceCategoriesSubscription.unsubscribe();

      companiesSubscription.unsubscribe();
      curiositiesSubscription.unsubscribe();
      eventsSubscription.unsubscribe();
      merchantsSubscription.unsubscribe();
      servicesSubscription.unsubscribe();
      municipalitiesSubscription.unsubscribe();
      itinerariesSubscription.unsubscribe();
    };
  }, []);
  const [map, setMap] = React.useState<google.maps.Map>();

  const getMarkerDataType = (markerData: any): string => {
    if (markerData instanceof Company) {
      return "company";
    } else if (markerData instanceof Curiosity) {
      return "curiosity";
    } else if (markerData instanceof Event) {
      return "event";
    } else if (markerData instanceof Merchant) {
      return "merchant";
    } else if (markerData instanceof Service) {
      return "service";
    } else if (markerData instanceof Municipality) {
      return "municipality";
    }
    return "";
  };

  const renderWaypointsMarker = () => {
    if (datatypeParams == "itinerary" && itinerariesState instanceof ItinerariesLoaded && drawnItinerary != undefined) {
      return drawnItinerary.waypoints
        .map((waypoint: any, index: number) => {
          if (waypoint.type == "waypoint" && waypoint.icon == "default" && index != 0 && index != drawnItinerary.waypoints.length - 1) {
            return null;
          }
          let icon;

          switch (waypoint.icon) {
            case "monument":
              icon = require("../../assets/icons/monument.svg").default;
              break;
            case "curiosity":
              icon = require("../../assets/icons/curiosity.svg").default;
              break;
            case "fountain":
              icon = require("../../assets/icons/fountain.svg").default;
              break;
            case "picnic":
              icon = require("../../assets/icons/picnic.svg").default;
              break;
            case "restaurant":
              icon = require("../../assets/icons/restaurant.svg").default;
              break;
            case "hotel":
              icon = require("../../assets/icons/hotel.svg").default;
              break;
            case "religious":
              icon = require("../../assets/icons/religious.svg").default;
              break;
            case "point_of_view":
              icon = require("../../assets/icons/point_of_view.svg").default;
              break;
            case "kids_park":
              icon = require("../../assets/icons/kids_park.svg").default;
              break;
            case "shops":
              icon = require("../../assets/icons/shops.svg").default;
              break;
            default:
              icon = require("../../assets/icons/pin.svg").default;
          }
          if (waypoint.icon == "default" && (index == 0 || index == drawnItinerary.waypoints.length - 1)) {
            icon = markerStartEnd;
          }

          return (
            <Marker
              key={waypoint.id}
              position={{ lat: waypoint.latitude, lng: waypoint.longitude }}
              icon={{
                url: icon,
                fillColor: "#FF0000",
                scaledSize: new google.maps.Size(35, 35),
              }}
              onClick={() => {
                if (waypoint.type === "curiosity" && curiositiesState instanceof CuriositiesLoaded) {
                  setSelectedMarkerData(curiositiesState.curiosities.find((curiosity: Curiosity) => curiosity.id == waypoint.itemId));
                }
                if (waypoint.type === "merchant" && merchantsState instanceof MerchantsLoaded) {
                  setSelectedMarkerData(merchantsState.merchants.find((merchant: Merchant) => merchant.id == waypoint.itemId));
                }
              }}
            />
          );
        })
        .filter((marker) => marker != null);
    }
  };

  if (curiositiesState instanceof CuriositiesLoading || merchantsState instanceof MerchantsLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }

  return (
    <>
      <BaseScreen id="map-screen">
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="d-flex flex-stack flex-wrap mb-6 mt-5 mt-lg-20">
              <div>
                <div className="mb-5">
                  <a className="cursor-pointer backLinkSingle" onClick={() => navigate(-1)}>
                    ← Retour
                  </a>
                </div>
                <h3 className="fs-1 fw-800">Carte interactive</h3>
                <span className="subTitle">Parcourez la carte et interagissez avec les points d'intérêt</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row">
              <div className="col-12">
                <div className="ctaMap mb-12">
                  <Map
                    center={{ lat: 47.4, lng: 7.1 }}
                    zoom={11}
                    style={{}}
                    onClick={() => {
                      setSelectedMarkerData(undefined);
                    }}
                    onMapInitialized={setMap}
                    defaultMapTypeId={datatypeParams == "itinerary" ? "satellite" : "roadmap"}
                  >
                    {datatypeParams != "itinerary" &&
                      curiositiesState instanceof CuriositiesLoaded &&
                      curiositiesState.curiosities.map((curiosity: Curiosity) => (
                        <Marker
                          key={curiosity.id}
                          position={{ lat: curiosity.latitude, lng: curiosity.longitude }}
                          icon={{
                            url: require("../../assets/icons/pin.svg").default,
                            fillColor: "#EB00FF",
                            scaledSize: new google.maps.Size(35, 35),
                          }}
                          onClick={() => {
                            setSelectedMarkerData(curiosity);
                          }}
                        />
                      ))}

                    {datatypeParams != "itinerary" &&
                      companiesState instanceof CompaniesLoaded &&
                      companiesState.companies.map((company: any) => (
                        <Marker
                          key={company.id}
                          position={{ lat: company.latitude, lng: company.longitude }}
                          icon={{
                            url: require("../../assets/icons/pin.svg").default,
                            fillColor: "#EB00FF",
                            scaledSize: new google.maps.Size(35, 35),
                          }}
                          onClick={() => {
                            setSelectedMarkerData(company);
                          }}
                        />
                      ))}

                    {datatypeParams != "itinerary" &&
                      eventsState instanceof EventsLoaded &&
                      eventsState.events.map((event: any) => (
                        <Marker
                          key={event.id}
                          position={{ lat: event.latitude, lng: event.longitude }}
                          icon={{
                            url: require("../../assets/icons/pin.svg").default,
                            fillColor: "#EB00FF",
                            scaledSize: new google.maps.Size(35, 35),
                          }}
                          onClick={() => {
                            setSelectedMarkerData(event);
                          }}
                        />
                      ))}

                    {datatypeParams != "itinerary" &&
                      merchantsState instanceof MerchantsLoaded &&
                      merchantsState.merchants.map((merchant: any) => (
                        <Marker
                          key={merchant.id}
                          position={{ lat: merchant.latitude, lng: merchant.longitude }}
                          icon={{
                            url: require("../../assets/icons/pin.svg").default,
                            fillColor: "#EB00FF",
                            scaledSize: new google.maps.Size(35, 35),
                          }}
                          onClick={() => {
                            setSelectedMarkerData(merchant);
                          }}
                        />
                      ))}

                    {datatypeParams != "itinerary" &&
                      servicesState instanceof ServicesLoaded &&
                      servicesState.services.map((service: any) => (
                        <Marker
                          key={service.id}
                          position={{ lat: service.latitude, lng: service.longitude }}
                          icon={{
                            url: require("../../assets/icons/pin.svg").default,
                            fillColor: "#EB00FF",
                            scaledSize: new google.maps.Size(35, 35),
                          }}
                          onClick={() => {
                            setSelectedMarkerData(service);
                          }}
                        />
                      ))}
                    {datatypeParams != "itinerary" &&
                      municipalitiesState instanceof MunicipalitiesLoaded &&
                      municipalitiesState.municipalities.map((municipality: any) => (
                        <Marker
                          key={municipality.id}
                          position={{ lat: municipality.latitude, lng: municipality.longitude }}
                          icon={{
                            url: require("../../assets/icons/pin.svg").default,
                            fillColor: "#EB00FF",
                            scaledSize: new google.maps.Size(35, 35),
                          }}
                          onClick={() => {
                            setSelectedMarkerData(municipality);
                          }}
                        />
                      ))}

                    {/* {datatypeParams == "itinerary" &&
                      itinerariesState instanceof ItinerariesLoaded &&
                      drawnItinerary != undefined &&
                      drawnItinerary.waypoints.map((waypoint: any) => (
                        // <Marker
                        //   position={{ lat: waypoint.latitude, lng: waypoint.longitude }}
                        //   icon={{ url: require("../../assets/icons/pin.svg").default, fillColor: "#FF0000", scaledSize: new google.maps.Size(35, 35) }}
                        //   onClick={() => {
                        //     // setSelectedMarkerData(itinerariesState.itineraries[0]);
                        //   }}
                        // />

                   
                      ))} */}
                    {datatypeParams == "itinerary" && itinerariesState instanceof ItinerariesLoaded && drawnItinerary != undefined && (
                      <Polyline
                        onClick={(e) => {}}
                        polylineOptions={{
                          path: drawnItinerary.waypoints.map((waypoint: any) => new google.maps.LatLng(waypoint.latitude, waypoint.longitude)),
                          strokeColor: "#0f53ff",
                          strokeWeight: 8,
                        }}
                      />
                    )}
                    {/*{datatypeParams == "itinerary" && itinerariesState instanceof ItinerariesLoaded && drawnItinerary != undefined && drawnItinerary.waypoints.length > 0 && (*/}
                    {/*  <Marker*/}
                    {/*    position={{ lat: drawnItinerary.waypoints[0].latitude, lng: drawnItinerary.waypoints[0].longitude }}*/}
                    {/*    icon={{ url: markerStartEnd, fillColor: "#FF0000", scaledSize: new google.maps.Size(35, 35) }}*/}
                    {/*    onClick={() => {*/}
                    {/*      // setSelectedMarkerData(itinerariesState.itineraries[0]);*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*)}*/}
                    {/*{datatypeParams == "itinerary" && itinerariesState instanceof ItinerariesLoaded && drawnItinerary != undefined && drawnItinerary.waypoints.length > 1 && (*/}
                    {/*  <Marker*/}
                    {/*    position={{ lat: drawnItinerary.waypoints[drawnItinerary.waypoints.length - 1].latitude, lng: drawnItinerary.waypoints[drawnItinerary.waypoints.length - 1].longitude }}*/}
                    {/*    icon={{ url: markerStartEnd, fillColor: "#FF0000", scaledSize: new google.maps.Size(35, 35) }}*/}
                    {/*    onClick={() => {*/}
                    {/*      // setSelectedMarkerData(itinerariesState.itineraries[0]);*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*)}*/}

                    {datatypeParams == "itinerary" && itinerariesState instanceof ItinerariesLoaded && drawnItinerary != undefined && renderWaypointsMarker()}

                    {/* {popupInfo && (<Popup store={popupInfo} style={{ position: 'absolute', top: 0, left: 0, width: '200px' }} />)} */}

                    {selectedMarkerData != undefined && (
                      <InfoWindow
                        // children={<div>test</div>}
                        content={
                          (selectedMarkerData.category != undefined
                            ? '<div class="singleItemTags map"><div class="tagsWrapper"><div class="singleItemCategory">' + selectedMarkerData.category.name + "</div>"
                            : "") +
                          (selectedMarkerData.municipality != undefined ? '<div class="singleItemMunicipality">' + selectedMarkerData.municipality.name + "</div></div></div>" : "") +
                          '<div class=""><div class="content-title mapTitleMarker"><div>' +
                          selectedMarkerData.name +
                          "</div></div>" +
                          (selectedMarkerData.address ?? "") +
                          '<p class="button-wrapper"><a href="/' +
                          getMarkerDataType(selectedMarkerData) +
                          "/" +
                          selectedMarkerData.id +
                          '"><span class="more-link">En savoir plus</span></a></p></div>'
                        }
                        position={{
                          lat: selectedMarkerData.latitude,
                          lng: selectedMarkerData.longitude,
                        }}
                        onCloseClick={() => {
                          setSelectedMarkerData(undefined);
                          // alert("close");
                        }}
                        pixelOffset={new google.maps.Size(0, -35)}
                      />
                      //   {selectedMarkerData.name}
                      // </InfoWindow>
                    )}
                  </Map>
                  {/* <div className="map" ref={mapRef}></div> */}
                  {/* <div
                                        className="btnSeeMap"
                                        onClick={() => {
                                            // var mapSelector = document.querySelector(".gm-style");
                                            // mapSelector?.requestFullscreen();
                                            navigate("/map");
                                        }}
                                    >
                                        Voir en plein écran ⛶
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseScreen>
    </>
  );
};

export default withTranslation("common")(MapScreen);

import Itinerary from "models/itinerary"; // Adapter l'import en conséquence

import Config from "config/config";
import axios from "axios";

export default class ItineraryRepository {
  static _itinerariesEndpoint: string = "routes"; // Adapter l'endpoint en conséquence
  static _itinerariesDataKey: string = "routes_data"; // Adapter la clé de données en conséquence

  async getData(): Promise<Array<Itinerary>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + ItineraryRepository._itinerariesEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToItineraries(data.data.routes);
  }

  dataToItineraries(data: any): Array<Itinerary> {
    var itineraries: Array<Itinerary> = [];
    for (var i = 0; i < data.length; i++) {
      itineraries.push(Itinerary.fromJson(data[i]));
    }
    return itineraries;
  }
}

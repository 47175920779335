import { LoadCompanies } from "blocs/companies_bloc/companies_event";
import { CompaniesFailure, CompaniesLoaded, CompaniesLoading, CompaniesState } from "blocs/companies_bloc/companies_state";
import ArchiveFilter from "components/data_components/archive_filter";
import CompanyCard from "components/data_components/company_card";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Company from "models/company";
import GenericFilters from "models/filters/generic_filters";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BlocBuilder } from "@felangel/react-bloc";
import { MunicipalitiesLoaded, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import useQuery from "helpers/use_query";

// import styles from './home.scss';

interface Props extends WithTranslation { }

const CompaniesScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  let query = useQuery();
  const municipalityIdParams = query.get("municipalityId");

  const [companiesFilters, setGenericFilters] = useState<GenericFilters>(
    new GenericFilters({
      municipalityId: municipalityIdParams != undefined ? parseInt(municipalityIdParams) : undefined,
    })
  );

  const [companiesState, setCompaniesState] = useState<CompaniesState>(new CompaniesLoading());

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setCompaniesState(blocsContext.companiesBloc.state);
    const subscription = blocsContext.companiesBloc.listen(setCompaniesState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const company: Company | undefined = undefined;

  if (companiesState instanceof CompaniesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (companiesState instanceof CompaniesFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div
              className="btn btn-primary mt-6" onClick={() => blocsContext.companiesBloc.add(new LoadCompanies())}            >
              Recharger
            </div>
          </div>

        </div>
      </BaseScreen>
    );
  }

  if (companiesState instanceof CompaniesLoaded) {
    const companies: Company[] = companiesFilters.filter(companiesState.companies);

    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="d-flex flex-stack flex-wrap mb-6 mt-5 mt-lg-20">
              <div>
                <h3 className="fs-1 fw-800">Associations</h3>
                <div className="BackLinkWrapper">
                  <a className="cursor-pointer" onClick={() => navigate(-1)}>
                    ← Retour
                  </a>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center">
                {companiesFilters.municipalityId != undefined && (
                  <BlocBuilder
                    bloc={blocsContext.municipalitiesBloc}
                    builder={(state: MunicipalitiesState) => {
                      if (state instanceof MunicipalitiesLoaded) {
                        return (
                          <div className="badge badge-secondary fw-bolder me-2 activeService">
                            <div
                              className="cursor-pointer me-2"
                              onClick={() => {
                                var newCompaniesFilters = companiesFilters.clone();
                                newCompaniesFilters.municipalityId = undefined;
                                setGenericFilters(newCompaniesFilters);
                              }}
                            >
                              <i className="fa fa-xmark"></i>
                            </div>
                            Commune : {state.municipalities.find((municipality) => municipality.id == companiesFilters.municipalityId)?.name}
                          </div>
                        );
                      }
                      return <></>;
                    }}
                  />
                )}

                <ArchiveFilter
                  filters={companiesFilters}
                  onSetFilters={(filters: GenericFilters) => {
                    setGenericFilters(new GenericFilters({ ...filters }));
                  }}
                  dataType="Company"
                />
              </div>
            </div>
            {companies.length == 0 && (
              <div className="card-px text-center py-20 my-20">
                <h2 className="fs-2x fw-bold mb-8">Aucun élément trouvé</h2>
                <p className="text-gray-400 fs-4 fw-semibold mb-10">Essayez d'ajuster les filtres pour trouver ce que vous cherchez</p>
                {/* <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add Customer</a> */}
              </div>
            )}
            {companies.length != 0 && (
              <div className="row row-cols-1 g-5 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                {companies.map((company) => (
                  <CompanyCard key={company.id} company={company} />
                ))}
              </div>
            )}
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(company)
};

export default withTranslation("common")(CompaniesScreen);

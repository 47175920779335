import { Bloc, Transition } from "@felangel/bloc";
import Notification from "models/notification";
import NotificationRepository from "repositories/notification_repository";
import {
  NotificationsEvent,
  LoadNotifications,
  AddNotification,
  EditNotification,
  DeleteNotification,
} from "./notifications_event";
import {
  NotificationsState,
  NotificationsLoaded,
  NotificationsLoading,
  NotificationsFailure,
} from "./notifications_state";

export default class NotificationsBloc extends Bloc<NotificationsEvent, NotificationsState> {
  notificationRepository: NotificationRepository;
  notifications: Array<Notification> = [];

  constructor(notificationRepository: NotificationRepository) {
    super(new NotificationsLoading());
    this.notificationRepository = notificationRepository;
  }

  async *mapEventToState(event: NotificationsEvent) {
    if (event instanceof LoadNotifications) {
      yield* this.onLoadNotifications(event);
    } else if (event instanceof AddNotification) {
      yield* this.onAddNotification(event);
    } else if (event instanceof EditNotification) {
      yield* this.onEditNotification(event);
    } else if (event instanceof DeleteNotification) {
      yield* this.onDeleteNotification(event);
    }
  }

  async *onLoadNotifications(event: LoadNotifications) {
    try {
      yield new NotificationsLoading();
      var notificationsList: Array<Notification> = await this.notificationRepository.getData();
      this.notifications = notificationsList;

      yield new NotificationsLoaded(this.notifications);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new NotificationsFailure(e);
      } else {
        yield new NotificationsFailure(e.toString());
      }
    }
  }

  async *onAddNotification(event: AddNotification) {
    try {
      yield new NotificationsLoading();
      this.notifications.push(event.notification);

      yield new NotificationsLoaded(this.notifications);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new NotificationsFailure(e);
      } else {
        yield new NotificationsFailure(e.toString());
      }
    }
  }

  async *onEditNotification(event: EditNotification) {
    try {
      yield new NotificationsLoading();
      this.notifications = this.notifications.map((notification: Notification) => {
        if (notification.id == event.notification.id) {
          notification = event.notification;
        }
        return notification;
      });

      yield new NotificationsLoaded(this.notifications);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new NotificationsFailure(e);
      } else {
        yield new NotificationsFailure(e.toString());
      }
    }
  }

  async *onDeleteNotification(event: DeleteNotification) {
    try {
      yield new NotificationsLoading();
      this.notifications = this.notifications.filter((notification: Notification) => {
        return notification.id != event.notificationId;
      });

      yield new NotificationsLoaded(this.notifications);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new NotificationsFailure(e);
      } else {
        yield new NotificationsFailure(e.toString());
      }
    }
  }

  onEvent(event: NotificationsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

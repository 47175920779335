import { LoadMunicipalities } from "blocs/municipalities_bloc/municipalities_event";
import { MunicipalitiesFailure, MunicipalitiesLoaded, MunicipalitiesLoading, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import MunicipalityCard from "components/data_components/municipality_card";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import Municipality from "models/municipality";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

// import styles from './home.scss';

interface Props extends WithTranslation { }

const MunicipalitiesScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [municipalitiesState, setMunicipalitiesState] = useState<MunicipalitiesState>(new MunicipalitiesLoading());

  const params = useParams<{ id: string }>();

  useEffect(() => {
    setMunicipalitiesState(blocsContext.municipalitiesBloc.state);
    const subscription = blocsContext.municipalitiesBloc.listen(setMunicipalitiesState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const municipality: Municipality | undefined = undefined;

  if (municipalitiesState instanceof MunicipalitiesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (municipalitiesState instanceof MunicipalitiesFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div
              className="btn btn-primary mt-6" onClick={() => blocsContext.municipalitiesBloc.add(new LoadMunicipalities())}            >
              Recharger
            </div>
          </div>

        </div>
      </BaseScreen>
    );
  }

  if (municipalitiesState instanceof MunicipalitiesLoaded) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <div>
                  <h3 className="fs-1 fw-800">Communes</h3>
                  <span className="subTitle">Découvrez les communes participantes au projet</span>
                </div>
              </div>
            </div>

            <div className="row row-cols-1 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
              {municipalitiesState.municipalities
                .filter((municipality) => municipality.parent == undefined)
                .map((municipality) => (
                  <MunicipalityCard key={municipality.id} municipality={municipality} />
                ))}
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(municipality)
};

export default withTranslation("common")(MunicipalitiesScreen);

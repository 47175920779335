import Municipality from "models/municipality";

export abstract class MunicipalitiesEvent {}

export class LoadMunicipalities extends MunicipalitiesEvent {
  public toString = (): string => `LoadMunicipalities`;
}

export class AddMunicipality extends MunicipalitiesEvent {
  municipality: Municipality;
  constructor(municipality: Municipality) {
    super();
    this.municipality = municipality;
  }

  public toString = (): string => `AddMunicipality`;
}

export class EditMunicipality extends MunicipalitiesEvent {
  municipality: Municipality;
  constructor(municipality: Municipality) {
    super();
    this.municipality = municipality;
  }

  public toString = (): string => `EditMunicipality`;
}

export class DeleteMunicipality extends MunicipalitiesEvent {
  municipalityId: number;
  constructor(municipalityId: number) {
    super();
    this.municipalityId = municipalityId;
  }

  public toString = (): string => `DeleteMunicipality`;
}

import Notification from "models/notification";
import Config from "config/config";
import axios from "axios";

export default class NotificationRepository {
  static _notificationsEndpoint: string = "notifications";
  static _notificationsDataKey: string = "notifications_data";

  async getData(): Promise<Array<Notification>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + NotificationRepository._notificationsEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToNotifications(data.data.notifications);
  }

  dataToNotifications(data: any): Array<Notification> {
    var notifications: Array<Notification> = [];
    for (var i = 0; i < data.length; i++) {
      notifications.push(Notification.fromJson(data[i]));
    }
    return notifications;
  }
}

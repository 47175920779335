import { Bloc, Transition } from "@felangel/bloc";
import UniversalCategory from "models/universal_category";
import ItineraryCategoriesRepository from "repositories/itinerary_category_repository"; // Adapter l'import en fonction de ton repository
import {
  ItineraryCategoriesEvent,
  LoadItineraryCategories,
} from "./itinerary_categories_event"; // Adapter les imports en fonction de tes événements
import {
  ItineraryCategoriesState,
  ItineraryCategoriesLoaded,
  ItineraryCategoriesLoading,
  ItineraryCategoriesFailure,
} from "./itinerary_categories_state"; // Adapter les imports en fonction des états

export default class ItineraryCategoriesBloc extends Bloc<ItineraryCategoriesEvent, ItineraryCategoriesState> {
  itineraryRepository: ItineraryCategoriesRepository; // Adapter le nom du repository en conséquence
  categories: Array<UniversalCategory> = [];

  constructor(itineraryRepository: ItineraryCategoriesRepository) {
    super(new ItineraryCategoriesLoading()); // Adapter l'état initial en conséquence
    this.itineraryRepository = itineraryRepository; // Adapter le nom du repository en conséquence
  }

  async *mapEventToState(event: ItineraryCategoriesEvent) {
    if (event instanceof LoadItineraryCategories) { 
      yield* this.onLoadItineraryCategories(event); 
    }
  }

  async *onLoadItineraryCategories(event: LoadItineraryCategories) { 
    try {
      yield new ItineraryCategoriesLoading(); // Adapter l'état en conséquence
      var categoriesList: Array<UniversalCategory> = await this.itineraryRepository.getData(); // Adapter le nom du repository en conséquence
      this.categories = categoriesList;

      yield new ItineraryCategoriesLoaded(this.categories); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ItineraryCategoriesFailure(e); // Adapter l'état en conséquence
      } else {
        yield new ItineraryCategoriesFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }

  onEvent(event: ItineraryCategoriesEvent): void { 
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event =", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition =", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error =", error);
  }
}

import React, { useEffect } from "react";
import { useGeneralModal } from "./general_modal_context";

// functional component
// const GeneralModal = () => {
const GeneralModal: React.FC = () => {
  const generalModal = useGeneralModal();

  // const [isTotalyOpened, setIsTotalyOpened] = React.useState(
  //   generalModal.isModalOpen
  // );

  // const [isTotalyClosed, setIsTotalyClosed] = React.useState(
  //   !generalModal.isModalOpen
  // );

  // useEffect(() => {
  //   if (generalModal.isModalOpen) {

  //     setTimeout(() => {
  //       setIsTotalyOpened(true);

  //       // document.body.classList.add("body-scroll-lock");
  //     }, 500);
  //   } else {
  //     setTimeout(() => {
  //       setIsTotalyOpened(false);
  //       // document.body.classList.remove("body-scroll-lock");
  //     }, 500);
  //   }
  // }, [generalModal.isModalOpen]);

  // if (!generalModal.isModalOpen) return null;

  return (
    <div
      className={["modal fade", generalModal.isModalOpen ? "show" : ""].join(
        " "
      )}
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-modal="true"
      role="dialog"
      style={{
        display: generalModal.isModalOpen ? "block" : "none",
        // display: isTotalyOpened ? "block" : "none",
        // opacity: !generalModal.isModalOpen ? "0" : "1",
        // visibility: !generalModal.isModalOpen ? "hidden" : "visible",
      }}
    >
      <div
        className="position-fixed top-0 bottom-0 start-0 end-0"
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        onClick={() => {
          generalModal.closeModal();
        }}
      ></div>
      <div
        className="modal-dialog modal-dialog-centered mw-900px"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div className="modal-content">{generalModal.content}</div>
      </div>
    </div>
  );
  return (
    <div
      className="modal fade show"
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-modal="true"
      role="dialog"
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content"></div>
      </div>
    </div>
  );
};

export default GeneralModal;

import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
// import styles from './home.scss';

import backgroundImage from "assets/images/home_section_1_background.jpg";
import { Link } from "react-router-dom";
import News from "models/news";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { NewsLoaded, NewsState } from "blocs/news_bloc/news_state";
import GeneralHelper from "helpers/general_helper";

import defaultPic from "assets/images/default_pic.png";
import moment from "moment";
import { ImageSizes } from "constants/constants";

interface Props extends WithTranslation {
  news: News;
}

const NewsCard: React.FC<Props> = ({ t, news }) => {
  return (
    <div className="col newsCard">
      <Link to={"/news/" + news.id} className="d-block mb-11 mb-md-5 cardLink" data-fslightbox="lightbox-hot-sales">
        {/* <a
            className="d-block overlay"
            data-fslightbox="lightbox-hot-sales"
            href="assets/media/stock/900x600/42.jpg"
          > */}
        <div className="singleItemCuriosity">
          <div className="cardCuriosity itinerary">
            <img src={news.getFirstImage(ImageSizes.small) ?? defaultPic} className="curiosityImg" />
            <div className="cardCuriosityInnerContent">
              <div className="cardMunicipalityTag">{news.municipality.name}</div>
              <div className="cardTitle">{GeneralHelper.truncateText(news.name, 40)}</div>
              <div className="cardCategory">{news.category.name}</div>
              <div className="dividerCardItinerary"></div>
              <div className="cardCategory additionnal">Publié le {moment(news.createdAt).locale("fr").format("D MMMM YYYY")}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default withTranslation("common")(NewsCard);

import ServiceComment from "models/service_comment";

export abstract class ServiceCommentsState {} 

export class ServiceCommentsLoading extends ServiceCommentsState {} 

export class ServiceCommentsLoaded extends ServiceCommentsState {
  comments: Array<ServiceComment>; // Adapter le nom du modèle en conséquence
  constructor(comments: Array<ServiceComment>) {
    super();
    this.comments = comments;
  }

  public toString = (): string => {
    return `ServiceCommentsLoaded ${this.comments.length} comments`; // Adapter le nom en conséquence
  };
}

export class ServiceCommentsFailure extends ServiceCommentsState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `ServiceCommentsLoadingFailed { errorMessage: ${this.errorMessage} }`; // Adapter le nom en conséquence
  };
}

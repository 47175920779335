import Curiosity from "models/curiosity";
import Config from "config/config";
import axios from "axios";

export default class CuriosityRepository {
  static _curiositiesEndpoint: string = "curiosities";
  static _curiositiesDataKey: string = "curiosities_data";

  async getData(): Promise<Array<Curiosity>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + CuriosityRepository._curiositiesEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToCuriosities(data.data.curiosities);
  }

  dataToCuriosities(data: any): Array<Curiosity> {
    var curiosities: Array<Curiosity> = [];
    for (var i = 0; i < data.length; i++) {
      curiosities.push(Curiosity.fromJson(data[i]));
    }
    return curiosities;
  }
}

import React, { createContext, useContext, useState } from "react";

interface ConfirmationModalContextType {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  onConfirm: Function;
  setOnConfirm: (arg1: Function) => void;
}

const ConfirmationModalContext = createContext<
  ConfirmationModalContextType | undefined
>(undefined);

export const useConfirmationModal = () => {
  const context = useContext(ConfirmationModalContext);
  if (!context) {
    throw new Error(
      "useConfirmationModal must be used within a ConfirmationModalProvider"
    );
  }
  return context;
};

export const ConfirmationModalProvider: React.FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState<Function>(() => {});

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ConfirmationModalContext.Provider
      value={{ isModalOpen, openModal, closeModal, onConfirm, setOnConfirm }}
    >
      {children}
    </ConfirmationModalContext.Provider>
  );
};

import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import "./back_button.scss";

interface Props {
  text: string;
  onClick: Function;
}

type State = {};

function useLocationEffect(callback: (location?: any) => any) {
  const location = useLocation();

  useEffect(() => {
    callback(location);
  }, [location, callback]);
}

function ScrollToTopOnpageChange() {

  useLocationEffect((location: Location) => window.scrollTo(0, 0));

  // useEffect(() => {
  //   const unlisten = history.listen(() => {
      
  //   });
  //   return () => {
  //     unlisten();
  //   };
  // }, []);

  return null;
}

export default ScrollToTopOnpageChange;

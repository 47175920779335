import Curiosity from "models/curiosity";

export abstract class CuriositiesState {}

// export class CuriositiesInitial extends CuriositiesState {}

export class CuriositiesLoading extends CuriositiesState {}

export class CuriositiesLoaded extends CuriositiesState {
  curiosities: Array<Curiosity>;
  constructor(curiosities: Array<Curiosity>) {
    super();
    this.curiosities = curiosities;
  }

  public toString = (): string => {
    return `CuriositiesLoaded ${this.curiosities.length} curiosities`;
  };
}

export class CuriositiesFailure extends CuriositiesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `CuriositiesLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}

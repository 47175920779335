import Event from "models/event";

export abstract class EventsEvent {}

export class LoadEvents extends EventsEvent {
  public toString = (): string => `LoadEvents`;
}

export class AddEvent extends EventsEvent {
  event: Event;
  constructor(event: Event) {
    super();
    this.event = event;
  }

  public toString = (): string => `AddEvent`;
}

export class EditEvent extends EventsEvent {
  event: Event;
  constructor(event: Event) {
    super();
    this.event = event;
  }

  public toString = (): string => `EditEvent`;
}

export class DeleteEvent extends EventsEvent {
  eventId: number;
  constructor(eventId: number) {
    super();
    this.eventId = eventId;
  }

  public toString = (): string => `DeleteEvent`;
}

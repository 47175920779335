import Itinerary from "models/itinerary";

export abstract class ItinerariesEvent {}

export class LoadItineraries extends ItinerariesEvent {
  public toString = (): string => `LoadItineraries`;
}

export class AddItinerary extends ItinerariesEvent {
  itinerary: Itinerary;
  constructor(itinerary: Itinerary) {
    super();
    this.itinerary = itinerary;
  }

  public toString = (): string => `AddItinerary`;
}

export class EditItinerary extends ItinerariesEvent {
  itinerary: Itinerary;
  constructor(itinerary: Itinerary) {
    super();
    this.itinerary = itinerary;
  }

  public toString = (): string => `EditItinerary`;
}

export class DeleteItinerary extends ItinerariesEvent {
  itineraryId: number;
  constructor(itineraryId: number) {
    super();
    this.itineraryId = itineraryId;
  }

  public toString = (): string => `DeleteItinerary`;
}

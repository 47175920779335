import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
// import styles from './home.scss';

import backgroundImage from "assets/images/home_section_1_background.jpg";
import { Link } from "react-router-dom";
import LocalProduct from "models/local_product";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { LocalProductsLoaded, LocalProductsState } from "blocs/local_products_bloc/local_products_state";
import GeneralHelper from "helpers/general_helper";

import defaultPic from "assets/images/default_pic.png";
import { ImageSizes } from "constants/constants";

interface Props extends WithTranslation {
  localProduct: LocalProduct;
}

const LocalProductCard: React.FC<Props> = ({ t, localProduct }) => {
  return (
    <div className="col localProduct">
      <Link to={"/local-product/" + localProduct.id} className="d-block mb-11 mb-md-5 cardLink" data-fslightbox="lightbox-hot-sales">
        {/* <a
            className="d-block overlay"
            data-fslightbox="lightbox-hot-sales"
            href="assets/media/stock/900x600/42.jpg"
          > */}
        <div className="singleItemCuriosity">
          <div className="cardCuriosity">
            <img src={localProduct.getFirstImage(ImageSizes.small) ?? defaultPic} className="curiosityImg" />
            <div className="cardCuriosityInnerContent">
              <div className="cardMunicipalityTag">{localProduct.municipality.name}</div>
              <div className="cardTitle">{GeneralHelper.truncateText(localProduct.name, 40)}</div>
              <div className="cardCategory">{localProduct.category.name}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default withTranslation("common")(LocalProductCard);

import UniversalCategory from "models/universal_category"; // Adapter l'import en conséquence

import Config from "config/config";
import axios from "axios";

export default class MerchantCategoryRepository {
  static _categoriesEndpoint: string = "merchant-categories"; // Adapter l'endpoint en conséquence
  static _categoriesDataKey: string = "merchant_categories_data"; // Adapter la clé de données en conséquence

  async getData(): Promise<Array<UniversalCategory>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + MerchantCategoryRepository._categoriesEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToCategories(data.data.merchantCategories);
  }

  dataToCategories(data: any): Array<UniversalCategory> {
    var categories: Array<UniversalCategory> = [];
    for (var i = 0; i < data.length; i++) {
      categories.push(UniversalCategory.fromJson(data[i]));
    }
    return categories;
  }
}

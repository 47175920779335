import React, {useEffect, useState} from "react";
import logo from "assets/images/logo_text.png";
// import "./header.scss";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import { BlocsContext } from "contexts/blocs_context";
import { BlocBuilder } from "@felangel/react-bloc";
import { AuthenticationAuthenticated, AuthenticationState } from "blocs/authentication_bloc/authentication_state";
import { AuthenticationLoggedOut } from "blocs/authentication_bloc/authentication_event";

import ReactModal from "react-modal";
import config from "config/config";

import AuthMenu from "./components/auth_menu";
import { useGeneralModal } from "components/modals/general_modal/general_modal_context";
import CreateServiceContent from "components/modals/create_service_content/create_service_content";
import iconMenu1 from "assets/images/home.png";
import iconMenu2 from "assets/images/help.png";
import iconMenu3 from "assets/images/tree.png";
import iconMenu4 from "assets/images/castle.png";
import iconMenu5 from "assets/images/villages.png";
import { useGlobalContext } from "contexts/global_context";

// type Props = {
// title: string,
// paragraph: string
// }

type State = {
  isToggleOn: boolean;
  modalIsOpen: boolean;
};

const CustomToggle = React.forwardRef(({ children, onClick, customClassName }: any, ref: any) => (
  <button
    // href="#"
    className={customClassName + ""}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    // onMouseEnter={(e) => onClick(e)}
    // onMouseLeave={(e) => onClick(e)}
  >
    {children}
    <span className="icon icon-angle-down"></span>
  </button>
));

interface Props extends WithTranslation {
  sticky: boolean;
}

const Header: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState<State>({
    isToggleOn: false,
    modalIsOpen: false,
  });

  const generalModal = useGeneralModal();
  const location = useLocation();

  const GlobalContext = useGlobalContext();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  const isRouteActive = (route: string): boolean => {
    return !!matchPath(location.pathname, route);
  };

  const handleClick = () => {
    setState({
      ...state,
      isToggleOn: !state.isToggleOn,
    });
  };

  const renderTopBar = () => {
    return (
      <div className="container-xxl d-flex flex-grow-1 flex-stack">
        <div className="align-items-center me-5" style={{
          display: props.sticky ? ((windowSize[0] >= 1200 && windowSize[0] <= 1280)? 'none' : 'flex') : 'flex'
        }}>
          <div className="d-xl-none btn btn-icon btn-active-color-primary w-30px h-30px ms-n2 me-3" id="kt_header_menu_toggle" onClick={GlobalContext.openDrawer}>
            <i className="ki-duotone ki-abstract-14 fs-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>

          <Link to={"/"}>
            <img alt="Logo" src={logo} className={"theme-light-show h-35px h-lg-45px ms-0 ms-lg-4"}   />
          </Link>
        </div>

        <div className={["d-flex align-items-center menu-on-sticky", props.sticky ? "show" : ""].join(" ")}>
          <div
            className="header-topbar-menu menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-state-primary menu-title-gray-800 menu-arrow-gray-400 align-items-stretch flex-grow-1 my-5 my-lg-0 px-2 px-lg-0 fw-semibold fs-6"
            id="#kt_header_menu"
          >
            {renderMenuLink("Accueil", "/", iconMenu1)}
            {renderMenuLink("Echange et entraide", "/echange-entraide", iconMenu2)}
            {renderMenuLink("Vie locale", "/vie-locale", iconMenu3)}
            {renderMenuLink("Tourisme et loisirs", "/tourisme-loisir", iconMenu4)}
            {renderMenuLink("Les communes", "/municipalities", iconMenu5)}
            {/* {renderMenuLink("A propos", "/a-propos")} */}
          </div>
        </div>

        <div className="d-flex align-items-center flex-shrink-0">
          <AuthMenu />
        </div>
      </div>
    );
  };

  const renderMenuLink = (title: string, to: string, imgURL: string) => {
    var selected: boolean = isRouteActive(to);
    return (
      <Link
        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
        data-kt-menu-placement="bottom-start"
        to={to}
        className={["menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2", selected ? "here show" : ""].join(" ")}
      >
        <span className="menu-link py-3">
          <span className="menu-icon">
            <img src={imgURL} />
          </span>
          <span className="menu-title">{title}</span>
          <span className="menu-arrow d-lg-none"></span>
        </span>
      </Link>
    );
  };
  const renderBottomBar = () => {
    return (
      <div className="header-menu-container container-xxl d-flex flex-stack h-lg-75px w-100" id="kt_header_nav">
        <div className="header-menu flex-column flex-lg-row">
          <div
            className="menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-state-primary menu-title-gray-800 menu-arrow-gray-400 align-items-stretch flex-grow-1 my-5 my-lg-0 px-2 px-lg-0 fw-semibold fs-6"
            id="#kt_header_menu"
            data-kt-menu="true"
          >
            {renderMenuLink("Accueil", "/", iconMenu1)}
            {renderMenuLink("Echange et entraide", "/echange-entraide", iconMenu2)}
            {renderMenuLink("Vie locale", "/vie-locale", iconMenu3)}
            {renderMenuLink("Tourisme et loisirs", "/tourisme-loisir", iconMenu4)}
            {renderMenuLink("Les communes", "/municipalities", iconMenu5)}
            {/* {renderMenuLink("A propos", "/a-propos")} */}
          </div>

          <div className="flex-shrink-0 p-4 p-lg-0">
            <Link
              to="/suggest-item"
              className="btn btn-sm text-primary fw-700 w-100 w-lg-auto me-3"
              title="200+ in-house components and 3rd-party plugins"
            >
              Suggérer un ajout
            </Link>

            <div
              onClick={() => {
                generalModal.setContent(<CreateServiceContent />);
                generalModal.openModal();
              }}
              className="btn btn-sm btn-light-primary fw-bold w-100 w-lg-auto btnAddClassified"
              title="200+ in-house components and 3rd-party plugins"
            >
              Publier une annonce
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="kt_header" className={["header", props.sticky ? "fixed" : ""].join(" ")} data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
      {renderTopBar()}

      <div className="separator"></div>
      {renderBottomBar()}
    </div>
  );
};

export default withTranslation("common")(Header);

import { Bloc, Transition } from "@felangel/bloc";
import Municipality from "models/municipality";
import MunicipalityRepository from "repositories/municipality_repository";
import {
  MunicipalitiesEvent,
  LoadMunicipalities,
  AddMunicipality,
  EditMunicipality,
  DeleteMunicipality,
} from "./municipalities_event";
import {
  MunicipalitiesState,
  MunicipalitiesLoaded,
  MunicipalitiesLoading,
  MunicipalitiesFailure,
} from "./municipalities_state";

export default class MunicipalitiesBloc extends Bloc<MunicipalitiesEvent, MunicipalitiesState> {
  municipalityRepository: MunicipalityRepository;
  municipalities: Array<Municipality> = [];

  constructor(municipalityRepository: MunicipalityRepository) {
    super(new MunicipalitiesLoading());
    this.municipalityRepository = municipalityRepository;
  }

  async *mapEventToState(event: MunicipalitiesEvent) {
    if (event instanceof LoadMunicipalities) {
      yield* this.onLoadMunicipalities(event);
    } else if (event instanceof AddMunicipality) {
      yield* this.onAddMunicipality(event);
    } else if (event instanceof EditMunicipality) {
      yield* this.onEditMunicipality(event);
    } else if (event instanceof DeleteMunicipality) {
      yield* this.onDeleteMunicipality(event);
    }
  }

  async *onLoadMunicipalities(event: LoadMunicipalities) {
    try {
      yield new MunicipalitiesLoading();
      var municipalitiesList: Array<Municipality> = await this.municipalityRepository.getData();
      this.municipalities = municipalitiesList;

      yield new MunicipalitiesLoaded(this.municipalities);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new MunicipalitiesFailure(e);
      } else {
        yield new MunicipalitiesFailure(e.toString());
      }
    }
  }

  async *onAddMunicipality(event: AddMunicipality) {
    try {
      yield new MunicipalitiesLoading();
      this.municipalities.push(event.municipality);

      yield new MunicipalitiesLoaded(this.municipalities);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new MunicipalitiesFailure(e);
      } else {
        yield new MunicipalitiesFailure(e.toString());
      }
    }
  }

  async *onEditMunicipality(event: EditMunicipality) {
    try {
      yield new MunicipalitiesLoading();
      this.municipalities = this.municipalities.map((municipality: Municipality) => {
        if (municipality.id == event.municipality.id) {
          municipality = event.municipality;
        }
        return municipality;
      });

      yield new MunicipalitiesLoaded(this.municipalities);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new MunicipalitiesFailure(e);
      } else {
        yield new MunicipalitiesFailure(e.toString());
      }
    }
  }

  async *onDeleteMunicipality(event: DeleteMunicipality) {
    try {
      yield new MunicipalitiesLoading();
      this.municipalities = this.municipalities.filter((municipality: Municipality) => {
        return municipality.id != event.municipalityId;
      });

      yield new MunicipalitiesLoaded(this.municipalities);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new MunicipalitiesFailure(e);
      } else {
        yield new MunicipalitiesFailure(e.toString());
      }
    }
  }

  onEvent(event: MunicipalitiesEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

import Notification from "models/notification";

export abstract class NotificationsEvent {}

export class LoadNotifications extends NotificationsEvent {
  public toString = (): string => `LoadNotifications`;
}

export class AddNotification extends NotificationsEvent {
  notification: Notification;
  constructor(notification: Notification) {
    super();
    this.notification = notification;
  }

  public toString = (): string => `AddNotification`;
}

export class EditNotification extends NotificationsEvent {
  notification: Notification;
  constructor(notification: Notification) {
    super();
    this.notification = notification;
  }

  public toString = (): string => `EditNotification`;
}

export class DeleteNotification extends NotificationsEvent {
  notificationId: number;
  constructor(notificationId: number) {
    super();
    this.notificationId = notificationId;
  }

  public toString = (): string => `DeleteNotification`;
}

import GeneralHelper from "helpers/general_helper";
import UniversalCategory from "./universal_category";
import ResponsiveImage from "./responsive_image";
import { ImageSizes } from "constants/constants";

export default class Municipality {
  id: number;
  name: string;
  description: string;
  parent: Municipality;
  altitude: string;
  area: string;
  population: string;
  latitude: number;
  longitude: number;
  distance: number;
  // images: Array<string>;
  responsiveImages: Array<ResponsiveImage>;
  createdAt: Date;

  constructor({ id, name, description, parent, altitude, area, population, latitude, longitude, distance, /*images,*/ responsiveImages, createdAt }: any /*NamedParameters*/) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.parent = parent;
    this.altitude = altitude;
    this.area = area;
    this.population = population;
    this.latitude = latitude;
    this.longitude = longitude;
    this.distance = distance;
    // this.images = images;
    this.responsiveImages = responsiveImages;
    this.createdAt = createdAt;
  }

  static areIdentical(a: Municipality, b: Municipality): boolean {
    if (!a && !b) return true;
    if (!a || !b) return false;
    return a.id === b.id;
  }

  static fromJson(map: Record<string, any>): Municipality | undefined {
    if (map == null || map == undefined) return undefined;
    return new Municipality({
      id: GeneralHelper.parseIntOrNull(map["id"]),
      name: GeneralHelper.avoidNullOrEmptyString(map["name"]),
      description: GeneralHelper.avoidNullOrEmptyString(map["description"]),
      parent: Municipality.fromJson(map["parent"]),
      altitude: GeneralHelper.avoidNullOrEmptyString(map["altitude"]),
      area: GeneralHelper.avoidNullOrEmptyString(map["area"]),
      population: GeneralHelper.avoidNullOrEmptyString(map["population"]),
      latitude: GeneralHelper.parseDoubleOrNull(map["latitude"]),
      longitude: GeneralHelper.parseDoubleOrNull(map["longitude"]),
      createdAt: new Date(map["created_at"]),
      images: map["images"] != null ? map["images"].map((image: string) => image) : undefined,
      responsiveImages: map["images_responsives"] != null ? map["images_responsives"].map((image: Map<string, string>) => ResponsiveImage.fromJson(image)) : [],
    });
  }

  // copyWith function
  public copyWith({ id, name, description, parent, altitude, area, population, latitude, longitude, distance, /*images,*/ responsiveImages, createdAt }: any): Municipality {
    return new Municipality({
      id: id ?? this.id,
      name: name ?? this.name,
      description: description ?? this.description,
      parent: parent ?? this.parent,
      altitude: altitude ?? this.altitude,
      area: area ?? this.area,
      population: population ?? this.population,
      latitude: latitude ?? this.latitude,
      longitude: longitude ?? this.longitude,
      distance: distance ?? this.distance,
      // images: images ?? this.images,
      responsiveImages: responsiveImages ?? this.responsiveImages,
      createdAt: createdAt ?? this.createdAt,
    });
  }

  public getFirstImage(size: ImageSizes): string | undefined {
    return this.responsiveImages.length > 0 ? this.responsiveImages[0].getUrlFromSize(size) : undefined;
    // return this.images.length > 0 ? this.images[0] : undefined;
  }
}

import BaseScreen from "layouts/base";
import React from "react";
import "./eula.scss";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {}

type State = {};

class EulaScreen extends React.Component<Props, State> {
  // static contextType = BlocsContext;
  // context!: React.ContextType<typeof BlocsContext>

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  renderTitle() {
    return (
      <div className="container">
        <div className="sort-and-filter d-flex py-3">
          <div className="me-5">
            <h2>Conditions générales d'utilisation</h2>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <BaseScreen>
        <div className="w-100 eula d-flex flex-column align-items-center">
          <div className="border-bottom my-4 w-100 mx-auto">{this.renderTitle()}</div>
          <div className="w-100 mx-auto">
            <div className="container align-items-center mx-auto">{this.renderContentAs(this.props.i18n.language)}</div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  renderContentAs(lang: String) {
    switch (lang) {
      case "fr":
        return (
          <div id="main-content" className="main">
            <h3>CONTRAT DE LICENCE UTILISATEUR FINAL</h3>

            <p>Dernière mise à jour le 11 septembre 2023</p>

            <p>
              L'application mobile Région Haute-Ajoie vous (l'utilisateur final) est concédé sous licence par Novadev Sàrl, situé à Rue des Places 38, 2855 Glovelier, Suisse (ci-après : le concédant),
              pour une utilisation uniquement selon les termes du présent contrat de licence.
            </p>
            <p>
              En téléchargeant l'Application depuis l'App Store d'Apple ou le Play Store de Google, et toute mise à jour de celle-ci (comme autorisé par le présent Contrat de licence), vous indiquez
              que vous acceptez d'être lié par tous les termes et conditions du présent Contrat de licence, et que vous acceptez ce Contrat de licence.
            </p>

            <p>
              Les parties au présent Contrat de licence reconnaissent que ni Apple ni Google ne sont parties au présent Contrat de licence et ne sont pas liés par les dispositions ou obligations
              relatives à l'Application, telles que la garantie, la responsabilité, la maintenance et le support de celle-ci. Novadev Sàrl, et non Apple ou Google, est seul responsable de
              l'Application sous licence et de son contenu.
            </p>

            <p>
              Le présent Contrat de licence ne peut pas prévoir de règles d'utilisation de l'Application qui soient en contradiction avec les dernières Conditions de service de l'App Store de Apple ou
              du Play Store de Google. Novadev Sàrl reconnaît qu'il a eu l'occasion d'examiner lesdites conditions et que le présent Contrat de licence n'est pas en conflit avec elles.
            </p>
            <p>Tous les droits qui ne vous sont pas expressément accordés sont réservés.</p>

            <h3>1. L'APPLICATION</h3>
            <p>
              Région Haute-Ajoie (ci-après : l'Application) est un logiciel créé pour faciliter la recherche d'informations touristiques et culturelles sur les régions de Haute-Ajoie, Fahy,
              Courtedoux, Bure et Grandfontaine. L'application permet également la mise en relation de personnes via le système de publication de services.
            </p>

            <h3>2. PORTÉE DE LA LICENCE</h3>

            <p>
              2.1 Il vous est accordé une licence non transférable, non exclusive et ne pouvant faire l'objet d'une sous-licence pour installer et utiliser l'Application sous licence sur tout appareil
              mobile Apple ou Android que vous (l'Utilisateur final) possédez ou contrôlez et comme le permettent les Règles d'utilisation énoncées dans la présente section et les Conditions de
              service de l'App Store ou de Google Play, à l'exception du fait que cette Application sous licence peut être accessible et utilisée par d'autres comptes associés à vous (l'Utilisateur
              final, l'Acheteur) par le biais du Partage familial ou de l'achat en volume.
            </p>
            <p>
              2.2 Cette licence régira également toutes les mises à jour de l'Application fournies par le Concédant qui remplacent, réparent et/ou complètent la première Application, sauf si une
              licence distincte est fournie pour cette mise à jour, auquel cas les conditions de cette nouvelle licence s'appliqueront.
            </p>
            <p>
              2.3 Vous ne pouvez pas partager ou mettre l'Application à la disposition de tiers (sauf dans la mesure où cela est autorisé par les conditions générales d'Apple ou de Google, et avec le
              consentement écrit préalable de Novadev Sàrl), vendre, louer, prêter, donner en location ou redistribuer l'Application de toute autre manière.
            </p>
            <p>
              2.4 Vous ne pouvez pas faire de l'ingénierie inverse, traduire, désassembler, intégrer, décompiler, intégrer, supprimer, modifier, combiner, créer des œuvres dérivées ou des mises à
              jour, adapter ou tenter de dériver le code source de l'Application, ou toute partie de celle-ci (sauf avec l'accord écrit préalable de Novadev Sàrl).
            </p>
            <p>
              2.5 Vous ne pouvez pas copier (sauf lorsque cela est expressément autorisé par la présente licence et les Règles d'Utilisation) ou modifier l'Application ou des parties de celle-ci. Vous
              pouvez créer et stocker des copies uniquement sur des appareils qui vous appartiennent ou que vous contrôlez, à des fins de sauvegarde, conformément aux conditions de la présente
              licence, aux conditions d'utilisation de l'App Store ou de Google Play, et à toute autre condition applicable à l'appareil ou au logiciel utilisé. Vous n'êtes pas autorisé à supprimer
              tout avis de propriété intellectuelle. Vous reconnaissez qu'aucun tiers non autorisé ne peut accéder à ces copies à tout moment.
            </p>
            <p>2.6 La violation des obligations mentionnées ci-dessus, ainsi que la tentative d'une telle violation, peuvent faire l'objet de poursuites et de dommages et intérêts.</p>
            <p>2.7 Le concédant se réserve le droit de modifier les termes et conditions de la licence.</p>
            <p>
              2.8 Aucune disposition de la présente licence ne doit être interprétée comme restreignant les conditions des tiers. Lorsque vous utilisez l'Application, vous devez vous assurer que vous
              vous conformez aux termes et conditions applicables aux tiers.
            </p>

            <h3>3. EXIGENCES TECHNIQUES</h3>

            <p>3.1 L'Application nécessite un micrologiciel de version 1.0.0 ou supérieure. Le Concédant recommande d'utiliser la dernière version du micrologiciel.</p>
            <p>
              3.2 Vous reconnaissez qu'il est de votre responsabilité de confirmer et de déterminer que l'appareil de l'utilisateur final sur lequel vous avez l'intention d'utiliser l'Application
              satisfait aux spécifications techniques mentionnées ci-dessus.
            </p>
            <p>3.3 Le Concédant se réserve le droit de modifier les spécifications techniques comme il le juge approprié à tout moment.</p>

            <h3>4. PAS DE MAINTENANCE NI DE SUPPORT</h3>

            <p>4.1 Novadev Sàrl n'a aucune obligation, expresse ou implicite, de fournir une maintenance, un support technique ou autre pour l'Application.</p>
            <p>
              4.2 Novadev Sàrl et l'Utilisateur final reconnaissent qu'Apple ou Google n'a aucune obligation de fournir des services de maintenance et de support concernant l'Application sous licence.
            </p>

            <h3>5. UTILISATION DES DONNEES</h3>

            <p>
              <a href="https://region-hauteajoie.ch/privacy-policy">https://region-hauteajoie.ch/privacy-policy</a>.
            </p>

            <h3>6. CONTRIBUTIONS GÉNÉRÉES PAR L'UTILISATEUR</h3>

            <p>
              L'Application peut vous inviter à contribuer ou à participer à la création de contenu ou à l'évaluation de produits et services de tiers, et peut vous donner la possibilité de créer,
              soumettre, poster, afficher, transmettre, exécuter, publier, distribuer ou diffuser du contenu et du matériel à notre intention ou dans l'Application, y compris, mais sans s'y limiter,
              des textes, des écrits, des vidéos, des sons, des photographies, des graphiques, des commentaires, des suggestions ou des informations personnelles ou tout autre matériel
              (collectivement, les "Contributions").&nbsp;
            </p>
            <p>
              Les Contributions peuvent être consultées par d'autres utilisateurs de l'Application et par des sites web ou des applications de tiers. À ce titre, toutes les Contributions que vous
              transmettez peuvent être traitées comme non confidentielles et non exclusives. Lorsque vous créez ou mettez à disposition des Contributions, vous déclarez et garantissez que :
            </p>
            <p>
              1. La création, la distribution, la transmission, l'affichage public ou la performance, ainsi que l'accès, le téléchargement ou la copie de vos Contributions ne violent pas et ne
              violeront pas les droits de propriété, y compris, mais sans s'y limiter, les droits d'auteur, les brevets, les marques, les secrets commerciaux ou les droits moraux d'un tiers.
            </p>
            <p>
              2. Vous êtes le créateur et le propriétaire ou disposez des licences, droits, consentements, décharges et autorisations nécessaires pour utiliser et nous autoriser, ainsi que
              l'Application et les autres utilisateurs de l'Application, à utiliser vos Contributions de toute manière envisagée par l'Application et les présentes Conditions d'utilisation.
            </p>
            <p>
              3. Vous disposez du consentement écrit, de l'autorisation et/ou de la permission de chaque personne physique identifiable dans vos Contributions pour utiliser le nom ou la ressemblance
              de chacune de ces personnes physiques identifiables afin de permettre l'inclusion et l'utilisation de vos Contributions de la manière envisagée par l'Application et les présentes
              Conditions d'utilisation.
            </p>
            <p>4. Vos Contributions ne sont pas fausses, inexactes ou trompeuses.</p>
            <p>
              5. Vos Contributions ne sont pas des publicités non sollicitées ou non autorisées, du matériel promotionnel, des systèmes pyramidaux, des chaînes de lettres, des spams, des envois en
              masse ou d'autres formes de sollicitation.
            </p>
            <p>
              6. Vos Contributions ne sont pas obscènes, obscènes, lascives, dégoûtantes, violentes, harcelantes, diffamatoires, calomnieuses ou autrement répréhensibles (selon notre appréciation).
            </p>
            <p>7. Vos Contributions ne ridiculisent pas, ne se moquent pas, ne dénigrent pas, n'intimident pas et n'abusent pas de quelqu'un.</p>
            <p>
              8. Vos Contributions ne sont pas utilisées pour harceler ou menacer (au sens juridique de ces termes) une autre personne et pour promouvoir la violence contre une personne spécifique ou
              une catégorie de personnes.
            </p>
            <p>9. Vos Contributions ne violent pas les lois, règlements ou règles applicables.</p>
            <p>10. Vos Contributions ne violent pas les droits à la vie privée ou à la publicité d'un tiers.</p>
            <p>
              11. Vos Contributions ne contiennent aucun matériel sollicitant des informations personnelles auprès de personnes âgées de moins de 18 ans ou exploitant des personnes âgées de moins de
              18 ans de manière sexuelle ou violente.
            </p>
            <p>12. Vos Contributions ne violent aucune loi applicable concernant la pornographie enfantine, ou autrement destinée à protéger la santé ou le bien-être des mineurs.</p>
            <p>13. Vos Contributions ne comprennent pas de commentaires offensants liés à la race, l'origine nationale, le sexe, la préférence sexuelle ou le handicap physique.</p>
            <p>
              14. Vos Contributions ne violent pas autrement, ou ne sont pas liées à du matériel qui viole, toute disposition des présentes Conditions d'utilisation, ou toute loi ou réglementation
              applicable.
            </p>

            <p>
              Toute utilisation de l'Application en violation de ce qui précède viole les présentes Conditions d'utilisation et peut entraîner, entre autres, la résiliation ou la suspension de vos
              droits d'utilisation de l'Application.
            </p>

            <h3>7. LICENCE DE CONTRIBUTION</h3>

            <p>
              En publiant vos Contributions sur toute partie de l'Application ou en rendant les Contributions accessibles à l'Application en liant votre compte de l'Application à l'un de vos comptes
              de réseaux sociaux, vous nous accordez automatiquement, et vous déclarez et garantissez que vous avez le droit de nous accorder, un droit et une licence illimités, illimités,
              irrévocables, perpétuels, non exclusifs, transférables, exempts de redevances, entièrement payés, dans le monde entier, pour héberger, utiliser la copie, reproduire, divulguer, vendre,
              revendre, publier, diffuser à grande échelle, réattribuer des titres, archiver, stocker, mettre en cache, afficher publiquement, reformater, traduire, transmettre, extraire (en tout ou
              en partie) et distribuer ces contributions (y compris, sans s'y limiter, votre image et votre voix) à toutes fins, qu'il s'agisse de publicité commerciale ou autre, et de préparer des
              œuvres dérivées de ces contributions ou de les incorporer dans d'autres œuvres, et d'accorder et d'autoriser des sous-licences de ce qui précède. L'utilisation et la distribution peuvent
              se faire dans tous les formats de médias et par tous les canaux de médias.
            </p>

            <p>
              Cette licence s'appliquera à toute forme, tout média ou toute technologie connus aujourd'hui ou développés par la suite, et inclut notre utilisation de votre nom, de votre nom de société
              et de votre nom de franchise, selon le cas, et de toutes les marques commerciales, marques de service, noms commerciaux, logos et images personnelles et commerciales que vous fournissez.
              Vous renoncez à tous les droits moraux sur vos contributions, et vous garantissez que les droits moraux n'ont pas été autrement revendiqués sur vos contributions.
            </p>

            <p>
              Nous ne revendiquons aucun droit de propriété sur vos contributions. Vous conservez la pleine propriété de toutes vos Contributions et de tous les droits de propriété intellectuelle ou
              autres droits de propriété associés à vos Contributions. Nous ne sommes pas responsables des déclarations ou représentations contenues dans vos Contributions que vous fournissez dans
              n'importe quelle zone de l'Application. Vous êtes seul responsable de vos Contributions à l'Application et vous acceptez expressément de nous exonérer de toute responsabilité et de vous
              abstenir de toute action en justice à notre encontre concernant vos Contributions.
            </p>

            <p>
              Nous avons le droit, à notre seule et entière discrétion, (1) d'éditer, de rédiger ou de modifier de toute autre manière toute Contribution ; (2) de re-catégoriser toute Contribution
              pour la placer à un endroit plus approprié de l'Application ; et (3) de présélectionner ou de supprimer toute Contribution à tout moment et pour toute raison, sans préavis. Nous n'avons
              aucune obligation de surveiller vos Contributions.
            </p>

            <h3>8. RESPONSABILITÉ</h3>

            <p>
              8.1 Le Concédant ne peut être tenu responsable de tout dommage causé par un manquement aux obligations prévues à l'article 2 du présent Contrat. Afin d'éviter toute perte de données,
              vous êtes tenu d'utiliser les fonctions de sauvegarde de l'Application dans la mesure où les conditions d'utilisation applicables aux tiers le permettent. Vous êtes conscient qu'en cas
              d'altérations ou de manipulations de l'Application, vous n'aurez pas accès à l'Application sous licence.
            </p>

            <h3>9. GARANTIE</h3>

            <p>
              9.1 Le Concédant garantit que l'Application est exempte de logiciels espions, de chevaux de Troie, de virus ou de tout autre logiciel malveillant au moment de votre téléchargement. Le
              Concédant garantit que l'Application fonctionne comme décrit dans la documentation utilisateur.
            </p>
            <p>
              9.2 Aucune garantie n'est fournie pour l'Application qui n'est pas exécutable sur l'appareil, qui a été modifiée de manière non autorisée, manipulée de manière inappropriée ou coupable,
              combinée ou installée avec du matériel ou des logiciels inappropriés, utilisée avec des accessoires inappropriés, que ce soit par Vous-même ou par des tiers, ou s'il existe d'autres
              raisons en dehors de la sphère d'influence de Novadev Sàrl qui affectent l'exécutabilité de l'Application.
            </p>
            <p>
              9.3 Vous êtes tenu d'inspecter l'Application immédiatement après l'avoir installée et d'informer Novadev Sàrl des problèmes découverts sans délai par l'e-mail fourni dans les
              réclamations sur les produits. Le rapport de défaut sera pris en considération et examiné plus avant s'il a été envoyé par courrier dans un délai de quatorze (14) jours après sa
              découverte.
            </p>
            <p>
              9.4 Si nous confirmons que l'application est défectueuse, Novadev Sàrl se réserve le choix de remédier à la situation soit en résolvant le défaut, soit en effectuant une livraison de
              remplacement.
            </p>
            <p>
              9.5 En cas de non-conformité de l'Application à toute garantie applicable, vous pouvez en informer l'opérateur du magasin d'applications, et le prix d'achat de votre Application (le cas
              échéant) vous sera remboursé. Dans la mesure maximale autorisée par la loi applicable, l'Exploitant du magasin d'applications n'aura aucune autre obligation de garantie que ce soit en ce
              qui concerne l'Application, et toutes autres pertes, réclamations, dommages, responsabilités, dépenses et coûts attribuables à une négligence de respecter une garantie.
            </p>
            <p>
              9.6 Si l'utilisateur est un entrepreneur, toute réclamation fondée sur des défauts se prescrit après un délai légal de douze (12) mois après la mise à disposition de l'application à
              l'utilisateur. Les délais de prescription légaux s'appliquent aux utilisateurs qui sont des consommateurs.
            </p>

            <h3>10. REVENDICATIONS DU PRODUIT</h3>

            <p>
              Novadev Sàrl et l'Utilisateur final reconnaissent que Novadev Sàrl, et non Apple ou Google, est responsable du traitement de toute réclamation de l'Utilisateur final ou de tout tiers
              concernant l'Application sous licence ou la possession et/ou l'utilisation par l'Utilisateur final de cette Application sous licence, y compris, mais sans s'y limiter :
            </p>

            <p>(i) les réclamations relatives à la responsabilité du fait des produits ;</p>
            <p>(ii) toute réclamation selon laquelle l'Application sous licence n'est pas conforme à toute exigence légale ou réglementaire applicable ; et</p>
            <p>
              (iii) les réclamations découlant de la protection des consommateurs, de la vie privée ou d'une législation similaire, y compris en ce qui concerne l'utilisation par votre Application
              sous licence du HealthKit et du HomeKit.
            </p>

            <h3>11. CONFORMITÉ LÉGALE</h3>

            <p>
              Vous déclarez et garantissez que Vous n'êtes pas situé dans un pays soumis à un embargo du gouvernement américain, ou qui a été désigné par le gouvernement américain comme un pays "
              soutenant le terrorisme " ; et que Vous ne figurez sur aucune liste du gouvernement américain de parties interdites ou restreintes.
            </p>

            <h3>12. INFORMATIONS DE CONTACT &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</h3>

            <p>Pour les demandes générales, les plaintes, les questions ou les réclamations concernant l'Application sous licence, veuillez contacter :</p>
            <p>&nbsp; &nbsp; &nbsp;</p>
            <p>Novadev Sàrl</p>
            <p>Rue des places 38</p>
            <p>2855 Glovelier</p>
            <p>Suisse</p>
            <p>
              <a href="mailto:info@novadev.ch">info@novadev.ch</a>
            </p>

            <h3>13. RÉSILIATION</h3>

            <p>
              La licence est valide jusqu'à sa résiliation par Novadev Sàrl ou par Vous. Vos droits en vertu de cette licence seront résiliés automatiquement et sans préavis de la part de Black
              Momentum e.K. si Vous ne respectez pas l'une ou l'autre des conditions de cette licence. En cas de résiliation de la licence, vous devez cesser toute utilisation de l'Application et
              détruire toutes les copies, complètes ou partielles, de l'Application.
            </p>

            <h3>14. CONDITIONS D'ACCORD AVEC DES TIERS ET BÉNÉFICIAIRE (APP iOS)</h3>

            <p>Novadev Sàrl déclare et garantit que Novadev Sàrl se conformera aux conditions d'accord applicables aux tiers lors de l'utilisation de l'Application sous licence.</p>
            <p>
              Conformément à la section 9 des "Instructions relatives aux conditions minimales du contrat de licence de l'utilisateur final du développeur" d'Apple, les filiales d'Apple seront des
              bénéficiaires tiers du présent contrat de licence de l'utilisateur final et - après votre acceptation des conditions du présent contrat de licence, Apple aura le droit (et sera réputé
              avoir accepté le droit) d'appliquer le présent contrat de licence de l'utilisateur final à votre encontre en tant que bénéficiaire tiers de celui-ci.
            </p>

            <h3>15. DROITS DE PROPRIÉTÉ INTELLECTUELLE</h3>

            <p>
              Novadev Sàrl et l'Utilisateur final reconnaissent que, dans le cas d'une réclamation d'un tiers selon laquelle l'Application sous licence ou la possession et l'utilisation par
              l'Utilisateur final de cette Application sous licence enfreint les droits de propriété intellectuelle du tiers, Novadev Sàrl, et non Apple ou Google, sera seul responsable de l'enquête,
              de la défense, du règlement et de la décharge de toute réclamation pour violation de la propriété intellectuelle.
            </p>

            <h3>16. DROIT APPLICABLE</h3>

            <p>Ce contrat de licence est régi par les lois de la Suisse, à l'exclusion de ses règles de conflits de lois.</p>

            <h3>17. DIVERS</h3>

            <p>
              17.1 Si l'un des termes du présent contrat devait être ou devenir invalide, la validité des autres dispositions n'en serait pas affectée. Les clauses invalides seront remplacées par des
              clauses valides formulées de manière à atteindre l'objectif principal. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </p>
            <p>17.2 Les accords annexes, les changements et les modifications ne sont valables que s'ils sont consignés par écrit. Il ne peut être renoncé à la clause précédente que par écrit.</p>
          </div>
        );
      case "de":
        return <div id="main-content" className="main"></div>;
      case "it":
        return <div id="main-content" className="main"></div>;
    }
  }
}

export default withTranslation("common")(EulaScreen);

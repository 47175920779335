import { Bloc, Transition } from "@felangel/bloc";
import Event from "models/event";
import EventRepository from "repositories/event_repository";
import {
  EventsEvent,
  LoadEvents,
  AddEvent,
  EditEvent,
  DeleteEvent,
} from "./events_event";
import {
  EventsState,
  EventsLoaded,
  EventsLoading,
  EventsFailure,
} from "./events_state";

export default class EventsBloc extends Bloc<EventsEvent, EventsState> {
  eventRepository: EventRepository;
  events: Array<Event> = [];

  constructor(eventRepository: EventRepository) {
    super(new EventsLoading());
    this.eventRepository = eventRepository;
  }

  async *mapEventToState(event: EventsEvent) {
    if (event instanceof LoadEvents) {
      yield* this.onLoadEvents(event);
    } else if (event instanceof AddEvent) {
      yield* this.onAddEvent(event);
    } else if (event instanceof EditEvent) {
      yield* this.onEditEvent(event);
    } else if (event instanceof DeleteEvent) {
      yield* this.onDeleteEvent(event);
    }
  }

  async *onLoadEvents(event: LoadEvents) {
    try {
      yield new EventsLoading();
      var eventsList: Array<Event> = await this.eventRepository.getData();
      this.events = eventsList;

      yield new EventsLoaded(this.events);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new EventsFailure(e);
      } else {
        yield new EventsFailure(e.toString());
      }
    }
  }

  async *onAddEvent(event: AddEvent) {
    try {
      yield new EventsLoading();
      this.events.push(event.event);

      yield new EventsLoaded(this.events);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new EventsFailure(e);
      } else {
        yield new EventsFailure(e.toString());
      }
    }
  }

  async *onEditEvent(event: EditEvent) {
    try {
      yield new EventsLoading();
      this.events = this.events.map((eventItem: Event) => {
        if (eventItem.id == event.event.id) {
          eventItem = event.event;
        }
        return eventItem;
      });

      yield new EventsLoaded(this.events);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new EventsFailure(e);
      } else {
        yield new EventsFailure(e.toString());
      }
    }
  }

  async *onDeleteEvent(event: DeleteEvent) {
    try {
      yield new EventsLoading();
      this.events = this.events.filter((eventItem: Event) => {
        return eventItem.id != event.eventId;
      });

      yield new EventsLoaded(this.events);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new EventsFailure(e);
      } else {
        yield new EventsFailure(e.toString());
      }
    }
  }

  onEvent(event: EventsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

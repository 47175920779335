import Merchant from "models/merchant";

export abstract class MerchantsState {}

// export class MerchantsInitial extends MerchantsState {}

export class MerchantsLoading extends MerchantsState {}

export class MerchantsLoaded extends MerchantsState {
  merchants: Array<Merchant>;
  constructor(merchants: Array<Merchant>) {
    super();
    this.merchants = merchants;
  }

  public toString = (): string => {
    return `MerchantsLoaded ${this.merchants.length} merchants`;
  };
}

export class MerchantsFailure extends MerchantsState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `MerchantsLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}

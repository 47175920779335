import { Bloc, Transition } from "@felangel/bloc";
import UniversalCategory from "models/universal_category";
import NewsCategoriesRepository from "repositories/news_category_repository"; // Adapter l'import en fonction de ton repository
import {
  NewsCategoriesEvent,
  LoadNewsCategories,
} from "./news_categories_event"; // Adapter les imports en fonction de tes événements
import {
  NewsCategoriesState,
  NewsCategoriesLoaded,
  NewsCategoriesLoading,
  NewsCategoriesFailure,
} from "./news_categories_state"; // Adapter les imports en fonction des états

export default class NewsCategoriesBloc extends Bloc<NewsCategoriesEvent, NewsCategoriesState> {
  newsRepository: NewsCategoriesRepository; // Adapter le nom du repository en conséquence
  categories: Array<UniversalCategory> = [];

  constructor(newsRepository: NewsCategoriesRepository) {
    super(new NewsCategoriesLoading()); // Adapter l'état initial en conséquence
    this.newsRepository = newsRepository; // Adapter le nom du repository en conséquence
  }

  async *mapEventToState(event: NewsCategoriesEvent) {
    if (event instanceof LoadNewsCategories) { 
      yield* this.onLoadNewsCategories(event); 
    }
  }

  async *onLoadNewsCategories(event: LoadNewsCategories) { 
    try {
      yield new NewsCategoriesLoading(); // Adapter l'état en conséquence
      var categoriesList: Array<UniversalCategory> = await this.newsRepository.getData(); // Adapter le nom du repository en conséquence
      this.categories = categoriesList;

      yield new NewsCategoriesLoaded(this.categories); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new NewsCategoriesFailure(e); // Adapter l'état en conséquence
      } else {
        yield new NewsCategoriesFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }

  onEvent(event: NewsCategoriesEvent): void { 
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event =", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition =", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error =", error);
  }
}

import React, { useState, useContext } from "react";
import axios from "axios";
import BaseScreen from "layouts/base";
import { WithTranslation, withTranslation } from "react-i18next";
import { BlocsContext } from "contexts/blocs_context";
import { AuthenticationLoggedIn, AuthenticationLoggedOut } from "blocs/authentication_bloc/authentication_event";
import AuthenticationData from "models/authentication_data";
import AuthenticationService from "services/authentication";
import User from "models/user";
import { AuthenticationAuthenticated } from "blocs/authentication_bloc/authentication_state";
// import "./login.scss";

import UsernameIcon from "assets/icons/mail.svg";
import PasswordIcon from "assets/icons/padlock.svg";
import { Link, useNavigate } from "react-router-dom";

import authBackgroundImage from "assets/images/home_section_1_background.jpg";

interface UserData {
  email: string;
  username: string;
  password: string;
  password_confirmation: string;
}

interface Props extends WithTranslation {}

const RegisterScreen: React.FC<Props> = ({ t }) => {
  // const blocsContext = useContext(BlocsContext);
  const navigate = useNavigate();
  const blocsContext = useContext(BlocsContext);
  const [authenticationBlocState, setAuthenticationBlocState] = React.useState(blocsContext.authenticationBloc.state);

  React.useEffect(() => {
    if (authenticationBlocState instanceof AuthenticationAuthenticated) {
      navigate({ pathname: "/" }, { replace: true });
    }

    const subscription = blocsContext.authenticationBloc.listen(setAuthenticationBlocState);
    return () => {
      subscription.unsubscribe();
    };
  }, [blocsContext.authenticationBloc, authenticationBlocState]);

  const [userData, setUserData] = useState<UserData>({
    email: "",
    username: "",
    password: "",
    password_confirmation: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorPassword, setErrorPassword] = useState<string | undefined>(undefined);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (userData.password != userData.password_confirmation) {
      setErrorPassword("Les mots de passe ne sont pas identiques");
      return;
    }
    setErrorPassword(undefined);
    try {
      // const response = await axios.post(
      //   "api/login",
      //   JSON.stringify(userData)
      // );
      setLoading(true);

      const response = await AuthenticationService.register(userData.email, userData.username, userData.password);

      if (response.status == 200) {
        if (response.data.status_code != 200) {
          setError(t("screen.login.errors.incorrectLogs"));
          setLoading(false);
          return;
        }

        const token = response.data.access_token;
        const user: User = User.fromJson(response.data.user);

        blocsContext.authenticationBloc.add(
          new AuthenticationLoggedIn(
            new AuthenticationData({
              accessToken: token,
              user: user,
            })
          )
        );
        setLoading(false);
        return;
      } else {
        if (response.data.errors != undefined) {
          console.log(response.data.errors);
          setError(response.data.errors.map((error: any) => error[0]).join("</br>"));
          setLoading(false);
          return;
        } else {
          setError(t("screen.login.errors.generalError"));
          setLoading(false);
        }
      }
    } catch (error: any) {
      if (error.response?.status == 422) {
        if (error.response.data.errors != undefined) {
          console.log(error.response.data.errors);
          setError(
            Object.values(error.response.data.errors)
              .map((error: any) => error[0])
              .join("</br>")
          );
          setLoading(false);
          return;
        }
      }

      if (error.response?.status == 429) {
        setError(t("screen.login.errors.tooManyRequests"));
        setLoading(false);
        return;
      }
      console.log("error", error);
      setError(t("screen.login.errors.generalError"));
      setLoading(false);
    }
  };

  const handleLogout = async (e: React.FormEvent) => {
    e.preventDefault();

    blocsContext.authenticationBloc.add(new AuthenticationLoggedOut());
  };

  return (
    <div className="d-flex flex-column flex-root auth-bg">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
          <div className="d-flex flex-column justify-content-center">
            <Link to="/" className="d-flex text-muted">
              <i className="bi bi-arrow-left fs-2"></i>
              <div className="ms-4">Retour à l'accueil</div>
            </Link>
          </div>

          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <div className="w-lg-500px p-10">
              <form className="form w-100" noValidate={true} id="kt_sign_in_form" data-kt-redirect-url="../../demo11/dist/index.html" action="#">
                <div className="text-center mb-11">
                  <h1 className="text-dark fw-bolder mb-3">Inscription</h1>

                  <div className="text-gray-500 fw-semibold fs-6">Créez votre compte Région Haute-Ajoie pour continuer</div>
                </div>

                <div className="fv-row mb-3">
                  <input type="text" name="email" placeholder={"Adresse email"} onChange={handleChange} value={userData.email} required className="form-control bg-transparent" />
                </div>
                <div className="fv-row mb-3">
                  <input type="text" name="username" placeholder={"Nom d'utilisateur"} onChange={handleChange} value={userData.username} required className="form-control bg-transparent" />
                </div>

                <div className="fv-row mb-3">
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    name="password"
                    autoComplete={"off"}
                    className="form-control bg-transparent"
                    onChange={handleChange}
                    value={userData.password}
                    required
                  />
                </div>
                <div className="fv-row mb-8">
                  <input
                    type="password"
                    placeholder="Confirmation du mot de passe"
                    name="password_confirmation"
                    autoComplete={"off"}
                    className="form-control bg-transparent"
                    onChange={handleChange}
                    value={userData.password_confirmation}
                    required
                  />
                  {errorPassword != undefined && <div className="text-danger mt-5 text-center">{errorPassword}</div>}
                </div>

                <div className="d-grid mb-10">
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-primary"
                    onClick={handleRegister}
                    data-kt-indicator={loading ? "on" : "off"}
                    disabled={loading || userData.email == "" || userData.password == "" || userData.password_confirmation == ""}
                  >
                    {!loading && <span className="indicator-label">Créer mon compte</span>}
                    {loading && (
                      <span className="indicator-progress">
                        Veuillez patienter...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                  {error && <div className="text-danger mt-5 text-center" dangerouslySetInnerHTML={{ __html: error }}></div>}
                </div>

                <div className="text-gray-500 text-center fw-semibold fs-6">
                  Déjà un compte ?{" "}
                  <Link to="/login" className="link-primary">
                    Se connecter
                  </Link>
                </div>
              </form>
            </div>
          </div>

          {/* <div className="w-lg-500px d-flex flex-stack px-10 mx-auto">
            <div className="me-10"></div>

            <div className="d-flex fw-semibold text-primary fs-base gap-5">
              <a href="../../demo11/dist/pages/team.html" target="_blank">
                Terms
              </a>
              <a
                href="../../demo11/dist/pages/pricing/column.html"
                target="_blank"
              >
                Plans
              </a>
              <a href="../../demo11/dist/pages/contact.html" target="_blank">
                Contact Us
              </a>
            </div>
          </div> */}
        </div>

        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{ backgroundImage: "url(" + authBackgroundImage + ")" }}>
          {/* <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
            <a href="../../demo11/dist/index.html" className="mb-0 mb-lg-12">
              <img
                alt="Logo"
                src="assets/media/logos/custom-1.png"
                className="h-60px h-lg-75px"
              />
            </a>

            <img
              className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
              src="assets/media/misc/auth-screens.png"
              alt=""
            />

            <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
              Fast, Efficient and Productive
            </h1>

            <div className="d-none d-lg-block text-white fs-base text-center">
              In this kind of post,
              <a
                href="#"
                className="opacity-75-hover text-warning fw-bold me-1"
              >
                the blogger
              </a>
              introduces a person they’ve interviewed
              <br />
              and provides some background information about
              <a
                href="#"
                className="opacity-75-hover text-warning fw-bold me-1"
              >
                the interviewee
              </a>
              and their
              <br />
              work following this is a transcript of the interview.
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default withTranslation("common")(RegisterScreen);

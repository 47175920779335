import GeneralHelper from "helpers/general_helper";
import UniversalCategory from "./universal_category";
import Municipality from "./municipality";
import LatLng from "./latlng";
import ItineraryWaypoint from "./itinerary_waypoint";
import { ImageSizes } from "constants/constants";
import ResponsiveImage from "./responsive_image";

export default class Itinerary {
  id: number;
  name: string;
  description: string;
  category: UniversalCategory;
  distance: string;
  duration: string;
  municipality: Municipality;
  createdAt: Date;
  // images: Array<string>;
  responsiveImages: Array<ResponsiveImage>;
  waypoints: ItineraryWaypoint[];

  constructor({ id, name, description, category, distance, duration, municipality, createdAt, responsiveImages, waypoints }: any /*NamedParameters*/) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.category = category;
    this.distance = distance;
    this.duration = duration;
    this.municipality = municipality;
    this.createdAt = createdAt;
    this.responsiveImages = responsiveImages;
    this.waypoints = waypoints;
  }

  static fromJson(map: Record<string, any>): Itinerary {
    var waypoints: ItineraryWaypoint[] = [];
    if (map["waypoints"] != null) {
      map["waypoints"].map((waypoint: any) => {
        // waypoints.push({
        //   latitude: parseFloat(waypoint.location.lat),
        //   longitude: parseFloat(waypoint.location.lng),
        // });
        waypoints.push(ItineraryWaypoint.fromJson(waypoint));
      });
    }

    return new Itinerary({
      id: GeneralHelper.parseIntOrNull(map["id"]),
      name: GeneralHelper.avoidNullOrEmptyString(map["name"]),
      description: GeneralHelper.avoidNullOrEmptyString(map["description"]),
      distance: GeneralHelper.avoidNullOrEmptyString(map["distance"]),
      duration: GeneralHelper.avoidNullOrEmptyString(map["duration"]),
      category: UniversalCategory.fromJson(map["category"]),
      createdAt: new Date(map["created_at"]),
      municipality: Municipality.fromJson(map["municipality"]),
      // images: map["images"] != null ? map["images"].map((image: string) => image) : [],
      responsiveImages: map["images_responsives"] != null ? map["images_responsives"].map((image: Map<string, string>) => ResponsiveImage.fromJson(image)) : [],
      waypoints: waypoints,
    });
  }

  public getFirstImage(size: ImageSizes): string | undefined {
    return this.responsiveImages.length > 0 ? this.responsiveImages[0].getUrlFromSize(size) : undefined;
    // return this.images.length > 0 ? this.images[0] : undefined;
  }

  public getBoundsFromLatLngList(): { ne: LatLng; sw: LatLng } {
    var maxLat: number = -90.0;
    var minLat: number = 90.0;
    var maxLng: number = -180.0;
    var minLng: number = 180.0;

    this.waypoints.map((waypoint) => {
      if (waypoint.latitude > maxLat) maxLat = waypoint.latitude;
      if (waypoint.latitude < minLat) minLat = waypoint.latitude;
      if (waypoint.longitude > maxLng) maxLng = waypoint.longitude;
      if (waypoint.longitude < minLng) minLng = waypoint.longitude;
    });

    return {
      ne: new LatLng(maxLat, maxLng),
      sw: new LatLng(minLat, minLng),
    };
  }
}

import { LoadMerchants } from "blocs/merchants_bloc/merchants_event";
import { MerchantsFailure, MerchantsLoaded, MerchantsLoading, MerchantsState } from "blocs/merchants_bloc/merchants_state";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import { Link, useNavigate } from "react-router-dom";
import Merchant from "models/merchant";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import imgGoogleMaps from "assets/images/google-maps.png";
import imgInnerMap from "assets/images/marker_activite.png";
import imgIconDesc from "assets/images/bubble.png";
import GeneralHelper from "helpers/general_helper";
import defaultPic from "assets/images/default_pic.png";
import ImagesGallery from "components/image_gallery";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const MerchantScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [merchantsState, setMerchantsState] = useState<MerchantsState>(new MerchantsLoading());

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setMerchantsState(blocsContext.merchantsBloc.state);
    const subscription = blocsContext.merchantsBloc.listen(setMerchantsState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const merchant: Merchant | undefined = undefined;

  if (merchantsState instanceof MerchantsLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (merchantsState instanceof MerchantsFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div className="btn btn-primary mt-6" onClick={() => blocsContext.merchantsBloc.add(new LoadMerchants())}>
              Recharger
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (merchantsState instanceof MerchantsLoaded) {
    const merchant: Merchant = merchantsState.merchants.find((merchant) => merchant.id.toString() == params.id) as Merchant;
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <a className="cursor-pointer backLinkSingle" onClick={() => navigate(-1)}>
                  ← Retour
                </a>
                <div className="singleItem">
                  <ImagesGallery images={merchant.responsiveImages} />
                  <div className="singleItemInnerContent">
                    <div className="singleItemTags">
                      <div className="tagsWrapper">
                        <div className="singleItemCategory">{merchant.category.name}</div>
                        <div className="singleItemMunicipality">{merchant.municipality.name}</div>
                      </div>
                      {/* <div className="singleItemShareLink">
                        <a href="#">⭷ Partager</a>
                      </div> */}
                    </div>
                    <h3 className="fs-1 fw-600 singleItemTitle">{merchant.name}</h3>
                    <div className="singleItemAddress">{merchant.address}</div>
                    {merchant.latitude != undefined && merchant.longitude != undefined && (
                      <div className="btnWrapper">
                        <Link to={{ pathname: "/map", search: new URLSearchParams({ datatype: "merchant", id: merchant.id.toString() }).toString() }} className="seeMapBtn btnWrapper">
                          <div className="btnIcon">
                            <img src={imgInnerMap} />
                            <div className="btnText text-black">Voir sur la carte</div>
                          </div>
                        </Link>

                        <a
                          href="#"
                          className="seeMapBtn btnWrapper"
                          onClick={() => {
                            var latitude = merchant.latitude;
                            var longitude = merchant.longitude;
                            var link = "https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=" + latitude + "," + longitude + "";
                            window.open(link, "_blank");
                          }}
                        >
                          <div className="btnIcon">
                            <img src={imgGoogleMaps} />
                            <span className="btnText">Obtenir l'itinéraire</span>
                          </div>
                        </a>
                      </div>
                    )}
                    <div className="singleItemDescription">
                      <img src={imgIconDesc} />
                      <div className="descTitle">Description</div>
                      {/* {GeneralHelper.cleanHtmlString(merchant.description || 'Aucune description disponible.')} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: merchant.description || "Aucune description disponible.",
                        }}
                      ></div>
                    </div>
                    <div className="singleItemContactInfo">
                      <div className="contactTitle">Informations de contact</div>
                      <div className="contactWebsite contactItem">
                        <div className="contactitemName">Site internet :</div>
                        <div className="contactItemValue">{merchant.website != null ? <a href={merchant.website}>{merchant.website}</a> : "non défini"}</div>
                      </div>
                      <div className="contactEmail contactItem">
                        <div className="contactitemName">Adresse email :</div>
                        <div className="contactItemValue">{merchant.email != null ? <a href={"mailto:" + merchant.email}>{merchant.email}</a> : "non défini"}</div>
                      </div>
                      <div className="contactPhone contactItem">
                        <div className="contactitemName">N° de téléphone :</div>
                        <div className="contactItemValue">{merchant.phone != null ? <a href={"tel:" + merchant.phone}>{merchant.phone}</a> : "non défini"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(merchant)
};

export default withTranslation("common")(MerchantScreen);

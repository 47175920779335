import BaseScreen from "layouts/base";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import image1 from "assets/images/calendar.png";
import image2 from "assets/images/undraw2.png";
import image3 from "assets/images/undraw3.png";
import image4 from "assets/images/undraw4.png";
import { Link } from "react-router-dom";
import { BlocsContext } from "contexts/blocs_context";
import { MunicipalitiesLoaded, MunicipalitiesLoading, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import { useParams } from "react-router-dom";
import { BlocBuilder } from "@felangel/react-bloc";
import Municipality from "models/municipality";
import MunicipalityCard from "components/data_components/municipality_card";
interface Props extends WithTranslation {}

const VieLocaleScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  return (
    <>
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <h3 className="fs-1 fw-800">Vie locale</h3>
                <span className="subTitle">Découvrez la vie locale et ses curiosités</span>
              </div>
            </div>

            <div className="row row-cols-2 g-3 row-cols-lg-4 greenCase">
              <Link to="/events">
                <div className="singleItemVieLocale">
                  <div className="innerContent">
                    <div className="localLifeSubTitle">
                      <img src={image1} className="localLifeIllustration" />
                    </div>
                    <div className="localLifeText">
                      <div className="localLifeSubTitle">Vie locale</div>
                      <div className="localLifeTitle">Evénements</div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/news">
                <div className="singleItemVieLocale">
                  <div className="innerContent">
                    <div className="localLifeSubTitle">
                      <img src={image2} className="localLifeIllustration" />
                    </div>
                    <div className="localLifeText">
                      <div className="localLifeSubTitle">Vie locale</div>
                      <div className="localLifeTitle">Actualités</div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/local-products">
                <div className="singleItemVieLocale">
                  <div className="innerContent">
                    <div className="localLifeSubTitle">
                      <img src={image3} className="localLifeIllustration" />
                    </div>
                    <div className="localLifeText">
                      <div className="localLifeSubTitle">Vie locale</div>
                      <div className="localLifeTitle">Terroir</div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/companies">
                <div className="singleItemVieLocale">
                  <div className="innerContent">
                    <div className="localLifeSubTitle">
                      <img src={image4} className="localLifeIllustration" />
                    </div>
                    <div className="localLifeText">
                      <div className="localLifeSubTitle">Vie locale</div>
                      <div className="localLifeTitle">Associations</div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="row gy-0 gx-5 row d-flex justify-content-start">
              <div className="col-12 mb-4 mb-xl-6 mt-10 mt-xl-20">
                <h3 className="fs-1 fw-800">Par commune</h3>
              </div>

              <div className="row row-cols-1 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5 mt-0">
                <BlocBuilder
                  bloc={blocsContext.municipalitiesBloc}
                  builder={(state: MunicipalitiesState) => {
                    if (state instanceof MunicipalitiesLoaded) {
                      return (
                        <>
                          {state.municipalities
                            .filter((municipality) => municipality.parent == undefined)
                            .slice(0, 5)
                            .map((municipality: Municipality) => {
                              return <MunicipalityCard key={municipality.id} municipality={municipality} />;
                            })}
                        </>
                      );
                    }
                    return <></>;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </BaseScreen>
    </>
  );
};

export default withTranslation("common")(VieLocaleScreen);

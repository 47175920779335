import React, { useContext, useState, useEffect } from "react";

import ServiceComment from "models/service_comment";
import moment from "moment";
import GeneralHelper from "helpers/general_helper";
import CommentInput from "./comment_input";
import { BlocsContext } from "contexts/blocs_context";
import { AuthenticationAuthenticated } from "blocs/authentication_bloc/authentication_state";
import Swal from "sweetalert2";

import ServiceCommentRepository from "repositories/service_comment_repository";
import ServiceCommentsBloc from "blocs/service_comments_bloc/service_comments_bloc";
import { AddServiceComment, DeleteServiceComment } from "blocs/service_comments_bloc/service_comments_event";
import { ServiceCommentsLoaded, ServiceCommentsLoading, ServiceCommentsState } from "blocs/service_comments_bloc/service_comments_state";

interface Props {
  comment: ServiceComment;
  serviceAuthorId: number;
  serviceCommentsBloc: ServiceCommentsBloc;
  canReply?: boolean;
  // addTopLine?: boolean;
}

const Comment: React.FC<Props> = ({ comment, serviceAuthorId, serviceCommentsBloc, canReply /*addTopLine*/ }) => {
  const blocsContext = useContext(BlocsContext);

  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [commentError, setCommentError] = useState<string | undefined>(undefined);

  const [serviceCommentsState, setServiceCommentsState] = useState<ServiceCommentsState>(new ServiceCommentsLoading());

  useEffect(() => {
    setServiceCommentsState(serviceCommentsBloc.state);
    const serviceCommentsSubscription = serviceCommentsBloc.listen(setServiceCommentsState);

    return () => {
      serviceCommentsSubscription.unsubscribe();
    };
  }, []);

  const [commentValue, setCommentValue] = useState<string>("");
  return (
    <div className="singleCommentFull">
      <div className="singleComment">
        <div className="singleCommentPic">
          <div className="singleCommentPicLetter">{comment.user.username.slice(0, 1)}</div>
        </div>
        <div className="singleCommentContent">
          <div className="singleCommentUsername">
            <div className="singleCommentUsernameText">{comment.user.username}</div>
            {serviceAuthorId === comment.user.id && <div className="isAuthorBox">Auteur</div>}
          </div>
          <div className="singleCommentMemberFrom">Membre depuis {GeneralHelper.calculateDaysSince(comment.user.createdAt)}</div>
          <div className="singleCommentText">{comment.comment}</div>
          <div className="singleCommentBottomPart">
            <div className="singleCommentDate">{moment(comment.createdAt).locale("fr").format("DD.MM.YYYY à HH:mm")}</div>

            {canReply == true && (
              <div className="d-flex gap-5">
                {blocsContext.authenticationBloc.state instanceof AuthenticationAuthenticated && blocsContext.authenticationBloc.state.authenticationData.user.id == comment.user.id && (
                  <div
                    className="singleCommentDeleteLink"
                    onClick={() => {
                      Swal.fire({
                        title: "Êtes-vous sûr de vouloir supprimer ce commentaire ?",
                        // icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: "Supprimer",
                        cancelButtonText: "Annuler",
                        confirmButtonColor: "#d33",
                        padding: "1rem",
                        reverseButtons: true,
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          new ServiceCommentRepository().deleteServiceComment(comment.id);
                          serviceCommentsBloc.add(new DeleteServiceComment(comment.id));
                        }
                      });
                    }}
                  >
                    Supprimer
                  </div>
                )}
                {comment.parentId == undefined && (
                  <div
                    className="singleCommentAnswerLink"
                    onClick={() => {
                      setIsReplying(!isReplying);
                    }}
                  >
                    {isReplying ? "Annuler" : "Répondre"}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="commentAnswers">
        {isReplying && (
          <CommentInput
            onAddComment={async (commentValue: string) => {
              var newComment: ServiceComment = await new ServiceCommentRepository().createServiceComment(commentValue, comment.serviceId, comment.id);
              serviceCommentsBloc.add(new AddServiceComment(newComment));
              setIsReplying(false);
              setCommentValue("");
            }}
            error={commentError}
            commentValue={commentValue}
            setCommentValue={setCommentValue}
          />
        )}

        {serviceCommentsState instanceof ServiceCommentsLoaded &&
          serviceCommentsState.comments
            .filter((subComment: ServiceComment) => subComment.parentId == comment.id)
            .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
            .map((subComment: ServiceComment) => <Comment key={subComment.id} comment={subComment} serviceAuthorId={serviceAuthorId} serviceCommentsBloc={serviceCommentsBloc} canReply={canReply} />)}
      </div>
    </div>
  );
};

export default Comment;

import GeneralHelper from "helpers/general_helper";
import UniversalCategory from "./universal_category";
import Municipality from "./municipality";
import ResponsiveImage from "./responsive_image";
import { ImageSizes } from "constants/constants";

export default class Event {
  id: number;
  name: string;
  description: string;
  address: string;
  category: UniversalCategory;
  latitude: number;
  longitude: number;
  distance: number;
  municipality: Municipality;
  website: string;
  phone: string;
  email: string;
  // images: Array<string>;
  responsiveImages: Array<ResponsiveImage>;
  startAt: Date;
  endAt: Date | null;
  createdAt: Date;
  organizer: string | null;

  constructor(
    {
      id,
      name,
      description,
      address,
      category,
      latitude,
      longitude,
      distance,
      municipality,
      website,
      phone,
      email,
      images,
      responsiveImages,
      startAt,
      endAt,
      createdAt,
      organizer,
    }: any /*NamedParameters*/
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.address = address;
    this.category = category;
    this.latitude = latitude;
    this.longitude = longitude;
    this.distance = distance;
    this.municipality = municipality;
    this.website = website;
    this.phone = phone;
    this.email = email;
    // this.images = images;
    this.responsiveImages = responsiveImages;
    this.startAt = startAt;
    this.endAt = endAt;
    this.createdAt = createdAt;
    this.organizer = organizer;
  }

  static fromJson(map: Record<string, any>): Event {
    return new Event({
      id: GeneralHelper.parseIntOrNull(map["id"]),
      name: GeneralHelper.avoidNullOrEmptyString(map["name"]),
      description: GeneralHelper.avoidNullOrEmptyString(map["description"]),
      address: GeneralHelper.avoidNullOrEmptyString(map["address"]),
      phone: GeneralHelper.avoidNullOrEmptyString(map["phone"]),
      email: GeneralHelper.avoidNullOrEmptyString(map["email"]),
      images: map["images"] != null ? map["images"].map((image: string) => image) : [],
      responsiveImages: map["images_responsives"] != null ? map["images_responsives"].map((image: Map<string, string>) => ResponsiveImage.fromJson(image)) : [],
      website: GeneralHelper.avoidNullOrEmptyString(map["website"]),
      latitude: GeneralHelper.parseDoubleOrNull(map["latitude"]),
      longitude: GeneralHelper.parseDoubleOrNull(map["longitude"]),
      municipality: Municipality.fromJson(map["municipality"]),
      category: UniversalCategory.fromJson(map["category"]),
      startAt: new Date(map["start_at"]),
      endAt: new Date(map["end_at"]),
      createdAt: new Date(map["created_at"]),
      organizer: GeneralHelper.avoidNullOrEmptyString(map["organizer"]),
    });
  }

  public getFirstImage(size: ImageSizes): string | undefined {
    return this.responsiveImages.length > 0 ? this.responsiveImages[0].getUrlFromSize(size) : undefined;
    // return this.images.length > 0 ? this.images[0] : undefined;
  }
}

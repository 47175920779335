import { LoadMunicipalities } from "blocs/municipalities_bloc/municipalities_event";
import { MunicipalitiesFailure, MunicipalitiesLoaded, MunicipalitiesLoading, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import { BlocsContext } from "contexts/blocs_context";
import { BlocBuilder } from "@felangel/react-bloc";
import BaseScreen from "layouts/base";

import { ServicesLoaded, ServicesState } from "blocs/services_bloc/services_state";
import { Link, useNavigate } from "react-router-dom";
import Municipality from "models/municipality";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import imgGoogleMaps from "assets/images/google-maps.png";
import imgInnerMap from "assets/images/marker_activite.png";
import imgIconDesc from "assets/images/bubble.png";
import imgMoutain from "assets/images/moutain.png";
import imgPeople from "assets/images/people.png";

import image1 from "assets/images/calendar.png";
import image2 from "assets/images/undraw2.png";
import image3 from "assets/images/undraw3.png";
import image4 from "assets/images/undraw4.png";
import image5 from "assets/images/undraw5.png";
import image6 from "assets/images/undraw6.png";
import image7 from "assets/images/undraw7.png";

import imgWide from "assets/images/wide.png";
import GeneralHelper from "helpers/general_helper";
import Service from "models/service";
import ServiceCard from "components/data_components/service_card";
import defaultPic from "assets/images/default_pic.png";

import GenericFilter from "models/filters/generic_filters";
import ImagesGallery from "components/image_gallery";
import { SortTypes } from "constants/constants";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const MunicipalityScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [municipalitiesState, setMunicipalitiesState] = useState<MunicipalitiesState>(new MunicipalitiesLoading());

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setMunicipalitiesState(blocsContext.municipalitiesBloc.state);
    const subscription = blocsContext.municipalitiesBloc.listen(setMunicipalitiesState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const municipality: Municipality | undefined = undefined;

  if (municipalitiesState instanceof MunicipalitiesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (municipalitiesState instanceof MunicipalitiesFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div className="btn btn-primary mt-6" onClick={() => blocsContext.municipalitiesBloc.add(new LoadMunicipalities())}>
              Recharger
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (municipalitiesState instanceof MunicipalitiesLoaded) {
    const municipality: Municipality = municipalitiesState.municipalities.find((municipality) => municipality.id.toString() == params.id) as Municipality;
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-0 mt-5 mt-lg-20">
              <div className="col-12">
                <a className="cursor-pointer backLinkSingle" onClick={() => navigate(-1)}>
                  ← Retour
                </a>
                <div className="singleItem">
                  <ImagesGallery images={municipality.responsiveImages} />

                  <div className="singleItemInnerContent">
                    <h3 className="fs-1 fw-600 singleItemTitle">{municipality.name}</h3>
                    <div className="singleItemAddress">à 15 km de votre position</div>
                    {municipality.latitude != undefined && municipality.longitude != undefined && (
                      <div className="btnWrapper">
                        <Link to={{ pathname: "/map", search: new URLSearchParams({ datatype: "municipality", id: municipality.id.toString() }).toString() }} className="seeMapBtn btnWrapper">
                          <div className="btnIcon">
                            <img src={imgInnerMap} />
                            <div className="btnText text-black">Voir sur la carte</div>
                          </div>
                        </Link>

                        <a
                          href="#"
                          className="seeMapBtn btnWrapper"
                          onClick={() => {
                            var latitude = municipality.latitude;
                            var longitude = municipality.longitude;
                            var link = "https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=" + latitude + "," + longitude + "";
                            window.open(link, "_blank");
                          }}
                        >
                          <div className="btnIcon">
                            <img src={imgGoogleMaps} />
                            <span className="btnText">Obtenir l'itinéraire</span>
                          </div>
                        </a>
                      </div>
                    )}
                    <div className="singleItemDescription municipality">
                      <img src={imgIconDesc} />
                      <div className="descTitle">A propos de la commune</div>
                      {/* {GeneralHelper.cleanHtmlString(municipality.description)} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: municipality.description || "Aucune description disponible.",
                        }}
                      ></div>
                    </div>
                    <div className="dataTitle">Chiffres-clés</div>
                    <div className="quickData">
                      <div className="singleItem">
                        <img src={imgMoutain} />
                        <div className="singleItemTitleData">Altitude</div>
                        <div className="singleItemValue">{municipality.altitude}</div>
                      </div>
                      <div className="singleItem">
                        <img src={imgWide} />
                        <div className="singleItemTitleData">Superficie</div>
                        <div className="singleItemValue">{municipality.area}</div>
                      </div>
                      <div className="singleItem">
                        <img src={imgPeople} />
                        <div className="singleItemTitleData">Population</div>
                        <div className="singleItemValue">{municipality.population}</div>
                      </div>
                    </div>

                    <BlocBuilder
                      bloc={blocsContext.servicesBloc}
                      builder={(state: ServicesState) => {
                        if (!(state instanceof ServicesLoaded)) return <></>;
                        var servicesFilter: GenericFilter = new GenericFilter({ municipalityId: municipality.id, sort: SortTypes.DATE_DESC });
                        var services: Service[] = servicesFilter.filter(state.services).filter((service) => service.isExpired() == false);

                        if (services.length == 0) return <></>;
                        return (
                          <>
                            <div className="TitleWithAllBlock">
                              <div className="dataTitle">Annonces et services à {municipality.name}</div>
                              <Link to={"/echange-entraide?municipalityId=" + municipality.id.toString()} className="fs-5 fw-bold">
                                Voir toutes les annonces →
                              </Link>
                            </div>
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
                              {services.slice(0, 5).map((service: Service) => {
                                return <ServiceCard key={service.id} service={service} />;
                              })}
                            </div>
                          </>
                        );
                      }}
                    />

                    <div className="row gy-0 gx-5 row d-flex justify-content-start">
                      <div className="col-12">
                        <h3 className="dataTitle fw-800">Vie locale et tourisme à {municipality.name}</h3>
                      </div>
                    </div>

                    <div className="row row-cols-2 g-3 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 localLifeMunicipality greenCase">
                      <Link to={"/events?municipalityId=" + municipality.id.toString()}>
                        <div className="singleItemVieLocale">
                          <div className="innerContent">
                            <div className="localLifeSubTitle">
                              <img src={image1} className="localLifeIllustration" />
                            </div>
                            <div className="localLifeText">
                              <div className="localLifeSubTitle">Vie locale</div>
                              <div className="localLifeTitle">Evénements</div>
                            </div>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/news?municipalityId=" + municipality.id.toString()}>
                        <div className="singleItemVieLocale">
                          <div className="innerContent">
                            <div className="localLifeSubTitle">
                              <img src={image2} className="localLifeIllustration" />
                            </div>
                            <div className="localLifeText">
                              <div className="localLifeSubTitle">Vie locale</div>
                              <div className="localLifeTitle">Actualités</div>
                            </div>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/local-products?municipalityId=" + municipality.id.toString()}>
                        <div className="singleItemVieLocale">
                          <div className="innerContent">
                            <div className="localLifeSubTitle">
                              <img src={image3} className="localLifeIllustration" />
                            </div>
                            <div className="localLifeText">
                              <div className="localLifeSubTitle">Vie locale</div>
                              <div className="localLifeTitle">Terroir</div>
                            </div>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/companies?municipalityId=" + municipality.id.toString()}>
                        <div className="singleItemVieLocale">
                          <div className="innerContent">
                            <div className="localLifeSubTitle">
                              <img src={image4} className="localLifeIllustration" />
                            </div>
                            <div className="localLifeText">
                              <div className="localLifeSubTitle">Vie locale</div>
                              <div className="localLifeTitle">Associations</div>
                            </div>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/curiosities?municipalityId=" + municipality.id.toString()}>
                        <div className="singleItemVieLocale">
                          <div className="innerContent">
                            <div className="localLifeSubTitle">
                              <img src={image5} className="localLifeIllustration" />
                            </div>
                            <div className="localLifeText">
                              <div className="localLifeSubTitle">Tourisme & loisir</div>
                              <div className="localLifeTitle">Curiosités</div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to={"/merchants?municipalityId=" + municipality.id.toString()}>
                        <div className="singleItemVieLocale">
                          <div className="innerContent">
                            <div className="localLifeSubTitle">
                              <img src={image6} className="localLifeIllustration" />
                            </div>
                            <div className="localLifeText">
                              <div className="localLifeSubTitle">Tourisme & loisir</div>
                              <div className="localLifeTitle">Commerces</div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to={"/itineraries?municipalityId=" + municipality.id.toString()}>
                        <div className="singleItemVieLocale">
                          <div className="innerContent">
                            <div className="localLifeSubTitle">
                              <img src={image7} className="localLifeIllustration" />
                            </div>
                            <div className="localLifeText">
                              <div className="localLifeSubTitle">Tourisme & loisir</div>
                              <div className="localLifeTitle">Parcours</div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(municipality)
};

export default withTranslation("common")(MunicipalityScreen);

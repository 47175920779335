import { Bloc, Transition } from "@felangel/bloc";
import News from "models/news";
import NewsRepository from "repositories/news_repository";
import {
  NewsEvent,
  LoadNews,
  AddNews,
  EditNews,
  DeleteNews,
} from "./news_event";
import {
  NewsState,
  NewsLoaded,
  NewsLoading,
  NewsFailure,
} from "./news_state";

export default class NewsBloc extends Bloc<NewsEvent, NewsState> {
  newsRepository: NewsRepository;
  news: Array<News> = [];

  constructor(newsRepository: NewsRepository) {
    super(new NewsLoading());
    this.newsRepository = newsRepository;
  }

  async *mapEventToState(event: NewsEvent) {
    if (event instanceof LoadNews) {
      yield* this.onLoadNews(event);
    } else if (event instanceof AddNews) {
      yield* this.onAddNews(event);
    } else if (event instanceof EditNews) {
      yield* this.onEditNews(event);
    } else if (event instanceof DeleteNews) {
      yield* this.onDeleteNews(event);
    }
  }

  async *onLoadNews(event: LoadNews) {
    try {
      yield new NewsLoading();
      var newsList: Array<News> = await this.newsRepository.getData();
      this.news = newsList;

      yield new NewsLoaded(this.news);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new NewsFailure(e);
      } else {
        yield new NewsFailure(e.toString());
      }
    }
  }

  async *onAddNews(event: AddNews) {
    try {
      yield new NewsLoading();
      this.news.push(event.news);

      yield new NewsLoaded(this.news);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new NewsFailure(e);
      } else {
        yield new NewsFailure(e.toString());
      }
    }
  }

  async *onEditNews(event: EditNews) {
    try {
      yield new NewsLoading();
      this.news = this.news.map((news: News) => {
        if (news.id == event.news.id) {
          news = event.news;
        }
        return news;
      });

      yield new NewsLoaded(this.news);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new NewsFailure(e);
      } else {
        yield new NewsFailure(e.toString());
      }
    }
  }

  async *onDeleteNews(event: DeleteNews) {
    try {
      yield new NewsLoading();
      this.news = this.news.filter((news: News) => {
        return news.id != event.newsId;
      });

      yield new NewsLoaded(this.news);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new NewsFailure(e);
      } else {
        yield new NewsFailure(e.toString());
      }
    }
  }

  onEvent(event: NewsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

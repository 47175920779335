import BaseScreen from "layouts/base";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
// import styles from './home.scss';

import backgroundImage from "assets/images/home_section_1_background.jpg";
import { Link } from "react-router-dom";
import Municipality from "models/municipality";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { MunicipalitiesLoaded, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import GeneralHelper from "helpers/general_helper";

import defaultPic from "assets/images/default_pic.png";
import { ImageSizes } from "constants/constants";

interface Props extends WithTranslation {
  municipality: Municipality;
}

const MunicipalityCard: React.FC<Props> = ({ t, municipality }) => {
  return (
    <div className="col municipality">
      <Link to={"/municipality/" + municipality.id} className="d-block b-md-5 linkMunicipality" data-fslightbox="lightbox-hot-sales">
        {/* <a
            className="d-block overlay"
            data-fslightbox="lightbox-hot-sales"
            href="assets/media/stock/900x600/42.jpg"
          > */}
        <div className="parent">
          <div
            className="child municipalityContent bg-zoom bgi-position-center bgi-no-repeat bgi-size-cover h-200px h-sm-250px card-rounded mb-3"
            style={{
              backgroundImage: "url('" + (municipality.getFirstImage(ImageSizes.small) ?? defaultPic) + "')",
            }}
          >
            <div className="d-flex d-xl-none contentMunicipalityPhone w-100">
              <div className="municipalityTitle fw-800 d-block mb-0">{municipality.name}</div>

              <span className="d-block mt-2 mb-4 fs-4 fw-800">Découvrir →</span>
            </div>
          </div>
        </div>

        <div className="m-0 pb-3 d-none d-xl-block">
          <div className="text-gray-800 municipalityTitle fs-3 fw-800 d-block mb-2 mt-5">{municipality.name}</div>

          <span className="fw-400 fs-5 text-gray-800 d-block ">{GeneralHelper.truncateText(GeneralHelper.cleanHtmlString(municipality.description), 60)}</span>
          <div className="municipalityAltitude text-hover-primary fs-3 fw-800 d-block mb-2 mt-5">{municipality.altitude}</div>
        </div>

        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
          {/* <i className="ki-duotone ki-eye fs-3x text-white">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i> */}
        </div>
      </Link>
    </div>
  );
};

export default withTranslation("common")(MunicipalityCard);

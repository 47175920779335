import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
// import styles from './home.scss';

import backgroundImage from "assets/images/home_section_1_background.jpg";
import { Link } from "react-router-dom";
import Curiosity from "models/curiosity";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { CuriositiesLoaded, CuriositiesState } from "blocs/curiosities_bloc/curiosities_state";
import GeneralHelper from "helpers/general_helper";

import defaultPic from "assets/images/default_pic.png";
import { ImageSizes } from "constants/constants";

interface Props extends WithTranslation {
  curiosity: Curiosity;
}

const CuriosityCard: React.FC<Props> = ({ t, curiosity }) => {
  return (
    <div className="col curiosityCard">
      <Link to={"/curiosity/" + curiosity.id} className="d-block cardLink " data-fslightbox="lightbox-hot-sales">
        <div className="singleItemCuriosity">
          <div className="cardCuriosity">
            <img src={curiosity.getFirstImage(ImageSizes.small) ?? defaultPic} className="curiosityImg" />
            <div className="cardCuriosityInnerContent">
              <div className="cardMunicipalityTag">{curiosity.municipality.name}</div>
              <div className="cardTitle">{GeneralHelper.truncateText(curiosity.name, 40)}</div>
              <div className="cardCategory">{curiosity.category.name}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>

    // <div className="col">
    //   <Link
    //     to={"/curiosity/" + curiosity.id}
    //     className="d-block mb-11 mb-md-5 overlay cardCuriosity singleItemCuriosity"
    //     data-fslightbox="lightbox-hot-sales"
    //   >
    //     {/* <a
    //         className="d-block overlay"
    //         data-fslightbox="lightbox-hot-sales"
    //         href="assets/media/stock/900x600/42.jpg"
    //       > */}
    //     <div
    //       className="bgi-position-center bgi-no-repeat bgi-size-cover h-150px card-rounded mb-3"
    //       style={{
    //         backgroundImage:
    //           "url('" +
    //           (curiosity.getFirstImage() ?? defaultPic) +
    //           "')",
    //       }}
    //     ></div>

    //     <div className="m-0 pb-3">

    //       <div className="cardMunicipalityTag">
    //         {curiosity.municipality.name}
    //       </div>

    //       <div className="text-gray-800 text-hover-primary fs-16 fw-800 d-block mb-2 cardTitle">
    //         {curiosity.name}
    //       </div>

    //       <span className="fw-400 fs-5 text-gray-400 d-block lh-1">
    //         {GeneralHelper.truncateText(
    //           GeneralHelper.cleanHtmlString(curiosity.description),
    //           60
    //         )}
    //       </span>
    //     </div>

    //     <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
    //       {/* <i className="ki-duotone ki-eye fs-3x text-white">
    //             <span className="path1"></span>
    //             <span className="path2"></span>
    //             <span className="path3"></span>
    //           </i> */}
    //     </div>
    //   </Link>
    // </div>
  );
};

export default withTranslation("common")(CuriosityCard);

import { LoadServices } from "blocs/services_bloc/services_event";
import {
  ServicesFailure,
  ServicesLoaded,
  ServicesLoading,
  ServicesState,
} from "blocs/services_bloc/services_state";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import Service from "models/service";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// import styles from './home.scss';

interface Props extends WithTranslation { }

const ServiceEditScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [servicesState, setServicesState] = useState<ServicesState>(
    new ServicesLoading()
  );

  const params = useParams<{ id: string }>();

  useEffect(() => {
    setServicesState(blocsContext.servicesBloc.state);
    const subscription = blocsContext.servicesBloc.listen(setServicesState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const service: Service | undefined = undefined;

  if (servicesState instanceof ServicesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (servicesState instanceof ServicesFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div
              className="btn btn-primary mt-6"
              onClick={() => blocsContext.servicesBloc.add(new LoadServices())}
            >
              Recharger
            </div>
          </div>

        </div>
      </BaseScreen>
    );
  }

  if (servicesState instanceof ServicesLoaded) {
    const service: Service = servicesState.services.find(
      (service) => service.id.toString() == params.id
    ) as Service;
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6">
              <div className="col-12">
                <h3 className="fs-1 fw-600">{service.name}</h3>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(service)
};

export default withTranslation("common")(ServiceEditScreen);

import GeneralHelper from "helpers/general_helper";

import LatLng from "./latlng";

export default class ItineraryWaypoint {
  id: number;
  // latlng: LatLng;
  latitude: number;
  longitude: number;
  type: string;
  itemId: number;
  icon: string;

  constructor({ id, latitude, longitude, type, itemId, icon }: any /*NamedParameters*/) {
    this.id = id;
    this.latitude = latitude;
    this.longitude = longitude;
    this.type = type;
    this.itemId = itemId;
    this.icon = icon;
  }

  static fromJson(map: Record<string, any>): ItineraryWaypoint {
    return new ItineraryWaypoint({
      id: GeneralHelper.parseIntOrNull(map["id"]),
      latitude: parseFloat(map["latlng"]["lat"]),
      longitude: parseFloat(map["latlng"]["lng"]),
      type: GeneralHelper.avoidNullOrEmptyString(map["type"]),
      itemId: GeneralHelper.parseIntOrNull(map["itemId"]),
      icon: GeneralHelper.avoidNullOrEmptyString(map["icon"]),
    });
  }
}

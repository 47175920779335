import Service from "models/service";

export abstract class ServicesState {}

// export class ServicesInitial extends ServicesState {}

export class ServicesLoading extends ServicesState {}

export class ServicesLoaded extends ServicesState {
  services: Array<Service>;
  constructor(services: Array<Service>) {
    super();
    this.services = services;
  }

  public toString = (): string => {
    return `ServicesLoaded ${this.services.length} services`;
  };
}

export class ServicesFailure extends ServicesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `ServicesLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}

import useQuery from "helpers/use_query";
import Footer from "./footer";
import Header from "./header/header";
import HeaderMobile from "./header/header_mobile";
import React, {useContext, useEffect, useRef, useState} from "react";
import {GlobalContextProvider, useGlobalContext} from "contexts/global_context";
import {CookiesProvider, useCookies} from "react-cookie";

// import './base.scss';

type Props = {
    id?: string;
    children: JSX.Element[] | JSX.Element;
    // center: boolean;
    // actionButtons: JSX.Element[] | JSX.Element;
};

const BaseScreen: React.FC<Props> = (props: Props) => {

    let query = useQuery();
    const contentOnly: boolean = query.get("contentOnly") == "true" || query.get("contentOnly") == "1";

    const [isSticky, setIsSticky] = useState(false);
    const GlobalContext = useGlobalContext();

    const [cookies, setCookie] = useCookies(["showModalSmartphone"]);
    const [showModal, setShowModal] = useState(cookies.showModalSmartphone == true)

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);

            //Si la page est grande alors on ferme le menu de navigation
            if (window.innerWidth > 1200) {
                GlobalContext.closeDrawer();
            }
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);


    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        let date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
        setCookie('showModalSmartphone', showModal, {expires: date, path: "/"});
    }, [showModal]);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <HeaderMobile sticky={isSticky} isOpened={GlobalContext.isDrawerOpen}
                          closeDrawer={() => GlobalContext.closeDrawer()}/>
            <div className="d-flex flex-column flex-root mainContent" id={props.id} style={{marginTop: isSticky ? (windowSize[0] > 1200 ? '-15px' : '-80px') : '0'}}>
                <div className="page d-flex flex-row flex-column-fluid">
                    <div
                        className={["wrapper d-flex flex-column flex-row-fluid", isSticky ? "fixed-space-top" : ""].join(" ")}
                        id="kt_wrapper">
                        {contentOnly == false && <Header sticky={isSticky}/>}

                        {props.children}

                        {contentOnly == false && <Footer/>}
                    </div>
                </div>
            </div>

            <div id="kt_scrolltop" className={["scrolltop", isSticky ? "show" : ""].join(" ")} data-kt-scrolltop="true"
                 onClick={scrollToTop}>
                <i className="ki-duotone ki-arrow-up">
                    <span className="path1"></span>
                    <span className="path2"></span>
                </i>
            </div>
            <CookiesProvider>
                {!showModal && (
                    <div className="modalSmartphone container-xxl position-fixed bottom-0 bg-white py-12 z-1000">
                        <h2>Vous êtes sur Smartphone ?</h2>
                        <p className="mt-4">Rendez-vous sur le PlayStore ou l'AppStore pour télécharger l'application
                            Haute
                            Ajoie !</p>
                        <div className="d-flex mt-7">
                            <a href="https://novadev.ch/region-hauteajoie/">
                                <button className="btnGreen" onClick={() => setShowModal(true)}>Télécharger</button>
                            </a>
                            <button className="btnNoApp" onClick={() => setShowModal(true)}>Non merci</button>
                        </div>
                    </div>
                )}
            </CookiesProvider>
        </>
    );

};

// BaseScreen.defaultProps = {
//   center: false,
//   actionButtons: null as any,
// };

export default BaseScreen;

import { LoadItineraries } from "blocs/itineraries_bloc/itineraries_event";
import { ItinerariesFailure, ItinerariesLoaded, ItinerariesLoading, ItinerariesState } from "blocs/itineraries_bloc/itineraries_state";
import ArchiveFilter from "components/data_components/archive_filter";
import ItineraryCard from "components/data_components/itinerary_card";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GenericFilters from "models/filters/generic_filters";
import Itinerary from "models/itinerary";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BlocBuilder } from "@felangel/react-bloc";
import { MunicipalitiesLoaded, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import useQuery from "helpers/use_query";

// import styles from './home.scss';

interface Props extends WithTranslation { }

const ItinerariesScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);
  let query = useQuery();
  const municipalityIdParams = query.get("municipalityId");

  const [itinerariesFilters, setGenericFilters] = useState<GenericFilters>(
    new GenericFilters({
      municipalityId: municipalityIdParams != undefined ? parseInt(municipalityIdParams) : undefined,
    })
  );

  const [itinerariesState, setItinerariesState] = useState<ItinerariesState>(new ItinerariesLoading());

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setItinerariesState(blocsContext.itinerariesBloc.state);
    const subscription = blocsContext.itinerariesBloc.listen(setItinerariesState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const itinerary: Itinerary | undefined = undefined;

  if (itinerariesState instanceof ItinerariesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (itinerariesState instanceof ItinerariesFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div
              className="btn btn-primary mt-6" onClick={() => blocsContext.itinerariesBloc.add(new LoadItineraries())}            >
              Recharger
            </div>
          </div>

        </div>
      </BaseScreen>
    );
  }

  if (itinerariesState instanceof ItinerariesLoaded) {
    const itineraries: Itinerary[] = itinerariesFilters.filter(itinerariesState.itineraries);

    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="d-flex flex-stack flex-wrap mb-6 mt-5 mt-lg-20">
              <div>
                <h3 className="fs-1 fw-800">Itinéraires</h3>
                <div className="BackLinkWrapper">
                  <a className="cursor-pointer" onClick={() => navigate(-1)}>
                    ← Retour
                  </a>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center">
                {itinerariesFilters.municipalityId != undefined && (
                  <BlocBuilder
                    bloc={blocsContext.municipalitiesBloc}
                    builder={(state: MunicipalitiesState) => {
                      if (state instanceof MunicipalitiesLoaded) {
                        return (
                          <div className="badge badge-secondary fw-bolder me-2 activeService">
                            <div
                              className="cursor-pointer me-2"
                              onClick={() => {
                                var newFilters = itinerariesFilters.clone();
                                newFilters.municipalityId = undefined;
                                setGenericFilters(newFilters);
                              }}
                            >
                              <i className="fa fa-xmark"></i>
                            </div>
                            Commune : {state.municipalities.find((municipality) => municipality.id == itinerariesFilters.municipalityId)?.name}
                          </div>
                        );
                      }
                      return <></>;
                    }}
                  />
                )}

                <ArchiveFilter
                  filters={itinerariesFilters}
                  onSetFilters={(filters: GenericFilters) => {
                    setGenericFilters(new GenericFilters({ ...filters }));
                  }}
                  dataType="Itinerary"
                />
              </div>
            </div>

            {itineraries.length == 0 && (
              <div className="card-px text-center py-20 my-20">
                <h2 className="fs-2x fw-bold mb-8">Aucun élément trouvé</h2>
                <p className="text-gray-400 fs-4 fw-semibold mb-10">Essayez d'ajuster les filtres pour trouver ce que vous cherchez</p>
                {/* <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add Customer</a> */}
              </div>
            )}
            {itineraries.length != 0 && (
              <div className="row row-cols-1 g-5 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 itineraryRow">
                {itineraries.map((itinerary) => (
                  <ItineraryCard key={itinerary.id} itinerary={itinerary} />
                ))}
              </div>
            )}
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(itinerary)
};

export default withTranslation("common")(ItinerariesScreen);

import LocalProduct from "models/local_product";

export abstract class LocalProductsEvent {}

export class LoadLocalProducts extends LocalProductsEvent {
  public toString = (): string => `LoadLocalProducts`;
}

export class AddLocalProduct extends LocalProductsEvent {
  localProduct: LocalProduct;
  constructor(localProduct: LocalProduct) {
    super();
    this.localProduct = localProduct;
  }

  public toString = (): string => `AddLocalProduct`;
}

export class EditLocalProduct extends LocalProductsEvent {
  localProduct: LocalProduct;
  constructor(localProduct: LocalProduct) {
    super();
    this.localProduct = localProduct;
  }

  public toString = (): string => `EditLocalProduct`;
}

export class DeleteLocalProduct extends LocalProductsEvent {
  localProductId: number;
  constructor(localProductId: number) {
    super();
    this.localProductId = localProductId;
  }

  public toString = (): string => `DeleteLocalProduct`;
}

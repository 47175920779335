import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const NotFoundScreen: React.FC<Props> = ({ t }) => {
  return (
    <BaseScreen>
      <div className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="content flex-row-fluid">
          <div className="row">
            <div className="col-12 fs-1 text-center my-20">
              404 - Page non trouvée
            </div>
          </div>
        </div>
      </div>
    </BaseScreen>
  );
};

export default withTranslation("common")(NotFoundScreen);

import News from "models/news";

export abstract class NewsState {}

// export class NewsInitial extends NewsState {}

export class NewsLoading extends NewsState {}

export class NewsLoaded extends NewsState {
  news: Array<News>;
  constructor(news: Array<News>) {
    super();
    this.news = news;
  }

  public toString = (): string => {
    return `NewsLoaded ${this.news.length} news`;
  };
}

export class NewsFailure extends NewsState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `NewsLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}

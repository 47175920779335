import GeneralHelper from "helpers/general_helper";
import UniversalCategory from "./universal_category";
import Municipality from "./municipality";
import ResponsiveImage from "./responsive_image";
import { ImageSizes } from "constants/constants";

export default class News {
  id: number;
  name: string;
  description: string;
  category: UniversalCategory;
  municipality: Municipality;
  createdAt: Date;
  // images: Array<string>;
  responsiveImages: Array<ResponsiveImage>;

  constructor({ id, name, description, category, municipality, createdAt, /*images,*/ responsiveImages }: any /*NamedParameters*/) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.category = category;
    this.municipality = municipality;
    this.createdAt = createdAt;
    // this.images = images;
    this.responsiveImages = responsiveImages;
  }

  static fromJson(map: Record<string, any>): News {
    return new News({
      id: GeneralHelper.parseIntOrNull(map["id"]),
      name: GeneralHelper.avoidNullOrEmptyString(map["name"]),
      description: GeneralHelper.avoidNullOrEmptyString(map["description"]),
      category: UniversalCategory.fromJson(map["category"]),
      municipality: Municipality.fromJson(map["municipality"]),
      createdAt: new Date(map["created_at"]),
      images: map["images"] != null ? map["images"].map((image: string) => image) : [],
      responsiveImages: map["images_responsives"] != null ? map["images_responsives"].map((image: Map<string, string>) => ResponsiveImage.fromJson(image)) : [],
    });
  }

  public getFirstImage(size: ImageSizes): string | undefined {
    return this.responsiveImages.length > 0 ? this.responsiveImages[0].getUrlFromSize(size) : undefined;
    // return this.images.length > 0 ? this.images[0] : undefined;
  }
}

import Event from "models/event"; // Adapter l'import en conséquence

import Config from "config/config";
import axios from "axios";

export default class EventRepository {
  static _eventsEndpoint: string = "events"; // Adapter l'endpoint en conséquence
  static _eventsDataKey: string = "events_data"; // Adapter la clé de données en conséquence

  async getData(): Promise<Array<Event>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + EventRepository._eventsEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToEvents(data.data.events);
  }

  dataToEvents(data: any): Array<Event> {
    var events: Array<Event> = [];
    for (var i = 0; i < data.length; i++) {
      events.push(Event.fromJson(data[i]));
    }
    return events;
  }
}

import Service from "models/service";

export abstract class ServicesEvent {}

export class LoadServices extends ServicesEvent {
  public toString = (): string => `LoadServices`;
}
export class AddService extends ServicesEvent {
  service: Service;
  constructor(service: Service) {
    super();
    this.service = service;
  }

  public toString = (): string => `AddService`;
}
export class EditService extends ServicesEvent {
  service: Service;
  constructor(service: Service) {
    super();
    this.service = service;
  }

  public toString = (): string => `EditService`;
}
export class DeleteService extends ServicesEvent {
  serviceId: number;
  constructor(serviceId: number) {
    super();
    this.serviceId = serviceId;
  }

  public toString = (): string => `DeleteService`;
}
// export class UpdateServicesUserLocation extends ServicesEvent {
//   position: Position;
//   constructor(service: Service) {
//     super();
//     this.service = service;
//   }

//   public toString = (): string => `AddService`;
// }

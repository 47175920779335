import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {}

const AboutScreen: React.FC<Props> = ({ t }) => {
  return (
    <>
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6">
              <div className="col-12">
                <h3 className="fs-1 fw-600">À propos</h3>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </BaseScreen>
    </>
  );
};

export default withTranslation("common")(AboutScreen);

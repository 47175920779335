import GeneralHelper from "helpers/general_helper";

export default class UniversalCategory {
  id: number;
  name: string;
  count: number;
  createdAt: Date;

  constructor({ id, name, count, createdAt }: any /*NamedParameters*/) {
    this.id = id;
    this.name = name;
    this.count = count;
    this.createdAt = createdAt;
  }

  static areIdentical(a: UniversalCategory, b: UniversalCategory): boolean {
    if (!a && !b) return true;
    if (!a || !b) return false;
    return a.id === b.id;
  }

  static fromJson(map: Record<string, any>): UniversalCategory {
    return new UniversalCategory({
      id: GeneralHelper.parseIntOrNull(map["id"]),
      name: GeneralHelper.avoidNullOrEmptyString(map["name"]),
      count: GeneralHelper.parseIntOrNull(map["count"]),
      createdAt: new Date(map["created_at"]),
    });
  }

  public copyWith({ id, name, count, createdAt }: any): UniversalCategory {
    return new UniversalCategory({
      id: id ?? this.id,
      name: name ?? this.name,
      count: count ?? this.count,
      createdAt: createdAt ?? this.createdAt,
    });
  }
}

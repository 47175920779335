import UniversalCategory from "models/universal_category";

export abstract class CompanyCategoriesState {}

// export class CompanyCategoriesInitial extends CompanyCategoriesState {}

export class CompanyCategoriesLoading extends CompanyCategoriesState {}

export class CompanyCategoriesLoaded extends CompanyCategoriesState {
  categories: Array<UniversalCategory>;
  constructor(categories: Array<UniversalCategory>) {
    super();
    this.categories = categories;
  }

  public toString = (): string => {
    return `CompanyCategoriesLoaded ${this.categories.length} categories`;
  };
}

export class CompanyCategoriesFailure extends CompanyCategoriesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `CompanyCategoriesLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}

import { LoadItineraries } from "blocs/itineraries_bloc/itineraries_event";
import { ItinerariesFailure, ItinerariesLoaded, ItinerariesLoading, ItinerariesState } from "blocs/itineraries_bloc/itineraries_state";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import Itinerary from "models/itinerary";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import imgGoogleMaps from "assets/images/google-maps.png";
import imgInnerMap from "assets/images/marker_activite.png";
import imgIconDesc from "assets/images/bubble.png";
import GeneralHelper from "helpers/general_helper";
import { Link } from "react-router-dom";
import defaultPic from "assets/images/default_pic.png";
import ImagesGallery from "components/image_gallery";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const ItineraryScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [itinerariesState, setItinerariesState] = useState<ItinerariesState>(new ItinerariesLoading());

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setItinerariesState(blocsContext.itinerariesBloc.state);
    const subscription = blocsContext.itinerariesBloc.listen(setItinerariesState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const itinerary: Itinerary | undefined = undefined;

  if (itinerariesState instanceof ItinerariesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (itinerariesState instanceof ItinerariesFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div className="btn btn-primary mt-6" onClick={() => blocsContext.itinerariesBloc.add(new LoadItineraries())}>
              Recharger
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (itinerariesState instanceof ItinerariesLoaded) {
    const itinerary: Itinerary = itinerariesState.itineraries.find((itinerary) => itinerary.id.toString() == params.id) as Itinerary;
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <a className="cursor-pointer" onClick={() => navigate(-1)}>
                  ← Retour
                </a>
                <div className="singleItem">
                  <ImagesGallery images={itinerary.responsiveImages} />
                  <div className="singleItemInnerContent">
                    <div className="singleItemTags">
                      <div className="tagsWrapper">
                        <div className="singleItemCategory">{itinerary.category.name}</div>
                        <div className="singleItemMunicipality">{itinerary.municipality.name}</div>
                      </div>
                      {/* <div className="singleItemShareLink">
                        <a href="#">⭷ Partager</a>
                      </div> */}
                    </div>
                    <h3 className="fs-1 fw-600 singleItemTitle">{itinerary.name}</h3>
                    <div className="singleItemAddress">{[itinerary.distance, itinerary.duration].filter((data) => data != undefined && data.trim() != "").join(" • ")}</div>
                    <div className="btnWrapper">
                      <Link to={{ pathname: "/map", search: new URLSearchParams({ datatype: "itinerary", id: itinerary.id.toString() }).toString() }} className="seeMapBtn btnWrapper">
                        <div className="btnIcon">
                          <img src={imgInnerMap} />
                          <div className="btnText text-black">Voir sur la carte</div>
                        </div>
                      </Link>
                      {/* <a href="#" className="seeMapBtn btnWrapper">
                        <div className="btnIcon">
                            <img src={imgGoogleMaps}/>
                            <span className="btnText">Obtenir l'itinéraire</span>
                        </div>
                      </a> */}
                    </div>
                    <div className="singleItemDescription">
                      <img src={imgIconDesc} />
                      <div className="descTitle">Description</div>
                      {/* {GeneralHelper.cleanHtmlString(itinerary.description || 'Aucune description disponible.')} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: itinerary.description || "Aucune description disponible.",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(itinerary)
};

export default withTranslation("common")(ItineraryScreen);

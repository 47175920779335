import React, { createContext, useContext, useState, useEffect } from "react";

type globalContextValueType = {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
};

const globalContextValue: globalContextValueType = {
  isDrawerOpen: false,
  openDrawer: () => {},
  closeDrawer: () => {},
};

const GlobalContext = createContext(globalContextValue);


export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error(
      "useGlobalContext must be used within a GlobalContextProvider"
    );
  }
  return context;
};



export const GlobalContextProvider: React.FC = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        isDrawerOpen,
        openDrawer,
        closeDrawer,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
// import styles from './home.scss';

import backgroundImage from "assets/images/home_section_1_background.jpg";
import { Link } from "react-router-dom";
import Itinerary from "models/itinerary";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { ItinerariesLoaded, ItinerariesState } from "blocs/itineraries_bloc/itineraries_state";
import GeneralHelper from "helpers/general_helper";

import defaultPic from "assets/images/default_pic.png";
import { ImageSizes } from "constants/constants";

interface Props extends WithTranslation {
  itinerary: Itinerary;
}

const ItineraryCard: React.FC<Props> = ({ t, itinerary }) => {
  return (
    <div className="col itenaryCard">
      <Link to={"/itinerary/" + itinerary.id} className="d-block mb-11 mb-md-5 cardLink" data-fslightbox="lightbox-hot-sales">
        {/* <a
            className="d-block overlay"
            data-fslightbox="lightbox-hot-sales"
            href="assets/media/stock/900x600/42.jpg"
          > */}
        <div className="singleItemCuriosity">
          <div className="cardCuriosity itinerary">
            <img src={itinerary.getFirstImage(ImageSizes.small) ?? defaultPic} className="curiosityImg" />
            <div className="cardCuriosityInnerContent">
              <div className="cardMunicipalityTag">{itinerary.municipality.name}</div>
              <div className="cardTitle">{GeneralHelper.truncateText(itinerary.name, 40)}</div>
              <div className="cardCategory">{itinerary.category.name}</div>
              <div className="dividerCardItinerary"></div>
              <div className="cardCategory additionnal">{[itinerary.distance, itinerary.duration].filter((data) => data != undefined && data.trim() != "").join(" • ")}</div>
            </div>
          </div>
        </div>
        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
          {/* <i className="ki-duotone ki-eye fs-3x text-white">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i> */}
        </div>
      </Link>
    </div>
  );
};

export default withTranslation("common")(ItineraryCard);

import GeneralHelper from "helpers/general_helper";
import UniversalCategory from "./universal_category";
import Municipality from "./municipality";
import ResponsiveImage from "./responsive_image";
import { ImageSizes } from "constants/constants";

export default class Merchant {
  id: number;
  name: string;
  description: string;
  category: UniversalCategory;
  phone: string;
  email: string;
  website: string;
  address: string;
  latitude: number;
  longitude: number;
  distance: number;
  // images: Array<string>;
  responsiveImages: Array<ResponsiveImage>;
  municipality: Municipality;
  createdAt: Date;

  constructor({ id, name, description, category, phone, email, website, address, latitude, longitude, distance, /*images,*/ responsiveImages, municipality, createdAt }: any /*NamedParameters*/) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.category = category;
    this.phone = phone;
    this.email = email;
    this.website = website;
    this.address = address;
    this.latitude = latitude;
    this.longitude = longitude;
    this.distance = distance;
    // this.images = images;
    this.responsiveImages = responsiveImages;
    this.municipality = municipality;
    this.createdAt = createdAt;
  }

  static fromJson(map: Record<string, any>): Merchant {
    return new Merchant({
      id: GeneralHelper.parseIntOrNull(map["id"]),
      name: GeneralHelper.avoidNullOrEmptyString(map["name"]),
      description: GeneralHelper.avoidNullOrEmptyString(map["description"]),
      phone: GeneralHelper.avoidNullOrEmptyString(map["phone"]),
      email: GeneralHelper.avoidNullOrEmptyString(map["email"]),
      website: GeneralHelper.avoidNullOrEmptyString(map["website"]),
      address: GeneralHelper.avoidNullOrEmptyString(map["address"]),
      images: map["images"] != null ? map["images"].map((image: string) => image) : [],
      responsiveImages: map["images_responsives"] != null ? map["images_responsives"].map((image: Map<string, string>) => ResponsiveImage.fromJson(image)) : [],
      latitude: GeneralHelper.parseDoubleOrNull(map["latitude"]),
      longitude: GeneralHelper.parseDoubleOrNull(map["longitude"]),
      category: UniversalCategory.fromJson(map["category"]),
      createdAt: new Date(map["created_at"]),
      municipality: Municipality.fromJson(map["municipality"]),
    });
  }

  public getFirstImage(size: ImageSizes): string | undefined {
    return this.responsiveImages.length > 0 ? this.responsiveImages[0].getUrlFromSize(size) : undefined;
    // return this.images.length > 0 ? this.images[0] : undefined;
  }
}

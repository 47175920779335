import Company from "models/company";

export abstract class CompaniesState {}

// export class CompaniesInitial extends CompaniesState {}

export class CompaniesLoading extends CompaniesState {}

export class CompaniesLoaded extends CompaniesState {
  companies: Array<Company>;
  constructor(companies: Array<Company>) {
    super();
    this.companies = companies;
  }

  public toString = (): string => {
    return `CompaniesLoaded ${this.companies.length} companies`;
  };
}

export class CompaniesFailure extends CompaniesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `CompaniesLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}

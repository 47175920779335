import { Bloc, Transition } from "@felangel/bloc";
import UniversalCategory from "models/universal_category";
import ServiceCategoriesRepository from "repositories/service_category_repository"; // Adapter l'import en fonction de ton repository
import {
  ServiceCategoriesEvent,
  LoadServiceCategories,
} from "./service_categories_event"; // Adapter les imports en fonction de tes événements
import {
  ServiceCategoriesState,
  ServiceCategoriesLoaded,
  ServiceCategoriesLoading,
  ServiceCategoriesFailure,
} from "./service_categories_state"; // Adapter les imports en fonction des états

export default class ServiceCategoriesBloc extends Bloc<ServiceCategoriesEvent, ServiceCategoriesState> {
  serviceRepository: ServiceCategoriesRepository; // Adapter le nom du repository en conséquence
  categories: Array<UniversalCategory> = [];

  constructor(serviceRepository: ServiceCategoriesRepository) {
    super(new ServiceCategoriesLoading()); // Adapter l'état initial en conséquence
    this.serviceRepository = serviceRepository; // Adapter le nom du repository en conséquence
  }

  async *mapEventToState(event: ServiceCategoriesEvent) {
    if (event instanceof LoadServiceCategories) { 
      yield* this.onLoadServiceCategories(event); 
    }
  }

  async *onLoadServiceCategories(event: LoadServiceCategories) { 
    try {
      yield new ServiceCategoriesLoading(); // Adapter l'état en conséquence
      var categoriesList: Array<UniversalCategory> = await this.serviceRepository.getData(); // Adapter le nom du repository en conséquence
      this.categories = categoriesList;

      yield new ServiceCategoriesLoaded(this.categories); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ServiceCategoriesFailure(e); // Adapter l'état en conséquence
      } else {
        yield new ServiceCategoriesFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }

  onEvent(event: ServiceCategoriesEvent): void { 
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event =", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition =", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error =", error);
  }
}

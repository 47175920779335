import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
// import styles from './home.scss';

import backgroundImage from "assets/images/home_section_1_background.jpg";
import { Link } from "react-router-dom";
import Company from "models/company";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { CompaniesLoaded, CompaniesState } from "blocs/companies_bloc/companies_state";
import GeneralHelper from "helpers/general_helper";

import defaultPic from "assets/images/default_pic.png";
import { ImageSizes } from "constants/constants";

interface Props extends WithTranslation {
  company: Company;
}

const CompanyCard: React.FC<Props> = ({ t, company }) => {
  return (
    <div className="col companyCard">
      <Link to={"/company/" + company.id} className="d-block mb-11 mb-md-5 cardLink" data-fslightbox="lightbox-hot-sales">
        <div className="singleItemCuriosity">
          <div className="cardCuriosity">
            <img src={company.getFirstImage(ImageSizes.small) ?? defaultPic} className="curiosityImg" />
            <div className="cardCuriosityInnerContent">
              <div className="cardMunicipalityTag">{company.municipality.name}</div>
              <div className="cardTitle">{GeneralHelper.truncateText(company.name, 40)}</div>
              <div className="cardCategory">{company.category.name}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default withTranslation("common")(CompanyCard);

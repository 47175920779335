import UniversalCategory from "models/universal_category";

export abstract class EventCategoriesState {} 

export class EventCategoriesLoading extends EventCategoriesState {} 

export class EventCategoriesLoaded extends EventCategoriesState {
  categories: Array<UniversalCategory>; // Adapter le nom du modèle en conséquence
  constructor(categories: Array<UniversalCategory>) {
    super();
    this.categories = categories;
  }

  public toString = (): string => {
    return `EventCategoriesLoaded ${this.categories.length} categories`; // Adapter le nom en conséquence
  };
}

export class EventCategoriesFailure extends EventCategoriesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `EventCategoriesLoadingFailed { errorMessage: ${this.errorMessage} }`; // Adapter le nom en conséquence
  };
}

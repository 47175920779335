import Service from "models/service";
import Config from "config/config";
import axios from "axios";
import AuthenticationData from "models/authentication_data";
import AuthenticationService from "services/authentication";

export default class ItemSuggestionRepository {
  async suggestItem(
    itemCategoryId: number,
    municipalityId: number,
    categoryId: number,
    title: string,
    address: string,
    website: string,
    phone: string,
    email: string,
    startDate: string,
    endDate: string,
    comment: string
  ): Promise<boolean> {

    var data: any = await axios.post(Config.REST_API_URL + "/suggest-item", {
      item_category_id: itemCategoryId,
      municipality_id: municipalityId,
      category_id: categoryId,
      title: title,
      address: address,
      website: website,
      phone: phone,
      email: email,
      start_date: startDate,
      end_date: endDate,
      comment: comment,
    });
    if (data.status == 200) {
      return true;
    }
    return false;
  }
}

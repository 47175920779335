import Itinerary from "models/itinerary";

export abstract class ItinerariesState {}

// export class ItinerariesInitial extends ItinerariesState {}

export class ItinerariesLoading extends ItinerariesState {}

export class ItinerariesLoaded extends ItinerariesState {
  itineraries: Array<Itinerary>;
  constructor(itineraries: Array<Itinerary>) {
    super();
    this.itineraries = itineraries;
  }

  public toString = (): string => {
    return `ItinerariesLoaded ${this.itineraries.length} itineraries`;
  };
}

export class ItinerariesFailure extends ItinerariesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `ItinerariesLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}

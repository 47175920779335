// import React from "react";
import { Route, Routes } from "react-router-dom";
// import HelpInfoScreen from "screens/help_info/help_info";
import PrivacyPolicyScreen from "screens/privacy_policy/privacy_policy";
import LoginScreen from "screens/login/login";
import ForgotPasswordScreen from "screens/forgot_password/forgot_password";

import MyAccountScreen from "screens/my_account/my_account";

import RegisterScreen from "screens/register/register";
import HomeScreen from "screens/home/home";
import MunicipalitiesScreen from "screens/municipalities/municipalities";
import MunicipalityScreen from "screens/municipality/municipality";

import ServicesScreen from "screens/services/services";
import ServiceScreen from "screens/service/service";
import ServiceCreateScreen from "screens/service_create/service_create";
import ServiceEditScreen from "screens/service_edit/service_edit";
import ServiceMyArchiveScreen from "screens/service_my_archive/service_my_archive";

import EventsScreen from "screens/events/events";
import EventScreen from "screens/event/event";

import CuriositiesScreen from "screens/curiosities/curiosities";
import CuriosityScreen from "screens/curiosity/curiosity";

import CompaniesScreen from "screens/companies/companies";
import CompanyScreen from "screens/company/company";

import NewsScreen from "screens/news/news";
import NewsSingleScreen from "screens/news_single/news_single";

import LocalProductsScreen from "screens/local_products/local_products";
import LocalProductScreen from "screens/local_product/local_product";

import MerchantsScreen from "screens/merchants/merchants";
import MerchantScreen from "screens/merchant/merchant";

import ItinerariesScreen from "screens/itineraries/itineraries";
import ItineraryScreen from "screens/itinerary/itinerary";

import MapScreen from "screens/map/map";
import EulaScreen from "screens/eula/eula";

// import BlogView from "./views/Blog/Blog";

import NotFound from "screens/not_found/not_found";
import EchangeEntraideScreen from "screens/echange_entraide/echange_entraide";
import VieLocaleScreen from "screens/vie_locale/vie_locale";
import TourismeLoisir from "screens/tourisme_loisir/tourisme_loisir";
import About from "screens/about/about";
import SuggestItemScreen from "screens/suggest_item/suggest_item";



const Router = (props: any) => {
  return (
    <Routes>
      {/* <Route path={"/"} isLoggedIn={ props.loggedIn } >(<Redirect to="/blog" />)</Route>
            <Route path="/index" isLoggedIn={ props.loggedIn } >(<Redirect to="/blog" />)</Route> */}

      <Route path={"/"} Component={HomeScreen} />

      <Route path={"/municipalities"} Component={MunicipalitiesScreen} />
      <Route path={"/municipality/:id"} Component={MunicipalityScreen} />

      <Route path={"/vie-locale"} Component={VieLocaleScreen} />
      <Route path={"/tourisme-loisir"} Component={TourismeLoisir} />
      <Route path={"/echange-entraide"} Component={EchangeEntraideScreen} />

      <Route path={"/services"} Component={ServicesScreen} />
      <Route path={"/service/:id"} Component={ServiceScreen} />
      {/* <Route path={"/service/authentication-required"} ><MunicipalityScreen /></Route> */}
      <Route path={"/service/new"} Component={ServiceCreateScreen} />
      <Route path={"/service/:id/edit"} Component={ServiceEditScreen} />
      <Route path={"/my-services/"} Component={ServiceMyArchiveScreen} />

      <Route path={"/events"} Component={EventsScreen} />
      <Route path={"/event/:id"} Component={EventScreen} />

      <Route path={"/curiosities"} Component={CuriositiesScreen} />
      <Route path={"/curiosity/:id"} Component={CuriosityScreen} />

      <Route path={"/companies"} Component={CompaniesScreen} />
      <Route path={"/company/:id"} Component={CompanyScreen} />

      <Route path={"/news"} Component={NewsScreen} />
      <Route path={"/news/:id"} Component={NewsSingleScreen} />

      <Route path={"/local-products"} Component={LocalProductsScreen} />
      <Route path={"/local-product/:id"} Component={LocalProductScreen} />

      <Route path={"/merchants"} Component={MerchantsScreen} />
      <Route path={"/merchant/:id"} Component={MerchantScreen} />

      <Route path={"/itineraries"} Component={ItinerariesScreen} />
      <Route path={"/itinerary/:id"} Component={ItineraryScreen} />
      <Route path={"/map"} Component={MapScreen} />
      <Route path={"/a-propos"} Component={About} />
      <Route path={"/privacy-policy"} Component={PrivacyPolicyScreen} />
      <Route path={"/eula"} Component={EulaScreen} />
      {/* <Route path={"/help-info"} ><HelpInfoScreen /></Route> */}

      <Route path={"/register"} Component={RegisterScreen} />
      <Route path={"/login"} Component={LoginScreen} />
      <Route path={"/forgot-password"} Component={ForgotPasswordScreen} />
      
      <Route path={"/my-account"} Component={MyAccountScreen} />

      
      <Route path={"/suggest-item"} Component={SuggestItemScreen} />

      {/* <Route path={"/my-services"} Component={MyServicesScreen} /> */}
      

      {/*Page Not Found*/}
      <Route path="*" Component={NotFound} />
    </Routes>
  );
};

// const AuthRoute = ({ isLoggedIn, ...props } : {isLoggedIn: boolean, props: any }) =>
//   isLoggedIn
//     ? <Route { ...props </Route>
//     : <Redirect to="/login" />

export default Router;

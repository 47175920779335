import React from 'react';
import { useConfirmationModal } from './logout_confirmation_modal_context';

const LogoutConfirmationModal: React.FC = () => {

  // const { isModalOpen, openModal, closeModal, onConfirm } = useConfirmationModal();
  const confirmationModal = useConfirmationModal();

  if (!confirmationModal.isModalOpen) return null;



  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Êtes-vous sûr de vouloir vous déconnecter ?</h2>
        <div className="modal-buttons">
          <button onClick={confirmationModal.closeModal}>Annuler</button>
          <button onClick={() => confirmationModal.onConfirm()}>Déconnexion</button>
        </div>
      </div>
    </div>


    // <div className="modal fade show" id="kt_modal_create_app" style={{ display: "block" }} aria-modal="true" role="dialog">
			
		// 	<div className="modal-dialog modal-dialog-centered mw-900px">
				
		// 		<div className="modal-content">
					
		// 			<div className="modal-header">
						
		// 				<h2>Create App</h2>
						
						
		// 				<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
		// 					<i className="ki-duotone ki-cross fs-1">
		// 						<span className="path1"></span>
		// 						<span className="path2"></span>
		// 					</i>
		// 				</div>
						
		// 			</div>
					
					
		// 			<div className="modal-body py-lg-10 px-lg-10">
						
		// 				<div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper" data-kt-stepper="true">
							
		// 					<div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
								
		// 						<div className="stepper-nav ps-lg-10">
									
		// 							<div className="stepper-item current" data-kt-stepper-element="nav">
										
		// 								<div className="stepper-wrapper">
											
		// 									<div className="stepper-icon w-40px h-40px">
		// 										<i className="ki-duotone ki-check stepper-check fs-2"></i>
		// 										<span className="stepper-number">1</span>
		// 									</div>
											
											
		// 									<div className="stepper-label">
		// 										<h3 className="stepper-title">Details</h3>
		// 										<div className="stepper-desc">Name your App</div>
		// 									</div>
											
		// 								</div>
										
										
		// 								<div className="stepper-line h-40px"></div>
										
		// 							</div>
									
									
		// 							<div className="stepper-item" data-kt-stepper-element="nav">
										
		// 								<div className="stepper-wrapper">
											
		// 									<div className="stepper-icon w-40px h-40px">
		// 										<i className="ki-duotone ki-check stepper-check fs-2"></i>
		// 										<span className="stepper-number">2</span>
		// 									</div>
											
											
		// 									<div className="stepper-label">
		// 										<h3 className="stepper-title">Frameworks</h3>
		// 										<div className="stepper-desc">Define your app framework</div>
		// 									</div>
											
		// 								</div>
										
										
		// 								<div className="stepper-line h-40px"></div>
										
		// 							</div>
									
									
		// 							<div className="stepper-item" data-kt-stepper-element="nav">
										
		// 								<div className="stepper-wrapper">
											
		// 									<div className="stepper-icon w-40px h-40px">
		// 										<i className="ki-duotone ki-check stepper-check fs-2"></i>
		// 										<span className="stepper-number">3</span>
		// 									</div>
											
											
		// 									<div className="stepper-label">
		// 										<h3 className="stepper-title">Database</h3>
		// 										<div className="stepper-desc">Select the app database type</div>
		// 									</div>
											
		// 								</div>
										
										
		// 								<div className="stepper-line h-40px"></div>
										
		// 							</div>
									
									
		// 							<div className="stepper-item" data-kt-stepper-element="nav">
										
		// 								<div className="stepper-wrapper">
											
		// 									<div className="stepper-icon w-40px h-40px">
		// 										<i className="ki-duotone ki-check stepper-check fs-2"></i>
		// 										<span className="stepper-number">4</span>
		// 									</div>
											
											
		// 									<div className="stepper-label">
		// 										<h3 className="stepper-title">Billing</h3>
		// 										<div className="stepper-desc">Provide payment details</div>
		// 									</div>
											
		// 								</div>
										
										
		// 								<div className="stepper-line h-40px"></div>
										
		// 							</div>
									
									
		// 							<div className="stepper-item mark-completed" data-kt-stepper-element="nav">
										
		// 								<div className="stepper-wrapper">
											
		// 									<div className="stepper-icon w-40px h-40px">
		// 										<i className="ki-duotone ki-check stepper-check fs-2"></i>
		// 										<span className="stepper-number">5</span>
		// 									</div>
											
											
		// 									<div className="stepper-label">
		// 										<h3 className="stepper-title">Completed</h3>
		// 										<div className="stepper-desc">Review and Submit</div>
		// 									</div>
											
		// 								</div>
										
		// 							</div>
									
		// 						</div>
								
		// 					</div>
							
							
		// 					<div className="flex-row-fluid py-lg-5 px-lg-15">
								
		// 						<form className="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_modal_create_app_form">
									
		// 							<div className="current" data-kt-stepper-element="content">
		// 								<div className="w-100">
											
		// 									<div className="fv-row mb-10 fv-plugins-icon-container">
												
		// 										<label className="d-flex align-items-center fs-5 fw-semibold mb-2">
		// 											<span className="required">App Name</span>
		// 											<span className="ms-1" data-bs-toggle="tooltip" aria-label="Specify your unique app name" data-bs-original-title="Specify your unique app name" data-kt-initialized="1">
		// 												<i className="ki-duotone ki-information-5 text-gray-500 fs-6">
		// 													<span className="path1"></span>
		// 													<span className="path2"></span>
		// 													<span className="path3"></span>
		// 												</i>
		// 											</span>
		// 										</label>
												
												
		// 										<input type="text" className="form-control form-control-lg form-control-solid" name="name" placeholder="" value="">
												
		// 									<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
											
											
		// 									<div className="fv-row">
												
		// 										<label className="d-flex align-items-center fs-5 fw-semibold mb-4">
		// 											<span className="required">Category</span>
		// 											<span className="ms-1" data-bs-toggle="tooltip" aria-label="Select your app category" data-bs-original-title="Select your app category" data-kt-initialized="1">
		// 												<i className="ki-duotone ki-information-5 text-gray-500 fs-6">
		// 													<span className="path1"></span>
		// 													<span className="path2"></span>
		// 													<span className="path3"></span>
		// 												</i>
		// 											</span>
		// 										</label>
												
												
		// 										<div className="fv-row fv-plugins-icon-container">
													
		// 											<label className="d-flex flex-stack mb-5 cursor-pointer">
														
		// 												<span className="d-flex align-items-center me-2">
															
		// 													<span className="symbol symbol-50px me-6">
		// 														<span className="symbol-label bg-light-primary">
		// 															<i className="ki-duotone ki-compass fs-1 text-primary">
		// 																<span className="path1"></span>
		// 																<span className="path2"></span>
		// 															</i>
		// 														</span>
		// 													</span>
															
															
		// 													<span className="d-flex flex-column">
		// 														<span className="fw-bold fs-6">Quick Online Courses</span>
		// 														<span className="fs-7 text-muted">Creating a clear text structure is just one SEO</span>
		// 													</span>
															
		// 												</span>
														
														
		// 												<span className="form-check form-check-custom form-check-solid">
		// 													<input className="form-check-input" type="radio" name="category" value="1">
		// 												</span>
														
		// 											</label>
													
													
		// 											<label className="d-flex flex-stack mb-5 cursor-pointer">
														
		// 												<span className="d-flex align-items-center me-2">
															
		// 													<span className="symbol symbol-50px me-6">
		// 														<span className="symbol-label bg-light-danger">
		// 															<i className="ki-duotone ki-element-11 fs-1 text-danger">
		// 																<span className="path1"></span>
		// 																<span className="path2"></span>
		// 																<span className="path3"></span>
		// 																<span className="path4"></span>
		// 															</i>
		// 														</span>
		// 													</span>
															
															
		// 													<span className="d-flex flex-column">
		// 														<span className="fw-bold fs-6">Face to Face Discussions</span>
		// 														<span className="fs-7 text-muted">Creating a clear text structure is just one aspect</span>
		// 													</span>
															
		// 												</span>
														
														
		// 												<span className="form-check form-check-custom form-check-solid">
		// 													<input className="form-check-input" type="radio" name="category" value="2">
		// 												</span>
														
		// 											</label>
													
													
		// 											<label className="d-flex flex-stack cursor-pointer">
														
		// 												<span className="d-flex align-items-center me-2">
															
		// 													<span className="symbol symbol-50px me-6">
		// 														<span className="symbol-label bg-light-success">
		// 															<i className="ki-duotone ki-timer fs-1 text-success">
		// 																<span className="path1"></span>
		// 																<span className="path2"></span>
		// 																<span className="path3"></span>
		// 															</i>
		// 														</span>
		// 													</span>
															
															
		// 													<span className="d-flex flex-column">
		// 														<span className="fw-bold fs-6">Full Intro Training</span>
		// 														<span className="fs-7 text-muted">Creating a clear text structure copywriting</span>
		// 													</span>
															
		// 												</span>
														
														
		// 												<span className="form-check form-check-custom form-check-solid">
		// 													<input className="form-check-input" type="radio" name="category" value="3">
		// 												</span>
														
		// 											</label>
													
		// 										<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
												
		// 									</div>
											
		// 								</div>
		// 							</div>
									
									
		// 							<div data-kt-stepper-element="content">
		// 								<div className="w-100">
											
		// 									<div className="fv-row fv-plugins-icon-container">
												
		// 										<label className="d-flex align-items-center fs-5 fw-semibold mb-4">
		// 											<span className="required">Select Framework</span>
		// 											<span className="ms-1" data-bs-toggle="tooltip" aria-label="Specify your apps framework" data-bs-original-title="Specify your apps framework" data-kt-initialized="1">
		// 												<i className="ki-duotone ki-information-5 text-gray-500 fs-6">
		// 													<span className="path1"></span>
		// 													<span className="path2"></span>
		// 													<span className="path3"></span>
		// 												</i>
		// 											</span>
		// 										</label>
												
												
		// 										<label className="d-flex flex-stack cursor-pointer mb-5">
													
		// 											<span className="d-flex align-items-center me-2">
														
		// 												<span className="symbol symbol-50px me-6">
		// 													<span className="symbol-label bg-light-warning">
		// 														<i className="ki-duotone ki-html fs-2x text-warning">
		// 															<span className="path1"></span>
		// 															<span className="path2"></span>
		// 														</i>
		// 													</span>
		// 												</span>
														
														
		// 												<span className="d-flex flex-column">
		// 													<span className="fw-bold fs-6">HTML5</span>
		// 													<span className="fs-7 text-muted">Base Web Projec</span>
		// 												</span>
														
		// 											</span>
													
													
		// 											<span className="form-check form-check-custom form-check-solid">
		// 												<input className="form-check-input" type="radio" checked="checked" name="framework" value="1">
		// 											</span>
													
		// 										</label>
												
												
		// 										<label className="d-flex flex-stack cursor-pointer mb-5">
													
		// 											<span className="d-flex align-items-center me-2">
														
		// 												<span className="symbol symbol-50px me-6">
		// 													<span className="symbol-label bg-light-success">
		// 														<i className="ki-duotone ki-react fs-2x text-success">
		// 															<span className="path1"></span>
		// 															<span className="path2"></span>
		// 														</i>
		// 													</span>
		// 												</span>
														
														
		// 												<span className="d-flex flex-column">
		// 													<span className="fw-bold fs-6">ReactJS</span>
		// 													<span className="fs-7 text-muted">Robust and flexible app framework</span>
		// 												</span>
														
		// 											</span>
													
													
		// 											<span className="form-check form-check-custom form-check-solid">
		// 												<input className="form-check-input" type="radio" name="framework" value="2">
		// 											</span>
													
		// 										</label>
												
												
		// 										<label className="d-flex flex-stack cursor-pointer mb-5">
													
		// 											<span className="d-flex align-items-center me-2">
														
		// 												<span className="symbol symbol-50px me-6">
		// 													<span className="symbol-label bg-light-danger">
		// 														<i className="ki-duotone ki-angular fs-2x text-danger">
		// 															<span className="path1"></span>
		// 															<span className="path2"></span>
		// 															<span className="path3"></span>
		// 														</i>
		// 													</span>
		// 												</span>
														
														
		// 												<span className="d-flex flex-column">
		// 													<span className="fw-bold fs-6">Angular</span>
		// 													<span className="fs-7 text-muted">Powerful data mangement</span>
		// 												</span>
														
		// 											</span>
													
													
		// 											<span className="form-check form-check-custom form-check-solid">
		// 												<input className="form-check-input" type="radio" name="framework" value="3">
		// 											</span>
													
		// 										</label>
												
												
		// 										<label className="d-flex flex-stack cursor-pointer">
													
		// 											<span className="d-flex align-items-center me-2">
														
		// 												<span className="symbol symbol-50px me-6">
		// 													<span className="symbol-label bg-light-primary">
		// 														<i className="ki-duotone ki-vue fs-2x text-primary">
		// 															<span className="path1"></span>
		// 															<span className="path2"></span>
		// 														</i>
		// 													</span>
		// 												</span>
														
														
		// 												<span className="d-flex flex-column">
		// 													<span className="fw-bold fs-6">Vue</span>
		// 													<span className="fs-7 text-muted">Lightweight and responsive framework</span>
		// 												</span>
														
		// 											</span>
													
													
		// 											<span className="form-check form-check-custom form-check-solid">
		// 												<input className="form-check-input" type="radio" name="framework" value="4">
		// 											</span>
													
		// 										</label>
												
		// 									<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
											
		// 								</div>
		// 							</div>
									
									
		// 							<div data-kt-stepper-element="content">
		// 								<div className="w-100">
											
		// 									<div className="fv-row mb-10 fv-plugins-icon-container">
												
		// 										<label className="required fs-5 fw-semibold mb-2">Database Name</label>
												
												
		// 										<input type="text" className="form-control form-control-lg form-control-solid" name="dbname" placeholder="" value="master_db">
												
		// 									<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
											
											
		// 									<div className="fv-row fv-plugins-icon-container">
												
		// 										<label className="d-flex align-items-center fs-5 fw-semibold mb-4">
		// 											<span className="required">Select Database Engine</span>
		// 											<span className="ms-1" data-bs-toggle="tooltip" aria-label="Select your app database engine" data-bs-original-title="Select your app database engine" data-kt-initialized="1">
		// 												<i className="ki-duotone ki-information-5 text-gray-500 fs-6">
		// 													<span className="path1"></span>
		// 													<span className="path2"></span>
		// 													<span className="path3"></span>
		// 												</i>
		// 											</span>
		// 										</label>
												
												
		// 										<label className="d-flex flex-stack cursor-pointer mb-5">
													
		// 											<span className="d-flex align-items-center me-2">
														
		// 												<span className="symbol symbol-50px me-6">
		// 													<span className="symbol-label bg-light-success">
		// 														<i className="ki-duotone ki-note text-success fs-2x">
		// 															<span className="path1"></span>
		// 															<span className="path2"></span>
		// 														</i>
		// 													</span>
		// 												</span>
														
														
		// 												<span className="d-flex flex-column">
		// 													<span className="fw-bold fs-6">MySQL</span>
		// 													<span className="fs-7 text-muted">Basic MySQL database</span>
		// 												</span>
														
		// 											</span>
													
													
		// 											<span className="form-check form-check-custom form-check-solid">
		// 												<input className="form-check-input" type="radio" name="dbengine" checked="checked" value="1">
		// 											</span>
													
		// 										</label>
												
												
		// 										<label className="d-flex flex-stack cursor-pointer mb-5">
													
		// 											<span className="d-flex align-items-center me-2">
														
		// 												<span className="symbol symbol-50px me-6">
		// 													<span className="symbol-label bg-light-danger">
		// 														<i className="ki-duotone ki-google text-danger fs-2x">
		// 															<span className="path1"></span>
		// 															<span className="path2"></span>
		// 														</i>
		// 													</span>
		// 												</span>
														
														
		// 												<span className="d-flex flex-column">
		// 													<span className="fw-bold fs-6">Firebase</span>
		// 													<span className="fs-7 text-muted">Google based app data management</span>
		// 												</span>
														
		// 											</span>
													
													
		// 											<span className="form-check form-check-custom form-check-solid">
		// 												<input className="form-check-input" type="radio" name="dbengine" value="2">
		// 											</span>
													
		// 										</label>
												
												
		// 										<label className="d-flex flex-stack cursor-pointer">
													
		// 											<span className="d-flex align-items-center me-2">
														
		// 												<span className="symbol symbol-50px me-6">
		// 													<span className="symbol-label bg-light-warning">
		// 														<i className="ki-duotone ki-microsoft text-warning fs-2x">
		// 															<span className="path1"></span>
		// 															<span className="path2"></span>
		// 															<span className="path3"></span>
		// 															<span className="path4"></span>
		// 														</i>
		// 													</span>
		// 												</span>
														
														
		// 												<span className="d-flex flex-column">
		// 													<span className="fw-bold fs-6">DynamoDB</span>
		// 													<span className="fs-7 text-muted">Microsoft Fast NoSQL Database</span>
		// 												</span>
														
		// 											</span>
													
													
		// 											<span className="form-check form-check-custom form-check-solid">
		// 												<input className="form-check-input" type="radio" name="dbengine" value="3">
		// 											</span>
													
		// 										</label>
												
		// 									<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
											
		// 								</div>
		// 							</div>
									
									
		// 							<div data-kt-stepper-element="content">
		// 								<div className="w-100">
											
		// 									<div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
												
		// 										<label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
		// 											<span className="required">Name On Card</span>
		// 											<span className="ms-1" data-bs-toggle="tooltip" aria-label="Specify a card holder's name" data-bs-original-title="Specify a card holder's name" data-kt-initialized="1">
		// 												<i className="ki-duotone ki-information-5 text-gray-500 fs-6">
		// 													<span className="path1"></span>
		// 													<span className="path2"></span>
		// 													<span className="path3"></span>
		// 												</i>
		// 											</span>
		// 										</label>
												
		// 										<input type="text" className="form-control form-control-solid" placeholder="" name="card_name" value="Max Doe">
		// 									<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
											
											
		// 									<div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
												
		// 										<label className="required fs-6 fw-semibold form-label mb-2">Card Number</label>
												
												
		// 										<div className="position-relative">
													
		// 											<input type="text" className="form-control form-control-solid" placeholder="Enter card number" name="card_number" value="4111 1111 1111 1111">
													
													
		// 											<div className="position-absolute translate-middle-y top-50 end-0 me-5">
		// 												<img src="assets/media/svg/card-logos/visa.svg" alt="" className="h-25px">
		// 												<img src="assets/media/svg/card-logos/mastercard.svg" alt="" className="h-25px">
		// 												<img src="assets/media/svg/card-logos/american-express.svg" alt="" className="h-25px">
		// 											</div>
													
		// 										</div>
												
		// 									<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
											
											
		// 									<div className="row mb-10">
												
		// 										<div className="col-md-8 fv-row">
													
		// 											<label className="required fs-6 fw-semibold form-label mb-2">Expiration Date</label>
													
													
		// 											<div className="row fv-row fv-plugins-icon-container">
														
		// 												<div className="col-6">
		// 													<select name="card_expiry_month" className="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Month" data-select2-id="select2-data-9-9w41" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
		// 														<option data-select2-id="select2-data-11-abwr"></option>
		// 														<option value="1">1</option>
		// 														<option value="2">2</option>
		// 														<option value="3">3</option>
		// 														<option value="4">4</option>
		// 														<option value="5">5</option>
		// 														<option value="6">6</option>
		// 														<option value="7">7</option>
		// 														<option value="8">8</option>
		// 														<option value="9">9</option>
		// 														<option value="10">10</option>
		// 														<option value="11">11</option>
		// 														<option value="12">12</option>
		// 													</select><span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-10-nsvp" style="width: 100%;"><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-card_expiry_month-hg-container" aria-controls="select2-card_expiry_month-hg-container"><span className="select2-selection__rendered" id="select2-card_expiry_month-hg-container" role="textbox" aria-readonly="true" title="Month"><span className="select2-selection__placeholder">Month</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
		// 												<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
														
														
		// 												<div className="col-6">
		// 													<select name="card_expiry_year" className="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Year" data-select2-id="select2-data-12-njot" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
		// 														<option data-select2-id="select2-data-14-74t7"></option>
		// 														<option value="2023">2023</option>
		// 														<option value="2024">2024</option>
		// 														<option value="2025">2025</option>
		// 														<option value="2026">2026</option>
		// 														<option value="2027">2027</option>
		// 														<option value="2028">2028</option>
		// 														<option value="2029">2029</option>
		// 														<option value="2030">2030</option>
		// 														<option value="2031">2031</option>
		// 														<option value="2032">2032</option>
		// 														<option value="2033">2033</option>
		// 													</select><span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-13-41bl" style="width: 100%;"><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-card_expiry_year-sf-container" aria-controls="select2-card_expiry_year-sf-container"><span className="select2-selection__rendered" id="select2-card_expiry_year-sf-container" role="textbox" aria-readonly="true" title="Year"><span className="select2-selection__placeholder">Year</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
		// 												<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
														
		// 											</div>
													
		// 										</div>
												
												
		// 										<div className="col-md-4 fv-row fv-plugins-icon-container">
													
		// 											<label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
		// 												<span className="required">CVV</span>
		// 												<span className="ms-1" data-bs-toggle="tooltip" aria-label="Enter a card CVV code" data-bs-original-title="Enter a card CVV code" data-kt-initialized="1">
		// 													<i className="ki-duotone ki-information-5 text-gray-500 fs-6">
		// 														<span className="path1"></span>
		// 														<span className="path2"></span>
		// 														<span className="path3"></span>
		// 													</i>
		// 												</span>
		// 											</label>
													
													
		// 											<div className="position-relative">
														
		// 												<input type="text" className="form-control form-control-solid" minlength="3" maxlength="4" placeholder="CVV" name="card_cvv">
														
														
		// 												<div className="position-absolute translate-middle-y top-50 end-0 me-3">
		// 													<i className="ki-duotone ki-credit-cart fs-2hx">
		// 														<span className="path1"></span>
		// 														<span className="path2"></span>
		// 													</i>
		// 												</div>
														
		// 											</div>
													
		// 										<div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
												
		// 									</div>
											
											
		// 									<div className="d-flex flex-stack">
												
		// 										<div className="me-5">
		// 											<label className="fs-6 fw-semibold form-label">Save Card for further billing?</label>
		// 											<div className="fs-7 fw-semibold text-muted">If you need more info, please check budget planning</div>
		// 										</div>
												
												
		// 										<label className="form-check form-switch form-check-custom form-check-solid">
		// 											<input className="form-check-input" type="checkbox" value="1" checked="checked">
		// 											<span className="form-check-label fw-semibold text-muted">Save Card</span>
		// 										</label>
												
		// 									</div>
											
		// 								</div>
		// 							</div>
									
									
		// 							<div data-kt-stepper-element="content">
		// 								<div className="w-100 text-center">
											
		// 									<h1 className="fw-bold text-dark mb-3">Release!</h1>
											
											
		// 									<div className="text-muted fw-semibold fs-3">Submit your app to kickstart your project.</div>
											
											
		// 									<div className="text-center px-4 py-15">
		// 										<img src="assets/media/illustrations/sketchy-1/9.png" alt="" className="mw-100 mh-300px">
		// 									</div>
											
		// 								</div>
		// 							</div>
									
									
		// 							<div className="d-flex flex-stack pt-10">
										
		// 								<div className="me-2">
		// 									<button type="button" className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
		// 									<i className="ki-duotone ki-arrow-left fs-3 me-1">
		// 										<span className="path1"></span>
		// 										<span className="path2"></span>
		// 									</i>Back</button>
		// 								</div>
										
										
		// 								<div>
		// 									<button type="button" className="btn btn-lg btn-primary" data-kt-stepper-action="submit">
		// 										<span className="indicator-label">Submit
		// 										<i className="ki-duotone ki-arrow-right fs-3 ms-2 me-0">
		// 											<span className="path1"></span>
		// 											<span className="path2"></span>
		// 										</i></span>
		// 										<span className="indicator-progress">Please wait...
		// 										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
		// 									</button>
		// 									<button type="button" className="btn btn-lg btn-primary" data-kt-stepper-action="next">Continue
		// 									<i className="ki-duotone ki-arrow-right fs-3 ms-1 me-0">
		// 										<span className="path1"></span>
		// 										<span className="path2"></span>
		// 									</i></button>
		// 								</div>
										
		// 							</div>
									
		// 						</form>
								
		// 					</div>
							
		// 				</div>
						
		// 			</div>
					
		// 		</div>
				
		// 	</div>
			
		// </div>


  );
};

export default LogoutConfirmationModal;
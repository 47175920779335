import BaseScreen from "layouts/base";
import React, { Fragment } from "react";
import "./starter_loading.scss";
import { WithTranslation, withTranslation, WithTranslationProps } from "react-i18next";
// import logo from "assets/images/logo.png";
import logo from "assets/images/logo_text.png";

import { BlocsContext } from "contexts/blocs_context";
import { Subscription } from "rxjs";


import { AuthenticationAppLoaded } from "blocs/authentication_bloc/authentication_event";
import { LoadServices } from "blocs/services_bloc/services_event";
import { ServicesLoaded, ServicesState } from "blocs/services_bloc/services_state";
import { LoadCompanyCategories } from "blocs/company_categories_bloc/company_categories_event";
import { LoadCuriosities } from "blocs/curiosities_bloc/curiosities_event";
import { LoadCuriosityCategories } from "blocs/curiosity_categories_bloc/curiosity_categories_event";
import { LoadEventCategories } from "blocs/event_categories_bloc/event_categories_event";
import { LoadEvents } from "blocs/events_bloc/events_event";
import { LoadItineraries } from "blocs/itineraries_bloc/itineraries_event";
import { LoadItineraryCategories } from "blocs/itinerary_categories_bloc/itinerary_categories_event";
import { LoadLocalProductCategories } from "blocs/local_product_categories_bloc/local_product_categories_event";
import { LoadLocalProducts } from "blocs/local_products_bloc/local_products_event";
import { LoadMerchantCategories } from "blocs/merchant_categories_bloc/merchant_categories_event";
import { LoadMerchants } from "blocs/merchants_bloc/merchants_event";
import { LoadMunicipalities } from "blocs/municipalities_bloc/municipalities_event";
import { LoadNews } from "blocs/news_bloc/news_event";
import { LoadNewsCategories } from "blocs/news_categories_bloc/news_categories_event";
import { LoadNotifications } from "blocs/notifications_bloc/notifications_event";
import { LoadServiceCategories } from "blocs/service_categories_bloc/service_categories_event";
import { LoadCompanies } from "blocs/companies_bloc/companies_event";
import { AuthenticationAuthenticated, AuthenticationState, AuthenticationUnAuthenticated } from "blocs/authentication_bloc/authentication_state";


// authenticationBloc: authenticationBloc,
// servicesBloc: servicesBloc,
// companyCategoriesBloc: companyCategoriesBloc,
// curiositiesBloc: curiositiesBloc,
// curiosityCategoriesBloc: curiosityCategoriesBloc,
// eventCategoriesBloc: eventCategoriesBloc,
// eventsBloc: eventsBloc,
// itinerariesBloc: itinerariesBloc,
// itineraryCategoriesBloc: itineraryCategoriesBloc,
// localProductCategoriesBloc: localProductCategoriesBloc,
// localProductsBloc: localProductsBloc,
// merchantCategoriesBloc: merchantCategoriesBloc,
// merchantsBloc: merchantsBloc,
// municipalitiesBloc: municipalitiesBloc,
// newsBloc: newsBloc,
// newsCategoriesBloc: newsCategoriesBloc,
// notificationsBloc: notificationsBloc,
// serviceCategoriesBloc: serviceCategoriesBloc,


interface Props extends WithTranslation { }

type State = {
  isLoading: boolean;
  dataReady: boolean;
};

class StarterLoadingScreen extends React.Component<Props, State> {
  // static contextType = BlocsContext;
  // context!: React.ContextType<typeof BlocsContext>

  _authenticationState: AuthenticationState = undefined as any;


  // // _coatingsBloc: CoatingsBloc;
  // _servicesState: ServicesState = undefined as any;
  // // _machinesBloc: MachinesBloc;
  // _machinesState: MachinesState = undefined as any;
  // // _materialsBloc: MaterialsBloc;
  // _materialsState: MaterialsState = undefined as any;
  // // _referencesBloc: ReferencesBloc;
  // _referencesState: ReferencesState = undefined as any;
  // // _articlesBloc: ArticlesBloc;
  // _articlesState: ArticlesState = undefined as any;

  // // _assRefMatBloc: AssRefMatBloc;
  // _assRefMatState: AssRefMatsState = undefined as any;
  // // _assRefUsiBloc: AssRefUsiBloc;
  // _assRefUsiState: AssRefUsisState = undefined as any;
  // // _assRevMatBloc: AssRevMatBloc;
  // _assRevMatState: AssRevMatsState = undefined as any;
  // // _machiningBloc: MachiningBloc;
  // _machiningState: MachiningsState = undefined as any;
  // // _machiningGroupBloc: MachiningGroupBloc;
  // _machiningGroupState: MachiningGroupsState = undefined as any;

  static contextType = BlocsContext;
  context!: React.ContextType<typeof BlocsContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      dataReady: false,
    };
  }
  componentDidMount() {
    // this.context.servicesBloc.listen((state: ServicesState) => {
    // 	this._servicesState = state;
    // 	this.checkLoadingFinished();
    // });

    this.context.authenticationBloc.listen((state: AuthenticationState) => {
    	this._authenticationState = state;
    	this.checkLoadingFinished();
    });


    this.startDataLoading();
  }

  async startDataLoading() {
    // var dataService: DataService = new DataService();
    // await dataService.init();
    this.setState({
      dataReady: true,
    }, () => {
      this.checkLoadingFinished();
    });
    this.context.servicesBloc.add(new LoadServices());
    this.context.companiesBloc.add(new LoadCompanies());
    this.context.companyCategoriesBloc.add(new LoadCompanyCategories());
    this.context.curiositiesBloc.add(new LoadCuriosities());
    this.context.curiosityCategoriesBloc.add(new LoadCuriosityCategories());
    this.context.eventCategoriesBloc.add(new LoadEventCategories());
    this.context.eventsBloc.add(new LoadEvents());
    this.context.itinerariesBloc.add(new LoadItineraries());
    this.context.itineraryCategoriesBloc.add(new LoadItineraryCategories());
    this.context.localProductCategoriesBloc.add(new LoadLocalProductCategories());
    this.context.localProductsBloc.add(new LoadLocalProducts());
    this.context.merchantCategoriesBloc.add(new LoadMerchantCategories());
    this.context.merchantsBloc.add(new LoadMerchants());
    this.context.municipalitiesBloc.add(new LoadMunicipalities());
    this.context.newsBloc.add(new LoadNews());
    this.context.newsCategoriesBloc.add(new LoadNewsCategories());
    this.context.notificationsBloc.add(new LoadNotifications());
    this.context.serviceCategoriesBloc.add(new LoadServiceCategories());



    this.context.authenticationBloc.add(new AuthenticationAppLoaded());
  }

  checkLoadingFinished() {
    if (
      // this._servicesState instanceof ServicesLoaded &&
      (this._authenticationState instanceof AuthenticationAuthenticated || this._authenticationState instanceof AuthenticationUnAuthenticated) &&
      this.state.dataReady == true
    ) {

      // const queryParameters = new URLSearchParams(window.location.search)
      // const goodid = queryParameters.get("goodid")

      this.setState({
        isLoading: false,
      });
      // Navigator.pushReplacementNamed(context, ROUTES.DASHBOARD);
    }
  }

  renderContent() {
    return (
      <div style={{ height: "100vh", width: "100vw" }}>
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100%" }}>
          <img src={logo} className="App-logo-loading" alt="logo" />
          <div className="d-flex flex-column justify-content-center align-items-center mt-10">

            <span>{this.props.t("screen.starter_loading.loading")}</span>
            <div className="spinner-border mt-3">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.state.isLoading == true && this.renderContent()}
        {this.state.isLoading == false && this.props.children}
      </Fragment>
    );
  }
}

export default withTranslation("common")(StarterLoadingScreen);

import { LoadNews } from "blocs/news_bloc/news_event";
import { NewsFailure, NewsLoaded, NewsLoading, NewsState } from "blocs/news_bloc/news_state";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import News from "models/news";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import imgIconDesc from "assets/images/bubble.png";
import GeneralHelper from "helpers/general_helper";
import { Link } from "react-router-dom";
import defaultPic from "assets/images/default_pic.png";
import ImagesGallery from "components/image_gallery";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const NewsSingleScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [newsState, setNewsState] = useState<NewsState>(new NewsLoading());

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setNewsState(blocsContext.newsBloc.state);
    const subscription = blocsContext.newsBloc.listen(setNewsState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const news: News | undefined = undefined;

  if (newsState instanceof NewsLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (newsState instanceof NewsFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div className="btn btn-primary mt-6" onClick={() => blocsContext.newsBloc.add(new LoadNews())}>
              Recharger
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (newsState instanceof NewsLoaded) {
    const news: News = newsState.news.find((news) => news.id.toString() == params.id) as News;
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <a className="cursor-pointer backLinkSingle" onClick={() => navigate(-1)}>
                  ← Retour
                </a>
                <div className="singleItem">
                  <ImagesGallery images={news.responsiveImages} />
                  <div className="singleItemInnerContent">
                    <div className="singleItemTags">
                      <div className="tagsWrapper">
                        <div className="singleItemCategory">{news.category.name}</div>
                        <div className="singleItemMunicipality">{news.municipality.name}</div>
                      </div>
                      {/* <div className="singleItemShareLink">
                      <a href="#">⭷ Partager</a>
                    </div> */}
                    </div>
                    <h3 className="fs-1 fw-600 singleItemTitle">{news.name}</h3>
                    <div className="singleItemAddress">Publiée le {news.createdAt.toLocaleDateString("fr-FR")}</div>

                    <div className="singleItemDescription">
                      <img src={imgIconDesc} />
                      <div className="descTitle">Contenu de l'actu</div>
                      {/* {GeneralHelper.cleanHtmlString(news.description || 'Aucune description disponible.')} */}

                      <div
                        dangerouslySetInnerHTML={{
                          __html: news.description || "Aucune description disponible.",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(news)
};

export default withTranslation("common")(NewsSingleScreen);

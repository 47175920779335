import React, { createContext, useContext, useState, useEffect } from "react";

interface GeneralModalContextType {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  content: JSX.Element;
  setContent: (arg1: JSX.Element) => void;
  onClose?: Function;
  setOnClose: (arg1: Function) => void;
}

const GeneralModalContext = createContext<GeneralModalContextType | undefined>(
  undefined
);

export const useGeneralModal = () => {
  const context = useContext(GeneralModalContext);
  if (!context) {
    throw new Error(
      "useGeneralModal must be used within a GeneralModalProvider"
    );
  }
  return context;
};

export const GeneralModalProvider: React.FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onClose, setOnClose] = useState<Function>(() => {});
  const [content, setContent] = useState<JSX.Element>(<></>);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("body-scroll-lock");
    } else {
      document.body.classList.remove("body-scroll-lock");
    }
  }, [isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setContent(<></>);
  };

  return (
    <GeneralModalContext.Provider
      value={{
        isModalOpen,
        openModal,
        closeModal,
        content,
        setContent,
        onClose: onClose,
        setOnClose: setOnClose,
      }}
    >
      {children}
    </GeneralModalContext.Provider>
  );
};

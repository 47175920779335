import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
// import styles from './home.scss';

import backgroundImage from "assets/images/home_section_1_background.jpg";
import { Link } from "react-router-dom";

interface Props extends WithTranslation {}

const HomeSection1: React.FC<Props> = ({ t }) => {
  return (
    <div
      className="homeFirstImage toolbar py-5 py-lg-5 flex  align-items-end topHeaderImg"
      id="kt_toolbar"
      style={{
        backgroundImage: 'url("' + backgroundImage + '")',
        backgroundSize: "cover",
        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.3)",
        height: "70vh",
      }}
    >
      <div id="kt_toolbar_container" className="container-xxl py-5">
        <div className="row gy-0 gx-10">
          <div className="col-xl-8 mb-20">
            <h1 className="text-white fs-3hx line-height-lg mb-5 fw-800">
              Bienvenue en région Haute-Ajoie.
            </h1>
            <div className="fs-1 text-white mb-7">
              Ensemble, vers une région connectée ! Région Haute-Ajoie est une
              plateforme touristique, d'échange et d'entraide à destination des
              habitants de la région ainsi que des touristes.
            </div>

            <Link
              to={"/municipalities"}
              className="btn btn-sm btn-primary fw-600 w-100 w-lg-auto px-8 py-4 fs-4"
            >
              Découvrir les communes
            </Link>
          </div>
          {/* <div className="col-xl-8">
            <div className="card card-xl-stretch bg-body border-0 mb-5 mb-xl-0">
              <div className="card-body d-flex flex-column flex-lg-row flex-stack p-lg-15">
                <div className="d-flex flex-column justify-content-center align-items-center align-items-lg-start me-10 text-center text-lg-start">
                  <h3 className="fs-2hx line-height-lg mb-5">
                    <span className="fw-bold">Brilliant App Ideas</span>
                    <br />
                    <span className="fw-bold">for Startups</span>
                  </h3>

                  <div className="fs-4 text-muted mb-7">
                    Long before you sit down to put the pen
                    <br />
                    need to make sure you breathe
                  </div>
                  <a
                    href="#"
                    className="btn btn-success fw-semibold px-6 py-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_campaign"
                  >
                    Create an Store
                  </a>
                </div>

                <img
                  src="assets/media/illustrations/sketchy-1/11.png"
                  alt=""
                  className="mw-200px mw-lg-350px mt-lg-n10"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card card-xl-stretch bg-body border-0">
              <div className="card-body pt-5 mb-xl-9 position-relative">
                <div className="d-flex flex-stack">
                  <h4 className="fw-bold text-gray-800 m-0">User Base</h4>

                  <div className="me-1">
                    <button
                      className="btn btn-icon btn-color-gray-500 w-auto px-0 btn-active-color-primary"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-overflow="true"
                    >
                      <i className="ki-duotone ki-dots-square fs-1 me-n1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                    </button>

                    <div
                      className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                      data-kt-menu="true"
                      id="kt_menu_64b776d7d9627"
                    >
                      <div className="px-7 py-5">
                        <div className="fs-5 text-dark fw-bold">
                          Filter Options
                        </div>
                      </div>

                      <div className="separator border-gray-200"></div>

                      <div className="px-7 py-5">
                        <div className="mb-10">
                          <label className="form-label fw-semibold">
                            Status:
                          </label>

                          <div>
                            <select
                              className="form-select form-select-solid"
                              multiple={true}
                              data-kt-select2="true"
                              data-close-on-select="false"
                              data-placeholder="Select option"
                              data-dropdown-parent="#kt_menu_64b776d7d9627"
                              data-allow-clear="true"
                            >
                              <option></option>
                              <option value="1">Approved</option>
                              <option value="2">Pending</option>
                              <option value="2">In Process</option>
                              <option value="2">Rejected</option>
                            </select>
                          </div>
                        </div>

                        <div className="mb-10">
                          <label className="form-label fw-semibold">
                            Member Type:
                          </label>

                          <div className="d-flex">
                            <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="1"
                              />
                              <span className="form-check-label">Author</span>
                            </label>

                            <label className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="2"
                                checked={true}
                              />
                              <span className="form-check-label">Customer</span>
                            </label>
                          </div>
                        </div>

                        <div className="mb-10">
                          <label className="form-label fw-semibold">
                            Notifications:
                          </label>

                          <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              name="notifications"
                              checked={true}
                            />
                            <label className="form-check-label">Enabled</label>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            type="reset"
                            className="btn btn-sm btn-light btn-active-light-primary me-2"
                            data-kt-menu-dismiss="true"
                          >
                            Reset
                          </button>
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            data-kt-menu-dismiss="true"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-center mb-5 mb-xxl-0">
                  <div
                    id="kt_charts_mixed_widget_16_chart"
                    style={{ height: "260px" }}
                  ></div>
                </div>

                <div className="text-center position-absolute bottom-0 start-50 translate-middle-x w-100 mb-10">
                  <p className="fw-semibold fs-4 text-gray-400 mb-7 px-5">
                    Long before you sit down to put the
                    <br />
                    make sure you breathe
                  </p>

                  <div className="m-0">
                    <a
                      href="#"
                      className="btn btn-success fw-semibold"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_invite_friends"
                    >
                      Invite Users
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(HomeSection1);

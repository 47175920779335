import React from "react";

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onMapInitialized?: (map: google.maps.Map) => void;
  defaultMapTypeId?: string;
}

const Map: React.FC<MapProps> = ({ onClick, onIdle, onMapInitialized, children, style, defaultMapTypeId, ...options }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      const newMap =
        new window.google.maps.Map(ref.current, {
          ...options,
          fullscreenControl: true,
          streetViewControl: false,
          mapTypeId: defaultMapTypeId,
        });

      setMap(newMap);
      if (onMapInitialized != undefined) onMapInitialized(newMap);

    }

    map?.addListener("click", (e) => {
      if (onClick != undefined) onClick(e);
    });


  }, [ref, map]);

  return (
    <>
      <div className="map" ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;

import { Bloc, Transition } from "@felangel/bloc";
import Itinerary from "models/itinerary";
import ItineraryRepository from "repositories/itinerary_repository";
import {
  ItinerariesEvent,
  LoadItineraries,
  AddItinerary,
  EditItinerary,
  DeleteItinerary,
} from "./itineraries_event";
import {
  ItinerariesState,
  ItinerariesLoaded,
  ItinerariesLoading,
  ItinerariesFailure,
} from "./itineraries_state";

export default class ItinerariesBloc extends Bloc<ItinerariesEvent, ItinerariesState> {
  itineraryRepository: ItineraryRepository;
  itineraries: Array<Itinerary> = [];

  constructor(itineraryRepository: ItineraryRepository) {
    super(new ItinerariesLoading());
    this.itineraryRepository = itineraryRepository;
  }

  async *mapEventToState(event: ItinerariesEvent) {
    if (event instanceof LoadItineraries) {
      yield* this.onLoadItineraries(event);
    } else if (event instanceof AddItinerary) {
      yield* this.onAddItinerary(event);
    } else if (event instanceof EditItinerary) {
      yield* this.onEditItinerary(event);
    } else if (event instanceof DeleteItinerary) {
      yield* this.onDeleteItinerary(event);
    }
  }

  async *onLoadItineraries(event: LoadItineraries) {
    try {
      yield new ItinerariesLoading();
      var itinerariesList: Array<Itinerary> = await this.itineraryRepository.getData();
      this.itineraries = itinerariesList;

      yield new ItinerariesLoaded(this.itineraries);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ItinerariesFailure(e);
      } else {
        yield new ItinerariesFailure(e.toString());
      }
    }
  }

  async *onAddItinerary(event: AddItinerary) {
    try {
      yield new ItinerariesLoading();
      this.itineraries.push(event.itinerary);

      yield new ItinerariesLoaded(this.itineraries);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ItinerariesFailure(e);
      } else {
        yield new ItinerariesFailure(e.toString());
      }
    }
  }

  async *onEditItinerary(event: EditItinerary) {
    try {
      yield new ItinerariesLoading();
      this.itineraries = this.itineraries.map((itinerary: Itinerary) => {
        if (itinerary.id == event.itinerary.id) {
          itinerary = event.itinerary;
        }
        return itinerary;
      });

      yield new ItinerariesLoaded(this.itineraries);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ItinerariesFailure(e);
      } else {
        yield new ItinerariesFailure(e.toString());
      }
    }
  }

  async *onDeleteItinerary(event: DeleteItinerary) {
    try {
      yield new ItinerariesLoading();
      this.itineraries = this.itineraries.filter((itinerary: Itinerary) => {
        return itinerary.id != event.itineraryId;
      });

      yield new ItinerariesLoaded(this.itineraries);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ItinerariesFailure(e);
      } else {
        yield new ItinerariesFailure(e.toString());
      }
    }
  }

  onEvent(event: ItinerariesEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

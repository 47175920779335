import ServiceComment from "models/service_comment";
import Config from "config/config";
import axios from "axios";
import AuthenticationData from "models/authentication_data";
import AuthenticationService from "services/authentication";

export default class ServiceCommentRepository {
  // static _serviceEndpoint: string = "serviceComments";
  static _serviceCommentsDataKey: string = "service_comments_data";

  async getData(serviceId: number): Promise<Array<ServiceComment>> {
    var data: any = await axios.get(Config.REST_API_URL + "/services/" + serviceId + "/comments", {
      headers: {
        Accept: "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Headers": "X-Requested-With",
      },
    });
    return this.dataToServiceComments(data.data.comments);
  }

  // create serviceComment
  async createServiceComment(comment: string, serviceId: number, parentId?: number): Promise<ServiceComment> {
    var formData = new FormData();
    formData.append("comment", comment);
    formData.append("service_id", serviceId.toString());
    if (parentId != undefined)
      formData.append("parent_id", parentId.toString());


    var token: string = AuthenticationService.storageGetToken();

    var data: any = await axios.post(Config.REST_API_URL + "/services/" + serviceId + "/comments", formData, {
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });

    return ServiceComment.fromJson(data.data.comment);
  }

  // Delete serviceComment
  async deleteServiceComment(id: number): Promise<boolean> {
    var token: string = AuthenticationService.storageGetToken();

    var data: any = await axios.delete(Config.REST_API_URL + "/services/comments/" + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return true;
  }

  dataToServiceComments(data: any): Array<ServiceComment> {
    var serviceComments: Array<ServiceComment> = [];
    for (var i = 0; i < data.length; i++) {
      serviceComments.push(ServiceComment.fromJson(data[i]));
    }
    return serviceComments;
  }
}

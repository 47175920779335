import BaseScreen from "layouts/base";
import React, {useState, useContext} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {BlocsContext} from "contexts/blocs_context";
import {BlocBuilder} from "@felangel/react-bloc";

import {
    MunicipalitiesLoaded,
    MunicipalitiesLoading,
    MunicipalitiesState
} from "blocs/municipalities_bloc/municipalities_state";
import image1 from "assets/images/fontaine.jpg";
import Municipality from "models/municipality";
import {Link, useNavigate} from "react-router-dom";
import MunicipalityCard from "components/data_components/municipality_card";
import {CuriositiesLoaded, CuriositiesState} from "blocs/curiosities_bloc/curiosities_state";
import Curiosity from "models/curiosity";
import CuriosityCard from "components/data_components/curiosity_card";
import {Wrapper, Status} from "@googlemaps/react-wrapper";
import HomeSectionMap from "screens/home/components/home_section_map";

// import {} from '@types/googlemaps';

interface Props extends WithTranslation {
}

const TourismeLoisirScreen: React.FC<Props> = ({t}) => {
    const blocsContext = useContext(BlocsContext);
    const history = useNavigate();

    const mapRef = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
    React.useEffect(() => {
        if (mapRef.current && !map) {
            setMap(
                new window.google.maps.Map(mapRef.current, {
                    center: {lat: 47.4, lng: 7.1},
                    zoom: 11,
                    mapTypeId: "roadmap",
                    fullscreenControl: false,
                    streetViewControl: false,
                })
            );
        }
    }, [mapRef, map]);

    return (
        <>
            <BaseScreen>
                <div className="d-flex flex-column-fluid align-items-start container-xxl">
                    <div className="content flex-row-fluid">
                        <div className="row gy-0 gx-5 row d-flex justify-content-start mb-4 mb-xl-6 mt-5 mt-xl-20">
                            <div className="col-12">
                                <h3 className="fs-1 fw-800">Tourisme et loisirs</h3>
                                <span
                                    className="subTitle">Découvrez les curiosités et les événements de la région</span>
                            </div>
                        </div>

                        <div className="row row-cols-2 gx-3 row-cols-md-4">
                            <Link to="/curiosities">
                                <div className="singleItemCategoryTourism">
                                    <div className="innerContent catCuriosity">
                                        <div className="tourismCatTitle">Curiosités</div>
                                        <div className="tourismCatLink">Découvrir →</div>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/merchants">
                                <div className="singleItemCategoryTourism">
                                    <div className="innerContent catCommerces">
                                        <div className="tourismCatTitle">Commerces</div>
                                        <div className="tourismCatLink">Découvrir →</div>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/itineraries" className="mt-3 mt-md-0">
                                <div className="singleItemCategoryTourism">
                                    <div className="innerContent catPath">
                                        <div className="tourismCatTitle">Chemins / Parcours</div>
                                        <div className="tourismCatLink">Découvrir →</div>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/events" className="mt-3 mt-md-0">
                                <div className="singleItemCategoryTourism">
                                    <div className="innerContent catEvents">
                                        <div className="tourismCatTitle">Événements</div>
                                        <div className="tourismCatLink">Découvrir →</div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <HomeSectionMap/>


                        <div className="row gy-0 gx-5 row d-flex justify-content-center justify-content-sm-start">
                            <div className="col-12 d-flex justify-content-between align-items-center mb-6">
                                <h3 className="fs-1 fw-700">Dernières curiosités</h3>
                                <Link to="/curiosities" className="fs-5 fw-bold">
                                    Voir tout →
                                </Link>
                            </div>

                            <div
                                className="CuriosityCardList row g-5 mt-0 row-cols-2  row-cols-sm-3 row-cols-lg-4 row-cols-xl-5" >
                                <BlocBuilder
                                    bloc={blocsContext.curiositiesBloc}
                                    builder={(state: CuriositiesState) => {
                                        if (state instanceof CuriositiesLoaded) {
                                            return (
                                                <>
                                                    {state.curiosities.slice(0, 5).map((curiosity: Curiosity) => {
                                                        return <CuriosityCard key={curiosity.id}
                                                                              curiosity={curiosity}/>;
                                                    })}
                                                </>
                                            );
                                        }
                                        return <></>;
                                    }}
                                />
                            </div>
                        </div>


                        <div className="row gy-0 gx-5 row d-flex justify-content-start">
                            <div className="col-12 mb-xl-6 mt-10 mt-xl-20">
                                <h3 className="fs-1 fw-700">Par commune</h3>
                            </div>

                            <div
                                className="MunicipalityCardList row row-cols-1 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
                                <BlocBuilder
                                    bloc={blocsContext.municipalitiesBloc}
                                    builder={(state: MunicipalitiesState) => {
                                        if (state instanceof MunicipalitiesLoaded) {
                                            return (
                                                <>
                                                    {state.municipalities.filter((municipality) => municipality.parent == undefined).slice(0, 5).map((municipality: Municipality, index) => {
                                                        return <MunicipalityCard key={municipality.id}
                                                                                 municipality={municipality}/>;
                                                    })}
                                                </>
                                            );
                                        }
                                        return <></>;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </BaseScreen>
        </>
    );
};

export default withTranslation("common")(TourismeLoisirScreen);

import React from "react";

interface PolylineProps extends google.maps.PolylineOptions {
  polylineOptions?: google.maps.PolylineOptions;
  onClick?: (e: google.maps.MapMouseEvent) => void;
}
const Polyline: React.FC<PolylineProps> = ({ polylineOptions, onClick, ...options }) => {
  const [polyline, setPolyline] = React.useState<google.maps.Polyline>();

  React.useEffect(() => {
    if (!polyline) {
      setPolyline(new google.maps.Polyline(polylineOptions));
    }

    polyline?.addListener("click", (e) => {
      if (onClick != undefined) onClick(e);
    });

    // remove polyline from map on unmount
    return () => {
      if (polyline) {
        polyline.setMap(null);
      }
    };
  }, [polyline]);

  React.useEffect(() => {
    if (polyline) {
      polyline.setOptions(options);
    }
  }, [polyline, options]);

  return null;
};

export default Polyline;

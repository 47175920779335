import Company from "models/company";
import Config from "config/config";
import axios from "axios";

export default class CompanyRepository {
  static _companiesEndpoint: string = "companies";
  static _companiesDataKey: string = "companies_data";

  async getData(): Promise<Array<Company>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + CompanyRepository._companiesEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToCompanies(data.data.companies);
  }

  dataToCompanies(data: any): Array<Company> {
    var companies: Array<Company> = [];
    for (var i = 0; i < data.length; i++) {
      companies.push(Company.fromJson(data[i]));
    }
    return companies;
  }
}

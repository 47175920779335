import { LoadMerchants } from "blocs/merchants_bloc/merchants_event";
import { MerchantsFailure, MerchantsLoaded, MerchantsLoading, MerchantsState } from "blocs/merchants_bloc/merchants_state";
import ArchiveFilter from "components/data_components/archive_filter";
import MerchantCard from "components/data_components/merchant_card";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GenericFilters from "models/filters/generic_filters";
import Merchant from "models/merchant";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { MunicipalitiesLoaded, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import { BlocBuilder } from "@felangel/react-bloc";
import useQuery from "helpers/use_query";

// import styles from './home.scss';

interface Props extends WithTranslation { }

const MerchantsScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  let query = useQuery();
  const municipalityIdParams = query.get("municipalityId");

  const [merchantsFilters, setGenericFilters] = useState<GenericFilters>(
    new GenericFilters({
      municipalityId: municipalityIdParams != undefined ? parseInt(municipalityIdParams) : undefined,
    })
  );

  const [merchantsState, setMerchantsState] = useState<MerchantsState>(new MerchantsLoading());

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setMerchantsState(blocsContext.merchantsBloc.state);
    const subscription = blocsContext.merchantsBloc.listen(setMerchantsState);
    return () => {
      subscription.unsubscribe();
    };
  });

  // const merchant: Merchant | undefined = undefined;

  if (merchantsState instanceof MerchantsLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (merchantsState instanceof MerchantsFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div
              className="btn btn-primary mt-6" onClick={() => blocsContext.merchantsBloc.add(new LoadMerchants())}            >
              Recharger
            </div>
          </div>

        </div>
      </BaseScreen>
    );
  }

  if (merchantsState instanceof MerchantsLoaded) {
    const merchants: Merchant[] = merchantsFilters.filter(merchantsState.merchants);

    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="d-flex flex-stack flex-wrap mb-6 mt-5 mt-lg-20">
              <div>
                <h3 className="fs-1 fw-800">Commerces</h3>
                <div className="BackLinkWrapper">
                  <a className="cursor-pointer backLinkSingle" onClick={() => navigate(-1)}>
                    ← Retour
                  </a>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center">
                {merchantsFilters.municipalityId != undefined && (
                  <BlocBuilder
                    bloc={blocsContext.municipalitiesBloc}
                    builder={(state: MunicipalitiesState) => {
                      if (state instanceof MunicipalitiesLoaded) {
                        return (
                          <div className="badge badge-secondary fw-bolder me-2 activeService">
                            <div
                              className="cursor-pointer me-2"
                              onClick={() => {
                                var newFilters = merchantsFilters.clone();
                                newFilters.municipalityId = undefined;
                                setGenericFilters(newFilters);
                              }}
                            >
                              <i className="fa fa-xmark"></i>
                            </div>
                            Commune : {state.municipalities.find((municipality) => municipality.id == merchantsFilters.municipalityId)?.name}
                          </div>
                        );
                      }
                      return <></>;
                    }}
                  />
                )}

                <ArchiveFilter
                  filters={merchantsFilters}
                  onSetFilters={(filters: GenericFilters) => {
                    setGenericFilters(new GenericFilters({ ...filters }));
                  }}
                  dataType="Merchant"
                />
              </div>
            </div>

            {merchants.length == 0 && (
              <div className="card-px text-center py-20 my-20">
                <h2 className="fs-2x fw-bold mb-8">Aucun élément trouvé</h2>
                <p className="text-gray-400 fs-4 fw-semibold mb-10">Essayez d'ajuster les filtres pour trouver ce que vous cherchez</p>
                {/* <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add Customer</a> */}
              </div>
            )}
            {merchants.length != 0 && (
              <div className="row g-5 row-cols-1 row-cols-sm-2 row-cols-xl-5">
                {merchants.map((merchant) => (
                  <MerchantCard key={merchant.id} merchant={merchant} />
                ))}
              </div>
            )}
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(merchant)
};

export default withTranslation("common")(MerchantsScreen);

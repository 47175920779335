import Merchant from "models/merchant";

export abstract class MerchantsEvent {}

export class LoadMerchants extends MerchantsEvent {
  public toString = (): string => `LoadMerchants`;
}

export class AddMerchant extends MerchantsEvent {
  merchant: Merchant;
  constructor(merchant: Merchant) {
    super();
    this.merchant = merchant;
  }

  public toString = (): string => `AddMerchant`;
}

export class EditMerchant extends MerchantsEvent {
  merchant: Merchant;
  constructor(merchant: Merchant) {
    super();
    this.merchant = merchant;
  }

  public toString = (): string => `EditMerchant`;
}

export class DeleteMerchant extends MerchantsEvent {
  merchantId: number;
  constructor(merchantId: number) {
    super();
    this.merchantId = merchantId;
  }

  public toString = (): string => `DeleteMerchant`;
}

import UniversalCategory from "models/universal_category";

export abstract class MerchantCategoriesState {} 

export class MerchantCategoriesLoading extends MerchantCategoriesState {} 

export class MerchantCategoriesLoaded extends MerchantCategoriesState {
  categories: Array<UniversalCategory>; // Adapter le nom du modèle en conséquence
  constructor(categories: Array<UniversalCategory>) {
    super();
    this.categories = categories;
  }

  public toString = (): string => {
    return `MerchantCategoriesLoaded ${this.categories.length} categories`; // Adapter le nom en conséquence
  };
}

export class MerchantCategoriesFailure extends MerchantCategoriesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `MerchantCategoriesLoadingFailed { errorMessage: ${this.errorMessage} }`; // Adapter le nom en conséquence
  };
}

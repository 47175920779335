import { BlocBuilder } from "@felangel/react-bloc";
import { AuthenticationLoggedIn, AuthenticationLoggedOut } from "blocs/authentication_bloc/authentication_event";
import { AuthenticationAuthenticated } from "blocs/authentication_bloc/authentication_state";
import { BlocsContext } from "contexts/blocs_context";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useConfirmationModal } from "components/modals/logout_confirmation_modal/logout_confirmation_modal_context";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { matchPath } from "react-router";

// AuthMenu functional component
const AuthMenu: React.FC = ({}) => {
  const blocsContext = useContext(BlocsContext);

  const location = useLocation();

  const menuRef = useRef();
  const [menuOpened, setMenuOpened] = useState(false);

  const [authenticationBlocState, setAuthenticationBlocState] = useState(blocsContext.authenticationBloc.state);

  useEffect(() => {
    const subscription = blocsContext.authenticationBloc.listen(setAuthenticationBlocState);

    const handleOutsideClick = (event: any) => {
      if (menuOpened && !event.target.closest(".menu-sub")) {
        setMenuOpened(false);
      }
    };

    if (menuOpened) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      subscription.unsubscribe();
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [blocsContext.authenticationBloc, authenticationBlocState, menuOpened]);

  const MySwal = withReactContent(Swal);

  const isRouteActive = (route: string): boolean => {
    return !!matchPath(location.pathname, route);
  };

  const handleLogout = () => {
    setMenuOpened(false);

    Swal.fire({
      title: "Êtes-vous sûr de vouloir vous déconnecter ?",
      // icon: 'question',
      showCancelButton: true,
      confirmButtonText: "Déconnexion",
      cancelButtonText: "Annuler",
      confirmButtonColor: "#d33",
      padding: "1rem",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        blocsContext.authenticationBloc.add(new AuthenticationLoggedOut());
        Swal.fire({
          title: "Déconnexion effectuée",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          iconColor: "#5cb85c",
        });
      }
    });
  };

  if (authenticationBlocState instanceof AuthenticationAuthenticated) {
    return (
      <>
        <div className="d-flex align-items-center ms-3 ms-lg-4" id="kt_header_user_menu_toggle" ref={(ref) => menuRef}>
          <div
            className={["btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px", menuOpened ? "show menu-dropdown" : ""].join(" ")}
            onClick={() => {
              setMenuOpened(!menuOpened);
            }}
          >
            <i className="ki-duotone ki-user fs-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>

          <div
            className={["menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px", menuOpened ? "show" : ""].join(" ")}
            data-kt-menu="true"
          >
            <>
              <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                  <div className="d-flex flex-column">
                    <div className="fw-bold d-flex align-items-center fs-5">{authenticationBlocState.authenticationData.user.username}</div>
                    <div className="fw-semibold text-muted fs-7">{authenticationBlocState.authenticationData.user.email}</div>
                  </div>
                </div>
              </div>

              <div className="separator my-2"></div>
            </>

            <div className="menu-item px-5">
              <Link to="/my-account" className={["menu-link px-5", isRouteActive("/my-account") ? "" : "text-black"].join(" ")}>
                Mon profil
              </Link>
            </div>
            <div className="menu-item px-5">
              <Link to="/my-services" className={["menu-link px-5", isRouteActive("/my-services") ? "" : "text-black"].join(" ")}>
                Mes annonces
              </Link>
            </div>

            {/* <div className="menu-item px-5">
              <Link to="/" className="menu-link px-5">
                Paramètres
              </Link>
            </div> */}

            <div className="separator my-2"></div>

            <div className="menu-item px-5">
              <div onClick={handleLogout} className="menu-link px-5">
                Se déconnecter
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Link to="/login" className="loginButton btn btn-sm btn-primary fw-500 w-100 w-lg-auto px-8 py-3 fs-5">
          Se connecter / S'inscrire
        </Link>
      </>
    );
  }
};

export default AuthMenu;

// import { useEffect, useRef, useState } from "react";

// // AuthMenu functional component
// const AuthMenu = () => {
//   const menuRef = useRef();
//   const [menuOpened, setMenuOpened] = useState(false);

//   useEffect(() => {
//     const handleOutsideClick = (event: any) => {
//       console.log(event);
//       if (menuOpened && !event.target.closest(".menu-sub")) {
//         setMenuOpened(false);
//       }
//     };

//     if (menuOpened) {
//       window.addEventListener("click", handleOutsideClick);
//     }

//     return () => {
//       window.removeEventListener("click", handleOutsideClick);
//     };
//   }, [menuOpened]);

//   return (
//     <div
//       ref={(ref) => menuRef}
//       className="d-flex align-items-center ms-3 ms-lg-4"
//       id="kt_header_user_menu_toggle"
//     >
//       <div
//         className="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px"
//         data-kt-menu-trigger="click"
//         data-kt-menu-attach="parent"
//         data-kt-menu-placement="bottom-end"
//         onClick={() => setMenuOpened(!menuOpened)}
//       >
//         <i className="ki-duotone ki-user fs-1">
//           <span className="path1"></span>
//           <span className="path2"></span>
//         </i>
//       </div>

//       <div
//         className={[
//           "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px",
//           menuOpened ? "show" : "",
//         ].join(" ")}
//         style={
//           menuOpened
//             ? {
//                 zIndex: 107,
//                 inset: "0px 0px auto auto",
//                 margin: "0px",
//                 // position: "fixed",
//                 // transform: "translate(-374px, 62px)",

//                 // transform: "translate(-100%, calc(50% + 20px))",
//                 // position: "relative",
//                 position: "absolute",
//                 top: "40px",
//               }
//             : {}
//         }
//         data-kt-menu="true"
//       >
//         <div className="menu-item px-3">
//           <div className="menu-content d-flex align-items-center px-3">
//             <div className="symbol symbol-50px me-5">
//               <img alt="Logo" src="assets/media/avatars/300-1.jpg" />
//             </div>

//             <div className="d-flex flex-column">
//               <div className="fw-bold d-flex align-items-center fs-5">
//                 Max Smith
//                 <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
//                   Pro
//                 </span>
//               </div>
//               <a
//                 href="#"
//                 className="fw-semibold text-muted text-hover-primary fs-7"
//               >
//                 max@kt.com
//               </a>
//             </div>
//           </div>
//         </div>

//         <div className="separator my-2"></div>

//         <div className="menu-item px-5">
//           <a
//             href="../../demo11/dist/account/overview.html"
//             className="menu-link px-5"
//           >
//             My Profile
//           </a>
//         </div>

//         <div className="menu-item px-5">
//           <a
//             href="../../demo11/dist/apps/projects/list.html"
//             className="menu-link px-5"
//           >
//             <span className="menu-text">My Projects</span>
//             <span className="menu-badge">
//               <span className="badge badge-light-danger badge-circle fw-bold fs-7">
//                 3
//               </span>
//             </span>
//           </a>
//         </div>

//         <div
//           className="menu-item px-5"
//           data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
//           data-kt-menu-placement="left-start"
//           data-kt-menu-offset="-15px, 0"
//         >
//           <a href="#" className="menu-link px-5">
//             <span className="menu-title">My Subscription</span>
//             <span className="menu-arrow"></span>
//           </a>

//           <div className="menu-sub menu-sub-dropdown w-175px py-4">
//             <div className="menu-item px-3">
//               <a
//                 href="../../demo11/dist/account/referrals.html"
//                 className="menu-link px-5"
//               >
//                 Referrals
//               </a>
//             </div>

//             <div className="menu-item px-3">
//               <a
//                 href="../../demo11/dist/account/billing.html"
//                 className="menu-link px-5"
//               >
//                 Billing
//               </a>
//             </div>

//             <div className="menu-item px-3">
//               <a
//                 href="../../demo11/dist/account/statements.html"
//                 className="menu-link px-5"
//               >
//                 Payments
//               </a>
//             </div>

//             <div className="menu-item px-3">
//               <a
//                 href="../../demo11/dist/account/statements.html"
//                 className="menu-link d-flex flex-stack px-5"
//               >
//                 Statements
//                 <span
//                   className="ms-2 lh-0"
//                   data-bs-toggle="tooltip"
//                   title="View your statements"
//                 >
//                   <i className="ki-duotone ki-information-5 fs-5">
//                     <span className="path1"></span>
//                     <span className="path2"></span>
//                     <span className="path3"></span>
//                   </i>
//                 </span>
//               </a>
//             </div>

//             <div className="separator my-2"></div>

//             <div className="menu-item px-3">
//               <div className="menu-content px-3">
//                 <label className="form-check form-switch form-check-custom form-check-solid">
//                   <input
//                     className="form-check-input w-30px h-20px"
//                     type="checkbox"
//                     value="1"
//                     checked={true}
//                     name="notifications"
//                   />
//                   <span className="form-check-label text-muted fs-7">
//                     Notifications
//                   </span>
//                 </label>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="menu-item px-5">
//           <a
//             href="../../demo11/dist/account/statements.html"
//             className="menu-link px-5"
//           >
//             My Statements
//           </a>
//         </div>

//         <div className="separator my-2"></div>

//         <div
//           className="menu-item px-5"
//           data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
//           data-kt-menu-placement="left-start"
//           data-kt-menu-offset="-15px, 0"
//         >
//           <a href="#" className="menu-link px-5">
//             <span className="menu-title position-relative">
//               Language
//               <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
//                 English
//                 <img
//                   className="w-15px h-15px rounded-1 ms-2"
//                   src="assets/media/flags/united-states.svg"
//                   alt=""
//                 />
//               </span>
//             </span>
//           </a>

//           <div className="menu-sub menu-sub-dropdown w-175px py-4">
//             <div className="menu-item px-3">
//               <a
//                 href="../../demo11/dist/account/settings.html"
//                 className="menu-link d-flex px-5 active"
//               >
//                 <span className="symbol symbol-20px me-4">
//                   <img
//                     className="rounded-1"
//                     src="assets/media/flags/united-states.svg"
//                     alt=""
//                   />
//                 </span>
//                 English
//               </a>
//             </div>

//             <div className="menu-item px-3">
//               <a
//                 href="../../demo11/dist/account/settings.html"
//                 className="menu-link d-flex px-5"
//               >
//                 <span className="symbol symbol-20px me-4">
//                   <img
//                     className="rounded-1"
//                     src="assets/media/flags/spain.svg"
//                     alt=""
//                   />
//                 </span>
//                 Spanish
//               </a>
//             </div>

//             <div className="menu-item px-3">
//               <a
//                 href="../../demo11/dist/account/settings.html"
//                 className="menu-link d-flex px-5"
//               >
//                 <span className="symbol symbol-20px me-4">
//                   <img
//                     className="rounded-1"
//                     src="assets/media/flags/germany.svg"
//                     alt=""
//                   />
//                 </span>
//                 German
//               </a>
//             </div>

//             <div className="menu-item px-3">
//               <a
//                 href="../../demo11/dist/account/settings.html"
//                 className="menu-link d-flex px-5"
//               >
//                 <span className="symbol symbol-20px me-4">
//                   <img
//                     className="rounded-1"
//                     src="assets/media/flags/japan.svg"
//                     alt=""
//                   />
//                 </span>
//                 Japanese
//               </a>
//             </div>

//             <div className="menu-item px-3">
//               <a
//                 href="../../demo11/dist/account/settings.html"
//                 className="menu-link d-flex px-5"
//               >
//                 <span className="symbol symbol-20px me-4">
//                   <img
//                     className="rounded-1"
//                     src="assets/media/flags/france.svg"
//                     alt=""
//                   />
//                 </span>
//                 French
//               </a>
//             </div>
//           </div>
//         </div>

//         <div className="menu-item px-5 my-1">
//           <a
//             href="../../demo11/dist/account/settings.html"
//             className="menu-link px-5"
//           >
//             Account Settings
//           </a>
//         </div>

//         <div className="menu-item px-5">
//           <a
//             href="../../demo11/dist/authentication/layouts/corporate/sign-in.html"
//             className="menu-link px-5"
//           >
//             Sign Out
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AuthMenu;

import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { ServicesFailure, ServicesLoaded, ServicesState } from "blocs/services_bloc/services_state";
import Service from "models/service";
import ServiceCard from "components/data_components/service_card";
import { Link } from "react-router-dom";
import { SortTypes } from "constants/constants";
import GenericFilters from "models/filters/generic_filters";

interface Props extends WithTranslation {}

const HomeSection2: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  return (
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
      <div className="content flex-row-fluid" id="kt_content">
        <div className="row gy-0 gx-5 row d-flex justify-content-start mb-4 mb-xl-6 mt-10 mt-xl-20">
          <div className="col-12">
            <div className="TitleWithAllBlock">
              <h3 className="fs-1 fw-700">Dernières annonces publiées</h3>
              <Link to={{ pathname: "/echange-entraide", search: new URLSearchParams({ sort: SortTypes.DATE_DESC }).toString() }} className="fs-5 fw-bold">
                Voir toutes les annonces →
              </Link>
            </div>
          </div>
        </div>

        <BlocBuilder
          bloc={blocsContext.servicesBloc}
          builder={(state: ServicesState) => {
            var services: Array<Service> = [];

            if (state instanceof ServicesLoaded) {
              services = new GenericFilters({ sort: SortTypes.DATE_DESC }).filter(state.services).filter((service) => service.isExpired() == false);
            }
            if (services.length == 0) {
              return (
                <div className="card-px text-center py-5 my-5">
                  <h2 className="fs-2x fw-bold mb-8">Aucun élément trouvé</h2>
                  <p className="text-gray-400 fs-4 fw-semibold mb-10">Aucune annonce trouvée pour le moment</p>
                </div>
              );
            }
            if (state instanceof ServicesLoaded) {
              return (
                <div className="ServiceCardList row g-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                  {services
                    .filter((service) => service.isExpired() == false)
                    .slice(0, 5)
                    .map((service: Service) => {
                      return <ServiceCard key={service.id} service={service} />;
                    })}
                </div>
              );
            }
            return <></>;
          }}
        />
      </div>
    </div>
  );
};

export default withTranslation("common")(HomeSection2);

import Company from "models/company";

export abstract class CompaniesEvent {}

export class LoadCompanies extends CompaniesEvent {
  public toString = (): string => `LoadCompanies`;
}

export class AddCompany extends CompaniesEvent {
  company: Company;
  constructor(company: Company) {
    super();
    this.company = company;
  }

  public toString = (): string => `AddCompany`;
}

export class EditCompany extends CompaniesEvent {
  company: Company;
  constructor(company: Company) {
    super();
    this.company = company;
  }

  public toString = (): string => `EditCompany`;
}

export class DeleteCompany extends CompaniesEvent {
  companyId: number;
  constructor(companyId: number) {
    super();
    this.companyId = companyId;
  }

  public toString = (): string => `DeleteCompany`;
}

import React, {useState} from "react";
import logo from "assets/images/logo_text.png";
// import "./header.scss";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {matchPath, useLocation} from "react-router";
import {WithTranslation, withTranslation} from "react-i18next";
import {BlocsContext} from "contexts/blocs_context";
import {BlocBuilder} from "@felangel/react-bloc";
import {AuthenticationAuthenticated, AuthenticationState} from "blocs/authentication_bloc/authentication_state";
import {AuthenticationLoggedOut} from "blocs/authentication_bloc/authentication_event";

import ReactModal from "react-modal";
import config from "config/config";

import AuthMenu from "./components/auth_menu";
import {useGeneralModal} from "components/modals/general_modal/general_modal_context";
import CreateServiceContent from "components/modals/create_service_content/create_service_content";

import iconMenu1 from "assets/images/home.png";
import iconMenu2 from "assets/images/help.png";
import iconMenu3 from "assets/images/tree.png";
import iconMenu4 from "assets/images/castle.png";
import iconMenu5 from "assets/images/villages.png";

// type Props = {
// title: string,
// paragraph: string
// }

const CustomToggle = React.forwardRef(({children, onClick, customClassName}: any, ref: any) => (
    <button
        // href="#"
        className={customClassName + ""}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        // onMouseEnter={(e) => onClick(e)}
        // onMouseLeave={(e) => onClick(e)}
    >
        {children}
        <span className="icon icon-angle-down"></span>
    </button>
));

interface Props extends WithTranslation {
    sticky: boolean;
    isOpened: boolean;
    closeDrawer: Function;
}

const HeaderMobile: React.FC<Props> = (props: Props) => {
    const generalModal = useGeneralModal();
    const location = useLocation();

    const isRouteActive = (route: string): boolean => {
        return !!matchPath(location.pathname, route);
    };

    const renderMenuLink = (title: string, to: string, imgURL: string) => {
        var selected: boolean = isRouteActive(to);
        return (
            <Link
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                to={to}
                onClick={() => props.closeDrawer()}
                className={["menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2", selected ? "here show" : ""].join(" ")}
            >
        <span className="menu-link py-3">
          <span className="menu-icon">
            <img src={imgURL}/>
          </span>
          <span className="menu-title">{title}</span>
        </span>
            </Link>
        );
    };

    return (
        <>
            <div
                className={["header-menu flex-column flex-lg-row drawer drawer-start", props.isOpened ? "drawer-on" : ""].join(" ")}
                style={{width: "250px"}}>
                <div
                    className="menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-state-primary menu-title-gray-800 menu-arrow-gray-400 align-items-stretch flex-grow-1 my-0 my-lg-0 px-2 px-lg-0 fw-semibold fs-6"
                    id="#kt_header_menu"
                    data-kt-menu="true"
                >
                    {/* <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
            <span className="menu-link py-3">
              <span className="menu-title">Dashboards</span>
              <span className="menu-arrow d-lg-none"></span>
            </span>
          </div> */}
                    {/*
          <div className="col-lg-6 mb-3">
            <div className="menu-item p-0 m-0">
              <a href="../../demo11/dist/index.html" className="menu-link">
                <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                  <i className="ki-duotone ki-element-11 text-primary fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </span>
                <span className="d-flex flex-column">
                  <span className="fs-6 fw-bold text-gray-800">Default</span>
                  <span className="fs-7 fw-semibold text-muted">Reports &amp; statistics</span>
                </span>
              </a>
            </div>
          </div> */}

                    <div className="text-end mb-5 divCross" onClick={() => props.closeDrawer()}>
                        <i className="ki-duotone ki-cross text-dark fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                    </div>

                    {renderMenuLink("Accueil", "/", iconMenu1)}
                    {renderMenuLink("Echange et entraide", "/echange-entraide", iconMenu2)}
                    {renderMenuLink("Vie locale", "/vie-locale", iconMenu3)}
                    {renderMenuLink("Tourisme et loisirs", "/tourisme-loisir", iconMenu4)}
                    {renderMenuLink("Les communes", "/municipalities", iconMenu5)}

                    <div className="flex-shrink-0 p-lg-0 mt-5 buttonGreenMenuMoblie">
                        <div
                            onClick={() => {
                                generalModal.setContent(<CreateServiceContent/>);
                                props.closeDrawer()
                                generalModal.openModal();
                            }}
                            className=" fs-3 fw-bold w-100 w-lg-auto text-start btnSeeMap"
                            title="200+ in-house components and 3rd-party plugins"
                        >
                            Publier une annonce
                        </div>

                        <Link
                            to="/suggest-item"
                            className="btn btn-sm text-primary fw-700 fs-3 w-100 w-lg-auto me-3 text-start mt-2"
                            title="200+ in-house components and 3rd-party plugins"
                            onClick={() => props.closeDrawer()}
                        >
                            Suggérer un ajout
                        </Link>
                    </div>

                </div>

                {/* <div className="flex-shrink-0 p-4 p-lg-0 me-lg-2">
        <a
          href="https://preview.keenthemes.com/html/metronic/docs"
          className="btn btn-sm btn-light-primary fw-bold w-100 w-lg-auto"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-dismiss-="click"
          data-bs-original-title="200+ in-house components and 3rd-party plugins"
          data-kt-initialized="1"
        >
          Docs &amp; Components
        </a>
      </div> */}
            </div>
            {props.isOpened &&
                <div style={{zIndex: 109}} className="drawer-overlay" onClick={() => props.closeDrawer()}></div>}
        </>
    );
};

export default withTranslation("common")(HeaderMobile);

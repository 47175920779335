import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { BlocsContext } from "contexts/blocs_context";
import { Link } from "react-router-dom";
import image1 from "assets/images/calendar.png";
import image2 from "assets/images/undraw2.png";
import image3 from "assets/images/undraw3.png";
import image4 from "assets/images/undraw4.png";
import image5 from "assets/images/undraw5.png";
import image6 from "assets/images/undraw6.png";
import image7 from "assets/images/undraw7.png";

import { ServiceCategoriesLoaded, ServiceCategoriesLoading, ServiceCategoriesState } from "blocs/service_categories_bloc/service_categories_state";
import HomeSectionMap from "./home_section_map";

interface Props extends WithTranslation { }

const HomeSection4: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [serviceCategoriesState, setServiceCategoriesState] = useState<ServiceCategoriesState>(new ServiceCategoriesLoading());


  useEffect(() => {
    setServiceCategoriesState(blocsContext.serviceCategoriesBloc.state);
    const serviceCategoriesSubscription = blocsContext.serviceCategoriesBloc.listen(setServiceCategoriesState);

    return () => {
      serviceCategoriesSubscription.unsubscribe();
    };
  });

  // const mapRef = React.useRef<HTMLDivElement>(null);
  // const [map, setMap] = React.useState<google.maps.Map>();
  // const [marker, setMarker] = React.useState<google.maps.Marker>();

  // React.useEffect(() => {
  //   if (mapRef.current && !map) {
  //     setMap(
  //       new window.google.maps.Map(mapRef.current, {
  //         center: { lat: 47.4, lng: 7.1 },
  //         zoom: 11,
  //         mapTypeId: "roadmap",
  //         fullscreenControl: false,
  //         streetViewControl: false,
  //       })
  //     );

  //     if (!marker) {
  //       setMarker(new google.maps.Marker());
  //     }
  //   }

  //   return () => {
  //     if (marker) {
  //       marker.setMap(null);
  //     }
  //   };
  // }, [mapRef, map, marker]);

  return (
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
      <div className="content flex-row-fluid" id="kt_content">
        <div className="row gy-0 gx-5 row d-flex justify-content-start mb-4 mb-xl-6 mt-5 mt-xl-20">
          <div className="col-12">
            <div className="TitleWithAllBlock">
              <h3 className="fs-1 fw-700">Catégories d'annonce</h3>
            </div>
          </div>
        </div>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
          <div className="categoriesList">


            {serviceCategoriesState instanceof ServiceCategoriesLoaded && serviceCategoriesState.categories.map((serviceCategory) => (
              <Link key={serviceCategory.id} to={{
                pathname: "/echange-entraide",
                search: new URLSearchParams({ "categoryId": serviceCategory.id.toString() }).toString(),
              }} className="singleCategory">{serviceCategory.name}</Link>
            ))}

          </div>
        </div>

        <HomeSectionMap />

        <div className="row gy-0 gx-5 row d-flex justify-content-start mb-4 mb-xl-6 mt-15 mt-xl-20">
          <div className="col-12">
            <h3 className="fs-1 fw-700">Vie locale et tourisme</h3>
          </div>
        </div>

        <div className="row row-cols-2 g-3 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 localLifeMunicipality greenCase">
          <Link to="/events">
            <div className="singleItemVieLocale">
              <div className="innerContent">
                <div className="localLifeSubTitle">
                  <img src={image1} className="localLifeIllustration" />
                </div>
                <div className="localLifeText">
                  <div className="localLifeSubTitle">Vie locale</div>
                  <div className="localLifeTitle">Evénements</div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/news">
            <div className="singleItemVieLocale">
              <div className="innerContent">
                <div className="localLifeSubTitle">
                  <img src={image2} className="localLifeIllustration" />
                </div>
                <div className="localLifeText">
                  <div className="localLifeSubTitle">Vie locale</div>
                  <div className="localLifeTitle">Actualités</div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/local-products">
            <div className="singleItemVieLocale">
              <div className="innerContent">
                <div className="localLifeSubTitle">
                  <img src={image3} className="localLifeIllustration" />
                </div>
                <div className="localLifeText">
                  <div className="localLifeSubTitle">Vie locale</div>
                  <div className="localLifeTitle">Terroir</div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/companies">
            <div className="singleItemVieLocale">
              <div className="innerContent">
                <div className="localLifeSubTitle">
                  <img src={image4} className="localLifeIllustration" />
                </div>
                <div className="localLifeText">
                  <div className="localLifeSubTitle">Vie locale</div>
                  <div className="localLifeTitle">Associations</div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/curiosities">
            <div className="singleItemVieLocale">
              <div className="innerContent">
                <div className="localLifeSubTitle">
                  <img src={image5} className="localLifeIllustration" />
                </div>
                <div className="localLifeText">
                  <div className="localLifeSubTitle">Tourisme & loisir</div>
                  <div className="localLifeTitle">Curiosités</div>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/merchants">
            <div className="singleItemVieLocale">
              <div className="innerContent">
                <div className="localLifeSubTitle">
                  <img src={image6} className="localLifeIllustration" />
                </div>
                <div className="localLifeText">
                  <div className="localLifeSubTitle">Tourisme & loisir</div>
                  <div className="localLifeTitle">Commerces</div>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/itineraries">
            <div className="singleItemVieLocale">
              <div className="innerContent">
                <div className="localLifeSubTitle">
                  <img src={image7} className="localLifeIllustration" />
                </div>
                <div className="localLifeText">
                  <div className="localLifeSubTitle">Tourisme & loisir</div>
                  <div className="localLifeTitle">Parcours</div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};



export default withTranslation("common")(HomeSection4);

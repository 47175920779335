import { Bloc, Transition } from "@felangel/bloc";
import UniversalCategory from "models/universal_category";
import CompanyCategoriesRepository from "repositories/company_category_repository";
import {
  CompanyCategoriesEvent,
  LoadCompanyCategories,
} from "./company_categories_event";
import {
  CompanyCategoriesState,
  CompanyCategoriesLoaded,
  CompanyCategoriesLoading,
  CompanyCategoriesFailure,
} from "./company_categories_state";

export default class CompanyCategoriesBloc extends Bloc<CompanyCategoriesEvent, CompanyCategoriesState> {
  companyRepository: CompanyCategoriesRepository;
  categories: Array<UniversalCategory> = [];

  constructor(companyRepository: CompanyCategoriesRepository) {
    super(new CompanyCategoriesLoading());
    this.companyRepository = companyRepository;
  }

  async *mapEventToState(event: CompanyCategoriesEvent) {
    if (event instanceof LoadCompanyCategories) {
      yield* this.onLoadCompanyCategories(event);
    }
  }

  async *onLoadCompanyCategories(event: LoadCompanyCategories) {
    try {
      yield new CompanyCategoriesLoading();
      var categoriesList: Array<UniversalCategory> = await this.companyRepository.getData();
      this.categories = categoriesList;

      yield new CompanyCategoriesLoaded(this.categories);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CompanyCategoriesFailure(e);
      } else {
        yield new CompanyCategoriesFailure(e.toString());
      }
    }
  }

  onEvent(event: CompanyCategoriesEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

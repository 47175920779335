import { AuthenticationAuthenticated, AuthenticationInitial, AuthenticationState } from "blocs/authentication_bloc/authentication_state";
import { LoadServices } from "blocs/services_bloc/services_event";
import { ServicesFailure, ServicesLoaded, ServicesLoading, ServicesState } from "blocs/services_bloc/services_state";
import MyServiceCard from "components/data_components/my_service_card";
import { SortTypes } from "constants/constants";
import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import GenericFilters from "models/filters/generic_filters";
import Service from "models/service";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// import styles from './home.scss';

interface Props extends WithTranslation {}

const ServiceMyArchiveScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);
  const navigate = useNavigate();

  const [servicesState, setServicesState] = useState<ServicesState>(new ServicesLoading());
  const [authenticationBlocState, setAuthenticationBlocState] = useState<AuthenticationState>(new AuthenticationInitial());

  const params = useParams<{ id: string }>();

  useEffect(() => {
    setServicesState(blocsContext.servicesBloc.state);
    const serviceSubscription = blocsContext.servicesBloc.listen(setServicesState);

    const authenticationSubscription = blocsContext.authenticationBloc.listen(onAuthenticationBlocAction);
    onAuthenticationBlocAction(blocsContext.authenticationBloc.state);

    return () => {
      serviceSubscription.unsubscribe();
      authenticationSubscription.unsubscribe();
    };
  });

  const onAuthenticationBlocAction = (state: AuthenticationState) => {
    setAuthenticationBlocState(state);
  };

  // const service: Service | undefined = undefined;

  if (!(authenticationBlocState instanceof AuthenticationAuthenticated)) {
    navigate("/login");
  }

  if (servicesState instanceof ServicesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (servicesState instanceof ServicesFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div className="btn btn-primary mt-6" onClick={() => blocsContext.servicesBloc.add(new LoadServices())}>
              Recharger
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (servicesState instanceof ServicesLoaded) {
    const genericFilter = new GenericFilters({ sort: SortTypes.DATE_DESC });
    const services: Array<Service> = genericFilter
      .filter(servicesState.services)
      .filter((service) => service.user.id == (authenticationBlocState as AuthenticationAuthenticated).authenticationData.user.id);

    if (services.length == 0) {
      return (
        <BaseScreen>
          <div className="card-px text-center py-20 my-20">
            <h2 className="fs-2x fw-bold mb-8">Aucun élément trouvé</h2>
            <p className="text-gray-400 fs-4 fw-semibold mb-10">Vous n'avez aucune annonce pour le moment</p>
            {/* <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add Customer</a> */}
          </div>
        </BaseScreen>
      );
    }

    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <h3 className="fs-1 fw-800">Mes services</h3>
                {/* <span className="subTitle">Découvrez la vie locale et ses curiosités</span> */}
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
              {services.map((service) => (
                <MyServiceCard key={service.id} service={service} />
              ))}
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(service)
};

export default withTranslation("common")(ServiceMyArchiveScreen);

import News from "models/news";

export abstract class NewsEvent {}

export class LoadNews extends NewsEvent {
  public toString = (): string => `LoadNews`;
}

export class AddNews extends NewsEvent {
  news: News;
  constructor(news: News) {
    super();
    this.news = news;
  }

  public toString = (): string => `AddNews`;
}

export class EditNews extends NewsEvent {
  news: News;
  constructor(news: News) {
    super();
    this.news = news;
  }

  public toString = (): string => `EditNews`;
}

export class DeleteNews extends NewsEvent {
  newsId: number;
  constructor(newsId: number) {
    super();
    this.newsId = newsId;
  }

  public toString = (): string => `DeleteNews`;
}

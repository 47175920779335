import { Bloc, Transition } from "@felangel/bloc";
import ServiceComment from "models/service_comment";
import ServiceCommentsRepository from "repositories/service_comment_repository"; // Adapter l'import en fonction de ton repository
import {
  ServiceCommentsEvent,
  LoadServiceComments,
  AddServiceComment,
  EditServiceComment,
  DeleteServiceComment,
} from "./service_comments_event"; // Adapter les imports en fonction de tes événements
import {
  ServiceCommentsState,
  ServiceCommentsLoaded,
  ServiceCommentsLoading,
  ServiceCommentsFailure,
} from "./service_comments_state"; // Adapter les imports en fonction des états

export default class ServiceCommentsBloc extends Bloc<ServiceCommentsEvent, ServiceCommentsState> {
  serviceCommentRepository: ServiceCommentsRepository; // Adapter le nom du repository en conséquence
  comments: Array<ServiceComment> = [];

  constructor(serviceCommentRepository: ServiceCommentsRepository) {
    super(new ServiceCommentsLoading()); // Adapter l'état initial en conséquence
    this.serviceCommentRepository = serviceCommentRepository; // Adapter le nom du repository en conséquence
  }

  async *mapEventToState(event: ServiceCommentsEvent) {
    if (event instanceof LoadServiceComments) {
      yield* this.onLoadServiceComments(event);
    } else if (event instanceof AddServiceComment) {
      yield* this.onAddServiceComment(event);
    }
    // else if(event instanceof EditServiceComment) { 
    //   yield* this.onEditServiceComment(event); 
    // } 
    else if (event instanceof DeleteServiceComment) {
      yield* this.onDeleteServiceComment(event);
    }

  }

  async *onLoadServiceComments(event: LoadServiceComments) {
    try {
      yield new ServiceCommentsLoading(); // Adapter l'état en conséquence
      var commentsList: Array<ServiceComment> = await this.serviceCommentRepository.getData(event.serviceId); // Adapter le nom du repository en conséquence
      this.comments = commentsList;

      yield new ServiceCommentsLoaded(this.comments); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ServiceCommentsFailure(e); // Adapter l'état en conséquence
      } else {
        yield new ServiceCommentsFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }

  async *onAddServiceComment(event: AddServiceComment) {
    try {
      // var newServiceComment: ServiceComment = await this.serviceCommentRepository.createServiceComment(event.serviceComment.comment, event.serviceComment.serviceId, event.serviceComment.parentId); // Adapter le nom du repository en conséquence
      this.comments.push(event.serviceComment);
      yield new ServiceCommentsLoaded(this.comments); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ServiceCommentsFailure(e); // Adapter l'état en conséquence
      } else {
        yield new ServiceCommentsFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }

  async *onDeleteServiceComment(event: DeleteServiceComment) {
    try {

      // Remove element from comments array
      var index: number = this.comments.findIndex((comment: ServiceComment) => comment.id === event.serviceCommentId);
      this.comments.splice(index, 1);

      yield new ServiceCommentsLoaded(this.comments); // Adapter l'état en conséquence
    } catch (e: any) {
      if (typeof e === "string") {
        yield new ServiceCommentsFailure(e); // Adapter l'état en conséquence
      } else {
        yield new ServiceCommentsFailure(e.toString()); // Adapter l'état en conséquence
      }
    }
  }


  onEvent(event: ServiceCommentsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event =", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition =", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error =", error);
  }
}

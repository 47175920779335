import UniversalCategory from "models/universal_category";

export abstract class ItineraryCategoriesState {} 

export class ItineraryCategoriesLoading extends ItineraryCategoriesState {} 

export class ItineraryCategoriesLoaded extends ItineraryCategoriesState {
  categories: Array<UniversalCategory>; // Adapter le nom du modèle en conséquence
  constructor(categories: Array<UniversalCategory>) {
    super();
    this.categories = categories;
  }

  public toString = (): string => {
    return `ItineraryCategoriesLoaded ${this.categories.length} categories`; // Adapter le nom en conséquence
  };
}

export class ItineraryCategoriesFailure extends ItineraryCategoriesState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `ItineraryCategoriesLoadingFailed { errorMessage: ${this.errorMessage} }`; // Adapter le nom en conséquence
  };
}

import { Bloc, Transition } from "@felangel/bloc";
import CuriosityRepository from "repositories/curiosity_repository";
import {
  CuriositiesEvent,
  LoadCuriosities,
  AddCuriosity,
  EditCuriosity,
  DeleteCuriosity,
} from "./curiosities_event";
import {
  CuriositiesState,
  CuriositiesLoaded,
  CuriositiesLoading,
  CuriositiesFailure,
} from "./curiosities_state";
import Curiosity from "models/curiosity";

export default class CuriositiesBloc extends Bloc<CuriositiesEvent, CuriositiesState> {
  curiosityRepository: CuriosityRepository;
  curiosities: Array<Curiosity> = [];

  constructor(curiosityRepository: CuriosityRepository) {
    super(new CuriositiesLoading());
    this.curiosityRepository = curiosityRepository;
  }

  async *mapEventToState(event: CuriositiesEvent) {
    if (event instanceof LoadCuriosities) {
      yield* this.onLoadCuriosities(event);
    } else if (event instanceof AddCuriosity) {
      yield* this.onAddCuriosity(event);
    } else if (event instanceof EditCuriosity) {
      yield* this.onEditCuriosity(event);
    } else if (event instanceof DeleteCuriosity) {
      yield* this.onDeleteCuriosity(event);
    }
  }

  async *onLoadCuriosities(event: LoadCuriosities) {
    try {
      yield new CuriositiesLoading();
      var curiositiesList: Array<Curiosity> = await this.curiosityRepository.getData();
      this.curiosities = curiositiesList;

      yield new CuriositiesLoaded(this.curiosities);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CuriositiesFailure(e);
      } else {
        yield new CuriositiesFailure(e.toString());
      }
    }
  }

  async *onAddCuriosity(event: AddCuriosity) {
    try {
      yield new CuriositiesLoading();
      this.curiosities.push(event.curiosity);

      yield new CuriositiesLoaded(this.curiosities);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CuriositiesFailure(e);
      } else {
        yield new CuriositiesFailure(e.toString());
      }
    }
  }

  async *onEditCuriosity(event: EditCuriosity) {
    try {
      yield new CuriositiesLoading();
      this.curiosities = this.curiosities.map((curiosity: Curiosity) => {
        if (curiosity.id == event.curiosity.id) {
          curiosity = event.curiosity;
        }
        return curiosity;
      });

      yield new CuriositiesLoaded(this.curiosities);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CuriositiesFailure(e);
      } else {
        yield new CuriositiesFailure(e.toString());
      }
    }
  }

  async *onDeleteCuriosity(event: DeleteCuriosity) {
    try {
      yield new CuriositiesLoading();
      this.curiosities = this.curiosities.filter((curiosity: Curiosity) => {
        return curiosity.id != event.curiosityId;
      });

      yield new CuriositiesLoaded(this.curiosities);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CuriositiesFailure(e);
      } else {
        yield new CuriositiesFailure(e.toString());
      }
    }
  }

  onEvent(event: CuriositiesEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

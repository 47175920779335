import { EventsLoading, EventsState } from "blocs/events_bloc/events_state";
import { MunicipalitiesLoaded, MunicipalitiesLoading, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import { ServiceCategoriesLoaded, ServiceCategoriesLoading, ServiceCategoriesState } from "blocs/service_categories_bloc/service_categories_state";
import { BlocsContext } from "contexts/blocs_context";
import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { useGeneralModal } from "../general_modal/general_modal_context";
import ServiceRepository from "repositories/service_repository";
import { BlocBuilder } from "@felangel/react-bloc";
import { AuthenticationState, AuthenticationUnAuthenticated } from "blocs/authentication_bloc/authentication_state";
import { useNavigate } from "react-router-dom";
import { LoadServices } from "blocs/services_bloc/services_event";
import ClockIcon from "assets/icons/clock.svg";
import Service from "models/service";
import GeneralHelper from "helpers/general_helper";

interface CreateServiceContentParams {
  editedService?: Service;
}

const CreateServiceContent: React.FC<CreateServiceContentParams> = ({ editedService }) => {
  const generalModal = useGeneralModal();

  const navigate = useNavigate();

  const stepCount = 4;
  const [step, setStep] = useState<number>(1);

  const [serviceCreationLoading, setServiceCreationLoading] = useState<boolean>(false);
  const [serviceCreationError, setServiceCreationError] = useState<string | undefined>(undefined);

  const { servicesBloc, serviceCategoriesBloc, municipalitiesBloc, authenticationBloc } = useContext(BlocsContext);
  const [serviceCategoriesState, setServiceCategoriesState] = useState<ServiceCategoriesState>(new ServiceCategoriesLoading());
  const [municipalitiesState, setMunicipalitiesState] = useState<MunicipalitiesState>(new MunicipalitiesLoading());
  const [authenticationState, setAuthenticationState] = useState<AuthenticationState>(new AuthenticationUnAuthenticated());

  // store all form data
  const [formData, setFormData] = useState<{
    title: string;
    description: string;
    categoryId?: number | undefined;
    municipalityId?: number | undefined;
    email: string;
    phone: string;
    expirationDuration: number | undefined;
    deleteInitialImage: boolean;
  }>({
    title: editedService?.name ?? "",
    description: editedService?.description != undefined ? GeneralHelper.cleanHtmlString(editedService.description) : "",
    categoryId: editedService?.category.id ?? undefined,
    municipalityId: editedService?.municipality.id ?? undefined,
    email: editedService?.email ?? "",
    phone: editedService?.phone ?? "",
    expirationDuration: editedService?.initialDaysBeforeExpiration ?? undefined,
    deleteInitialImage: false,
  });

  const [formDataError, setFormDataError] = useState({
    images: "",
    title: "",
    description: "",
    categoryId: "",
    municipalityId: "",
    email: "",
    phone: "",
    expirationDuration: "",
  });

  const [images, setImages] = useState<Array<any>>([]);

  const [serviceIdRedirect, setServiceIdRedirect] = useState<number | undefined>(undefined);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    setServiceCategoriesState(serviceCategoriesBloc.state);
    const serviceCategoriesBlocsubscription = serviceCategoriesBloc.listen(setServiceCategoriesState);

    setMunicipalitiesState(municipalitiesBloc.state);
    const municipalitiesBlocsubscription = municipalitiesBloc.listen(setMunicipalitiesState);

    setAuthenticationState(authenticationBloc.state);
    const authenticationBlocsubscription = authenticationBloc.listen(setAuthenticationState);

    return () => {
      serviceCategoriesBlocsubscription.unsubscribe();
      municipalitiesBlocsubscription.unsubscribe();
      authenticationBlocsubscription.unsubscribe();
    };
  });

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   images.forEach((file) => URL.revokeObjectURL(file.preview));
  // }, [images]);

  // const thumbs = images.map((image) => (
  //   <div style={{}} key={image.name}>
  //     <div style={{}}>
  //       <img
  //         src={image.preview}
  //         style={{}}
  //       />
  //     </div>
  //   </div>
  // ));

  if (authenticationState instanceof AuthenticationUnAuthenticated) {
    return (
      <>
        <div className="modal-header">
          <h2>{editedService != undefined ? "Modifier l'annonce" : "Publier une annonce"}</h2>

          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              generalModal.closeModal();
            }}
          >
            <i className="ki-duotone ki-cross fs-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>
        </div>
        <div className="modal-body py-lg-10 px-lg-10">
          <div className="mustLoginTitle">Connexion obligatoire</div>
          <div className="mustLoginText">Vous devez être connecté(e) pour publier une annonce.</div>
          <div
            className="btn btn-primary"
            onClick={() => {
              navigate("/login");
              generalModal.closeModal();
            }}
          >
            Se connecter
          </div>
        </div>
      </>
    );
  }

  const renderImage = () => {
    if (editedService != undefined && editedService!.images.length != 0 && formData.deleteInitialImage == false) {
      return (
        <>
          <div className="service-dropzone">
            <img src={editedService!.images[0]} />
          </div>
          <div
            onClick={() => {
              setFormData({ ...formData, deleteInitialImage: true });
            }}
            className="btn btn-sm btn-icon color-danger dropzone-delete-button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
            </svg>
          </div>
        </>
      );
    }

    return (
      <>
        {images.length == 0 && (
          <div {...getRootProps({ className: "service-dropzone" })}>
            <input {...getInputProps()} />
            <div>
              Photo de l'annonce
              <br />
              (Cliquez ici)
            </div>
          </div>
        )}

        {images.length != 0 && (
          <>
            <div {...getRootProps({ className: "service-dropzone" })}>
              <img src={images[0].preview} />
            </div>
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.images}</div>
          </>
        )}

        {images.length != 0 && (
          <div
            onClick={() => {
              setImages([]);
            }}
            className="btn btn-sm btn-icon color-danger dropzone-delete-button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
            </svg>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="modal-header">
        <h2>{editedService != undefined ? "Modifier l'annonce" : "Publier une annonce"}</h2>

        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          data-bs-dismiss="modal"
          onClick={() => {
            generalModal.closeModal();
          }}
        >
          <i className="ki-duotone ki-cross fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>

      <div className="modal-body p-5 p-md-5 py-lg-10 px-lg-10">
        <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper" data-kt-stepper="true">
          <div className="d-none d-xl-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
            <div className="stepper-nav ps-lg-10">
              <div className={["stepper-item", step == 1 ? "current" : ""].join(" ")} data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="ki-duotone ki-check stepper-check fs-2"></i>
                    <span className="stepper-number">1</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Informations de base</h3>
                    {/* <div className="stepper-desc">Name your App</div> */}
                  </div>
                </div>

                <div className="stepper-line h-40px"></div>
              </div>

              <div className={["stepper-item", step == 2 ? "current" : ""].join(" ")} data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="ki-duotone ki-check stepper-check fs-2"></i>
                    <span className="stepper-number">2</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Informations de contact</h3>
                  </div>
                </div>

                <div className="stepper-line h-40px"></div>
              </div>

              <div className={["stepper-item", step == 3 ? "current" : ""].join(" ")} data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="ki-duotone ki-check stepper-check fs-2"></i>
                    <span className="stepper-number">3</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Durée de publication</h3>
                  </div>
                </div>

                <div className="stepper-line h-40px"></div>
              </div>

              <div className={["stepper-item", step == 4 ? "current" : ""].join(" ")} data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="ki-duotone ki-check stepper-check fs-2"></i>
                    <span className="stepper-number">4</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Confirmation de l'annonce</h3>
                    {/* <div className="stepper-desc">
                Select the app database type
              </div> */}
                  </div>
                </div>

                <div className="stepper-line h-40px"></div>
              </div>
              {/* 
        <div className="stepper-item" data-kt-stepper-element="nav">
          <div className="stepper-wrapper">
            <div className="stepper-icon w-40px h-40px">
              <i className="ki-duotone ki-check stepper-check fs-2"></i>
              <span className="stepper-number">4</span>
            </div>

            <div className="stepper-label">
              <h3 className="stepper-title">Billing</h3>
              <div className="stepper-desc">Provide payment details</div>
            </div>
          </div>

          <div className="stepper-line h-40px"></div>
        </div>
*/}
              <div className={["stepper-item mark-completed", step == 4 ? "current" : ""].join(" ")} data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="ki-duotone ki-check stepper-check fs-2"></i>
                    <span className="stepper-number">4</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">{editedService != null ? "Annonce modifiée" : "Annonce publiée"}</h3>
                    {/* <div className="stepper-desc">Review and Submit</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row-fluid py-lg-0 px-lg-15">
            <form className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate={true} id="kt_modal_create_app_form">
              <div className={[step == 1 ? "current" : ""].join(" ")} data-kt-stepper-element="content">
                <div className="w-100">
                  <div className="fv-row mb-10 fv-plugins-icon-container">{renderImage()}</div>

                  <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                      <span className="required">Titre de l'annonce</span>
                    </label>

                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="title"
                      placeholder="Titre de l'annonce"
                      value={formData.title}
                      onChange={(e) => {
                        setFormData({ ...formData, title: e.target.value });
                      }}
                    />

                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.title}</div>
                  </div>

                  <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                      <span className="">Description de l'annonce</span>
                    </label>

                    <textarea
                      className="form-control form-control-lg form-control-solid"
                      name="name"
                      placeholder="Description de l'annonce"
                      value={formData.description}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description: e.target.value,
                        });
                      }}
                      style={{ resize: "none" }}
                    />

                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.description}</div>
                  </div>

                  <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                      <span className="required">Catégorie de l'annonce</span>
                    </label>

                    {serviceCategoriesState instanceof ServiceCategoriesLoading && (
                      <span className="indicator-progress">
                        Chargement des annonces...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}

                    {/* value={formData.description}
                onChange={(e) => {
                  setFormData({ ...formData, description: e.target.value });
                }} */}

                    {serviceCategoriesState instanceof ServiceCategoriesLoaded && (
                      <select
                        className="form-control form-control-lg form-control-solid"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            categoryId: e.target.value != "-1" ? parseInt(e.target.value) : undefined,
                          });
                        }}
                      >
                        <option value={-1} selected={formData.categoryId == undefined}>
                          Séléctionner une catégorie
                        </option>
                        {serviceCategoriesState.categories.map((serviceCategory) => (
                          <option value={serviceCategory.id} selected={formData.categoryId == serviceCategory.id}>
                            {serviceCategory.name}
                          </option>
                        ))}
                      </select>
                    )}

                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.categoryId}</div>
                  </div>

                  <div className="fv-row mb-10 fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                      <span className="required">Commune de l'annonce</span>
                    </label>

                    {municipalitiesState instanceof MunicipalitiesLoading && (
                      <span className="indicator-progress">
                        Chargement des communes...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}

                    {municipalitiesState instanceof MunicipalitiesLoaded && (
                      <select
                        className="form-control form-control-lg form-control-solid"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            municipalityId: e.target.value != "-1" ? parseInt(e.target.value) : undefined,
                          });
                        }}
                      >
                        <option value={-1} selected={formData.municipalityId == undefined} disabled>
                          Séléctionner une commune
                        </option>
                        {municipalitiesState.municipalities
                          .filter((municipality) => municipality.parent == undefined)
                          .map((municipality) => (
                            <option key={municipality.id} value={municipality.id} selected={formData.municipalityId == municipality.id}>
                              {municipality.name}
                            </option>
                          ))}
                      </select>
                    )}

                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.municipalityId}</div>
                  </div>
                </div>
              </div>

              <div className={[step == 2 ? "current" : ""].join(" ")} data-kt-stepper-element="content">
                <div className="w-100">
                  <div className="fv-row fv-plugins-icon-container">
                    <div className="mb-5">
                      {/* <h2 className="mb-13">Informations de contact</h2> */}
                      <h4 className="mb-3">Comment souhaitez-vous être contacté(e) ?</h4>

                      <div className="text-muted fw-semibold fs-5">Ces informations seront affichées sur votre annonce.</div>
                    </div>

                    <div className="fv-row mb-10 fv-plugins-icon-container">
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                        <span className="required">Adresse email</span>
                      </label>

                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="email"
                        placeholder="Adresse email"
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />

                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.email}</div>
                    </div>

                    <div className="fv-row mb-10 fv-plugins-icon-container">
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                        <span className="">Numéro de téléphone</span>
                      </label>

                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="phone"
                        placeholder="Numéro de téléphone"
                        value={formData.phone}
                        onChange={(e) => {
                          setFormData({ ...formData, phone: e.target.value });
                        }}
                      />

                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.phone}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={[step == 3 ? "current" : ""].join(" ")} data-kt-stepper-element="content">
                <div className="w-100">
                  <div className="fv-row fv-plugins-icon-container">
                    <div className="mb-5">
                      {/* <h2 className="mb-13">Informations de contact</h2> */}
                      <h4 className="mb-3">Durée de publication</h4>

                      <div className="text-muted fw-semibold fs-5">Une fois la durée de publication dépassée, votre annonce sera désactivée. Si nécessaire, vous pourrez renouveler votre annonce.</div>
                    </div>

                    <div className="fv-row mb-10 fv-plugins-icon-container">
                      <div
                        onClick={() => {
                          if (editedService != undefined) return;
                          setFormData({ ...formData, expirationDuration: 10 });
                        }}
                        className={["expirationDurationItem", formData.expirationDuration == 10 ? "selected" : "", editedService != undefined ? "locked" : ""].join(" ")}
                      >
                        <div className="expirationDurationImageContainer">
                          <img src={ClockIcon} className="expirationDurationImage" />
                        </div>
                        <div className="d-flex flex-column flex-grow-1">
                          <div>Durée de la publication</div>
                          <div className="expirationDuration">10 jours</div>
                        </div>
                        <div className="selector"></div>
                      </div>

                      <div
                        onClick={() => {
                          if (editedService != undefined) return;
                          setFormData({ ...formData, expirationDuration: 30 });
                        }}
                        className={["expirationDurationItem", formData.expirationDuration == 30 ? "selected" : "", editedService != undefined ? "locked" : ""].join(" ")}
                      >
                        <div className="expirationDurationImageContainer">
                          <img src={ClockIcon} className="expirationDurationImage" />
                        </div>
                        <div className="d-flex flex-column flex-grow-1">
                          <div>Durée de la publication</div>
                          <div className="expirationDuration">30 jours</div>
                        </div>
                        <div className="selector"></div>
                      </div>

                      <div
                        onClick={() => {
                          if (editedService != undefined) return;
                          setFormData({ ...formData, expirationDuration: 60 });
                        }}
                        className={["expirationDurationItem", formData.expirationDuration == 60 ? "selected" : "", editedService != undefined ? "locked" : ""].join(" ")}
                      >
                        <div className="expirationDurationImageContainer">
                          <img src={ClockIcon} className="expirationDurationImage" />
                        </div>
                        <div className="d-flex flex-column flex-grow-1">
                          <div>Durée de la publication</div>
                          <div className="expirationDuration">60 jours</div>
                        </div>
                        <div className="selector"></div>
                      </div>

                      {editedService != undefined && (
                        <div className="text-muted fw-semibold fs-5 mt-5">
                          Vous ne pouvez plus modifier la durée d'une annonce publiée. Vous pourrez la renouveler à partir de 3 jours avant son expiration.
                        </div>
                      )}

                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.expirationDuration}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={[step == 4 ? "current" : ""].join(" ")} data-kt-stepper-element="content">
                <div className="w-100">
                  <div className="resumeAnnonce">
                    <div className="imgAnnonce">{images[0] != null ? <img src={images[0].preview} /> : <></>}</div>
                    <div className="title">{formData.title}</div>
                    <div className="description">{formData.description}</div>
                    <div className="contact">
                      <div className="contactTitle">Informations de contact</div>
                      <div className="contactInfo">
                        <p>{formData.email}</p>
                        <p>{formData.phone}</p>

                        <div className="contactTitle">Durée de publication</div>
                        <div className="contactInfo">
                          <p>{formData.expirationDuration} jours</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={[step == 5 ? "current" : ""].join(" ")} data-kt-stepper-element="content">
                <div className="w-100 h-100 d-flex align-items-center justify-content-center text-center flex-column">
                  <h1 className="fw-bold text-dark mb-3">{editedService != undefined ? "Votre annonce a été modifiée !" : "Votre annonce a été publié !"}</h1>

                  <div className="text-muted fw-semibold fs-3">
                    {/* Vous pouvez désormais consulter votre annonce en vous rendant sur la page . */}

                    {editedService != undefined
                      ? "Les modifications de votre annonce sont désormais visibles publiquement."
                      : "Votre annonce est désormais visible publiquement. Vous pouvez la consulter en vous rendant sur la page des annonces."}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-stack pt-10 justify-content-between">
                <div>
                  <button type="button" className={["btn btn-lg btn-light-primary", step == 1 || step == 5 ? "d-none" : ""].join(" ")} onClick={() => setStep(step - 1)}>
                    <i className="ki-duotone ki-arrow-left fs-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    Précédent
                  </button>
                </div>
                <div className="step d-xl-none d-block">{step}/5</div>
                <div>
                  {step == 4 && (
                    <button
                      type="button"
                      className={["btn btn-lg btn-primary publishButton"].join(" ")}
                      onClick={async () => {
                        if (serviceCreationLoading == true) return;

                        setServiceCreationLoading(true);
                        setServiceCreationError(undefined);
                        var serviceRepository = new ServiceRepository();
                        var response: Service;
                        try {
                          if (editedService != null) {
                            response = await serviceRepository.editService(
                              editedService.id,
                              formData.title,
                              formData.description != "" ? formData.description : undefined,
                              images.length != 0 ? images[0] : undefined,
                              formData.categoryId!,
                              formData.municipalityId!,
                              formData.email,
                              formData.phone != "" ? formData.phone : undefined,
                              formData.expirationDuration!,
                              formData.deleteInitialImage
                            );
                          } else {
                            response = await serviceRepository.createService(
                              formData.title,
                              formData.description != "" ? formData.description : undefined,
                              images.length != 0 ? images[0] : undefined,
                              formData.categoryId!,
                              formData.municipalityId!,
                              formData.email,
                              formData.phone != "" ? formData.phone : undefined,
                              formData.expirationDuration!
                            );
                          }

                          // if (response.status != 200) {
                          //   console.log(response);
                          //   setServiceCreationError(response.data.message);
                          // } else {
                          setServiceCreationLoading(false);
                          servicesBloc.add(new LoadServices());
                          setStep(step + 1);
                          setServiceIdRedirect(response.id);
                          return;
                          // }
                        } catch (e: any) {
                          setServiceCreationError(e.message);
                          setServiceCreationLoading(false);
                          return;
                        }
                      }}
                    >
                      {!serviceCreationLoading && (
                        <span className="indicator-label bg-red d-flex align-items-center">
                          {editedService != null ? "Modifier mon annonce" : "Publier mon annonce"}
                          <i className="ki-duotone ki-arrow-right fs-3 ms-2 me-0">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      )}
                      {serviceCreationLoading && (
                        <span className="indicator-progress">
                          Chargement...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  )}

                  {step != 4 && step != 5 && (
                    <button
                      type="button"
                      className={[" btn btn-lg btn-primary"].join(" ")}
                      onClick={() => {
                        var newFormDataError = {
                          images: "",
                          title: "",
                          description: "",
                          categoryId: "",
                          municipalityId: "",
                          email: "",
                          phone: "",
                          expirationDuration: "",
                        };

                        if (step == 1) {
                          if(images[0] != undefined && images[0].size > 8192000) {
                            newFormDataError = {
                              ...newFormDataError,
                              images: "La taille de l'image ne doit pas dépasser 8Mo",
                            };
                          }
                          if (formData.title == "") {
                            newFormDataError = {
                              ...newFormDataError,
                              title: "Veuillez renseigner le titre de l'annonce",
                            };
                          }
                          if (formData.categoryId == undefined) {
                            newFormDataError = {
                              ...newFormDataError,
                              categoryId: "Veuillez séléctionner une catégorie",
                            };
                          }
                          if (formData.municipalityId == undefined) {
                            newFormDataError = {
                              ...newFormDataError,
                              municipalityId: "Veuillez séléctionner une commune",
                            };
                          }
                        } else if (step == 2) {
                          if (formData.email == "") {
                            newFormDataError = {
                              ...newFormDataError,
                              email: "Veuillez renseigner votre adresse email",
                            };
                          } else if (GeneralHelper.validateEmail(formData.email) == false) {
                            newFormDataError = {
                              ...newFormDataError,
                              email: "Veuillez renseigner une adresse email valide",
                            };
                          }

                          // if (formData.phone == "") {
                          //   newFormDataError = {
                          //     ...newFormDataError,
                          //     phone:
                          //       "Veuillez renseigner votre numéro de téléphone",
                          //   };
                          // }
                        } else if (step == 3) {
                          if (formData.expirationDuration == undefined) {
                            newFormDataError = {
                              ...newFormDataError,
                              expirationDuration: "Veuillez séléctionner une durée de publication",
                            };
                          }
                        } else if (step == 4) {
                        }

                        setFormDataError(newFormDataError);
                        if (
                          newFormDataError.images != "" ||
                          newFormDataError.title != "" ||
                          newFormDataError.description != "" ||
                          newFormDataError.categoryId != "" ||
                          newFormDataError.municipalityId != "" ||
                          newFormDataError.email != "" ||
                          newFormDataError.phone != "" ||
                          newFormDataError.expirationDuration != ""
                        ) {
                          return;
                        }

                        setStep(step + 1);
                      }}
                    >
                      Suivant
                      <i className="ki-duotone ki-arrow-right fs-3 ms-1 me-0">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </button>
                  )}

                  {step == 5 && (
                    <button
                      type="button"
                      className={["btn btn-lg btn-primary"].join(" ")}
                      onClick={() => {
                        generalModal.closeModal();
                        navigate("/service/" + serviceIdRedirect);
                      }}
                    >
                      Voir mon annonce
                      <i className="ki-duotone ki-arrow-right fs-3 ms-1 me-0">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </button>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{serviceCreationError}</div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateServiceContent;

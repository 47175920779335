import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
// import styles from './home.scss';

import backgroundImage from "assets/images/home_section_1_background.jpg";
import { Link } from "react-router-dom";
import Service from "models/service";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { ServicesLoaded, ServicesState } from "blocs/services_bloc/services_state";
import GeneralHelper from "helpers/general_helper";

import defaultPic from "assets/images/default_pic.png";
import Swal from "sweetalert2";
import { AuthenticationLoggedOut } from "blocs/authentication_bloc/authentication_event";
import ServiceRepository from "repositories/service_repository";
import { DeleteService, LoadServices } from "blocs/services_bloc/services_event";
import { useGeneralModal } from "components/modals/general_modal/general_modal_context";
import CreateServiceContent from "components/modals/create_service_content/create_service_content";
import moment from "moment";
import { ImageSizes } from "constants/constants";

interface Props extends WithTranslation {
  service: Service;
}

const MyServiceCard: React.FC<Props> = ({ t, service }) => {
  const generalModal = useGeneralModal();
  const blocsContext = useContext(BlocsContext);
  return (
    <div className="col mb-11 mb-md-5">
      <Link to={"/service/" + service.id} className="d-block" data-fslightbox="lightbox-hot-sales">
        <div className="parent service">
          <div
            className="child bgi-position-center bgi-no-repeat bgi-size-cover h-150px card-rounded mb-3"
            style={{
              backgroundImage: "url('" + (service.getFirstImage(ImageSizes.small) ?? defaultPic) + "')",
            }}
          ></div>
        </div>

        <div className="d-flex flex-wrap gap-1">
          <div className="cardMunicipalityTag">{service.municipality.name}</div>
          {service.isExpired() && <div className="cardExpiredTag">Expiré</div>}
        </div>

        <div className="m-0 pb-3">
          <div className="text-gray-800 fs-16 fw-800 d-block mb-2 cardTitle">{service.name}</div>

          <span className="fw-500 fs-6 text-gray-400 d-block lh-1">{service.category.name}</span>
        </div>
      </Link>
      <div className="d-flex flex-wrap justify-content-start">
        <div
          className="text-primary fw-700 cursor-pointer d-flex align-items-center me-6 mb-2"
          onClick={() => {
            generalModal.setContent(<CreateServiceContent editedService={service} />);
            generalModal.openModal();
          }}
        >
          <i className="ki-outline ki-pencil fs-4 text-primary me-2"></i>
          Modifier
        </div>

        {/* if service endAt is equal or before today + 3 days */}
        {service.canBeRenewed() && (
          <div
            className="text-primary fw-700 cursor-pointer d-flex align-items-center me-6 mb-2"
            onClick={() => {
              var serviceRepository = new ServiceRepository();
              serviceRepository
                .renewService(service.id)
                .then((success: boolean) => {
                  blocsContext.servicesBloc.add(new LoadServices());
                  Swal.fire({
                    title: "Annonce renouvelée",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                    iconColor: "#5cb85c",
                  });
                })
                .catch((error: any) => {
                  Swal.fire({
                    title: "Une erreur inconue s'est produite, veuillez réessayer plus tard",
                    icon: "error",
                    timer: 1500,
                    showConfirmButton: false,
                    iconColor: "#f44336",
                  });
                });
            }}
          >
            <i className="ki-outline ki-arrows-loop fs-4 text-primary me-2"></i>
            Renouveler
          </div>
        )}
        <div
          className="text-danger fw-700 cursor-pointer d-flex align-items-center me-6 mb-2"
          onClick={() => {
            Swal.fire({
              title: "Êtes-vous sûr de vouloir supprimer cette annonce ?",
              // icon: 'question',
              showCancelButton: true,
              confirmButtonText: "Supprimer",
              cancelButtonText: "Annuler",
              confirmButtonColor: "#d33",
              padding: "1rem",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                var serviceRepository = new ServiceRepository();
                serviceRepository
                  .deleteService(service.id)
                  .then((success: boolean) => {
                    if (success) {
                      blocsContext.servicesBloc.add(new DeleteService(service.id));

                      Swal.fire({
                        title: "Annonce supprimée",
                        icon: "success",
                        timer: 1500,
                        showConfirmButton: false,
                        iconColor: "#5cb85c",
                      });
                    } else {
                      Swal.fire({
                        title: "Une erreur inconue s'est produite, veuillez réessayer plus tard",
                        icon: "error",
                        timer: 1500,
                        showConfirmButton: false,
                        iconColor: "#f44336",
                      });
                    }
                  })
                  .catch((error: any) => {
                    Swal.fire({
                      title: "Une erreur inconue s'est produite, veuillez réessayer plus tard",
                      icon: "error",
                      timer: 1500,
                      showConfirmButton: false,
                      iconColor: "#f44336",
                    });
                  });
              }
            });
          }}
        >
          <i className="ki-outline ki-trash fs-4 text-danger me-2"></i>
          Supprimer
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(MyServiceCard);

import Config from "config/config";
import axios from "axios";
import UniversalCategory from "models/universal_category";

export default class CuriosityRepository {
  static _curiosityCategoriesEndpoint: string = "curiosity-categories";
  static _curiosityCategoriesDataKey: string = "curiosity_categories_data";

  async getData(): Promise<Array<UniversalCategory>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + CuriosityRepository._curiosityCategoriesEndpoint,
      {
        headers: {
          Accept: "application/json",
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToCuriosities(data.data.curiosityCategories);
  }

  dataToCuriosities(data: any): Array<UniversalCategory> {
    var curiosities: Array<UniversalCategory> = [];
    for (var i = 0; i < data.length; i++) {
      curiosities.push(UniversalCategory.fromJson(data[i]));
    }
    return curiosities;
  }
}

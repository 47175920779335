import Notification from "models/notification";

export abstract class NotificationsState {}

// export class NotificationsInitial extends NotificationsState {}

export class NotificationsLoading extends NotificationsState {}

export class NotificationsLoaded extends NotificationsState {
  notifications: Array<Notification>;
  constructor(notifications: Array<Notification>) {
    super();
    this.notifications = notifications;
  }

  public toString = (): string => {
    return `NotificationsLoaded ${this.notifications.length} notifications`;
  };
}

export class NotificationsFailure extends NotificationsState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `NotificationsLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}

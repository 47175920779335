import BaseScreen from "layouts/base";
import React, { useState, useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
// import styles from './home.scss';

import backgroundImage from "assets/images/home_section_1_background.jpg";
import { Link } from "react-router-dom";
import Service from "models/service";
import { BlocBuilder } from "@felangel/react-bloc";
import { BlocsContext } from "contexts/blocs_context";
import { ServicesLoaded, ServicesState } from "blocs/services_bloc/services_state";
import GeneralHelper from "helpers/general_helper";

import defaultPic from "assets/images/default_pic.png";
import { ImageSizes } from "constants/constants";

interface Props extends WithTranslation {
  service: Service;
}

const ServiceCard: React.FC<Props> = ({ t, service }) => {
  return (
    <div className="col serviceCard">
      <Link to={"/service/" + service.id} className="d-block mb-md-5" data-fslightbox="lightbox-hot-sales">
        {/* <a
            className="d-block overlay"
            data-fslightbox="lightbox-hot-sales"
            href="assets/media/stock/900x600/42.jpg"
          > */}
        <div className="parent service">
          <div
            className="child bgi-position-center bgi-no-repeat bgi-size-cover h-90px w-90px w-md-auto h-md-150px card-rounded mb-xl-3"
            style={{
              backgroundImage: "url('" + (service.getFirstImage(ImageSizes.small) ?? defaultPic) + "')",
            }}
          ></div>
        </div>

        <div className="cardMunicipalityTag">{service.municipality.name}</div>

        <div className="m-0 pb-3">
          <div className="text-gray-800 fs-16 fw-800 d-block mb-2 cardTitle">{service.name}</div>

          <span className="fw-500 fs-6 text-gray-400 d-block lh-1">{service.category.name}</span>
        </div>

        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
          {/* <i className="ki-duotone ki-eye fs-3x text-white">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i> */}
        </div>
      </Link>
    </div>
  );
};

export default withTranslation("common")(ServiceCard);

import { BlocBuilder } from "@felangel/react-bloc";
import { AuthenticationLoggedIn, AuthenticationLoggedOut } from "blocs/authentication_bloc/authentication_event";
import { AuthenticationAuthenticated } from "blocs/authentication_bloc/authentication_state";
import { BlocsContext } from "contexts/blocs_context";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useConfirmationModal } from "components/modals/logout_confirmation_modal/logout_confirmation_modal_context";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MunicipalitiesLoaded, MunicipalitiesLoading, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import GenericFilters from "models/filters/generic_filters";
import { SortTypes, getSortType } from "constants/constants";
import Municipality from "models/municipality";

interface Props {
  filters: GenericFilters;
  onSetFilters: Function;
  dataType: string;
  // categories: Array<any>;
}

const ArchiveFilter: React.FC<Props> = ({ filters, onSetFilters, dataType }) => {
  const blocsContext = useContext(BlocsContext);

  const menuRef = useRef();
  const [popupOpened, setPopupOpened] = useState(false);
  const [municipalitiesState, setMunicipalitiesState] = useState<MunicipalitiesState>(new MunicipalitiesLoading());
  const { serviceCategoriesBloc, municipalitiesBloc } = useContext(BlocsContext);

  const [formData, setFormData] = useState<{
    municipalityId?: number | undefined;
    sort?: SortTypes;
  }>({
    municipalityId: filters.municipalityId != undefined ? filters.municipalityId : undefined,
    sort: filters.sort,
  });

  useEffect(() => {
    setMunicipalitiesState(municipalitiesBloc.state);
    const municipalitiesBlocsubscription = municipalitiesBloc.listen(setMunicipalitiesState);

    const handleOutsideClick = (event: any) => {
      if (popupOpened && !event.target.closest(".menu-sub")) {
        closeWithoutSaving();
      }
    };

    if (popupOpened) {
      window.addEventListener("click", handleOutsideClick);
      setFormData({
        municipalityId: filters.municipalityId != undefined ? filters.municipalityId : undefined,
        sort: filters.sort,
      })
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
      municipalitiesBlocsubscription.unsubscribe();
    };
  }, [blocsContext.authenticationBloc, popupOpened]);

  const resetFilters = () => {
    const newFilters = {
      municipalityId: undefined,
      sort: SortTypes.NAME_ASC,
    };
    setFormData(newFilters);
    onSetFilters(newFilters);
    setPopupOpened(false);
  };

  const closeWithoutSaving = () => {
    const newFilters = {
      municipalityId: filters.municipalityId != undefined ? filters.municipalityId : undefined,
      sort: filters.sort,
    };
    setFormData(newFilters);
    setPopupOpened(false);
  };

  const saveFilters = () => {
    var newFilters = new GenericFilters({
      municipalityId: formData.municipalityId,
      sort: formData.sort,
    });
    onSetFilters(newFilters);
    setPopupOpened(false);
  };

  const renderMunicipalitiesFilter = () => {
    const header = <label className="form-label fw-semibold">Commune:</label>;

    if (municipalitiesState instanceof MunicipalitiesLoading) {
      return (
        <>
          {header}
          <span className="indicator-progress show">
            Chargement des communes...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </>
      );
    }

    if (municipalitiesState instanceof MunicipalitiesLoaded) {
      const municipalities: Array<Municipality> = municipalitiesState.municipalities.filter((municipality) => municipality.parent == undefined);
      return (
        <>
          {header}
          <select
            className="form-control form-control-lg form-control-solid"
            onChange={(e) => {
              const newMunicipality: Municipality | undefined = municipalitiesState.municipalities.find((municipality) => municipality.id == parseInt(e.target.value));
              setFormData({
                ...formData,
                municipalityId: newMunicipality?.id,
              });
            }}
          >
            <option value={-1} selected={formData.municipalityId == undefined}>
              Séléctionner une commune
            </option>
            {municipalities.map((municipality) => (
              <option key={municipality.id} value={municipality.id} selected={formData.municipalityId != undefined && formData.municipalityId == municipality.id}>
                {municipality.name}
              </option>
            ))}
          </select>
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      <div className="card-toolbar position-relative" ref={(ref) => menuRef}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary show menu-dropdown filterBtn"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          onClick={() => {
            if (popupOpened) {
              closeWithoutSaving();
            } else {
              setPopupOpened(true);
            }
          }}
        >
          
        <span className="filterText">Filtrer</span>
          <i className="ki-duotone ki-category fs-6">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </i>
        </button>

        <div
          className={["menu menu-sub menu-sub-dropdown w-250px w-md-300px top archive-filter-popup", popupOpened ? "show" : ""].join(" ")}
          data-kt-menu="true"
          id="kt_menu_64b776d7da185"
          data-popper-placement="top-end"
          // style={{ zIndex: 107, position: "absolute", inset: "auto 0px 0px auto", margin: "0px", transform: "" }}
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bold">Filtres et tris</div>
          </div>

          <div className="separator border-gray-200"></div>

          <div className="px-7 py-5">
            <div className="mb-10">{renderMunicipalitiesFilter()}</div>

            <div className="mb-10">
              <label className="form-label fw-semibold">Tris:</label>

              <select
                className="form-control form-control-lg form-control-solid"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    sort: getSortType(e.target.value),
                  });
                }}
              >
                <option value={SortTypes.NAME_ASC} selected={formData.sort == SortTypes.NAME_ASC}>
                  Nom A-Z
                </option>
                <option value={SortTypes.NAME_DESC} selected={formData.sort == SortTypes.NAME_DESC}>
                  Nom Z-A
                </option>
                <option value={SortTypes.DATE_DESC} selected={formData.sort == SortTypes.DATE_DESC}>
                  { dataType == "Event" ? "Prochainement" : "Nouveauté"}
                </option>
              </select>
            </div>

            <div className="d-flex justify-content-end">
              <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" onClick={resetFilters}>
                Réinitialiser
              </button>
              <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true" onClick={saveFilters}>
                Appliquer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchiveFilter;

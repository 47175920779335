import { Bloc, Transition } from "@felangel/bloc";
import Merchant from "models/merchant";
import MerchantRepository from "repositories/merchant_repository";
import {
  MerchantsEvent,
  LoadMerchants,
  AddMerchant,
  EditMerchant,
  DeleteMerchant,
} from "./merchants_event";
import {
  MerchantsState,
  MerchantsLoaded,
  MerchantsLoading,
  MerchantsFailure,
} from "./merchants_state";

export default class MerchantsBloc extends Bloc<MerchantsEvent, MerchantsState> {
  merchantRepository: MerchantRepository;
  merchants: Array<Merchant> = [];

  constructor(merchantRepository: MerchantRepository) {
    super(new MerchantsLoading());
    this.merchantRepository = merchantRepository;
  }

  async *mapEventToState(event: MerchantsEvent) {
    if (event instanceof LoadMerchants) {
      yield* this.onLoadMerchants(event);
    } else if (event instanceof AddMerchant) {
      yield* this.onAddMerchant(event);
    } else if (event instanceof EditMerchant) {
      yield* this.onEditMerchant(event);
    } else if (event instanceof DeleteMerchant) {
      yield* this.onDeleteMerchant(event);
    }
  }

  async *onLoadMerchants(event: LoadMerchants) {
    try {
      yield new MerchantsLoading();
      var merchantsList: Array<Merchant> = await this.merchantRepository.getData();
      this.merchants = merchantsList;

      yield new MerchantsLoaded(this.merchants);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new MerchantsFailure(e);
      } else {
        yield new MerchantsFailure(e.toString());
      }
    }
  }

  async *onAddMerchant(event: AddMerchant) {
    try {
      yield new MerchantsLoading();
      this.merchants.push(event.merchant);

      yield new MerchantsLoaded(this.merchants);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new MerchantsFailure(e);
      } else {
        yield new MerchantsFailure(e.toString());
      }
    }
  }

  async *onEditMerchant(event: EditMerchant) {
    try {
      yield new MerchantsLoading();
      this.merchants = this.merchants.map((merchant: Merchant) => {
        if (merchant.id == event.merchant.id) {
          merchant = event.merchant;
        }
        return merchant;
      });

      yield new MerchantsLoaded(this.merchants);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new MerchantsFailure(e);
      } else {
        yield new MerchantsFailure(e.toString());
      }
    }
  }

  async *onDeleteMerchant(event: DeleteMerchant) {
    try {
      yield new MerchantsLoading();
      this.merchants = this.merchants.filter((merchant: Merchant) => {
        return merchant.id != event.merchantId;
      });

      yield new MerchantsLoaded(this.merchants);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new MerchantsFailure(e);
      } else {
        yield new MerchantsFailure(e.toString());
      }
    }
  }

  onEvent(event: MerchantsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

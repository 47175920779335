import LocalProduct from "models/local_product"; // Adapter l'import en conséquence

import Config from "config/config";
import axios from "axios";

export default class LocalProductRepository {
  static _localProductsEndpoint: string = "local-products"; // Adapter l'endpoint en conséquence
  static _localProductsDataKey: string = "local_products_data"; // Adapter la clé de données en conséquence

  async getData(): Promise<Array<LocalProduct>> {
    var data: any = await axios.get(
      Config.REST_API_URL + "/" + LocalProductRepository._localProductsEndpoint,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers": "X-Requested-With",
        },
      }
    );

    return this.dataToLocalProducts(data.data.localProducts);
  }

  dataToLocalProducts(data: any): Array<LocalProduct> {
    var localProducts: Array<LocalProduct> = [];
    for (var i = 0; i < data.length; i++) {
      localProducts.push(LocalProduct.fromJson(data[i]));
    }
    return localProducts;
  }
}

import { Bloc, Transition } from "@felangel/bloc";
import Company from "models/company";
import CompanyRepository from "repositories/company_repository";
import {
  CompaniesEvent,
  LoadCompanies,
  AddCompany,
  EditCompany,
  DeleteCompany,
} from "./companies_event";
import {
  CompaniesState,
  CompaniesLoaded,
  CompaniesLoading,
  CompaniesFailure,
} from "./companies_state";

export default class CompaniesBloc extends Bloc<CompaniesEvent, CompaniesState> {
  companyRepository: CompanyRepository;
  companies: Array<Company> = [];

  constructor(companyRepository: CompanyRepository) {
    super(new CompaniesLoading());
    this.companyRepository = companyRepository;
  }

  async *mapEventToState(event: CompaniesEvent) {
    if (event instanceof LoadCompanies) {
      yield* this.onLoadCompanies(event);
    } else if (event instanceof AddCompany) {
      yield* this.onAddCompany(event);
    } else if (event instanceof EditCompany) {
      yield* this.onEditCompany(event);
    } else if (event instanceof DeleteCompany) {
      yield* this.onDeleteCompany(event);
    }
  }

  async *onLoadCompanies(event: LoadCompanies) {
    try {
      yield new CompaniesLoading();
      var companiesList: Array<Company> = await this.companyRepository.getData();
      this.companies = companiesList;

      yield new CompaniesLoaded(this.companies);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CompaniesFailure(e);
      } else {
        yield new CompaniesFailure(e.toString());
      }
    }
  }

  async *onAddCompany(event: AddCompany) {
    try {
      yield new CompaniesLoading();
      this.companies.push(event.company);

      yield new CompaniesLoaded(this.companies);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CompaniesFailure(e);
      } else {
        yield new CompaniesFailure(e.toString());
      }
    }
  }

  async *onEditCompany(event: EditCompany) {
    try {
      yield new CompaniesLoading();
      this.companies = this.companies.map((company: Company) => {
        if (company.id == event.company.id) {
          company = event.company;
        }
        return company;
      });

      yield new CompaniesLoaded(this.companies);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CompaniesFailure(e);
      } else {
        yield new CompaniesFailure(e.toString());
      }
    }
  }

  async *onDeleteCompany(event: DeleteCompany) {
    try {
      yield new CompaniesLoading();
      this.companies = this.companies.filter((company: Company) => {
        return company.id != event.companyId;
      });

      yield new CompaniesLoaded(this.companies);
    } catch (e: any) {
      if (typeof e === "string") {
        yield new CompaniesFailure(e);
      } else {
        yield new CompaniesFailure(e.toString());
      }
    }
  }

  onEvent(event: CompaniesEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("New event = ", event);
  }

  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      console.log("Error = ", error);
  }
}

import { AuthenticationAuthenticated, AuthenticationInitial, AuthenticationState, AuthenticationUnAuthenticated } from "blocs/authentication_bloc/authentication_state";
import { BlocsContext } from "contexts/blocs_context";
import { on } from "events";
import GeneralHelper from "helpers/general_helper";
import BaseScreen from "layouts/base";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "services/authentication";

interface Props extends WithTranslation { }

const ProfilScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);

  const [authenticationBlocState, setAuthenticationBlocState] = useState<AuthenticationState>(
    new AuthenticationInitial()
  );

  const navigate = useNavigate();


  // store all form data
  const [formData, setFormData] = useState<{
    username: string;
    email: string;
    password: string;
    passwordConfirm: string;
  }>({
    username: "",
    email: "",
    password: "",
    passwordConfirm: "",

  });

  const [formDataError, setFormDataError] = useState({
    username: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });

  const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    const subscription = blocsContext.authenticationBloc.listen(
      onAuthenticationBlocAction
    );
    onAuthenticationBlocAction(blocsContext.authenticationBloc.state);

    return () => {
      subscription.unsubscribe();
    };
  }, [blocsContext.authenticationBloc]);

  const onAuthenticationBlocAction = (state: AuthenticationState) => {
    setAuthenticationBlocState(state);
    if (authenticationBlocState instanceof AuthenticationInitial && state instanceof AuthenticationAuthenticated) {
      setFormData({
        ...formData,
        username: state.authenticationData.user.username,
        email: state.authenticationData.user.email,
      });
    }
  }


  if (authenticationBlocState instanceof AuthenticationInitial) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement du profil...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }

  if (authenticationBlocState instanceof AuthenticationUnAuthenticated) {
    navigate("/login");
  }



  return (
    <>
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-5 mt-lg-20">
              <div className="col-12">
                <h3 className="fs-1 fw-800">Mon compte</h3>
                {/* <span className="subTitle">Découvrez la vie locale et ses curiosités</span> */}
              </div>
            </div>

            <div className="row g-5">
              <div className="col-12 col-md-6">

                <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                  <span className="required">Nom d'utilisateur</span>
                </label>

                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  name="username"
                  placeholder="Nom d'utilisateur"
                  value={formData.username}
                  onChange={(e) => {
                    setFormData({ ...formData, username: e.target.value });
                  }}
                />

                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.username}</div>
              </div>

              <div className="col-12 col-md-6">

                <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                  <span className="required">Adresse email</span>
                </label>

                <input
                  type="email"
                  className="form-control form-control-lg form-control-solid"
                  name="email"
                  placeholder="Adresse email"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                />

                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.email}</div>
              </div>

              <div className="col-12 col-md-6">

                <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                  <span className="">Mot de passe (laisser vide pour ne pas le modifier)</span>
                </label>

                <input
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  name="password"
                  placeholder="Mot de passe"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                  }}
                />

                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.password}</div>
              </div>

              <div className="col-12 col-md-6">

                <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                  <span className="">Confirmation de mot de passe</span>
                </label>

                <input
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  name="password_confirm"
                  placeholder="Mot de passe"
                  value={formData.passwordConfirm}
                  onChange={(e) => {
                    setFormData({ ...formData, passwordConfirm: e.target.value });
                  }}
                />

                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{formDataError.passwordConfirm}</div>
              </div>


              <div className="col-12 mt-10 d-flex justify-content-end">

                <button
                  type="button"
                  className={["btn btn-lg btn-primary"].join(" ")}
                  onClick={() => {

                    setLoading(true);

                    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                    // check if username is not empty else set error
                    if (formData.username.trim() == "") {
                      setFormDataError({ ...formDataError, username: "Le nom d'utilisateur ne peut pas être vide" });
                      setLoading(false);
                      return;
                    } else if (emailRegex.test(formData.email) == false) {
                      setFormDataError({ ...formDataError, email: "L'adresse email n'est pas valide" });
                      setLoading(false);
                      return;
                    } else if (formData.password != formData.passwordConfirm) {
                      setFormDataError({ ...formDataError, password: "Les mots de passe ne correspondent pas", passwordConfirm: "Les mots de passe ne correspondent pas" });
                      setLoading(false);
                      return;

                    } else {
                      setFormDataError({ ...formDataError, username: "", email: "", });
                    }



                    AuthenticationService.editMySelft(
                      (authenticationBlocState as AuthenticationAuthenticated).authenticationData.accessToken,
                      formData.username,
                      formData.email,
                      formData.password,
                      formData.passwordConfirm
                    ).then((response) => {
                      setLoading(false);
                      setFormDataError({ ...formDataError, password: "", passwordConfirm: "" });
                      if (response.status == 200) {
                        blocsContext.authenticationBloc.add(new AuthenticationAuthenticated(response.data));
                        // generalModal.show("success", "Profil mis à jour", "Votre profil a été mis à jour avec succès");
                        GeneralHelper.successToast("Votre profil a été mis à jour avec succès");

                      } else {
                        // generalModal.show("error", "Erreur", "Une erreur est survenue lors de la mise à jour de votre profil");
                        GeneralHelper.warningToast("Une erreur est survenue lors de la mise à jour de votre profil");

                      }
                    }

                    ).catch((error) => {
                      GeneralHelper.warningToast("Une erreur est survenue lors de la mise à jour de votre profil");
                      setLoading(false);
                      setFormDataError({ ...formDataError, password: "", passwordConfirm: "" });

                    });

                    // check if email is valid else set error
                    // if (formData.email.trim() == "") {
                    //   setFormDataError({ ...formDataError, email: "L'adresse email ne peut pas être vide" });
                    //   return;
                    // } else {

                  }}
                >

                  {!loading && (
                    <>
                      Sauvegarder
                      <i className="ki-duotone ki-arrow-right fs-3 ms-1 me-0">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </>
                  )}

                  {loading && (
                    <span className="indicator-progress">
                      Chargement...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}

                </button>
              </div>


            </div>
          </div>
        </div>
      </BaseScreen >
    </>
  );
};

export default withTranslation("common")(ProfilScreen);

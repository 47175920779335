import { BlocBuilder } from "@felangel/react-bloc";
import { MunicipalitiesLoaded, MunicipalitiesLoading, MunicipalitiesState } from "blocs/municipalities_bloc/municipalities_state";
import { ServiceCategoriesLoaded, ServiceCategoriesLoading, ServiceCategoriesState } from "blocs/service_categories_bloc/service_categories_state";
import { LoadServices } from "blocs/services_bloc/services_event";
import { ServicesFailure, ServicesLoaded, ServicesLoading, ServicesState } from "blocs/services_bloc/services_state";
import ArchiveFilter from "components/data_components/archive_filter";
import ServiceCard from "components/data_components/service_card";
import { SortTypes, getSortType } from "constants/constants";
import { BlocsContext } from "contexts/blocs_context";
import useQuery from "helpers/use_query";
import BaseScreen from "layouts/base";
import GenericFilters from "models/filters/generic_filters";
import Service from "models/service";
import React, { useState, useContext, useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

interface Props extends WithTranslation { }

const EchangeEntraideScreen: React.FC<Props> = ({ t }) => {
  const blocsContext = useContext(BlocsContext);
  let query = useQuery();
  const navigate = useNavigate();

  const municipalityIdParams = query.get("municipalityId");
  const categoryIdParams = query.get("categoryId");
  const sortParams = query.get("sort");

  const [servicesFilters, setGenericFilters] = useState<GenericFilters>(
    new GenericFilters({
      municipalityId: municipalityIdParams != undefined ? parseInt(municipalityIdParams) : undefined,
      categories: categoryIdParams != undefined ? [parseInt(categoryIdParams)] : undefined,
      sort: sortParams != undefined ? getSortType(sortParams) : SortTypes.DATE_DESC,
    })
  );

  const [serviceCategoriesState, setServiceCategoriesState] = useState<ServiceCategoriesState>(new ServiceCategoriesLoading());
  const [servicesState, setServicesState] = useState<ServicesState>(new ServicesLoading());

  const [municipalitiesState, setMunicipalitiesState] = useState<MunicipalitiesState>(new MunicipalitiesLoading());

  const params = useParams<{ id: string }>();

  useEffect(() => {
    setServiceCategoriesState(blocsContext.serviceCategoriesBloc.state);
    const serviceCategoriesSubscription = blocsContext.serviceCategoriesBloc.listen(setServiceCategoriesState);

    setServicesState(blocsContext.servicesBloc.state);
    const servicesSubscription = blocsContext.servicesBloc.listen(setServicesState);

    setMunicipalitiesState(blocsContext.municipalitiesBloc.state);
    const municipalitiesSubscription = blocsContext.municipalitiesBloc.listen(setMunicipalitiesState);

    return () => {
      serviceCategoriesSubscription.unsubscribe();
      servicesSubscription.unsubscribe();
      municipalitiesSubscription.unsubscribe();
    };
  });

  useEffect(() => {
    let newFilters = servicesFilters.clone();

    if (categoryIdParams != undefined) {
      newFilters.categories = [parseInt(categoryIdParams)];
    }
    if (municipalityIdParams != undefined) {
      newFilters.municipalityId = parseInt(municipalityIdParams);
    }
    if (sortParams != undefined) {
      newFilters.sort = getSortType(sortParams);
    }

    setGenericFilters(newFilters);
  }, [categoryIdParams, municipalityIdParams, sortParams]);

  const setUrlParams = (filters: GenericFilters) => {
    let newParams = {};
    if (filters.categories != undefined && filters.categories.length != 0) {
      newParams = { ...newParams, categoryId: filters.categories[0].toString() };
    }
    if (filters.municipalityId != undefined) {
      newParams = { ...newParams, municipalityId: filters.municipalityId?.toString() };
    }
    if (filters.sort != undefined) {
      newParams = { ...newParams, sort: filters.sort?.toString() };
    }
    navigate(
      {
        pathname: undefined,
        search: new URLSearchParams(newParams).toString(),
      },
      { replace: true }
    );
  };

  // const service: Service | undefined = undefined;

  if (servicesState instanceof ServicesLoading) {
    return (
      <BaseScreen>
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      </BaseScreen>
    );
  }
  if (servicesState instanceof ServicesFailure) {
    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl justify-content-center align-items-center">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div>Une erreur inconnue s'est produite.</div>
            <div
              className="btn btn-primary mt-6" onClick={() => blocsContext.servicesBloc.add(new LoadServices())}            >
              Recharger
            </div>
          </div>

        </div>
      </BaseScreen>
    );
  }

  if (servicesState instanceof ServicesLoaded) {
    const services: Service[] = servicesFilters.filter(servicesState.services).filter((service) => service.isExpired() == false);

    return (
      <BaseScreen>
        <div className="d-flex flex-column-fluid align-items-start container-xxl">
          <div className="content flex-row-fluid">
            <div className="d-flex flex-stack flex-wrap mb-6 mt-5 mt-lg-20">
              <div>
                <h3 className="fs-1 fw-800">Echange et entraide</h3>
                <span className="subTitle">Lieu d'entraide et d'échange de services</span>
              </div>
            </div>

            <div className="row gy-0 gx-5 row d-flex justify-content-start mb-6 mt-10">
              <div className="col-12">
                <div className="TitleWithAllBlockArchive">
                  <h3 className="fs-2  fw-700">Par catégorie</h3>
                </div>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5">
              <div className="categoriesList">
                {/* <Link to={{ pathname: "/echange-entraide", search: new URLSearchParams({}).toString() }} className={["singleCategory", (servicesFilters.categories?.length == 0 ? "selected" : "")].join(" ")}>Tout</Link>

                {serviceCategoriesState instanceof ServiceCategoriesLoaded && serviceCategoriesState.categories.map((serviceCategory) => (
                  <Link to={{ pathname: "/echange-entraide", search: new URLSearchParams({ "categoryId": serviceCategory.id.toString(), "municipalityId": servicesFilters.municipalityId != undefined ? servicesFilters.municipalityId?.toString() : "" }).toString() }} className={["singleCategory", (servicesFilters.categories != undefined && servicesFilters.categories?.length != 0 && servicesFilters.categories[0] == serviceCategory.id ? "selected" : "")].join(" ")}>{serviceCategory.name}</Link>
                ))} */}

                <div
                  onClick={() => {
                    var newFilters = servicesFilters.clone();
                    newFilters.categories = [];
                    setGenericFilters(newFilters);
                    setUrlParams(newFilters);
                  }}
                  className={["singleCategory", servicesFilters.categories?.length == 0 ? "selected" : ""].join(" ")}
                >
                  Tout
                </div>

                {serviceCategoriesState instanceof ServiceCategoriesLoaded &&
                  serviceCategoriesState.categories.map((serviceCategory) => (
                    <div
                      key={serviceCategory.id}
                      onClick={() => {
                        var newFilters = servicesFilters.clone();
                        newFilters.categories = [serviceCategory.id];
                        setGenericFilters(newFilters);
                        setUrlParams(newFilters);
                      }}
                      className={[
                        "singleCategory",
                        servicesFilters.categories != undefined && servicesFilters.categories?.length != 0 && servicesFilters.categories[0] == serviceCategory.id ? "selected" : "",
                      ].join(" ")}
                    >
                      {serviceCategory.name}
                    </div>
                  ))}
              </div>
            </div>

            <div className="d-flex flex-stack flex-wrap mb-4 mb-xl-6 mt-10 mt-xl-20">
              <div>
                <h3 className="fs-2 fw-800">Toutes les annonces</h3>
                <span className="subTitle">{services.length} annonces trouvées</span>
              </div>

              <div className="d-flex flex-row align-items-center">
                {servicesFilters.municipalityId != undefined && municipalitiesState instanceof MunicipalitiesLoaded && (
                  <div className="badge badge-secondary fw-bolder me-2 activeService">
                    <div
                      className="cursor-pointer me-2"
                      onClick={() => {
                        var newFilters = servicesFilters.clone();
                        newFilters.municipalityId = undefined;
                        setGenericFilters(newFilters);
                        setUrlParams(newFilters);
                      }}
                    >
                      <i className="fa fa-xmark"></i>
                    </div>
                    Commune : {municipalitiesState.municipalities.find((municipality) => municipality.id == servicesFilters.municipalityId)?.name}
                  </div>
                )}

                <ArchiveFilter
                  filters={servicesFilters}
                  onSetFilters={(filters: GenericFilters) => {
                    let newFilters = servicesFilters.clone();
                    newFilters.sort = filters.sort;
                    newFilters.municipalityId = filters.municipalityId;
                    setGenericFilters(newFilters);
                    setUrlParams(newFilters);
                  }}
                  dataType="Service"
                />
              </div>
            </div>

            {services.length == 0 && (
              <div className="card-px text-center py-20 my-20">
                <h2 className="fs-2x fw-bold mb-8">Aucun élément trouvé</h2>
                <p className="text-gray-400 fs-4 fw-semibold mb-10">Essayez d'ajuster les filtres pour trouver ce que vous cherchez</p>
                {/* <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add Customer</a> */}
              </div>
            )}
            {services.length != 0 && (
              <div className="row g-3 mt-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                {services.map((service) => (
                  <ServiceCard key={service.id} service={service} />
                ))}
              </div>
            )}
          </div>
        </div>
      </BaseScreen>
    );
  }
  return <></>;

  // if(service)
};

export default withTranslation("common")(EchangeEntraideScreen);

import Event from "models/event";

export abstract class EventsState {}

// export class EventsInitial extends EventsState {}

export class EventsLoading extends EventsState {}

export class EventsLoaded extends EventsState {
  events: Array<Event>;
  constructor(events: Array<Event>) {
    super();
    this.events = events;
  }

  public toString = (): string => {
    return `EventsLoaded ${this.events.length} events`;
  };
}

export class EventsFailure extends EventsState {
  errorMessage: string;

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  public toString = (): string => {
    return `EventsLoadingFailed { errorMessage: ${this.errorMessage} }`;
  };
}
